import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, maxBy, range, size } from 'lodash';
import Papa from 'papaparse';

import { FETCHING_STATUSES } from 'constants/globals';
import * as apiV6 from 'lib/apiV6';
import { camelKeysRecursive } from 'lib/object';
import { getErrorHandler } from 'slices/app';

export const ROWS_COUNT = 7;
const SLICE_NAME = 'dataEnrichment';

export type DataEnrichmentState = {
  columnsCount: number;
  fieldTypes: Array<string>;
  imports: {
    data: Array<AH$DEImport>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  invalidFields: Map<string, string>;
  tableData: Array<Array<string>>;
};

export const receiveImports = createAsyncThunk<Array<AH$DEImport>>(
  `${SLICE_NAME}/receiveImports`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.getDeImports();

      return payload.map(camelKeysRecursive) as Array<AH$DEImport>;
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const exportEnrichedProfiles = createAsyncThunk(
  `${SLICE_NAME}/exportEnrichedProfiles`,
  async ({ id, params }: { id: number; params: AH$DownloadFileParams }, { rejectWithValue }) => {
    try {
      await apiV6.exportEnrichedProfiles(id, params);
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const parseCSVFile = createAsyncThunk(
  `${SLICE_NAME}/parseCSVFile`,
  async (
    {
      file,
      onParseError,
      onQuotaExceeded,
      quota,
    }: {
      file: File;
      onParseError: () => void;
      onQuotaExceeded: () => void;
      quota: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { columnsCount, tableData, fieldTypes } = await new Promise((resolve) => {
        Papa.parse<Array<string>>(file, {
          skipEmptyLines: 'greedy',
          complete: async (results) => {
            if (size(get(results, 'errors')) && size(get(results, 'data[0]')) > 2) {
              onParseError();
            } else if (size(get(results, 'data')) > quota) {
              onQuotaExceeded();
            } else {
              const columnsCount = get(
                maxBy(results.data, (row) => row.length),
                'length',
                0
              );
              const rowsDiff = ROWS_COUNT - size(results.data);
              let tableData;

              if (rowsDiff > 0) {
                tableData = [...results.data, ...(range(rowsDiff) as Array<any>).fill([])];
              } else {
                tableData = [...results.data.slice(0, ROWS_COUNT)];
              }
              const fieldTypes = Array(columnsCount).fill('ignore');

              resolve({ columnsCount, tableData, fieldTypes });
            }
          },
        });
      });

      return { columnsCount, tableData, fieldTypes };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const dataEnrichmentInitialState: DataEnrichmentState = {
  imports: {
    data: [],
    status: FETCHING_STATUSES.SUCCESS,
  },
  columnsCount: 0,
  tableData: (range(ROWS_COUNT) as Array<any>).fill([]),
  fieldTypes: [],
  invalidFields: new Map(),
};

export const dataEnrichmentSlice = createSlice({
  name: SLICE_NAME,
  initialState: dataEnrichmentInitialState,
  reducers: {
    storeInvalidFields: (state, action: PayloadAction<Map<string, string>>) => {
      state.invalidFields = new Map(action.payload);
    },
    changeFieldType: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const fieldTypes = [...state.fieldTypes];

      fieldTypes.splice(action.payload.index, 1, action.payload.value);

      state.fieldTypes = fieldTypes;
    },
    resetTableData: (state) => {
      state.tableData = (range(ROWS_COUNT) as Array<any>).fill([]);
      state.fieldTypes = [];
      state.columnsCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveImports.pending, (state) => {
      state.imports.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(receiveImports.fulfilled, (state, action) => {
      state.imports.data = action.payload;
      state.imports.status = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(receiveImports.rejected, (state) => {
      state.imports.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(parseCSVFile.fulfilled, (state, action) => {
      state.columnsCount = action.payload.columnsCount;
      state.tableData = action.payload.tableData;
      state.fieldTypes = action.payload.fieldTypes;
    });
  },
});
export const { storeInvalidFields, changeFieldType, resetTableData } = dataEnrichmentSlice.actions;
export const dataEnrichment = dataEnrichmentSlice.reducer;
