import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import thematize from 'lib/thematize';
import { whiteLabelName } from 'lib/whitelabel';
import styles from './Logo.scss';

const theme = thematize(styles);

interface Props {
  containerClassName: string;
  full: boolean;
  initial: boolean;
  isLink: boolean;
  link?: string;
  logoClassName?: string;
}

const Logo = ({
  initial,
  isLink,
  full,
  containerClassName,
  logoClassName,
  link = '/',
}: Props): ReactElement => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const isNewYear =
    (currentMonth === 11 && currentDay >= 20) || (currentMonth === 0 && currentDay <= 10);

  return (
    <div
      className={`${theme('container', {
        initial,
      })} ${theme(`container_${whiteLabelName}`)} ${containerClassName}`}
    >
      {isLink ? (
        <Link
          to={link}
          className={`${theme(`icon_${whiteLabelName}`, {
            full,
            festive: isNewYear,
          })} ${logoClassName}`}
        />
      ) : (
        <span
          className={`${theme(`icon_${whiteLabelName}`, {
            full,
            festive: isNewYear,
          })} ${logoClassName}`}
        />
      )}
    </div>
  );
};

Logo.defaultProps = {
  initial: false,
  isLink: true,
  full: false,
  containerClassName: '',
};

export default Logo;
