import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from 'lib/api';
import { combineOrdersWithChosenProfile } from 'lib/utils';
import { State } from 'stores/ReduxStore';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'serviceOrders';

export type ServiceOrdersState = {
  orders: AH$ProfileServiceOrders['ordersById'];
};

export const serviceOrdersInitialState: ServiceOrdersState = {
  orders: new Map(),
};

export const receiveOrder = createAsyncThunk(
  `${SLICE_NAME}/receiveOrder`,
  async (
    {
      orderId,
      status,
      onSuccess,
    }: {
      onSuccess: (orders: AH$ProfileServiceOrders['ordersById']) => void;
      orderId: number;
      status: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    const onResolve = ({
      payload,
    }: {
      payload: API$ProfileServiceOrder;
    }): Map<number, AH$ProfileServiceOrder> => {
      const { orders } = (getState() as State).serviceOrders;
      const transformedOrders = combineOrdersWithChosenProfile(payload, orders);

      onSuccess(transformedOrders);
      return transformedOrders;
    };

    try {
      if (status) {
        const { payload } = await api.changeOrderStatus(orderId, status);

        return onResolve({ payload });
      }
      const { payload } = await api.serviceOrderGet(orderId);

      return onResolve({ payload });
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const receiveOrders = createAsyncThunk<Map<number, AH$ProfileServiceOrder>>(
  `${SLICE_NAME}/receiveOrders`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.serviceOrdersGet();

      return new Map(payload.map((order) => [order.id, order]));
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

const serviceOrdersSlice = createSlice({
  name: SLICE_NAME,
  initialState: serviceOrdersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(receiveOrder.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(receiveOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
  },
});

export const serviceOrders = serviceOrdersSlice.reducer;
