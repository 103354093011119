import { useEffect, useRef } from 'react';

function usePrevious<V>(value: V): V | undefined {
  const ref: { current: V | undefined } = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
