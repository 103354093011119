import {
  capitalize,
  find,
  flatten,
  flow,
  get,
  groupBy,
  head,
  isEqual,
  last,
  map,
  mapValues,
  omit,
  omitBy,
  sortBy,
  uniqBy,
  values,
} from 'lodash';

import { AH$RouterLocation } from 'types';
import { createLocation } from 'lib/url';
import { FiltersState, FiltersState as State } from 'slices/filters';
import { Config } from './config';

/**
 * Преобразует URL
 * в объект с «выбранными» и «исключёнными» фасетами
 *
 * @exemple
 * "-contacts[0]:email&-contacts[0]:phone&contacts[0]:skype"
 * {
 *  excludedFilters: { contacts: Set("email", "phone") },
 *  checkedFilters: { contacts: Set("skype")
 * }
 */
export const compileFromUrlFormat = (
  location: AH$RouterLocation | Location = window.location
): {
  checkedFilters: AH$Filters;
  excludedFilters: AH$Filters;
  commentDays?: State['commentDays'];
  extViewed?: State['extViewed'];
  mltrDays?: State['mltrDays'];
  viewsTransformer?: State['viewsTransformer'];
} => {
  const {
    profilesQuery: { f = [] },
  } = createLocation(location as Location);
  const checkedFilters: Record<string, Set<string>> = {};
  const excludedFilters: Record<string, Set<string>> = {};
  let viewsTransformer;
  let extViewed;
  let mltrDays;
  let commentDays;

  flatten([f]).forEach((param) => {
    const [, flag, rawName, , rawValue] = /^(-)?([a-zA-Z]+)\[(\d+)\]:(.+)$/.exec(param) || [];
    const decodedValue = decodeURIComponent(rawValue);
    const name = rawName === 'smartFolderHits' ? 'shown' : rawName;
    const value = rawName === 'smartFolderHits' ? 'folder' : decodedValue;

    if (name) {
      if (!checkedFilters[name as keyof typeof checkedFilters]) {
        (checkedFilters as Record<string, any>)[name] = new Set<string>();
      }
      checkedFilters[name as keyof typeof checkedFilters].add(value);

      if (!excludedFilters[name as keyof typeof excludedFilters]) {
        (excludedFilters as Record<string, any>)[name] = new Set<string>();
      }
      if (flag === '-') {
        excludedFilters[name as keyof typeof excludedFilters].add(value);
      }
      if (['views', 'shown', 'folders', 'contacted'].includes(name)) {
        const [by, countString] = value.split(',');

        viewsTransformer = {
          value: name,
          by,
          count: countString ? parseInt(countString, 10) : 30,
        };
      }
      if (name === 'extViewed') {
        const [by, countString] = value.split(',');

        extViewed = { value: 'extViewed', by, count: countString ? parseInt(countString, 10) : 30 };
      }
      if (name === 'mltrDays') {
        const [countString, type] = value.split(',');

        mltrDays = { value: 'mltrDays', count: countString ? parseInt(countString, 10) : 30, type };
      }
      if (name === 'commentDays') {
        const [countString] = value.split(',');

        commentDays = { value: 'commentDays', count: countString ? parseInt(countString, 10) : 30 };
      }
    }
  });

  if (checkedFilters['companySize']) {
    const filtersArray = Array.from(checkedFilters['companySize']);
    const prefix = filtersArray[0].split(',')[0];
    const filtersSet = new Set(filtersArray.map((val) => val.split(',')[1]));

    filtersSet.add(prefix);
    checkedFilters['companySize'] = new Set(filtersSet);
  } else {
    checkedFilters['companySize'] = new Set(['curr']);
  }

  if (excludedFilters['companySize']) {
    const filtersArray = Array.from(excludedFilters['companySize']);
    const filtersSet = new Set(filtersArray.map((val) => val.split(',')[1]));

    excludedFilters['companySize'] = new Set(filtersSet);
  }

  return {
    checkedFilters,
    excludedFilters,
    viewsTransformer,
    extViewed,
    mltrDays,
    commentDays,
  };
};

/**
 * Получает вторым параметром пару [<facetId>, <urlId>]
 * и добавляет <urlId> в Set объекта из первого парамета
 * по <facetId>
 */
export const groupByPairOfKeyVal = (
  object: AH$Filters,
  [key, urlKey]: [string, string]
): AH$Filters => {
  const prevValuesByKey = Array.from(object[key as keyof typeof object] || []);

  if (get(object[urlKey as keyof typeof object], 'size')) {
    return {
      ...omit(object, urlKey),
      [key]: new Set([...prevValuesByKey, urlKey]),
    };
  }
  return omit(object, urlKey);
};

/**
 * Последовательно выполняет пробразования от структуры URL
 * к структуре для store
 */
export const convertFromUrl = (
  keys: Array<[string, string]>,
  location: AH$RouterLocation | Location = window.location
): {
  checkedFilters: AH$Filters;
  excludedFilters: AH$Filters;
  commentDays?: State['commentDays'];
  extViewed?: State['extViewed'];
  mltrDays?: State['mltrDays'];
  viewsTransformer?: State['viewsTransformer'];
} =>
  flow(
    compileFromUrlFormat,
    ({ viewsTransformer, extViewed, mltrDays, commentDays, ...objects }) => ({
      viewsTransformer,
      extViewed,
      mltrDays,
      commentDays,
      ...mapValues(objects, (object) => keys.reduce(groupByPairOfKeyVal, object)),
    })
  )(location);

/**
 * Преобразует фасет { <facetId>: Set(<values>) }
 * в двумерный массив [ [<facetId>, <value1>], [<facetId>, <value2>], ... ]
 */
export const toPairOfKeyVal = (filters: AH$Filters): Array<[string, string]> =>
  flatten(
    map(filters, (set, key) =>
      Array.from(set as Set<string>).map((val) => [key, val] as [string, string])
    )
  );

/**
 * Помечает исключённые пары [<facetId>, <value1>]
 * @return [[<facetId>, <value>, <boolean>], [<facetId>, <value>, <boolean>]]
 */
export const markExcluded = (
  filters: Array<[string, string]>,
  excludedFilters: AH$Filters
): Array<[string, any, boolean]> =>
  filters.map(([key, val]) => [
    key,
    val,
    (excludedFilters[key as keyof typeof excludedFilters] || new Set()).has(val),
  ]);

/**
 * Получает вторы параметром пару [<facetId>, <urlId>]
 * и меняет на <urlId> первый элемент каждого из массивов первого парамета
 * по <facetId>
 */
export const ungroupByPairOfKeyVal = (
  filters: Array<[string, any, boolean]>,
  mapper: [string, string]
): Array<[string, any, boolean]> =>
  filters.map(([key, val, ...rest]) =>
    isEqual([key, val], mapper)
      ? ([val, true, ...rest] as [string, any, boolean])
      : ([key, val, ...rest] as [string, any, boolean])
  );

// Логика для языка уникальная. Написать более общую кастомную логику, если еще появятся такие кейсы
const markLanguageFilterQueryIndex = (
  filters: Array<[string, any, boolean]>
): Array<[string, any, boolean, number]> => {
  let queryIndex = 0;
  const filtersMap: Record<string, number> = {};

  return filters.map(([key, val, excluded], i) => {
    const isChild = val.includes('-');
    const language = isChild ? val.split('-')[0] : val.toLowerCase();
    const index = filtersMap[language];

    if (index === undefined) {
      if (i > 0) {
        queryIndex += 1;
      }
      filtersMap[language] = queryIndex;

      return [key, val, excluded, queryIndex];
    }

    return [key, val, excluded, index];
  });
};

/**
 * Добавляет index указывающий по какому опреатору нужно объединять запросы при разборе URL
 * отдельно для каждого фасета: общий индекс = OR, разный = AND
 */
export const markQueryIndex = (
  filters: Array<[string, any, boolean]>,
  isAndBetween: (faceId: string) => boolean
): Array<[string, any, boolean, number]> =>
  flatten(
    values(
      mapValues(groupBy(sortBy(filters, last), head), (filters, facetId) => {
        if (facetId === 'language') return markLanguageFilterQueryIndex(filters);

        const andBetween = isAndBetween(facetId);

        let queryIndex = 0;

        return filters.map(([key, val, excluded], i) => {
          queryIndex += +((andBetween || excluded) && !!i);
          return [key, val, excluded, queryIndex];
        });
      })
    )
  ) as Array<[string, any, boolean, number]>;

export const setValForHasComment = (
  filters: Array<[string, any, boolean, number]>
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) =>
    key === 'hasComment'
      ? ([key, false, ...rest] as [string, any, boolean, number])
      : ([key, val, ...rest] as [string, any, boolean, number])
  );

export const setValForViewsTransformer = (
  filters: Array<[string, any, boolean, number]>,
  viewsTransformer: State['viewsTransformer'],
  folderId?: number | string
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) => {
    const isSmartFolders = key === 'shown' && viewsTransformer.by === 'folder';
    const by = (() => {
      if (key === 'folders') return viewsTransformer.by;
      if (isSmartFolders) return folderId;
      return [viewsTransformer.by, viewsTransformer.count];
    })();

    return ['views', 'shown', 'folders', 'contacted'].includes(key)
      ? ([isSmartFolders ? 'smartFolderHits' : key, by, ...rest] as [string, any, boolean, number])
      : ([key, val, ...rest] as [string, any, boolean, number]);
  });

export const setValForExtViewed = (
  filters: Array<[string, any, boolean, number]>,
  extViewed: State['extViewed']
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) =>
    key === 'extViewed'
      ? ([key, [extViewed.by, extViewed.count], ...rest] as [string, any, boolean, number])
      : ([key, val, ...rest] as [string, any, boolean, number])
  );

export const setValForMLTRDays = (
  filters: Array<[string, any, boolean, number]>,
  mltrDays: State['mltrDays']
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) =>
    key === 'mltrDays'
      ? ([key, [mltrDays.count, mltrDays.type], ...rest] as [string, any, boolean, number])
      : ([key, val, ...rest] as [string, any, boolean, number])
  );

export const setValForCommentsDay = (
  filters: Array<[string, any, boolean, number]>,
  commentDays: State['commentDays']
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) =>
    key === 'commentDays'
      ? ([key, [commentDays.count], ...rest] as [string, any, boolean, number])
      : ([key, val, ...rest] as [string, any, boolean, number])
  );

export const setValForMoreLikelyToMove = (
  filters: Array<[string, any, boolean, number]>
): Array<[string, any, boolean, number]> =>
  filters.map(([key, val, ...rest]) => [key, val, ...rest] as [string, any, boolean, number]);

export const setValForCompanySize = (
  filters: Array<[string, any, boolean, number]>,
  allCheckedFilters: AH$Filters
): Array<[string, any, boolean, number]> => {
  const checkedFilters = allCheckedFilters.companySize || new Set();

  let prefix: string;

  if (
    checkedFilters.has('any') ||
    ['any', 'curr', 'prev'].every((val) => !checkedFilters.has(val)) ||
    ['curr', 'prev'].every((val) => checkedFilters.has(val))
  ) {
    prefix = 'any';
  } else if (checkedFilters.has('prev')) {
    prefix = 'prev';
  } else {
    prefix = 'curr';
  }

  return filters
    .filter(([key, val]) => key !== 'companySize' || !['any', 'curr', 'prev'].includes(val))
    .map(([key, val, ...rest]) =>
      key === 'companySize'
        ? ([key, `${prefix},${encodeURIComponent(val)}`, ...rest] as [string, any, boolean, number])
        : ([key, val, ...rest] as [string, any, boolean, number])
    );
};

/**
 * Преобразует фасет в URL-формат
 * */
export const compileToUrlFormat = (filters: Array<[string, any, boolean, number]>): Array<string> =>
  filters.map(
    ([key, val, excluded, index]) =>
      `${excluded ? '-' : ''}${key}[${index}]:${flatten([val]).join(',')}`
  );

/**
 * Последовательно выполняет пробразования от структуры store
 * к структуре для генерации URL
 */
export const convertToUrl = ({
  valIdsForUrlFacetIds,
  checkedFilters,
  excludedFilters,
  isAndBetween,
  viewsTransformer,
  extViewed,
  smartFolderId,
  mltrDays,
  commentDays,
}: {
  checkedFilters: AH$Filters;
  commentDays: State['commentDays'];
  excludedFilters: AH$Filters;
  extViewed: State['extViewed'];
  isAndBetween: (faceId: string) => boolean;
  mltrDays: State['mltrDays'];
  valIdsForUrlFacetIds: Array<[string, string]>;
  viewsTransformer: State['viewsTransformer'];
  smartFolderId?: number | string;
}): Array<string> =>
  compileToUrlFormat(
    setValForCompanySize(
      setValForMoreLikelyToMove(
        setValForCommentsDay(
          setValForMLTRDays(
            setValForExtViewed(
              setValForViewsTransformer(
                setValForHasComment(
                  markQueryIndex(
                    valIdsForUrlFacetIds.reduce(
                      ungroupByPairOfKeyVal,
                      markExcluded(
                        toPairOfKeyVal(checkedFilters) as Array<[string, string]>,
                        excludedFilters
                      )
                    ),
                    isAndBetween
                  )
                ),
                viewsTransformer,
                smartFolderId
              ),
              extViewed
            ),
            mltrDays
          ),
          commentDays
        )
      ),
      checkedFilters
    )
  );

/**
 * Преобразует строку вида <start>-<end>
 * в массив [<start>, <end>]
 */
export const rangeFromUrl = (range: string): any => {
  const [, min, max] = /(\*|\d+)-(\*|\d+)/.exec(range) || [];

  if (min || max) {
    return [parseInt(min, 10) || 0, parseInt(max, 10) || Infinity];
  }
  return range;
};

/**
 * Преобразует массив [<start>, <end>]
 * в строку вида <start>-<end>
 */
export const rangeToUrl = ([low, high]: Array<number>): string =>
  `${low === 0 ? '*' : low}-${high === Infinity ? '*' : high}`;

const putInAnyNo = (filters: Array<AH$FilterValue>): Array<AH$FilterValue> => {
  const isNotAnyAndNo = ({ id }: { id: string }) => !['any', 'no'].includes(id);

  if (!filters.every(isNotAnyAndNo)) {
    return [
      {
        id: 'any',
        count: get(find(filters, { id: 'any' }), 'count'),
        children: filters.filter(isNotAnyAndNo),
      },
      { id: 'no', count: get(find(filters, { id: 'no' }), 'count'), children: [] },
    ];
  }
  return filters;
};

const isFirstSymbolInUpperCase = ([firstSymbol]: string): boolean =>
  firstSymbol === firstSymbol.toUpperCase();

export const capitalizeNames = (values: Array<AH$FilterValue>): Array<AH$FilterValue> =>
  values.map((filter) => ({
    ...filter,
    name:
      filter.name && isFirstSymbolInUpperCase(filter.name) ? filter.name : capitalize(filter.name),
    children: filter.children ? capitalizeNames(filter.children) : undefined,
  }));

export const transformLoadedFilters = (
  filters: { [key: string]: Array<AH$FilterValue> },
  config: Map<string, AH$ConfigValue> | Config
): State['values'] =>
  mapValues(
    filters,
    flow(
      (values, key) =>
        values.filter(({ id }) => {
          const configType = config.get(key);

          return configType ? configType.isShowedFilter(id) : null;
        }),
      putInAnyNo,
      capitalizeNames
    )
  );

export const isSemichecked = (
  filter: AH$FilterValue,
  checkedFilters: Set<string> = new Set()
): boolean =>
  (filter.children as Array<AH$FilterValue>).some(
    (filter) => checkedFilters.has(filter.id) || isSemichecked(filter, checkedFilters)
  );

export const excludeBlankCompanySizeFilters = (filters: AH$Filters): AH$Filters =>
  omitBy(
    filters,
    (val, key) =>
      key === 'companySize' &&
      !find(Array.from(val as Set<string>), (filter) => !['any', 'curr', 'prev'].includes(filter))
  );

export const getIsChecked = (
  checkedFilters: Set<string>,
  id: string,
  getCustomCheckedIds?: AH$FilterConfig['getCustomCheckedIds']
): boolean => {
  const ids = getCustomCheckedIds ? getCustomCheckedIds(id) : undefined;

  return (ids || [id]).some((id) => checkedFilters.has(id));
};

/*
 * Сохраняет значения фасетов в форме, даже если с бэка вернется пустой массив по фасету
 *  */
export const saveFiltersValues = (
  prevStateValues: FiltersState['values'],
  nextValues: FiltersState['values']
): FiltersState['values'] => {
  const result: FiltersState['values'] = {};
  const copyPrevStateValues = { ...prevStateValues };

  for (const [key, value] of Object.entries(nextValues)) {
    if (copyPrevStateValues[key as keyof AH$Filters]) {
      result[key as keyof AH$Filters] = uniqBy(
        [...(copyPrevStateValues[key as keyof AH$Filters] || []), ...value],
        'id'
      );

      delete copyPrevStateValues[key as keyof AH$Filters];
    } else {
      result[key as keyof AH$Filters] = value;
    }
  }

  return { ...result, ...copyPrevStateValues };
};
