import { FETCHING_STATUSES } from 'constants/globals';

// правила найминга:
// префикс - fetchingStatuses - для определения в слайсе
// экшен - e.g. messageCreated, folderDeleted - название самого экшена
// итог - e.g. fetchingStatuses/messageCreated, fetchingStatuses/folderDeleted
export enum FetchingStatusesActionTypes {
  aiSearchId = 'fetchingStatuses/aiSearchId',
  companyCreated = 'fetchingStatuses/companyCreated',
  companyDeleted = 'fetchingStatuses/companyDeleted',
  creditsAdded = 'fetchingStatuses/creditsAdded',
  employeeSaved = 'fetchingStatuses/employeeSaved',
  movedToCompany = 'fetchingStatuses/movedToCompany',
  hmSettingsSaved = 'fetchingStatuses/hmSettingsSaved',
  bannedLinkAdded = 'fetchingStatuses/bannedLinkAdded',
  bannedLinkDeleted = 'fetchingStatuses/bannedLinkDeleted',
  extensionNotificationSaved = 'fetchingStatuses/extensionNotificationSaved',
  metaQuerySaved = 'fetchingStatuses/metaQuerySaved',
  passwordRecovered = 'fetchingStatuses/passwordRecovered',
  passwordReset = 'fetchingStatuses/passwordReset',
  customSettingsSaved = 'fetchingStatuses/customSettingsSaved',
  sendProfileReport = 'fetchingStatuses/sendProfileReport',
  systemNotificationSaved = 'fetchingStatuses/systemNotificationSaved',
  sequenceSaved = 'fetchingStatuses/sequenceSaved',
  sequenceMessagesRelaunch = 'fetchingStatuses/sequenceMessagesRelaunch',
  gptVacancySaved = 'fetchingStatuses/gptVacancySaved',
  gptVacancyDeleted = 'fetchingStatuses/gptVacancyDeleted',
}

export type FetchingStatusesState = {
  readonly [key in FetchingStatusesActionTypes]?: {
    error: any;
    status: $Values<typeof FETCHING_STATUSES>;
  };
};

type FetchingStatusesAction = {
  payload: $Values<typeof FETCHING_STATUSES>;
  type: FetchingStatusesActionTypes;
  error?: any;
};

export const fetchingStatuses = (
  state: FetchingStatusesState = {},
  action: FetchingStatusesAction = {} as FetchingStatusesAction
): FetchingStatusesState => {
  if (action.type.startsWith('fetchingStatuses')) {
    return {
      ...state,
      [action.type]: {
        status: action.payload,
        error: action.error,
      },
    };
  }

  return state;
};
