import { FC, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { get } from 'lodash';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import { useUser } from 'hooks';
import useDispatch from 'hooks/useDispatch';
import { getRealtimeCount } from 'slices/filters';
import { changeField } from 'slices/searchForm';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import { KM_TO_MI } from 'components/SearchForm/LocationRanges';
import messages from 'components/TokenAppliedTerm/messages';
import Button from 'components/TokenTerm/LocationRangeButton';
import styles from './TokenAppliedTerm.scss';

const theme = thematize(styles);

type LocationRangeActionProps = {
  field: Map<string, any>;
  queryKey: number;
};

export const LocationRangeAction: FC<LocationRangeActionProps> = ({ queryKey, field }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const user = useUser();
  const format: 'mi' | 'km' = get(user, 'settings.rangeMeasure') || 'km';
  const isLocationRange = field.get('type') === 'locationRange';
  const isLocation = field.get('type') === 'location';
  const locationRange: number = field.get('locationRange');
  const [isLocationRangeOpen, setIsLocationRangeOpen] = useState(false);

  const handleClickDropdownLocationRange = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLocationRangeOpen(!isLocationRangeOpen);
  };

  const handleCloseDropdownLocationRange = () => {
    setIsLocationRangeOpen(false);
  };

  const handleSetLocationRange = (range: number) => {
    const changedField =
      range === -1
        ? field.set('type', 'location').set('locationRange', 0).toJS()
        : field.set('type', 'locationRange').set('locationRange', range).toJS();

    dispatch(
      changeField({
        queryKey,
        excluded: false,
        field: changedField,
        isV2: true,
      })
    );

    dispatch(getRealtimeCount());
  };

  const handleOptionClick = (e: MouseEvent<HTMLElement>, range: number) => {
    e.preventDefault();
    e.stopPropagation();

    handleSetLocationRange(range);
    handleCloseDropdownLocationRange();

    tracking.changeLocationRange(
      tracking.LOCATIONS[window.location.pathname === '/' ? 'home' : 'search']
    );
  };

  return (
    <Dropdown
      disableAnimation
      menuShouldBlockScroll
      sticky
      placement="center"
      onButtonClick={handleClickDropdownLocationRange}
      onFocusLoss={handleCloseDropdownLocationRange}
      bodyClassName={theme('location-range-body')}
      optionsOpened={isLocationRangeOpen}
      button={
        <div className={theme('location-range')}>
          <Tooltip id="locationRange" message={formatMessage(messages.radius)} placement="top">
            <span className={theme('actions__icon-container', { location: false })}>
              <Icon
                className={theme('actions__icon', { active: isLocationRangeOpen })}
                type="location"
              />
            </span>
          </Tooltip>
          {isLocationRange && (
            <Button
              range={locationRange}
              format={formatMessage(messages[format])}
              className={theme('location-range-wrapper')}
            />
          )}
        </div>
      }
    >
      <Dropdown.Option
        optionClassName={theme('location-range-option')}
        onOptionClick={(_, e): void => {
          handleOptionClick(e, -1);
        }}
        style={{ zIndex: 2080, position: 'relative' }}
      >
        {formatMessage(messages.notSelected)}
        {isLocation && <Icon type="check" className={theme('location-range-success')} />}
      </Dropdown.Option>
      {Object.keys(KM_TO_MI).map((range) => {
        const rangeInt = parseInt(range, 10) as keyof typeof KM_TO_MI;
        const value = format === 'mi' ? KM_TO_MI[rangeInt] : rangeInt;

        return (
          <Dropdown.Option
            key={value}
            optionClassName={theme('location-range-option')}
            onOptionClick={(_, e): void => {
              handleOptionClick(e, rangeInt);
            }}
          >
            {`${value} ${formatMessage(messages[format])}`}
            {isLocationRange && locationRange === rangeInt && (
              <Icon type="check" className={theme('location-range-success')} />
            )}
          </Dropdown.Option>
        );
      })}
    </Dropdown>
  );
};
