import { ReactElement } from 'react';

import styles from './Icon.css';

export interface Props {
  kind:
    | 'search'
    | 'info'
    | 'cross'
    | 'check'
    | 'expand'
    | 'logo_full'
    | 'logo_short'
    | 'github-medal'
    | 'stackoverflow-medal'
    | 'ru.stackoverflow-medal'
    | 'kaggle-medal'
    | 'google-medal'
    | 'itunes.apple.com-medal'
    | 'menu'
    | 'ru-medal';
  size: 'xsmall' | 'small' | 'medium' | 'large';
  status: 'default' | 'gold' | 'silver' | 'bronze' | 'regular';
}

const Icon = ({ kind, size, status }: Props): ReactElement => (
  <svg className={styles[size]}>
    <use className={styles[status]} xlinkHref={`#icon-${kind}`} />
  </svg>
);

Icon.defaultProps = {
  status: 'default',
  size: 'small',
};

export default Icon;
