import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import { useSelector } from 'hooks';
import styles from './Modal.scss';

export { default as ModalContainer } from './Container';

const theme = thematize(styles);

const Modal = (): ReactElement => {
  const { show, content, className }: AH$Modal = useSelector(({ app }) => app.modal);

  return <div className={`${theme('wrapper', { show })} ${className}`}>{content}</div>;
};

export default Modal;
