import { debounce } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

export function createBodyResizeObserver(callback: (body: HTMLBodyElement) => void): void {
  const observer = new ResizeObserver(
    debounce((entries) => {
      callback(entries[0].target);
    }, 100)
  );

  observer.observe(document.body);
}
