import { isFullProfile } from 'lib/url';
import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

export enum MESSAGE_EDITOR_TYPES {
  message = 'Message',
  template = 'Template',
  signature = 'Signature',
  notification = 'Notification',
}

export enum FILE_UPLOAD_TYPES {
  click = 'Click',
  dnd = 'DnD',
}

export const getMailingListCategory = (isMass = false): CATEGORIES => {
  if (window.location.pathname.includes('messaging/sequences')) return CATEGORIES.sequences;
  if (isMass || window.location.pathname.includes('messaging')) return CATEGORIES.messaging;
  if (isFullProfile()) return CATEGORIES.fullProfile;

  return CATEGORIES.shortProfile;
};

export const getMailingListLocation = (): LOCATIONS => {
  if (window.location.pathname.includes('folders')) return LOCATIONS.folders;
  if (window.location.pathname.includes('messaging')) return LOCATIONS.messaging;
  if (isFullProfile()) return LOCATIONS.fullProfile;

  return LOCATIONS.search;
};

export const openMailingList = (
  isOpenFromFolder = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(isOpenFromFolder);

  sendEvent('Open Mass Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing: true,
    Access_Type,
  });
};

export const openSequence = (
  isOpenFromFolder = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(isOpenFromFolder);

  sendEvent('Open Sequence', {
    Location,
    Category,
    Access_Type,
  });
};

export const manageMailingList = (
  Is_Mass_Mailing = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Manage Mass Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing: true,
    Access_Type,
  });
};

export const manageSequence = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory();

  sendEvent('Manage Sequence', {
    Location,
    Category,
    Access_Type,
  });
};

export const stopMailingList = (
  Is_Mass_Mailing = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Stop Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const deleteMailingList = (
  Is_Mass_Mailing = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Delete Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const filterMailingListRecipients = (
  Filter_ID: string,
  Messaging_Filter_Location: 'Folder' | 'Personalization'
): void => {
  sendEvent('Filter Mailing List Recipients', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.messaging,
    Is_Mass_Mailing: true,
    Filter_ID,
    Messaging_Filter_Location,
  });
};

export const previewMailingList = (
  Is_Mass_Mailing = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Preview Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const deleteRecipient = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  sendEvent('Delete Recipient', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.messaging,
    Is_Mass_Mailing: true,
    Access_Type,
  });
};

export const startMailingList = (
  Profiles_Count: number,
  Messages_Count: number,
  Is_Mass_Mailing = false,
  Is_Mailing_List_Delayed = false,
  Is_Mailing_List_Changing_Status = false,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent(
    'Start Mailing List',
    {
      Location,
      Category,
      Is_Mass_Mailing,
      Profiles_Count,
      Is_Mailing_List_Delayed,
      Messages_Count,
      Is_Mailing_List_Changing_Status,
      Access_Type,
    },
    ['amplitude']
  );
};

export const sendTestMessage = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory();

  sendEvent('Send Test Message', {
    Location,
    Category,
    Is_Mass_Mailing: false,
    Access_Type,
  });
};

export const collapseMailingList = (): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory();

  sendEvent('Collapse Mailing List', {
    Location,
    Category,
    Is_Mass_Mailing: false,
  });
};

export const expandMailingList = (): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory();

  sendEvent('Expand Mailing List', { Location, Category, Is_Mass_Mailing: false });
};

export const changeMailingListTypeFilter = (): void => {
  sendEvent('Change Mailing List Type Filter', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
  });
};

export const changeMailingListStatusFilter = (): void => {
  sendEvent('Change Mailing List Status Filter', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
  });
};

export const changeTemplateCreatorFilter = (): void => {
  sendEvent('Change Template Creator Filter', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
  });
};

export const createTemplate = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  sendEvent('Create Template', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
    Access_Type,
  });
};

export const editTemplate = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  sendEvent('Edit Template', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
    Access_Type,
  });
};

export const copyTemplate = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  sendEvent('Copy Template', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
    Access_Type,
  });
};

export const deleteTemplate = (Access_Type: AH$Template['accessType'] = 'public'): void => {
  sendEvent('Delete Template', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
    Access_Type,
  });
};

export const saveMessagingSettings = (
  Is_Company_Messaging_Settings_Changed: boolean,
  Is_Personal_Messaging_Settings_Changed: boolean
): void => {
  sendEvent('Save Messaging Settings', {
    Location: LOCATIONS.messaging,
    Category: CATEGORIES.messaging,
    Is_Company_Messaging_Settings_Changed,
    Is_Personal_Messaging_Settings_Changed,
  });
};

export const selectMessageTemplate = (
  Is_Mass_Mailing: boolean,
  Template_Category: string,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Select Message Template', {
    Location,
    Category,
    Is_Mass_Mailing,
    Template_Category,
    Access_Type,
  });
};

export const saveMessageAsTemplate = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Save Message As Template', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const toggleMessageEditorStyling = (
  Message_Editor_Styling_Type: string,
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type?: AH$Template['accessType']
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Toggle Message Editor Style', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Message_Editor_Styling_Type,
    Access_Type,
  });
};

export const insertLinkInMessageEditor = (
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type?: AH$Template['accessType']
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Insert Link In Message Editor', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Access_Type,
  });
};

export const clearMessageEditorFormatting = (
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type?: AH$Template['accessType']
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Clear Message Editor Formatting', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Access_Type,
  });
};

export const insertMessageEditorVariable = (
  Message_Editor_Part: 'Body' | 'Subject',
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Insert Message Editor Variable', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Message_Editor_Part,
    Access_Type,
  });
};

export const createMessageEditorVariable = (
  Message_Editor_Part: 'Body' | 'Subject',
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Create Message Editor Variable', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Message_Editor_Part,
    Access_Type,
  });
};

export const changeRecipientCustomData = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Change Recipient Custom Data', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const addMessageFollowUp = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Add Message Follow Up', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const deleteMessageFollowUp = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Delete Message Follow Up', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const changeMessageFollowUpCondition = (
  Follow_Up_Condition_Parameter: string,
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Change Message Follow Up Condition', {
    Location,
    Category,
    Is_Mass_Mailing,
    Follow_Up_Condition_Parameter,
    Access_Type,
  });
};

export const changeRecipientEmail = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Change Recipient Email', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const addCustomRecipientEmail = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Add Custom Recipient Email', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const openAdvancedSettings = (
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(false);

  sendEvent('Open Advanced Settings', {
    Location,
    Category,
    Message_Editor_Type,
    Is_Mass_Mailing: false,
    Access_Type,
  });
};

export const attachFiles = (
  Is_Mass_Mailing: boolean,
  Attachments_Count: number,
  File_Upload_Type: FILE_UPLOAD_TYPES,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Attach Files To Message', {
    Location,
    Category,
    Is_Mass_Mailing,
    Attachments_Count,
    File_Upload_Type,
    Access_Type,
  });
};

export const removeAttachment = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Remove Message Attachment', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const downloadAttachment = (
  Is_Mass_Mailing: boolean,
  Access_Type: AH$Template['accessType'] = 'public'
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Download Message Attachment', {
    Location,
    Category,
    Is_Mass_Mailing,
    Access_Type,
  });
};

export const addImages = (
  Is_Mass_Mailing: boolean,
  Attachments_Count: number,
  File_Upload_Type: FILE_UPLOAD_TYPES,
  Access_Type?: AH$Template['accessType']
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Add Images To Message', {
    Location,
    Category,
    Is_Mass_Mailing,
    Attachments_Count,
    File_Upload_Type,
    Access_Type,
  });
};

export const resizeImage = (
  Is_Mass_Mailing: boolean,
  Message_Editor_Type: MESSAGE_EDITOR_TYPES,
  Access_Type?: AH$Template['accessType']
): void => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory(Is_Mass_Mailing);

  sendEvent('Resize Image', {
    Location,
    Category,
    Is_Mass_Mailing,
    Message_Editor_Type,
    Access_Type,
  });
};

export const enrollInSequence = (Access_Type: AH$Template['accessType'] = 'public') => {
  const Location = getMailingListLocation();
  const Category = getMailingListCategory();

  sendEvent('Enroll In Sequence', {
    Location,
    Category,
    Access_Type,
  });
};
