import { EditorState } from 'draft-js';
import {
  assign,
  compact,
  differenceWith,
  filter,
  find,
  findLast,
  flatten,
  flow,
  get,
  keyBy,
  keys,
  mapValues,
  merge,
  omit,
  pick,
  uniq,
} from 'lodash';
import moment from 'moment';

import { camelKeysRecursive } from 'lib/object';
import { generateId } from 'lib/utils';
import { getAllImages, updateImages } from './Editor/lib';

export const getDataByPath = (donor: AH$Profile | API$Profile, path: string): Array<string> =>
  compact(
    path
      .split('.')
      .reduce(
        (values, prop) =>
          prop === '*' ? flatten(values) : values.map((value) => get(value, prop)),
        [donor]
      )
      .map((item) => (item || '').toString())
  );

export const toStringBy = <T extends Record<string, any>>(
  items: Array<T>,
  key: string | number
): Array<T & { toString: () => string }> =>
  items.map((item) => assign(item, { toString: () => item[key] }));

export const toCustomData = flow([
  (variables): Array<AH$TransformedMailingVariable> => filter(variables, 'used'),
  (variables): Array<AH$TransformedMailingVariable> =>
    variables.map((variable: AH$TransformedMailingVariable) => ({
      ...variable,
      value: get(variable.data, [0], ''),
    })),
  (variables): Record<string, string> => keyBy(variables, 'name'),
]);

export const mergeCustomData = <T extends Record<string, unknown>>(newData: T, prevData: T): T => ({
  ...newData,
  ...pick(prevData, keys(newData)),
});

const getUtcFromRaw = (rawUtc: string | null): string => {
  if (!rawUtc) {
    return '';
  }

  if (rawUtc[0] === '-') {
    return rawUtc.slice(0, 3);
  }

  return `+${rawUtc.slice(0, 2)}`;
};

export const transformTemplate = (template: API$Template): AH$Template => {
  const timeFrom = moment(template.send_time_from as string, 'hh:mm:ss');
  const timeTo = moment(template.send_time_to as string, 'hh:mm:ss');
  const isFromToTimeValid = timeFrom.isValid() && timeTo.isValid();
  const utc = getUtcFromRaw(template.send_time_utc_offset);

  return {
    ...camelKeysRecursive(
      omit(template, ['send_delay', 'send_time_from', 'send_time_to', 'send_time_utc_offset'])
    ),
    attachments: (template.attachments || []).map((attachment) => ({
      ...camelKeysRecursive(attachment),
      internalId: generateId(),
    })) as Array<AH$MessageAttachment>,
    condition: {
      days: template.send_delay ? template.send_delay.split(' ')[0] : null,
      hours: isFromToTimeValid ? `0${(timeTo.hours() + timeFrom.hours()) / 2}`.slice(-2) : '',
      minutes: isFromToTimeValid ? `0${timeFrom.minutes()}`.slice(-2) : '',
      plusMinus: isFromToTimeValid ? `${(timeTo.hours() - timeFrom.hours()) / 2}` : '0',
      utc,
    },
  };
};

export const extractCustomData = (
  customData: { [key: string]: string },
  mailingVariables: Array<AH$MailingVariable>,
  profile: AH$Profile | API$Profile
): Record<string, AH$TransformedMailingVariable> =>
  mapValues(customData, (value, name) => {
    const variable = find(mailingVariables, ['name', name]) || ({} as AH$MailingVariable);

    return {
      id: variable.id,
      value,
      name,
      used: true,
      data: getDataByPath(profile, variable.path || ''),
    } as AH$TransformedMailingVariable;
  });

export const filterTemplates = <T extends { id: number }, M extends { template: T }>(
  templates: Array<T>,
  messages: Array<M>
): Array<T> =>
  differenceWith(templates, messages, (template, message) => template.id === message.template.id);

export const transformEvents = (
  events: Array<{ timestamp: string; type: 'replied' | 'opened' }>,
  sentAt?: string | null,
  sendAt?: string | null
): Array<{ date: string; type: AH$MessagingEventStatus }> =>
  compact([
    sendAt && !sentAt && { type: 'planned', date: sendAt },
    sentAt && { type: 'sent', date: sentAt },
    ...events.map(({ type, timestamp }) => ({ type, date: timestamp })),
  ]);

interface GetRecipientEventDataReturn {
  message: AH$Message | undefined;
  messageName: string;
  status: AH$MessagingEventStatus;
  updatedAt: string;
}

export function getRecipientEventData(recipient: AH$Recipient): GetRecipientEventDataReturn {
  const { statusChangedAt, status: recipientStatus, messages, mailingList } = recipient;
  const firstMessage = messages[0] as AH$Message | undefined;
  const status =
    firstMessage && firstMessage.sendAt && !firstMessage.sentAt && recipientStatus === 'pending'
      ? 'planned'
      : recipientStatus;
  const message = findLast(messages, ['status', status]) || firstMessage;
  const updatedAt =
    status === 'planned' && firstMessage && firstMessage.sendAt
      ? firstMessage.sendAt
      : statusChangedAt;

  const messageName = message?.subject || mailingList.name;

  return {
    status,
    updatedAt,
    message,
    messageName,
  };
}

export const extractVariables = (
  templates: Array<AH$Template | AH$ExampleTemplate>,
  mailingListVariables: Array<AH$TransformedMailingVariable>,
  messageVariables: AH$MailingVariable[] = []
): Array<AH$MailingVariable> => {
  const templateVariables = uniq(
    templates.reduce(
      (acc, template) =>
        template && template.id !== -1 && (template as AH$Template).customVariables
          ? [...acc, ...(template as Required<AH$Template>).customVariables]
          : acc,
      [] as Array<string>
    )
  );

  const customVariables = mailingListVariables
    .filter(({ id, name }) => !id && !templateVariables.includes(name))
    .map(({ name }) => ({ name, id: 'custom', path: '' }));

  return messageVariables
    .map(({ name }) => ({ name, id: 'custom', path: '' }))
    .concat(
      customVariables,
      templateVariables.map((name) => ({ name, id: 'template', path: '' }))
    );
};

export const transformError = (
  status: AH$MessagingEventStatus,
  errorReason: string
): { reason: string; type: 'error' } | null =>
  status === 'error' ? { type: 'error', reason: errorReason } : null;

export const transformMessages = <
  T extends { attachments: Array<Partial<AH$MessageAttachment>>; body: EditorState }
>(
  messages: Array<T>
): Array<T> =>
  messages.map(({ attachments, body, ...rest }) => {
    const { editorState: newBody, additionalAttachments } = updateImages(body, attachments);
    const images = getAllImages(newBody);
    const newAttachments = [
      ...attachments.map((attachment) => {
        const image = find(images, ['internalId', attachment.internalId]);

        return image ? merge(attachment, image) : attachment;
      }),
      ...additionalAttachments,
    ];

    return {
      ...rest,
      attachments: newAttachments,
      body: newBody,
    } as T;
  });
