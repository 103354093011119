import { ReactElement } from 'react';
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Set } from 'immutable';
import {
  find,
  findIndex,
  flow,
  get,
  has,
  isEmpty,
  isNumber,
  mapValues,
  noop,
  omit,
  size,
  uniq,
  uniqueId,
} from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import * as apiV6 from 'lib/apiV6';
import { sequenceRecipientsGet } from 'lib/apiV6';
import { camelKeys, camelKeysRecursive } from 'lib/object';
import {
  generateId,
  startLoading,
  stopLoading,
  transformExampleTemplates,
  transformRecipientProfile,
} from 'lib/utils';
import { State } from 'stores/ReduxStore';
import { getErrorHandler, handleError } from 'slices/app';
import { getMailingList } from 'slices/folders';
import {
  convertMessagingStatusesFromUrl,
  convertMessagingStatusesToUrl,
  getIncludedStatuses,
  transformFolder,
} from 'components/Folders/lib';
import { RECIPIENT_STATUS } from 'components/Messaging/Message/Events/Activity';
import {
  filterTemplates,
  transformEvents,
  transformTemplate,
} from 'components/Messaging/Message/lib';
import { currentUserReceived } from './app';
import { FetchingStatusesActionTypes } from './fetchingStatuses';
import { createStatusActions } from './utils';

export type MessagingState = {
  gptGeneration: {
    data: API$GptGeneration | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  gptVacancies: {
    data: API$GptVacancy[] | null;
    languages: API$GptVacancyLanguages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  mailingVariables: {
    data: Array<AH$MailingVariable>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  modals: AH$MessageModals;
  recipientDetailed: {
    data: AH$RecipientDetailed | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  recipients: {
    data: Array<AH$Recipient>;
    pages: API$Pages | undefined;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  sendSingleMessageStatus: $Values<typeof FETCHING_STATUSES>;
  sendTestMessageStatus: $Values<typeof FETCHING_STATUSES>;
  sequence: {
    data: AH$Folder | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  sequenceRecipients: {
    chosenCandidatesIds: Set<number>;
    data: Array<
      API$MailingRecipient & {
        profileInfo: AH$MiniProfile;
        source: string;
        stats: {
          error: {
            restartable: number;
            unrestartable: number;
          };
          total_sent: number;
        };
      }
    >;
    pages: API$Pages | undefined;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  sequences: {
    data: Array<AH$Sequence> | null;
    pages: API$Pages | undefined;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  templateCreators: {
    data: Array<API$Assignee>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  templateStatus: $Values<typeof FETCHING_STATUSES>;
  templates: {
    data: Array<AH$Template>;
    exampleTemplates: Array<AH$ExampleTemplate>;
    pages: API$Pages | undefined;
    status: $Values<typeof FETCHING_STATUSES>;
  };
};
export const initialMessagingState: MessagingState = {
  templates: {
    data: [
      { id: -1, name: 'blank', subject: '', body: '', variables: [] } as unknown as AH$Template,
    ],
    exampleTemplates: [],
    status: FETCHING_STATUSES.LOADING,
    pages: undefined,
  },
  mailingVariables: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
  },
  templateStatus: FETCHING_STATUSES.SUCCESS,
  templateCreators: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
  },
  sendSingleMessageStatus: FETCHING_STATUSES.SUCCESS,
  sendTestMessageStatus: FETCHING_STATUSES.SUCCESS,
  recipients: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
    pages: undefined,
  },
  recipientDetailed: {
    data: null,
    status: FETCHING_STATUSES.SUCCESS,
  },
  modals: {},
  sequenceRecipients: {
    data: [],
    chosenCandidatesIds: Set([]),
    status: FETCHING_STATUSES.LOADING,
    pages: undefined,
  },
  sequence: {
    data: null,
    status: FETCHING_STATUSES.SUCCESS,
  },
  sequences: {
    data: null,
    pages: undefined,
    status: FETCHING_STATUSES.LOADING,
  },
  gptVacancies: {
    data: null,
    languages: null,
    status: FETCHING_STATUSES.LOADING,
  },
  gptGeneration: {
    data: null,
    status: FETCHING_STATUSES.SUCCESS,
  },
};
const SLICE_NAME = 'messaging';
const recipientDetailedLoading = createAction(`${SLICE_NAME}/recipientDetailedLoading`);

export const templateLoading = createAction(`${SLICE_NAME}/templateLoading`);
export const sequenceLoaded = createAction<AH$Folder | null>(`${SLICE_NAME}/sequenceLoaded`);

export const getTemplates = createAsyncThunk(
  `${SLICE_NAME}/getTemplates`,
  async (
    {
      limit,
      offset,
      created_by,
      onSuccess = noop,
    }: Partial<API$TemplatesParams & { onSuccess: (showExampleTemplates: boolean) => void }>,
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.templatesGet({ limit, offset, created_by });
      const data = payload.results.map(camelKeysRecursive) as Array<AH$Template>;

      onSuccess(isEmpty(data) && !isEmpty(payload.example_templates));
      return {
        data,
        exampleTemplates: transformExampleTemplates(payload.example_templates),
        pages: {
          ...omit(payload, 'results'),
        },
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getMailingVariables = createAsyncThunk(
  `${SLICE_NAME}/getMailingVariables`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.mailingVariablesGet();

      return payload;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createTemplate = createAsyncThunk(
  `${SLICE_NAME}/createTemplate`,
  async (
    {
      name,
      subject,
      body,
      onError = noop,
      onSuccess = noop,
      customVariables,
      accessType,
    }: {
      body: string;
      name: string;
      onError: (invalidFields: AH$MessageInvalidFields) => void;
      onSuccess: (template: AH$Template) => void;
      subject: string | undefined;
      accessType?: AH$Template['accessType'];
      customVariables?: Array<string>;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.templateCreate(
        name,
        body,
        customVariables,
        accessType,
        subject
      );

      onSuccess(camelKeysRecursive(payload));
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          onError(camelKeysRecursive(error));
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const editTemplate = createAsyncThunk(
  `${SLICE_NAME}/editTemplate`,
  async (
    {
      id,
      name,
      subject,
      body,
      onError = noop,
      onSuccess = noop,
      customVariables,
      accessType,
    }: {
      body: string;
      id: number;
      name: string;
      onError: (invalidFields: AH$MessageInvalidFields) => void;
      onSuccess: (template: AH$Template) => void;
      subject: string | undefined;
      accessType?: AH$Template['accessType'];
      customVariables?: Array<string>;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.templateEdit(
        id,
        name,
        body,
        customVariables,
        accessType,
        subject
      );

      onSuccess(camelKeysRecursive(payload));
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          onError(camelKeysRecursive(error));
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  `${SLICE_NAME}/deleteTemplate`,
  async ({ id, onSuccess = noop }: { id: number; onSuccess: () => void }, { rejectWithValue }) => {
    try {
      await apiV6.templateDelete(id);
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const getTemplateCreators = createAsyncThunk(
  `${SLICE_NAME}/getTemplateCreators`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { payload } = await apiV6.usersGet({
        has_templates: true,
        exclude_self: true,
        role: ['admin', 'worker', 'service_admin'],
        limit: 1000,
      });
      const { user } = (getState() as State).app;

      return [{ id: user.id, name: user.name } as API$Assignee, ...payload.results];
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const sendSingleMessage = createAsyncThunk(
  `${SLICE_NAME}/sendSingleMessage`,
  async (
    {
      templates,
      contact = '',
      profileId,
      customData,
      params,
      sendDelayInfo,
      onError = noop,
      onSuccess = noop,
      customVariables,
      accessType,
      sendAsEmail,
    }: {
      contact: string;
      customData: { [key: string]: any };
      profileId: number;
      sendDelayInfo: API$SendDelayInfo;
      templates: Array<API$MessageTemplate>;
      accessType?: AH$Folder['accessType'];
      customVariables?: Array<string>;
      onError?: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess?: (params?: { launch?: boolean }) => void;
      params?: { launch?: boolean };
      sendAsEmail?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { subject } = templates[0];
      const data = {
        name: subject,
        recipients: [
          {
            email: contact,
            profile: profileId,
            custom_data: customData,
            messaging_type: 'mailing',
          },
        ],
        templates,
        custom_variables: customVariables,
        messaging_type: 'mailing',
        ...(accessType ? { access_type: accessType } : undefined),
        ...(sendAsEmail ? { send_as_email: sendAsEmail } : undefined),
        ...sendDelayInfo,
      } as API$SingleMessageData;

      await apiV6.sendSingleMessage(data, params);
      onSuccess(params);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          onError(
            (error.templates as Array<{ [key: string]: Array<string> }>).map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const sendTestMessage = createAsyncThunk(
  `${SLICE_NAME}/sendTestMessage`,
  async (
    {
      body,
      subject,
      profileId,
      customData,
      attachmentIds,
      onError = noop,
      onSuccess = noop,
      customVariables,
    }: {
      body: string;
      customData: {
        [key: string]: string;
      };
      profileId: number;
      subject: string;
      attachmentIds?: Array<number>;
      customVariables?: Array<string>;
      onError?: (invalidFields: AH$MessageInvalidFields) => void;
      onSuccess?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const data = {
        profile: profileId,
        custom_data: customData,
        custom_variables: customVariables,
        templates: [
          {
            subject,
            body,
            attachment_ids: attachmentIds,
          },
        ],
      };

      await apiV6.sendTestMessage(data);
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          onError(
            mapValues(
              camelKeysRecursive((error.templates as Array<{ [key: string]: Array<string> }>)[0]),
              (message) => message[0]
            )
          );
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getMailingRecipients = createAsyncThunk(
  `${SLICE_NAME}/getMailingRecipients`,
  async (
    {
      params,
      onSuccess = noop,
    }: { onSuccess?: (data: Array<AH$Recipient>) => void; params?: API$MailingRecipientsParams },
    { rejectWithValue }
  ) => {
    try {
      const getArray = <V>(data: V | Array<V>): Array<V> =>
        (Array.isArray(data) ? (data as Array<V>) : [data]) as Array<V>;
      const withIncludedStatuses = (
        params?: API$MailingRecipientsParams
      ): API$MailingRecipientsParams | undefined =>
        params && params.status
          ? {
              ...params,
              status: uniq(
                getArray(params.status).reduce(
                  (acc, status) => [...acc, status, ...getIncludedStatuses(status)],
                  [] as Array<API$MailingRecipientsStatus>
                )
              ),
            }
          : params;

      const { payload } = await apiV6.getMailingRecipients(withIncludedStatuses(params));

      if (isEmpty(payload.results) && payload.previous) {
        const { payload } = await apiV6.getMailingRecipients(
          withIncludedStatuses({ ...params, offset: 0 })
        );
        const data = payload.results
          .filter(({ profile }) => !isNumber(profile) || !params || !params.profileFormat)
          .map((recipient) => {
            const profile = transformRecipientProfile(recipient.profile);

            return {
              ...camelKeysRecursive({ ...recipient, profile }),
              customData: recipient.custom_data,
            };
          }) as Array<AH$Recipient>;

        onSuccess(data);

        return {
          data,
          pages: {
            ...omit(payload, 'results'),
          },
        };
      }

      const data = payload.results
        .filter(({ profile }) => !isNumber(profile) || !params || !params.profileFormat)
        .map((recipient) => {
          const profile = transformRecipientProfile(recipient.profile);

          return {
            ...camelKeysRecursive({ ...recipient, profile }),
            customData: recipient.custom_data,
          };
        }) as Array<AH$Recipient>;

      onSuccess(data);

      return {
        data,
        pages: {
          ...omit(payload, 'results'),
        },
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const updateMailingRecipient = createAsyncThunk(
  `${SLICE_NAME}/updateMailingRecipient`,
  async (
    {
      recipientId,
      params,
      onSuccess = noop,
    }: {
      params: { [key: string]: any };
      recipientId: number;
      onSuccess?: (recipient: AH$RecipientDetailed) => void;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const { payload } = await apiV6.updateMailingRecipient(recipientId, params);
      const recipients = [...(getState() as State).messaging.recipients.data];
      const updatedRecipientIndex = findIndex(
        recipients,
        (recipient) => recipient.id === recipientId
      );

      if (recipients[updatedRecipientIndex] instanceof Object) {
        recipients[updatedRecipientIndex] = {
          ...camelKeys(payload),
          profile: recipients[updatedRecipientIndex].profile as API$Profile,
        };
        onSuccess(camelKeys(payload));

        return recipients;
      }

      return undefined;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getRecipientDetailed = createAsyncThunk(
  `${SLICE_NAME}/getRecipientDetailed`,
  async (
    {
      recipientId,
      mailingListId,
      loading = true,
    }: { mailingListId: number; recipientId: number; loading?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (loading) {
        dispatch(recipientDetailedLoading());
      }
      const [{ payload: mailingList }, { payload: recipientDetailed }] = await Promise.all([
        apiV6.getMailingList(mailingListId),
        apiV6.getRecipientDetailed(recipientId),
      ]);
      const templates = mailingList.stats.replied
        ? []
        : filterTemplates(mailingList.templates, recipientDetailed.messages).map(
            (template: API$Template) => ({
              id: template.id,
              subject: template.subject,
              body: template.body,
              createdAt: template.created_at,
              createdBy: '',
              fromEmail: null,
              fromName: null,
              fromUsername: null,
              fromPhone: null,
              opened: 0,
              replied: 0,
              status: mailingList.stopped_at || !mailingList.last_launched_at ? 'draft' : 'pending',
              sendAt: null,
              sentAt: null,
              events: [],
              attachments: (template.attachments || []).map((attachment) => ({
                ...camelKeysRecursive(attachment),
                internalId: generateId(),
              })) as Array<AH$MessageAttachment>,
              template: transformTemplate(template),
            })
          );

      return {
        ...camelKeysRecursive(recipientDetailed),
        messages: recipientDetailed.messages
          .map((message) => ({
            ...camelKeysRecursive(message),
            template: transformTemplate({
              ...message.template,
              attachments: get(
                find(mailingList.templates, ['id', message.template.id]),
                'attachments',
                []
              ),
            }),
          }))
          .concat(templates),
        customData: recipientDetailed.custom_data,
      } as AH$RecipientDetailed;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getMessagePreview = createAsyncThunk(
  `${SLICE_NAME}/getMessagePreview`,
  async (
    {
      templates,
      profileId,
      customData,
      onError = noop,
      onSuccess = noop,
      customVariables,
    }: {
      customData: { [key: string]: string };
      profileId: number;
      templates: Array<{ body: string; subject?: string }>;
      customVariables?: Array<string>;
      onError?: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess?: (payload: Array<{ body: string; id: number; subject: string | null }>) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const data = {
        templates,
        profile: profileId,
        custom_data: customData,
        custom_variables: customVariables,
      };
      const { payload } = await apiV6.getMessagePreview(data);

      onSuccess(payload);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          onError(
            (error.templates as Array<{ [key: string]: Array<string> }>).map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const launchMailingList = createAsyncThunk(
  `${SLICE_NAME}/launchMailingList`,
  async (
    {
      mailingListId,
      sendDelayInfo,
      onSuccess = noop,
      onError = noop,
      additionalMailingData = {},
    }: {
      mailingListId: number;
      onError: () => void;
      onSuccess: (id: number) => void;
      sendDelayInfo: API$SendDelayInfo;
      additionalMailingData?: { send_as_email?: string };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.launchMailingList(
        mailingListId,
        sendDelayInfo,
        additionalMailingData
      );

      onSuccess(payload.launched + payload.relaunched + payload.error_relaunched);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          const content =
            (error.start_time_from && error.start_time_from[0]) ||
            (error.start_time_to && error.start_time_to[0]);

          handleError({ content });
          onError();
        },
      });

      rejectWithValue(e);
    }
  }
);

export const stopMailingListAction = createAction<{ isMass: boolean }>(
  `${SLICE_NAME}/stopMailingList`
);

export const stopMailingList = createAsyncThunk(
  `${SLICE_NAME}/stopMailingList`,
  async (
    {
      mailingListId,
      onSuccess = noop,
      onError = noop,
      isMass = false,
    }: {
      mailingListId: number;
      isMass?: boolean;
      onError?: () => void;
      onSuccess?: () => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await apiV6.stopMailingList(mailingListId);
      onSuccess();

      dispatch(stopMailingListAction({ isMass }));
    } catch (e) {
      onError();
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const addContactToProfile = createAsyncThunk(
  `${SLICE_NAME}/addContactToProfile`,
  async (
    {
      contact,
      docId,
      onSuccess = noop,
      onError = noop,
    }: { contact: string; docId: number; onError: () => void; onSuccess: () => void },
    { rejectWithValue, getState }
  ) => {
    try {
      const { payload } = await apiV6.resumeFragmentAdd('contacts', contact, 'email', docId);
      const { unitedProfile: profile } = payload;
      const recipients = [...(getState() as State).messaging.recipients.data];
      const updatedRecipientIndex = findIndex(
        recipients,
        (recipient) => (recipient.profile as API$Profile).docId === docId
      );

      if (recipients[updatedRecipientIndex].profile instanceof Object) {
        if (
          size((recipients[updatedRecipientIndex].profile as API$Profile).displayContacts.email) ===
          size(profile.displayContacts.email)
        ) {
          onError();
        } else {
          recipients[updatedRecipientIndex] = { ...recipients[updatedRecipientIndex], profile };
          onSuccess();

          return recipients;
        }
      }

      return undefined;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getRecipientPreview = createAsyncThunk(
  `${SLICE_NAME}/getRecipientPreview`,
  async (
    {
      recipientId,
      onSuccess,
    }: {
      onSuccess: (
        messages: Array<{
          body: string;
          subject: string | null;
          condition?: AH$MessageCondition;
          events?: Array<{ date: string; type: AH$MessagingEventStatus }>;
        }>
      ) => void;
      recipientId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const [{ payload: templates }, { payload: recipientDetailed }] = await Promise.all([
        apiV6.getRecipientPreview(recipientId),
        apiV6.getRecipientDetailed(recipientId),
      ]);

      onSuccess(
        recipientDetailed.messages
          .map(({ body, subject, template, sent_at, events, send_at }) => ({
            body,
            subject,
            events: transformEvents(events, sent_at, send_at),
            condition: transformTemplate(template).condition,
          }))
          .concat(
            recipientDetailed.status === RECIPIENT_STATUS.replied
              ? []
              : (filterTemplates(templates, recipientDetailed.messages) as unknown as Array<{
                  body: string;
                  condition: AH$MessageCondition;
                  events: Array<{ date: string; type: AH$MessagingEventStatus }>;
                  subject: string;
                }>)
          )
      );
    } catch (e) {
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const deleteRecipient = createAsyncThunk(
  `${SLICE_NAME}/deleteRecipient`,
  async (
    {
      recipientId,
      onSuccess = noop,
      params,
    }: {
      recipientId: number;
      onSuccess?: () => void;
      params?: AH$SaveMessagingHistory;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.deleteRecipient(recipientId, params);
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const addRecipientsToMailingList = createAsyncThunk(
  `${SLICE_NAME}/addRecipientsToMailingList`,
  async (
    {
      mailingListId,
      candidateIds,
      onSuccess = noop,
    }: {
      candidateIds: Array<number>;
      mailingListId: number;
      onSuccess: (addedCount: number) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      startLoading();
      const { payload } = await apiV6.addRecipientsToMailingList(mailingListId, candidateIds);

      dispatch(
        getMailingList({
          mailingListId,
          onSuccess: () => {
            stopLoading();
            onSuccess(payload.added_candidate_ids.length);
          },
        })
      );
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const changeMessagingSettings = createAsyncThunk(
  `${SLICE_NAME}/changeMessagingSettings`,
  async (
    {
      data,
      onSuccess = noop,
      onError = noop,
    }: { data: API$MessagingSettingsData; onError: () => void; onSuccess: () => void },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const { payload } = await apiV6.changeMessagingSettings(data);
      const { user } = (getState() as State).app;

      onSuccess();
      dispatch(currentUserReceived({ ...user, ...camelKeysRecursive(payload) }));
    } catch (e) {
      onError();
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const startSingleMessage = createAsyncThunk(
  `${SLICE_NAME}/startSingleMessage`,
  async (
    {
      mailingListId,
      recipientId,
      templates,
      customData,
      sendAsEmail,
      sendDelayInfo,
      launch = false,
      onError = noop,
      onSuccess = noop,
      customVariables,
      accessType,
    }: {
      mailingListId: number;
      accessType?: AH$Folder['accessType'];
      customData?: { [key: string]: any };
      customVariables?: Array<string>;
      launch?: boolean;
      onError?: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess?: (params?: { launch?: boolean }) => void;
      recipientId?: number;
      sendAsEmail?: string;
      sendDelayInfo?: API$SendDelayInfo;
      templates?: Array<API$MessageTemplate>;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.editMailingList(mailingListId, {
        ...(templates
          ? { templates, custom_variables: customVariables, name: templates[0].subject }
          : undefined),
        ...(accessType ? { access_type: accessType } : undefined),
        ...(sendAsEmail ? { send_as_email: sendAsEmail } : undefined),
      });
      if (recipientId) {
        apiV6.updateMailingRecipient(recipientId, { custom_data: customData });
      }
      if (launch) {
        await apiV6.launchMailingList(mailingListId, sendDelayInfo);
      }
      onSuccess({ launch });
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: ({ templates }: { templates: Array<{ [key: string]: Array<string> }> }) => {
          onError(
            templates.map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const changeCompanyMessagingSettings = createAsyncThunk(
  `${SLICE_NAME}/changeCompanyMessagingSettings`,
  async (
    {
      data,
      onSuccess = noop,
      onError = noop,
    }: { data: { messaging_unsubscribe: boolean }; onError?: () => void; onSuccess?: () => void },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const { payload } = await apiV6.changeCompanyMessagingSettings(data);
      const { user } = (getState() as State).app;

      dispatch(
        currentUserReceived({
          ...user,
          company: { ...user.company, ...camelKeysRecursive(payload) },
        })
      );
      onSuccess();
    } catch (e) {
      onError();
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const getEmailSources = createAsyncThunk(
  `${SLICE_NAME}/getEmailSources`,
  async (
    {
      profileId,
      onSuccess,
    }: { onSuccess: (source: { [key: string]: string }) => void; profileId: number },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.getEmailSources(profileId);

      onSuccess(payload);
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const saveSequence = createAsyncThunk(
  `${SLICE_NAME}/saveSequence`,
  async (
    {
      data,
      id,
      onSuccess = noop,
      onError = noop,
    }: {
      data: API$FolderData;
      id?: number;
      onError?: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess?: () => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.sequenceSaved
    );

    try {
      setLoading();
      if (id) {
        await apiV6.sequenceEdit(id, data);
      } else {
        await apiV6.sequenceCreate(data);
      }

      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: ({
          templates,
        }: {
          folder: Array<string>;
          templates: Array<{ [key: string]: Array<string> }>;
        }) => {
          setError();
          onError(
            (templates || []).map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getSequence = createAsyncThunk(
  `${SLICE_NAME}/getSequence`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.sequenceGet(id);
      const { folder } = transformFolder(payload);

      return folder;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getSequences = createAsyncThunk(
  `${SLICE_NAME}/getSequences`,
  async (params: API$PaginationParams | undefined, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.sequencesGet(params);
      const transformedSequences = payload.results.map((sequence) => {
        const { folder } = transformFolder(sequence);

        return { ...folder, stats: sequence.mailing_stats };
      });

      return {
        data: transformedSequences,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const deleteSequence = createAsyncThunk(
  `${SLICE_NAME}/deleteSequence`,
  async (
    { id, saveMessagingHistory }: { id: number; saveMessagingHistory?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await apiV6.sequenceDelete(id, saveMessagingHistory);
      dispatch(getSequences());
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const addRecipientToSequence = createAsyncThunk(
  `${SLICE_NAME}/addRecipientToSequence`,
  async (
    {
      sequenceId,
      profileData,
      onSuccess,
    }: { profileData: API$SequenceProfileData; sequenceId: number; onSuccess?: () => void },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.sequenceSaved
    );

    try {
      setLoading();
      await apiV6.sequenceAddRecipient(sequenceId, profileData);
      setSuccess();
      onSuccess?.();
    } catch (e) {
      getErrorHandler({
        getError: () => {
          setError();
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getSequenceRecipients = createAsyncThunk(
  `${SLICE_NAME}/getSequenceRecipients`,
  async (
    { sequenceId, params }: { params: AH$CandidatesParams; sequenceId: number },
    { rejectWithValue }
  ) => {
    try {
      const getWithIncludedStatuses = flow([
        convertMessagingStatusesFromUrl,
        (statuses) =>
          statuses.reduce(
            (
              acc: Array<{ mailingListId: number; status: API$MailingRecipientsStatus }>,
              messStatus: { mailingListId: number; status: API$MailingRecipientsStatus }
            ) => [
              ...acc,
              messStatus,
              ...getIncludedStatuses(messStatus.status).map((status) => ({
                status,
                mailingListId: messStatus.mailingListId,
              })),
            ],
            [] as Array<{ mailingListId: number; status: string }>
          ),
        convertMessagingStatusesToUrl,
      ]);

      const { payload } = await sequenceRecipientsGet(sequenceId, {
        ...omit(params, ['messaging_status']),
        messaging_status: getWithIncludedStatuses(params.messaging_status),
      });

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const sequenceRelaunchFailedMessages = createAsyncThunk(
  `${SLICE_NAME}/sequenceRelaunchFailedMessages`,
  async (
    {
      sequenceId,
      params,
      onSuccess,
    }: { onSuccess: (count: number) => void; params: AH$CandidatesParams; sequenceId: number },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.sequenceMessagesRelaunch
    );

    try {
      setLoading();
      const { payload } = await apiV6.sequenceRelaunchFailedMessages(sequenceId);

      setSuccess();
      onSuccess(payload.error_relaunched);
      dispatch(getSequence(sequenceId));
      dispatch(getSequenceRecipients({ sequenceId, params }));
    } catch (e) {
      getErrorHandler({
        getError: () => {
          setError();
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getGptVacancies = createAsyncThunk(
  `${SLICE_NAME}/getGptVacancies`,
  async (_, { rejectWithValue }) => {
    try {
      const [{ payload }, { payload: languages }] = await Promise.all([
        apiV6.gptVacanciesGet(),
        apiV6.gptVacancyLanguagesGet(),
      ]);

      return {
        data: payload,
        languages,
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const saveGptVacancy = createAsyncThunk(
  `${SLICE_NAME}/saveGptVacancy`,
  async (
    {
      data,
      id,
      onSuccess,
    }: {
      data: API$GptVacancyCreateData;
      id?: number;
      onSuccess?: (id: number) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.gptVacancySaved
    );

    try {
      setLoading();
      const { payload } = id
        ? await apiV6.gptVacancyUpdate(id, data)
        : await apiV6.gptVacancyCreate(data);

      setSuccess();
      onSuccess?.(payload.id);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (e) => {
          setError(e);
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const deleteGptVacancy = createAsyncThunk(
  `${SLICE_NAME}/deleteGptVacancy`,
  async (
    { id, onSuccess }: { id: number; onSuccess?: () => void },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.gptVacancyDeleted
    );

    try {
      setLoading();
      await apiV6.gptVacancyDelete(id);
      dispatch(getGptVacancies());
      setSuccess();
      onSuccess?.();
    } catch (e) {
      getErrorHandler({
        getError: () => {
          setError();
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const startGptGeneration = createAsyncThunk(
  `${SLICE_NAME}/startGptGeneration`,
  async (
    {
      profileId,
      vacancyId,
      onSuccess,
      onError,
    }: {
      profileId: number;
      vacancyId: number;
      onError?: () => void;
      onSuccess?: (id: number) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.gptGenerationStart(profileId, vacancyId);

      onSuccess?.(payload.id);
    } catch (e) {
      getErrorHandler({
        getError: () => {
          onError?.();
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getGptGeneration = createAsyncThunk(
  `${SLICE_NAME}/getGptGeneration`,
  async ({ id, onError }: { id: number; onError?: () => void }, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.gptGenerationGet(id);

      return {
        ...payload,
        messages: payload.messages.map((m, i) => ({
          subject: i === 0 ? m.subject : `RE: ${payload.messages[0].subject}`,
          body: m.body.replaceAll('\n', '<br/>'),
        })),
      };
    } catch (e) {
      getErrorHandler({
        getError: () => {
          onError?.();
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const messagingSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialMessagingState,
  reducers: {
    setModal: (
      state,
      action: PayloadAction<{ createModal: (id: string) => ReactElement; id?: string }>
    ) => {
      const { createModal, id = uniqueId() } = action.payload;
      const modals = mapValues(state.modals, (modal) => ({
        ...modal,
        view: modal.view === 'showed' ? 'hidden' : modal.view,
      }));

      state.modals = {
        ...modals,
        [id]: { id, modal: createModal(id), view: 'showed' },
      };
    },
    toggleViewModal: (state, action: PayloadAction<{ id: string; view: AH$MessageModalView }>) => {
      const { id, view } = action.payload;
      const hasModal = has(state.modals, [id]);
      const modals = hasModal
        ? mapValues(state.modals, (modal) => {
            if (modal.id === id) {
              return {
                ...modal,
                view,
              };
            }
            return {
              ...modal,
              view: modal.view === 'showed' ? 'hidden' : modal.view,
            };
          })
        : state.modals;

      state.modals = modals;
    },
    unsetModal: (state, action: PayloadAction<{ id: string }>) => {
      state.modals = omit(state.modals, [action.payload.id]);
    },
    clearModal: (state) => {
      state.modals = initialMessagingState.modals;
    },
    chooseSequenceRecipients: (state, action: PayloadAction<Array<number>>) => {
      state.sequenceRecipients.chosenCandidatesIds =
        state.sequenceRecipients.chosenCandidatesIds.union(action.payload);
    },
    removeChosenSequenceRecipients: (state, action: PayloadAction<Array<number>>) => {
      state.sequenceRecipients.chosenCandidatesIds =
        state.sequenceRecipients.chosenCandidatesIds.subtract(action.payload);
    },
    clearGptGeneration: (state, action: PayloadAction<null>) => {
      state.gptGeneration = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(templateLoading, (state) => {
      state.templateStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(sequenceLoaded, (state, action) => {
      state.sequence = { data: action.payload, status: FETCHING_STATUSES.SUCCESS };
    });
    builder.addCase(getTemplates.pending, (state) => {
      state.templates.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templates = {
        data: [...initialMessagingState.templates.data, ...action.payload.data],
        exampleTemplates: action.payload.exampleTemplates,
        status: FETCHING_STATUSES.SUCCESS,
        pages: action.payload.pages,
      };
    });
    builder.addCase(getTemplates.rejected, (state) => {
      state.templates.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getMailingVariables.pending, (state) => {
      state.mailingVariables.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getMailingVariables.fulfilled, (state, action) => {
      state.mailingVariables = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(createTemplate.pending, (state) => {
      state.templateStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(createTemplate.fulfilled, (state) => {
      state.templateStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(createTemplate.rejected, (state) => {
      state.templateStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(editTemplate.pending, (state) => {
      state.templateStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(editTemplate.fulfilled, (state) => {
      state.templateStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(editTemplate.rejected, (state) => {
      state.templateStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(deleteTemplate.pending, (state) => {
      state.templates.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(deleteTemplate.rejected, (state) => {
      state.templates.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getTemplateCreators.pending, (state) => {
      state.templateCreators.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getTemplateCreators.fulfilled, (state, action) => {
      state.templateCreators = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getTemplateCreators.rejected, (state) => {
      state.templateCreators.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(sendSingleMessage.pending, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(sendSingleMessage.fulfilled, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(sendSingleMessage.rejected, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(sendTestMessage.pending, (state) => {
      state.sendTestMessageStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(sendTestMessage.fulfilled, (state) => {
      state.sendTestMessageStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(sendTestMessage.rejected, (state) => {
      state.sendTestMessageStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getMailingRecipients.pending, (state) => {
      state.recipients.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getMailingRecipients.fulfilled, (state, action) => {
      state.recipients = {
        data: action.payload.data,
        pages: action.payload.pages,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getMailingRecipients.rejected, (state) => {
      state.recipients.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(updateMailingRecipient.fulfilled, (state, action) => {
      if (action.payload) {
        state.recipients.data = action.payload;
      }
    });
    builder.addCase(recipientDetailedLoading, (state) => {
      state.recipientDetailed.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getRecipientDetailed.fulfilled, (state, action) => {
      state.recipientDetailed = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(getRecipientDetailed.rejected, (state) => {
      state.recipientDetailed.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(addContactToProfile.fulfilled, (state, action) => {
      if (action.payload) {
        state.recipients.data = action.payload;
      }
    });
    builder.addCase(startSingleMessage.pending, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(startSingleMessage.fulfilled, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(startSingleMessage.rejected, (state) => {
      state.sendSingleMessageStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getSequence.pending, (state) => {
      state.sequence.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getSequence.fulfilled, (state, action) => {
      state.sequence = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getSequence.rejected, (state) => {
      state.sequence.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getSequences.pending, (state) => {
      state.sequences.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getSequences.fulfilled, (state, action) => {
      state.sequences = {
        data: action.payload.data,
        pages: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getSequences.rejected, (state) => {
      state.sequences.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(deleteSequence.rejected, (state) => {
      state.sequences.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getSequenceRecipients.pending, (state) => {
      state.sequenceRecipients.chosenCandidatesIds = Set([]);
      state.sequenceRecipients.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getSequenceRecipients.fulfilled, (state, action) => {
      state.sequenceRecipients.pages = {
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
      };
      state.sequenceRecipients.data = action.payload.results.map((recipient) => {
        const profileInfoWithId: API$MiniProfile = {
          ...recipient.profile_info,
          profileID: recipient.profile as number,
        };
        const transformedProfileInfo = transformRecipientProfile(
          profileInfoWithId
        ) as AH$MiniProfile;

        return { ...omit(recipient, 'profile_info'), profileInfo: transformedProfileInfo };
      });
      state.sequenceRecipients.status = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(getSequenceRecipients.rejected, (state) => {
      state.sequenceRecipients.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getGptVacancies.pending, (state) => {
      state.gptVacancies.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getGptVacancies.fulfilled, (state, action) => {
      state.gptVacancies = {
        data: action.payload.data,
        languages: action.payload.languages,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getGptVacancies.rejected, (state) => {
      state.gptVacancies.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(startGptGeneration.pending, (state) => {
      state.gptGeneration.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(startGptGeneration.rejected, (state) => {
      state.gptGeneration.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getGptGeneration.fulfilled, (state, action) => {
      state.gptGeneration = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getGptGeneration.rejected, (state) => {
      state.gptGeneration.status = FETCHING_STATUSES.ERROR;
    });
  },
});

export const {
  clearGptGeneration,
  clearModal,
  setModal,
  toggleViewModal,
  unsetModal,
  removeChosenSequenceRecipients,
  chooseSequenceRecipients,
} = messagingSlice.actions;
export const messaging = messagingSlice.reducer;
