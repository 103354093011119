export default {
  title: {
    id: 'errorBoundary.title',
    defaultMessage: 'Something went wrong',
  },
  main: {
    id: 'errorBoundary.main',
    defaultMessage: 'Main page',
  },
};
