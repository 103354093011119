import * as React from 'react';
import { useIntl } from 'react-intl';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import Dropdown from 'components/Base/Dropdown';
import Tooltip from 'components/CustomTooltip';
import messages from './messages';
import styles from './Group.scss';

const theme = thematize(styles);

export const KM_TO_MI = {
  20: 12.5,
  40: 25,
  80: 50,
  160: 100,
} as const;

interface Props {
  disabled: boolean;
  onChange: (range: number) => void;
  range: number;
  format?: 'km' | 'mi';
}

const LocationRanges = ({
  range,
  onChange,
  disabled,
  format = 'km',
}: Props): React.ReactElement => {
  const { formatMessage } = useIntl();

  const renderButton = (): React.ReactElement => (
    <div className={theme('location-ranges', { disabled })}>
      <Tooltip id="only-cities" show={disabled} message={formatMessage(messages.onlyCities)}>
        <div className={theme('btn')}>
          <span className={theme('btn__title')}>{`${
            format === 'mi' ? KM_TO_MI[range as keyof typeof KM_TO_MI] : range
          } ${formatMessage(messages[format])}`}</span>
          <span className={theme('btn__caret')} />
        </div>
      </Tooltip>
    </div>
  );

  return disabled ? (
    renderButton()
  ) : (
    <Dropdown button={renderButton()}>
      <div
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>): void => {
          // // не допускает изменения позиции каретки
          e.preventDefault();
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
          // // не допускает закрытия списка
          e.stopPropagation();
        }}
      >
        {Object.keys(KM_TO_MI).map((range) => {
          const rangeInt = parseInt(range, 10) as keyof typeof KM_TO_MI;
          const value = format === 'mi' ? KM_TO_MI[rangeInt] : rangeInt;

          return (
            <Dropdown.Option
              key={value}
              onOptionClick={(): void => {
                tracking.changeLocationRange(
                  tracking.LOCATIONS[window.location.pathname === '/' ? 'home' : 'search']
                );
                onChange(rangeInt);
              }}
            >
              {`${value} ${formatMessage(messages[format])}`}
            </Dropdown.Option>
          );
        })}
      </div>
    </Dropdown>
  );
};

export default LocationRanges;
