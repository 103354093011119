import { FC, ReactNode, useCallback, useEffect } from 'react';

import { useUser } from 'hooks';
import useDispatch from 'hooks/useDispatch';
import { setDialogModal, setSnackbar } from 'slices/app';
import FullNotification from './FullNotification';
import { metrics } from './metrics';
import ShortNotification from './ShortNotification';

const DELAY_FOR_SHOW_NOTIFICATION = 3000;

const NotificationCenter: FC = () => {
  const dispatch = useDispatch();
  const user = useUser();

  const setFullNotification = useCallback(
    (content: ReactNode) => {
      dispatch(
        setDialogModal({
          show: true,
          content,
        })
      );
    },
    [dispatch]
  );

  const setShortNotification = (content: ReactNode) => {
    dispatch(setSnackbar({ show: true, content }));
  };

  const closeShortNotification = useCallback(() => {
    dispatch(setSnackbar({ show: false }));
  }, [dispatch]);

  const handleLearnMoreClick = useCallback(
    (notification: AH$SystemNotification) => () => {
      setFullNotification(<FullNotification notification={notification} />);
      closeShortNotification();
      metrics.clickOnLearnMore();
    },
    [closeShortNotification, setFullNotification]
  );

  useEffect(() => {
    if (user && user.systemNotifications?.length) {
      const activeNotification = user.systemNotifications.find(
        (notification) => !notification.interacted
      );

      if (activeNotification) {
        setTimeout(() => {
          if (activeNotification.showFormat === 'full') {
            setFullNotification(<FullNotification notification={activeNotification} />);
            metrics.showFullNotification();
          } else {
            setShortNotification(
              <ShortNotification
                notification={activeNotification}
                handleLearnMoreClick={handleLearnMoreClick(activeNotification)}
                handleClose={closeShortNotification}
              />
            );
            metrics.showShortNotification();
          }
        }, DELAY_FOR_SHOW_NOTIFICATION);
      }
    }
  }, [user]);

  return null;
};

export default NotificationCenter;
