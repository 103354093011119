export default {
  synonyms: {
    id: 'term.synonyms',
    defaultMessage: 'Synonyms',
  },
  variants: {
    id: 'term.variants',
    defaultMessage: 'Variants',
  },
};
