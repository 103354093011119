import * as React from 'react';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import Dropdown from 'components/Base/Dropdown';
import styles from './SideMenuDropdown.scss';

const theme = thematize(styles);

interface Props {
  buttonContent: React.ReactElement;
  children: Array<React.ReactElement> | React.ReactElement;
  containerClassName: string;
  handleOpenCallback: (value: boolean) => void;
  hideCorner: boolean;
  inverted: boolean;
  isButtonActive: boolean;
  alignItems?: string;
  badge?: React.ReactElement;
  forceOpened?: boolean;
}

interface State {
  opened: boolean;
}

class SideMenuDropdown extends React.Component<Props, State> {
  static defaultProps = {
    containerClassName: '',
    isButtonActive: false,
    handleOpenCallback: noop,
    inverted: false,
    hideCorner: false,
  };

  state = {
    opened: false,
  };

  toggleDropdown = (value: boolean): void => {
    this.setState({ opened: value });
    this.props.handleOpenCallback(value);
  };

  renderButton = (): React.ReactElement => (
    <div
      className={theme('button', {
        active: this.props.isButtonActive,
        opened: this.state.opened || this.props.forceOpened,
      })}
    >
      {this.props.buttonContent}
    </div>
  );

  renderContent = (): React.ReactElement => {
    const { hideCorner, inverted, alignItems } = this.props;

    return (
      <div className={theme('content', { align: alignItems })}>
        <div
          className={theme('corner', {
            hidden: hideCorner,
            inverted,
            small: (this.props.children as Array<any>).length === 2,
          })}
        />
        <div className={theme('items')}>{this.props.children}</div>
      </div>
    );
  };

  render(): React.ReactElement {
    const { opened } = this.state;
    const { inverted, badge, forceOpened } = this.props;

    return (
      <div
        className={`${theme('side-menu-dropdown', { opened: opened || forceOpened })} ${
          this.props.containerClassName
        }`}
      >
        <Dropdown
          sticky
          disableAnimation
          optionsOpened={opened || forceOpened}
          bodyClassName={theme('custom-dropdown-body', { inverted })}
          button={this.renderButton()}
          bodyStyles={{ left: 70 }}
          containerInnerProps={{
            onMouseEnter: () => this.toggleDropdown(true),
            onMouseLeave: () => this.toggleDropdown(false),
          }}
          trigger="hover"
        >
          {this.renderContent()}
        </Dropdown>
        {badge && <div className={theme('badge')}>{badge}</div>}
      </div>
    );
  }
}

export default SideMenuDropdown;
