import {
  createAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Set } from 'immutable';
import { every, get, merge, noop, omit, uniq, values, without } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import * as api from 'lib/api';
import { reportProfile, TReasonProfileReport, TSourceProfileReport } from 'lib/api';
import * as apiFilesV1 from 'lib/apiFilesV1';
import * as apiV6 from 'lib/apiV6';
import { camelKeysRecursive } from 'lib/object';
import {
  filterResumesAlt,
  isManager,
  mergeProfileDisplayContacts,
  startLoading,
  stopLoading,
  transformContacts,
  transformFolders,
  transformProfile,
} from 'lib/utils';
import { getErrorHandler, receiveLimitsInfo } from 'slices/app';
import { FetchingStatusesActionTypes } from 'slices/fetchingStatuses';
import { createStatusActions, REDUCER_KEYS } from 'slices/utils';

export type ProfileState = {
  readonly comments: {
    status: $Values<typeof FETCHING_STATUSES>;
    data?: Array<AH$ProfileComment>;
  };
  readonly contactsEditing: boolean;
  readonly error: any;
  readonly fetchingContactsStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingExternalContactsStatus: $Values<typeof FETCHING_STATUSES> | null;
  readonly fetchingOrderStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingProfileStatus: $Values<typeof FETCHING_STATUSES>;
  readonly folderStatuses: {
    data: Array<API$Status>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly folders: {
    status: $Values<typeof FETCHING_STATUSES>;
    data?: Array<AH$ProfileFolder>;
  };
  readonly loadingContactsIds: Set<number>;
  readonly mixmaxReports: {
    data: Array<AH$MixmaxReport>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly profile: AH$Profile | null;
  readonly referer: null | string;
  readonly resumes: {
    data: Array<AH$ProfileResumeFull>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly similarProfiles: {
    count: number;
    data: Array<AH$Profile>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly views: {
    data: Array<AH$ProfileView>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
};

export const profileInitialState: ProfileState = {
  profile: null,
  fetchingProfileStatus: FETCHING_STATUSES.LOADING,
  error: null,
  fetchingContactsStatus: FETCHING_STATUSES.SUCCESS,
  contactsEditing: false,
  loadingContactsIds: Set<number>(),
  fetchingOrderStatus: FETCHING_STATUSES.SUCCESS,
  similarProfiles: {
    data: [],
    count: 0,
    status: FETCHING_STATUSES.LOADING,
  },
  resumes: {
    data: [],
    status: FETCHING_STATUSES.SUCCESS,
  },
  comments: {
    data: undefined,
    status: FETCHING_STATUSES.SUCCESS,
  },
  folders: {
    data: undefined,
    status: FETCHING_STATUSES.LOADING,
  },
  views: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
  },
  mixmaxReports: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
  },
  folderStatuses: {
    data: [],
    status: FETCHING_STATUSES.SUCCESS,
  },
  referer: null,
  fetchingExternalContactsStatus: null,
};

const createProfileSlice = (
  sliceName: typeof REDUCER_KEYS.profileMain | typeof REDUCER_KEYS.profilePossible
) => {
  const getProfileFetching = createAction(`${sliceName}/getProfileFetching`);

  const getProfileLoaded = createAction<AH$Profile>(`${sliceName}/getProfileLoaded`);

  const getProfileErrored = createAction(`${sliceName}/getProfileErrored`);

  const getProfile = createAsyncThunk(
    `${sliceName}/getProfile`,
    async (
      {
        id,
        profileReferer,
        onSuccess = noop,
        onError = noop,
        loading = true,
      }: {
        id: number;
        loading?: boolean;
        onError?: () => void;
        onSuccess?: (profile: AH$Profile) => void;
        profileReferer?: string;
      },
      { dispatch }
    ) => {
      if (loading) {
        dispatch(getProfileFetching());
      }

      try {
        const { payload } = await api.getProfileById(id, profileReferer);
        const profile = transformProfile(payload[0]);

        onSuccess(profile);
        dispatch(getProfileLoaded(profile));
      } catch (e) {
        onError();
        getErrorHandler({
          parseError: true,
          getError: (error) => {
            dispatch(getProfileErrored(error));
          },
        })(e);
      }
    }
  );

  const fileUploadError = createAction(`${sliceName}/fileUploadError`);

  const fileParsingError = createAction(`${sliceName}/fileParsingError`);

  const createProfileFromFile = createAsyncThunk(
    `${sliceName}/createProfileFromFile`,
    async (
      {
        formData,
        onSuccess,
        onError,
      }: {
        formData: FormData;
        onError: () => void;
        onSuccess: (profileId: number) => void;
      },
      { dispatch }
    ) => {
      startLoading();

      try {
        const response = await apiFilesV1.uploadFile(formData);
        const createdFile = await apiV6.createProfileFromFile(response.payload.file_id);

        onSuccess(createdFile.payload.docId);
        stopLoading();
      } catch (error: any) {
        getErrorHandler({
          getError: () => {
            stopLoading();
            onError();
          },
        })(error);
        if (error.response && error.response.status === 406) {
          dispatch(fileParsingError());
        } else {
          dispatch(fileUploadError());
        }
      }
    }
  );

  const getProfileContacts = createAsyncThunk(
    `${sliceName}/getProfileContacts`,
    async (
      {
        id,
        type,
        onSuccess = noop,
      }: {
        id: number;
        type: 'open' | 'update';
        onSuccess?: (payload: API$ProfileDisplayContacts) => void;
      },
      { dispatch, getState, rejectWithValue }
    ) => {
      try {
        const {
          app: { user },
        } = getState() as { app: { user: AH$User } };
        const isCustomContactFinderEnabled = get(user, 'company.enableContactsWithoutSources');
        let contactsOfContactFinder: AH$ProfileDisplayContacts = {};

        if (type === 'open') {
          const { payload } = await api.contactsGet(id);

          if (isCustomContactFinderEnabled) {
            const {
              payload: { displayContacts },
            } = await apiV6.findContacts(id);

            contactsOfContactFinder = displayContacts;
          }
          const displayContacts = mergeProfileDisplayContacts(payload, contactsOfContactFinder);

          onSuccess(displayContacts);
          if (!isManager(user.role)) {
            dispatch(receiveLimitsInfo());
          }

          return displayContacts;
        }
        const {
          payload: { displayContacts },
        } = await apiV6.findContacts(id);

        onSuccess(displayContacts);
        if (!isManager(user.role)) {
          dispatch(receiveLimitsInfo());
        }

        return displayContacts;
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const removeContact = createAsyncThunk(
    `${sliceName}/removeContact`,
    async (contact: AH$ProfileContact, { rejectWithValue, getState }) => {
      const {
        [sliceName]: { profile },
      } = getState() as {
        profileMain: ProfileState;
        profilePossible: ProfileState;
      };

      if (contact.saved && contact.sourceInfo && contact.sourceInfo.privateRawDataId) {
        try {
          await apiV6.resumeFragmentRemove(
            contact.sourceInfo.privateRawDataId,
            contact.profileId,
            get(profile, 'orderId')
          );

          return contact;
        } catch (e) {
          getErrorHandler()(e);
          return rejectWithValue(e);
        }
      } else {
        return contact;
      }
    }
  );

  const setLoadingContactsIds = createAction<Set<number>>(`${sliceName}/setLoadingContactsIds`);

  const toggleContactsEditing = createAction<boolean>(`${sliceName}/toggleContactsEditing`);

  const changeContact = createAction<AH$ProfileContact>(`${sliceName}/changeContact`);

  const profileContactsUpdated = createAction<{
    contactsById: AH$ProfileContactsById;
    contactsIdByType: Map<string, Array<number>>;
  }>(`${sliceName}/profileContactsUpdated`);

  const saveContact = createAsyncThunk(
    `${sliceName}/saveContact`,
    async (
      {
        contact,
        onSuccess,
        onError,
      }: {
        contact: AH$ProfileContact;
        onError: () => void;
        onSuccess: () => void;
      },
      { rejectWithValue, dispatch, getState }
    ) => {
      const {
        [sliceName]: { profile, loadingContactsIds },
      } = getState() as {
        profileMain: ProfileState;
        profilePossible: ProfileState;
      };
      const { type, value, profileId, _id } = contact;
      const field = type === 'link' ? 'userlinks' : 'contacts';
      const prevContacts = values(get(profile, 'contactsById')).filter(({ saved }) => saved);

      dispatch(setLoadingContactsIds(loadingContactsIds.add(_id)));

      try {
        const { payload } = await apiV6.resumeFragmentAdd(
          field,
          value,
          type,
          profileId,
          get(profile, 'orderId')
        );
        const updatedProfile = transformProfile({
          ...payload.unitedProfile,
          orderId: get(profile, 'orderId'),
        });
        const nextContacts = values(updatedProfile.contactsById);
        const saved = prevContacts.length !== nextContacts.length;
        const notSavedContacts: Record<number, AH$ProfileContact> = values(
          get(profile, 'contactsById')
        )
          .filter((contact) => !contact.saved && contact._id !== _id)
          .reduce((res, contact) => ({ ...res, [contact._id]: contact }), {});
        const loadingIds = loadingContactsIds.delete(_id);

        dispatch(setLoadingContactsIds(loadingIds));

        dispatch((_, getState) => {
          const {
            [sliceName]: { profile, contactsEditing },
          } = getState() as {
            profileMain: ProfileState;
            profilePossible: ProfileState;
          };

          let contactsById = get(profile, 'contactsById');

          if (saved) {
            const contactsIdByType = Array.from(updatedProfile.contactsIdByType.keys()).reduce(
              (res, type) =>
                res.set(
                  type,
                  (updatedProfile.contactsIdByType.get(type) || []).concat(
                    values(notSavedContacts)
                      .filter((contact) => contact.type === type)
                      .map(({ _id }) => _id)
                  )
                ),
              new Map()
            );

            contactsById = merge({ ...updatedProfile.contactsById }, { ...notSavedContacts });
            dispatch(profileContactsUpdated({ contactsById, contactsIdByType }));
            onSuccess();
          } else {
            dispatch(setLoadingContactsIds(loadingIds));
            dispatch(changeContact({ ...contact, valid: false }));

            if (
              (loadingIds.size === 0 || !every(contactsById, { saved: true })) &&
              !contactsEditing
            ) {
              dispatch(toggleContactsEditing(true));
              onError();
            }
          }
        });
      } catch (e) {
        getErrorHandler({
          getError: () => {
            dispatch((_, getState) => {
              const {
                [sliceName]: { loadingContactsIds, contactsEditing },
              } = getState() as {
                profileMain: ProfileState;
                profilePossible: ProfileState;
              };
              const loadingIds = loadingContactsIds.delete(_id);

              dispatch(changeContact({ ...contact, valid: false }));
              dispatch(setLoadingContactsIds(loadingIds));
              if (loadingIds.size === 0 && !contactsEditing) {
                dispatch(toggleContactsEditing(true));
                onError();
              }
            });
          },
        })(e);

        rejectWithValue(e);
      }
    }
  );

  const receiveSimilarProfiles = createAsyncThunk(
    `${sliceName}/receiveSimilarProfiles`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const {
          payload: { profiles, count },
        } = await apiV6.getSimilarProfiles(profileId);

        return {
          data: profiles.map((profile) => ({ ...profile, id: profile.docId })),
          count,
        };
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const receiveProfileResumes = createAsyncThunk(
    `${sliceName}/receiveProfileResumes`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const { payload } = await api.getResumesById(profileId);

        return filterResumesAlt(payload);
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const receiveProfileComments = createAsyncThunk(
    `${sliceName}/receiveProfileComments`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const { payload } = await apiV6.commentsGet(profileId, { general: true });

        return payload.map(camelKeysRecursive) as Array<AH$ProfileComment>;
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const receiveProfileFolders = createAsyncThunk(
    `${sliceName}/receiveProfileFolders`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const { payload } = await apiV6.candidatesGet({ profile: profileId, short: true });

        return transformFolders(payload.results);
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const receiveProfileViews = createAsyncThunk(
    `${sliceName}/receiveProfileViews`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const { payload } = await api.viewsGet(profileId);

        return payload.sort((a, b) => b.timestamp - a.timestamp);
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const receiveMixmaxSequenceReports = createAsyncThunk(
    `${sliceName}/receiveMixmaxSequenceReports`,
    async (profileId: number, { rejectWithValue }) => {
      try {
        const { payload } = await api.mixmaxSequenceReports(profileId);

        return payload;
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const removeResume = createAsyncThunk(
    `${sliceName}/removeResume`,
    async (
      { resumeId: id, profileId }: { profileId: number; resumeId: string },
      { rejectWithValue, dispatch, getState }
    ) => {
      startLoading();

      try {
        await apiV6.removeResume(id, profileId);
        stopLoading();
        dispatch(receiveProfileResumes(profileId));
        const {
          profileMain: { profile },
        } = getState() as { profileMain: ProfileState };

        if (profile)
          dispatch(
            getProfileLoaded({
              ...profile,
              attachments: profile.attachments.filter((attach) => id !== attach.id),
            })
          );
      } catch (e) {
        stopLoading();
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const addComment = createAsyncThunk(
    `${sliceName}/addComment`,
    async (
      {
        profileId,
        text,
        candidateId,
      }: {
        candidateId: number | null;
        profileId: number;
        text: string;
      },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsAdd(profileId, text, candidateId);
        dispatch(receiveProfileComments(profileId));
        dispatch(receiveProfileFolders(profileId));
      } catch (e) {
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const updateComment = createAsyncThunk(
    `${sliceName}/updateComment`,
    async (
      {
        profileId,
        text,
        commentId,
      }: {
        commentId: number;
        profileId: number;
        text: string;
      },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsUpdate(profileId, commentId, text);
        dispatch(receiveProfileComments(profileId));
        dispatch(receiveProfileFolders(profileId));
      } catch (e) {
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const deleteComment = createAsyncThunk(
    `${sliceName}/deleteComment`,
    async (
      {
        profileId,
        commentId,
      }: {
        commentId: number;
        profileId: number;
      },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsDelete(profileId, commentId);
        dispatch(receiveProfileComments(profileId));
        dispatch(receiveProfileFolders(profileId));
      } catch (e) {
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const receiveFolderStatuses = createAsyncThunk(
    `${sliceName}/receiveFolderStatuses`,
    async (folderId: number, { rejectWithValue }) => {
      try {
        const { payload } = await apiV6.folderGet(folderId);

        return payload.candidate_statuses;
      } catch (e) {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }
    }
  );

  const changeCandidateStatus = createAsyncThunk(
    `${sliceName}/changeCandidateStatus`,
    async (
      {
        profileId,
        candidateId,
        statusId,
      }: {
        candidateId: number;
        profileId: number;
        statusId: number;
      },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.candidatePatch(candidateId, statusId);
        dispatch(receiveProfileFolders(profileId));
      } catch (e) {
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const fileUploaded = createAction<string>(`${sliceName}/fileUploaded`);

  const attachFile = createAsyncThunk(
    `${sliceName}/attachFile`,
    async (
      {
        formData,
        onSuccess,
        onError,
      }: {
        formData: FormData;
        onError: () => void;
        onSuccess: (fileId: number) => (profile: AH$Profile) => void;
      },
      { rejectWithValue, dispatch }
    ) => {
      startLoading();

      try {
        const { payload } = await apiFilesV1.uploadFile(formData);

        stopLoading();
        dispatch(
          getProfile({
            id: parseInt(formData.get('profile_id') as string, 10),
            loading: false,
            onSuccess: onSuccess(payload.file_id),
          })
        );
        dispatch(fileUploaded((formData.get('file') as File).name));
      } catch (e) {
        stopLoading();
        getErrorHandler()(e);
        onError();
        rejectWithValue(e);
      }
    }
  );

  const deleteFile = createAsyncThunk(
    `${sliceName}/deleteFile`,
    async (
      { id, resumeId, profileId }: { id: number; profileId: number; resumeId: string },
      { rejectWithValue, dispatch }
    ) => {
      startLoading();

      try {
        await apiFilesV1.deleteFile(id, resumeId);

        stopLoading();
        dispatch(
          getProfile({
            id: profileId,
            loading: false,
          })
        );
      } catch (e) {
        stopLoading();
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const enrichByFile = createAsyncThunk(
    `${sliceName}/enrichByFile`,
    async (
      {
        profileId,
        resumeId,
        onSuccess,
        onError,
      }: { onError: () => void; onSuccess: () => void; profileId: number; resumeId: string },
      { rejectWithValue, dispatch }
    ) => {
      startLoading();

      try {
        const { payload } = await apiV6.enrichByFile(profileId, resumeId);

        stopLoading();
        dispatch(getProfileLoaded(transformProfile(payload)));
        onSuccess();
      } catch (e) {
        onError();
        stopLoading();
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const sendProfileReport = createAsyncThunk(
    `${sliceName}/sendProfileReport`,
    async (
      {
        profileId,
        source,
        optionValue,
        textareaValue,
        query,
        onSuccess,
        onError,
      }: {
        onError: () => void;
        onSuccess: () => void;
        optionValue: TReasonProfileReport;
        profileId: number;
        query: string | undefined;
        source: TSourceProfileReport;
        textareaValue: string | undefined;
      },
      { rejectWithValue, dispatch }
    ) => {
      const { setLoading, setError, setSuccess } = createStatusActions(
        dispatch,
        FetchingStatusesActionTypes.sendProfileReport
      );

      setLoading();
      try {
        await reportProfile(profileId, source, optionValue, textareaValue, query);

        setSuccess();
        onSuccess();
      } catch (e) {
        onError();
        setError();
        getErrorHandler()(e);
        rejectWithValue(e);
      }
    }
  );

  const getProfileExternalContacts = createAsyncThunk(
    `${sliceName}/getProfileExternalContacts`,
    async (
      {
        profileId,
        errorMessage,
        onSuccess = noop,
      }: { errorMessage: string; profileId: number; onSuccess?: () => void },
      { rejectWithValue, getState, dispatch }
    ) => {
      try {
        const { payload } = await apiV6.findContacts(profileId);
        const {
          app: { user },
        } = getState() as { app: { user: AH$User } };

        dispatch({
          type: getProfileContacts.fulfilled.toString(),
          payload: payload.displayContacts,
        });

        onSuccess();

        if (!isManager(user.role)) {
          dispatch(receiveLimitsInfo());
        }
      } catch (e) {
        getErrorHandler()({ message: errorMessage, name: 'ResponseError' });
        rejectWithValue(e);
      }
    }
  );

  const slice = createSlice({
    name: sliceName,
    initialState: profileInitialState,
    reducers: {
      storeProfile: (state, action: PayloadAction<API$Profile>) => {
        state.fetchingProfileStatus = FETCHING_STATUSES.SUCCESS;
        state.profile = transformProfile(action.payload);
      },
      setProfileRefererLink: (state, action: PayloadAction<string | null>) => {
        state.referer = action.payload;
      },
      setDisplayTags: (state, action: PayloadAction<AH$DisplayTag[]>) => {
        if (state.profile) state.profile.displayProfileTags = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getProfileFetching, (state) => {
          state.fetchingProfileStatus = FETCHING_STATUSES.LOADING;
          state.error = null;
        })
        .addCase(getProfileLoaded, (state, action) => {
          state.fetchingProfileStatus = FETCHING_STATUSES.SUCCESS;
          state.profile = action.payload;
        })
        .addCase(getProfileErrored, (state, action) => {
          state.fetchingProfileStatus = FETCHING_STATUSES.ERROR;
          if (action.payload) {
            state.error = action.payload;
          }
        })
        .addCase(getProfileContacts.pending, (state) => {
          state.fetchingContactsStatus = FETCHING_STATUSES.LOADING;
        })
        .addCase(getProfileContacts.fulfilled, (state, action) => {
          state.fetchingContactsStatus = FETCHING_STATUSES.SUCCESS;
          const contacts = transformContacts(
            { ...action.payload, link: get(state.profile as AH$Profile, 'displayLinks') },
            get(state.profile as AH$Profile, 'id'),
            get(state.profile as AH$Profile, 'orderId')
          );
          const profile = {
            ...(state.profile as AH$Profile),
            displayContacts: action.payload,
            displayContactMasks: {},
            ...contacts,
          };

          state.profile = profile;
        })
        .addCase(getProfileContacts.rejected, (state) => {
          state.fetchingContactsStatus = FETCHING_STATUSES.ERROR;
        })
        .addCase(removeContact.fulfilled, (state, action) => {
          const { _id, type } = action.payload;
          const contactsIdByType: Map<string, Array<number>> = new Map(
            get(state, 'profile.contactsIdByType')
          );
          const _ids = contactsIdByType.get(type);

          contactsIdByType.set(type, without(_ids, _id));
          const profile = {
            ...(state.profile as AH$Profile),
            contactsById: omit(get(state, 'profile.contactsById'), action.payload._id),
            contactsIdByType,
          };

          state.profile = profile;
        })
        .addCase(setLoadingContactsIds, (state, action) => {
          state.loadingContactsIds = action.payload;
        })
        .addCase(toggleContactsEditing, (state, action) => {
          state.contactsEditing = action.payload;
        })
        .addCase(changeContact, (state, action) => {
          const { _id, type } = action.payload;
          const contactsIdByType: Map<string, Array<number>> = new Map(
            get(state, 'profile.contactsIdByType')
          );
          const _ids: Array<number> = contactsIdByType.get(type) || [];

          contactsIdByType.set(type, uniq([..._ids, _id]));
          const profile = {
            ...(state.profile as AH$Profile),
            contactsById: {
              ...get(state.profile as AH$Profile, 'contactsById'),
              [_id]: action.payload,
            },
            contactsIdByType,
          };

          state.profile = profile;
        })
        .addCase(profileContactsUpdated, (state, action) => {
          const contactsIdByType: Map<string, Array<number>> = new Map(
            action.payload.contactsIdByType
          );
          const profile = {
            ...(state.profile as AH$Profile),
            contactsById: action.payload.contactsById,
            contactsIdByType,
          };

          state.profile = profile;
        })
        .addCase(receiveSimilarProfiles.pending, (state) => {
          state.similarProfiles.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveSimilarProfiles.fulfilled, (state, action) => {
          state.similarProfiles = {
            data: action.payload.data.map(transformProfile),
            count: action.payload.count,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveSimilarProfiles.rejected, (state) => {
          state.similarProfiles.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveProfileResumes.pending, (state) => {
          state.resumes.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveProfileResumes.fulfilled, (state, action) => {
          state.resumes = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveProfileResumes.rejected, (state) => {
          state.resumes.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveProfileComments.pending, (state) => {
          state.comments.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveProfileComments.fulfilled, (state, action) => {
          state.comments = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveProfileComments.rejected, (state) => {
          state.comments.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveProfileFolders.pending, (state) => {
          state.folders.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveProfileFolders.fulfilled, (state, action) => {
          state.folders = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveProfileFolders.rejected, (state) => {
          state.folders.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveProfileViews.pending, (state) => {
          state.views.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveProfileViews.fulfilled, (state, action) => {
          state.views = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveProfileViews.rejected, (state) => {
          state.views.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveMixmaxSequenceReports.pending, (state) => {
          state.mixmaxReports.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveMixmaxSequenceReports.fulfilled, (state, action) => {
          state.mixmaxReports = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveMixmaxSequenceReports.rejected, (state) => {
          state.mixmaxReports.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(receiveFolderStatuses.pending, (state) => {
          state.folderStatuses.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(receiveFolderStatuses.fulfilled, (state, action) => {
          state.folderStatuses = {
            data: action.payload,
            status: FETCHING_STATUSES.SUCCESS,
          };
        })
        .addCase(receiveFolderStatuses.rejected, (state) => {
          state.folderStatuses.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(changeCandidateStatus.pending, (state) => {
          state.folders.status = FETCHING_STATUSES.LOADING;
        })
        .addCase(changeCandidateStatus.rejected, (state) => {
          state.folders.status = FETCHING_STATUSES.ERROR;
        })
        .addCase(getProfileExternalContacts.pending, (state) => {
          state.fetchingExternalContactsStatus = FETCHING_STATUSES.LOADING;
        })
        .addCase(getProfileExternalContacts.fulfilled, (state) => {
          state.fetchingExternalContactsStatus = FETCHING_STATUSES.SUCCESS;
        })
        .addCase(getProfileExternalContacts.rejected, (state) => {
          state.fetchingExternalContactsStatus = FETCHING_STATUSES.ERROR;
        });
    },
  });

  return {
    ...slice.actions,
    getProfileErrored,
    getProfileFetching,
    getProfileLoaded,
    getProfile,
    createProfileFromFile,
    getProfileContacts,
    getProfileExternalContacts,
    removeContact,
    saveContact,
    toggleContactsEditing,
    changeContact,
    receiveSimilarProfiles,
    receiveProfileResumes,
    receiveProfileComments,
    receiveProfileFolders,
    receiveProfileViews,
    receiveMixmaxSequenceReports,
    removeResume,
    addComment,
    updateComment,
    deleteComment,
    receiveFolderStatuses,
    changeCandidateStatus,
    attachFile,
    deleteFile,
    enrichByFile,
    sendProfileReport,
    fileParsingError,
    fileUploadError,
    fileUploaded,
    reducer: slice.reducer,
  };
};

export const { reducer: profileMainReducer, ...profileMainActions } = createProfileSlice(
  REDUCER_KEYS.profileMain
);
export const { reducer: profilePossibleReducer, ...profilePossibleActions } = createProfileSlice(
  REDUCER_KEYS.profilePossible
);

export const getProfileRealtimeDiff =
  ({
    profileId,
    companyId,
    redirectToProfile,
  }: {
    companyId: number;
    profileId: number;
    redirectToProfile: () => void;
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    const handleError = (payload: any, error?: any): void => {
      getErrorHandler({
        getError: () => dispatch(profileMainActions.getProfileErrored(payload)),
      })(error || payload);
    };

    dispatch(profileMainActions.getProfileFetching());

    try {
      const {
        payload: { afterUP, beforeUP },
      } = await apiV6.getProfileRealtimeDiff(profileId, companyId);

      if (afterUP) {
        dispatch(profilePossibleActions.getProfileLoaded(transformProfile(afterUP)));
        dispatch(profileMainActions.getProfileLoaded(transformProfile(beforeUP)));
      } else {
        redirectToProfile();
      }
    } catch (error: any) {
      if (error.response) {
        error.response
          .json()
          .then((errorResponse: any) => {
            if (errorResponse.status.code) {
              handleError(errorResponse, error);
            } else {
              handleError(error);
            }
          })
          .catch(() => handleError(error));
      } else {
        handleError(error);
      }
    }
  };
