import { DragEvent, useState } from 'react';

const useDnD = (
  callback: (e: DragEvent<HTMLElement>) => void
): {
  dndHandlers: {
    onDragEnter: () => void;
    onDragLeave: () => void;
    onDragOver: (e: DragEvent<HTMLElement>) => void;
    onDrop: (e: DragEvent<HTMLElement>) => void;
  };
  isDragging: boolean;
} => {
  const [dragCounter, setDragCounter] = useState(0);

  return {
    isDragging: !!dragCounter,
    dndHandlers: {
      onDragEnter: (): void => {
        setDragCounter((prevState) => prevState + 1);
      },
      onDragLeave: (): void => {
        setDragCounter((prevState) => prevState - 1);
      },
      onDragOver: (e: DragEvent<HTMLElement>): void => {
        e.preventDefault();
      },
      onDrop: (e: DragEvent<HTMLElement>): void => {
        e.preventDefault();
        setDragCounter(0);
        callback(e);
      },
    },
  };
};

export default useDnD;
