import * as React from 'react';
import { useIntl } from 'react-intl';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import messages from 'components/Messaging/Message/Editor/messages';
import styles from './UploadFile.scss';

const theme = thematize(styles);

export const ACCEPTABLE_FILES =
  '.doc,.docx,.pdf,.odt,.csv,.ods,.rtf,.txt,.jpg,.jpeg,.gif,.png,.svg,.txt';

interface Props {
  onFileUpload: (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) => void;
  index?: number;
}

const UploadFile = ({ onFileUpload, index }: Props): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip id={`file-upload-tooltip-${index}`} message={formatMessage(messages.attachFiles)}>
      <label
        htmlFor={`file-upload-${index}`}
        className={theme('container')}
        {...dataQa('file-upload')}
      >
        <input
          multiple
          id={`file-upload-${index}`}
          type="file"
          onChange={onFileUpload}
          accept={ACCEPTABLE_FILES}
        />
        <Icon type="file" />
      </label>
    </Tooltip>
  );
};

export default UploadFile;
