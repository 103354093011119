import { ChangeEvent, useState } from 'react';

interface Values {
  [key: string]: any;
}
interface Return {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setValues: (values: Values) => void;
  values: Values;
}

const useForm = ({ initialValues }: Values): Return => {
  const [values, setValues] = useState(initialValues || {});
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setValues({
      ...values,
      [target.id]: value,
    });
  };

  return {
    values,
    handleChange,
    setValues,
  };
};

export default useForm;
