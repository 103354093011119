import { ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  createAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  camelCase,
  difference,
  get,
  isEmpty,
  keys,
  mapValues,
  noop,
  omit,
  snakeCase,
} from 'lodash';
import queryString from 'query-string';

import { AH$RouterQuery } from 'types';
import { FETCHING_STATUSES, VENDORS } from 'constants/globals';
import * as api from 'lib/api';
import {
  passwordRecover,
  passwordRecoveryEmailGet,
  passwordReset,
  recaptchaSitekeyGet,
  updateHMCompanyDisplaySectionsAsAdmin,
  updateHMCompanyDisplaySectionsAsManager,
  updateUserSettings as apiUpdateUserSettings,
  usersGet,
} from 'lib/apiV6';
import { camelKeys, camelKeysRecursive } from 'lib/object';
import { pingServerForData } from 'lib/pingServerForData';
import {
  bodyScrollDisable,
  bodyScrollEnable,
  enrichApps,
  startLoading,
  stopLoading,
  transformUser,
} from 'lib/utils';
import Store from 'stores/ReduxStore';
import errorMessages from 'components/FormattedError/messages';
import { FetchingStatusesActionTypes } from './fetchingStatuses';
import { systemMessageCleared, systemMessageReceived } from './systemMessage';
import { createStatusActions } from './utils';

const SLICE_NAME = 'app';

export type AppState = {
  readonly apps: AH$Apps;
  readonly bodyRect: DOMRect;
  readonly chosenCandidatesIds: Set<number>;
  readonly dialogModal: AH$Modal | null;
  readonly fetchingSocialAccountsStatus: (typeof FETCHING_STATUSES)[keyof typeof FETCHING_STATUSES];
  readonly limitsInfo: AH$LimitsInfo;
  readonly modal: AH$Modal;
  readonly openedRightSlide: boolean;
  readonly profileReferer: string | null;
  readonly recaptchaSitekey: string | null;
  readonly snackbar: { show: boolean; content?: ReactNode };
  readonly updateTimeouts: {
    [key: string]: {
      interval: NodeJS.Timeout;
      timeout: NodeJS.Timeout;
    };
  };
  readonly user: AH$User;
  readonly users: Array<API$Assignee>;
};

export const appInitialState: AppState = {
  user: {} as AH$User,
  users: [],
  chosenCandidatesIds: new Set<number>(),
  modal: {
    show: false,
    content: null,
    callback: undefined,
  },
  apps: {} as AH$Apps,
  limitsInfo: {} as AH$LimitsInfo,
  fetchingSocialAccountsStatus: FETCHING_STATUSES.SUCCESS,
  openedRightSlide: false,
  profileReferer: null,
  updateTimeouts: {},
  dialogModal: null,
  recaptchaSitekey: null,
  bodyRect: document.body.getBoundingClientRect(),
  snackbar: { show: false, content: null },
};

export const sendSystemMessage = ({
  payload,
  warning,
  error,
}: {
  payload: AH$SystemMessage['data'];
  error?: boolean;
  formatted?: boolean;
  warning?: boolean;
}): void => {
  Store.dispatch(
    systemMessageReceived({
      data: payload,
      warning,
      error,
    })
  );
};

export const clearSystemMessage = (): void => {
  Store.dispatch(systemMessageCleared());
};

export const handleError = (err: any): void => {
  sendSystemMessage({
    payload: err,
    error: true,
  });
};

export const getErrorHandler =
  (params?: {
    formatError?: boolean;
    getError?: (e: any) => void;
    parseError?: boolean;
    silent?: boolean;
  }) =>
  (e: any): any => {
    stopLoading();

    if (e?.name !== 'ResponseError') {
      throw e;
    }

    const handleBaseError = (): void => {
      if (!params || !params.silent) {
        handleError({
          content: [get(errorMessages, e.response.status, errorMessages.defaultMessage)],
          formatted: false,
        });
      }
    };

    const handleDefaultError = (): void => {
      handleBaseError();
      if (params && params.getError) {
        params.getError(e);
      }
    };

    if (params && params.parseError && params.getError && e?.response) {
      e.response
        .json()
        .then((payload: any): void => {
          params.getError?.(
            params.formatError ? mapValues(payload, (message) => message[0]) : payload
          );
        })
        .catch(handleBaseError);
    } else {
      handleDefaultError();
    }
  };

const modalSet = createAction<AH$Modal>(`${SLICE_NAME}/modalSet`);

export const showModal =
  (modal: Partial<AH$Modal>) =>
  (dispatch: Dispatch): void => {
    bodyScrollDisable();
    dispatch(
      modalSet({
        ...modal,
        show: true,
      })
    );
  };

export const hideModal =
  () =>
  (dispatch: Dispatch, getState: () => { app: AppState }): void => {
    const { callback } = getState().app.modal;

    if (callback) callback();
    bodyScrollEnable();
    dispatch(
      modalSet({
        show: false,
        content: null,
        callback: undefined,
      })
    );
  };

export const receiveUsers = createAsyncThunk(
  `${SLICE_NAME}/receiveUsers`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await usersGet({ role: ['admin', 'worker'], limit: 1000 });

      return payload.results;
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export type NavigateTo =
  | string
  | {
      hash?: string;
      pathname?: string;
      query?: AH$RouterQuery;
      replace?: boolean;
      search?: string;
      state?: any;
    };

export const changeRoute =
  (navigate: NavigateFunction) =>
  (to: NavigateTo): void => {
    if (typeof to === 'string') {
      navigate(to);
    } else {
      const { pathname, search, query, hash, replace = false, state } = to;
      const resultSearch = search || queryString.stringify(query);

      navigate(
        {
          pathname,
          search: resultSearch,
          hash,
        },
        {
          replace,
          state,
        }
      );
    }
  };

export const socialAccountsLoading = createAction<string | undefined>(
  `${SLICE_NAME}/socialAccountsLoading`
);

const socialAccountsLoaded = createAction<AH$SocialAccounts>(`${SLICE_NAME}/socialAccountsLoaded`);

const socialAccountsErrored = createAction<string | undefined>(
  `${SLICE_NAME}/socialAccountsErrored`
);

export const receiveSocialAccounts = createAsyncThunk(
  `${SLICE_NAME}/receiveSocialAccounts`,
  async (_, { dispatch }) => {
    dispatch(socialAccountsLoading());
    try {
      const { payload } = await api.socialAccountsGet();

      dispatch(
        socialAccountsLoaded(
          camelKeysRecursive(camelKeysRecursive(omit(payload, 'telegram'))) as AH$SocialAccounts
        )
      );
    } catch (e) {
      getErrorHandler()(e);
      dispatch(socialAccountsErrored());
    }
  }
);

export const socialAccountCreated = createAction<AH$SocialAccount>(
  `${SLICE_NAME}/socialAccountCreated`
);

export const addSocialAccount = createAsyncThunk(
  `${SLICE_NAME}/addSocialAccount`,
  async ({ id, token }: { id: string; token: string }, { dispatch }) => {
    dispatch(socialAccountsLoading(id));

    try {
      const { payload } = await api.socialAccountsPost(id, token);

      dispatch(socialAccountCreated(payload));
    } catch (e) {
      getErrorHandler()(e);
      dispatch(socialAccountsErrored());
    }
  }
);

export const socialAccountDeleted = createAction<string>(`${SLICE_NAME}/socialAccountDeleted`);

export const deleteSocialAccount = createAsyncThunk(
  `${SLICE_NAME}/deleteSocialAccount`,
  async (id: string, { dispatch }) => {
    dispatch(socialAccountsLoading(id));

    try {
      await api.socialAccountDelete(id);

      dispatch(socialAccountDeleted(id));
    } catch (e) {
      getErrorHandler()(e);
      dispatch(socialAccountsErrored(id));
    }
  }
);

export const getEmailAliases = createAsyncThunk(
  `${SLICE_NAME}/getEmailAliases`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.getEmailAliases();

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const renewAndGetEmailAliases = createAsyncThunk(
  `${SLICE_NAME}/renewAndGetEmailAliases`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.renewAndGetEmailAliases();

      return payload;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const receiveLimitsInfo = createAsyncThunk(
  `${SLICE_NAME}/receiveLimitsInfo`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.checkCreditLimits();
      const {
        quotas_contact_available: contactsAvailable,
        quotas_contact_total: contactsTotal,
        quotas_data_enrichment_available: dataEnrichmentAvailable,
        quotas_data_enrichment_total: dataEnrichmentTotal,
      } = payload;
      const contactsSpent = contactsTotal - contactsAvailable;

      return {
        contactsAvailable,
        contactsTotal,
        contactsSpent,
        dataEnrichmentAvailable,
        dataEnrichmentTotal,
      };
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

const setUpdateTimeouts = createAction<{
  interval: NodeJS.Timeout;
  name: string;
  timeout: NodeJS.Timeout;
}>(`${SLICE_NAME}/setUpdateTimeouts`);

export const startPingServerForData =
  ({
    name,
    callback,
    updateTimings = {},
  }: {
    callback: () => void;
    name: string;
    updateTimings?: {
      updateIntervalTime?: number;
      updateTimeoutTime?: number;
      updateTimes?: number;
    };
  }) =>
  (dispatch: Dispatch): void => {
    const { timeout, interval } = pingServerForData(callback, updateTimings);

    dispatch(setUpdateTimeouts({ name, timeout, interval }));
  };

export const currentUserReceived = createAction<AH$User>(`${SLICE_NAME}/currentUserReceived`);

export const getCurrentUser = createAsyncThunk(
  `${SLICE_NAME}/getCurrentUser`,
  async (_, { dispatch }) => {
    try {
      const { payload } = await api.currentUser();

      dispatch(
        currentUserReceived(
          transformUser(payload, payload.system_notifications.map(camelKeys<AH$SystemNotification>))
        )
      );
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const getRecaptchaSitekey = createAsyncThunk(
  `${SLICE_NAME}/getRecaptchaSitekey`,
  async (_, { rejectWithValue }) => {
    startLoading();

    try {
      const { payload } = await recaptchaSitekeyGet();

      stopLoading();
      return payload.key;
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const recoverPassword =
  ({
    data,
    onSuccess,
  }: {
    data: API$RecoverPasswordData;
    onSuccess: (data: API$RecoverPasswordPayload) => void;
  }) =>
  async (dispatch: Dispatch) => {
    const { setLoading, setError, setSuccess } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.passwordRecovered
    );

    setLoading();

    try {
      const { payload } = await passwordRecover(data);

      setSuccess();
      onSuccess(payload);
    } catch (e) {
      getErrorHandler({ parseError: true, formatError: true, getError: (err) => setError(err) })(e);
    }
  };

export const getPasswordRecoveryEmail =
  ({
    token,
    onSuccess,
    onError,
  }: {
    onError: (e: unknown) => void;
    onSuccess: (email: string) => void;
    token: string;
  }) =>
  async () => {
    try {
      const { payload } = await passwordRecoveryEmailGet(token);

      onSuccess(payload.email);
    } catch (e) {
      onError?.(e);
      getErrorHandler()(e);
    }
  };

export const resetPassword =
  ({
    token,
    data,
    onSuccess,
    onError,
  }: {
    data: API$ResetPasswordData;
    onSuccess: () => void;
    token: string;
    onError?: (e: unknown) => void;
  }) =>
  async (dispatch: Dispatch) => {
    const { setLoading, setError, setSuccess } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.passwordReset
    );

    setLoading();

    try {
      await passwordReset(token, data);
      setSuccess();
      onSuccess();
    } catch (e) {
      onError?.(e);
      getErrorHandler({ parseError: true, formatError: true, getError: (err) => setError(err) })(e);
    }
  };

export const updateHMCompanyDisplaySections = createAsyncThunk(
  `${SLICE_NAME}/updateHMCompanyDisplaySections`,
  async (
    {
      companyId,
      displaySections,
      onSuccess = noop,
    }: {
      companyId: string | null;
      displaySections: Array<string>;
      onSuccess: () => void;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.hmSettingsSaved
    );

    setLoading();

    try {
      if (companyId) {
        await updateHMCompanyDisplaySectionsAsManager(companyId, displaySections.map(snakeCase));
      } else {
        await updateHMCompanyDisplaySectionsAsAdmin(displaySections.map(snakeCase));
      }
      const { payload } = await api.currentUser();

      dispatch(currentUserReceived(transformUser(payload)));
      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);
      setError();
    }
  }
);

export const updateUserSettings = createAsyncThunk(
  `${SLICE_NAME}/updateUserSettings`,
  async (
    {
      payload,
      handleResponse,
    }: {
      handleResponse: (promise: Promise<any>) => void;
      payload: {
        first_name?: string;
        language?: string;
        last_name?: string;
        old_password?: string;
        password?: string;
      };
    },
    { dispatch }
  ) => {
    handleResponse(
      apiUpdateUserSettings(payload).then(() => {
        api
          .currentUser()
          .then(({ payload }) => {
            dispatch(currentUserReceived(transformUser(payload)));
          })
          .catch(getErrorHandler());
      })
    );
  }
);

export const updateUserInCurrentCompany =
  (payload: API$CompanyUpdateData) =>
  (dispatch: Dispatch, getState: () => { app: AppState }): void => {
    const { user } = getState().app;

    dispatch(
      currentUserReceived({ ...user, company: { ...user.company, ...camelKeysRecursive(payload) } })
    );
  };

const appSlice = createSlice({
  name: SLICE_NAME,
  initialState: appInitialState,
  reducers: {
    updateCurrentFromEmail: (state, action: PayloadAction<string>) => {
      state.user.emailAliases.from = {
        ...state.user.emailAliases.from,
        email: action.payload,
      };
    },
    chooseCandidates: (state, action: PayloadAction<number[]>) => {
      const chosenCandidatesIds: Set<number> = new Set([
        ...Array.from(state.chosenCandidatesIds),
        ...action.payload,
      ]);

      state.chosenCandidatesIds = chosenCandidatesIds;
      state.openedRightSlide = !!chosenCandidatesIds.size;
    },
    removeCandidates: (state, action: PayloadAction<number[] | undefined>) => {
      const chosenCandidatesIds: Set<number> = new Set(
        action.payload ? difference(Array.from(state.chosenCandidatesIds), action.payload) : null
      );

      state.chosenCandidatesIds = chosenCandidatesIds;
      state.openedRightSlide = !!chosenCandidatesIds.size;
    },
    openRightSlide: (state) => {
      state.openedRightSlide = true;
    },
    closeRightSlide: (state) => {
      state.openedRightSlide = false;
    },
    setProfileReferer: (state, action: PayloadAction<AppState['profileReferer']>) => {
      state.profileReferer = action.payload;
    },
    stopPingServer: (state, action: PayloadAction<string>) => {
      if (!isEmpty(state.updateTimeouts[action.payload])) {
        const { timeout, interval } = state.updateTimeouts[action.payload];

        clearTimeout(timeout);
        clearInterval(interval);
      }

      state.updateTimeouts = {
        ...omit(state.updateTimeouts, action.payload),
      };
    },
    setDialogModal: (state, action: PayloadAction<AH$Modal | undefined>) => {
      state.dialogModal = action.payload ? { ...state.dialogModal, ...action.payload } : null;
    },
    setSnackbar: (
      state,
      action: PayloadAction<{ show: boolean; content?: ReactNode | null } | null>
    ) => {
      state.snackbar = {
        ...state.snackbar,
        ...(action.payload || { show: false, content: null }),
      };
    },
    setBodyRect: (state, action: PayloadAction<DOMRect>) => {
      state.bodyRect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(modalSet, (state, action) => {
        state.modal = action.payload;
      })
      .addCase(receiveUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(socialAccountsLoading, (state, action) => {
        const setAppsLoading = <T extends { [key: string]: any }>(apps: T): T =>
          keys(apps).reduce((acc, key) => {
            const value = apps[key];

            return {
              ...acc,
              [key]: value.id
                ? {
                    ...value,
                    loading: action.payload ? value.id === action.payload : true,
                  }
                : setAppsLoading(value),
            };
          }, {} as T);

        const apps = setAppsLoading(state.apps);

        state.apps = apps;
        state.fetchingSocialAccountsStatus = FETCHING_STATUSES.LOADING;
      })
      .addCase(socialAccountsLoaded, (state, action) => {
        const { email, name } = action.payload.outlook || action.payload.gmail || {};

        const user = {
          ...state.user,
          socialAccounts: action.payload,
          emailAliases: {
            ...state.user.emailAliases,
            from: { email, name },
            baseEmail: email,
          },
        };

        state.user = user;
        state.apps = enrichApps(
          user.socialAccounts,
          get(user, 'company.availableApps', []),
          VENDORS
        );
        state.fetchingSocialAccountsStatus = FETCHING_STATUSES.SUCCESS;
      })
      .addCase(socialAccountsErrored, (state, action) => {
        const setAppsLoading = <T extends { [key: string]: any }>(apps: T): T =>
          keys(apps).reduce((acc, key) => {
            const value = apps[key];

            return {
              ...acc,
              [key]: value.id
                ? {
                    ...value,
                    loading: action.payload && value.id === action.payload ? false : value.loading,
                  }
                : setAppsLoading(value),
            };
          }, {} as T);
        const apps = setAppsLoading(state.apps);

        state.apps = apps;
        state.fetchingSocialAccountsStatus = FETCHING_STATUSES.ERROR;
      })
      .addCase(socialAccountCreated, (state, action) => {
        const user = {
          ...state.user,
          socialAccounts: {
            ...state.user.socialAccounts,
            [action.payload.vendor]: action.payload,
          },
        };

        state.user = user;
        state.apps = enrichApps(
          user.socialAccounts,
          get(user, 'company.availableApps', []),
          VENDORS
        );
        state.fetchingSocialAccountsStatus = FETCHING_STATUSES.SUCCESS;
      })
      .addCase(socialAccountDeleted, (state, action) => {
        const user = {
          ...state.user,
          socialAccounts: omit(state.user.socialAccounts, camelCase(action.payload)),
        };

        state.user = user;
        state.apps = enrichApps(
          user.socialAccounts,
          get(user, 'company.availableApps', []),
          VENDORS
        );
        state.fetchingSocialAccountsStatus = FETCHING_STATUSES.SUCCESS;
      })
      .addCase(getEmailAliases.pending, (state) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.LOADING,
        };
      })
      .addCase(getEmailAliases.fulfilled, (state, action) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.SUCCESS,
          aliases: action.payload,
        };
      })
      .addCase(getEmailAliases.rejected, (state) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.ERROR,
        };
      })
      .addCase(renewAndGetEmailAliases.pending, (state) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.LOADING,
        };
      })
      .addCase(renewAndGetEmailAliases.fulfilled, (state, action) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.SUCCESS,
          aliases: action.payload,
        };
      })
      .addCase(renewAndGetEmailAliases.rejected, (state) => {
        state.user.emailAliases = {
          ...state.user.emailAliases,
          status: FETCHING_STATUSES.ERROR,
        };
      })
      .addCase(receiveLimitsInfo.fulfilled, (state, action) => {
        state.limitsInfo = action.payload;
      })
      .addCase(setUpdateTimeouts, (state, action) => {
        if (!isEmpty(state.updateTimeouts[action.payload.name])) {
          const { timeout, interval } = state.updateTimeouts[action.payload.name];

          clearTimeout(timeout);
          clearInterval(interval);
        }

        state.updateTimeouts = {
          ...state.updateTimeouts,
          [action.payload.name]: {
            timeout: action.payload.timeout,
            interval: action.payload.interval,
          },
        };
      })
      .addCase(currentUserReceived, (state, action) => {
        state.user = action.payload;
        state.apps = enrichApps(
          action.payload.socialAccounts,
          get(action.payload, 'company.availableApps', []),
          VENDORS
        );
      })
      .addCase(getRecaptchaSitekey.fulfilled, (state, action) => {
        state.recaptchaSitekey = action.payload;
      });
  },
});

export const {
  chooseCandidates,
  removeCandidates,
  openRightSlide,
  closeRightSlide,
  setProfileReferer,
  stopPingServer,
  setDialogModal,
  setSnackbar,
  setBodyRect,
  updateCurrentFromEmail,
} = appSlice.actions;
export const app = appSlice.reducer;
