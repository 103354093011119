import { FC } from 'react';
import { useIntl } from 'react-intl';

import thematize from 'lib/thematize';
import Button from 'components/Base/Button';
import Icon from 'components/Base/Icon';
import Text, { Is } from 'components/Base/Text';
import messages from '../../messages';
import styles from './DefaultShortNotification.scss';

const theme = thematize(styles);

type DefaultNotificationProps = {
  handleHideClick: () => void;
  handleLearnMoreClick: () => void;
  notification: AH$SystemNotification;
};

const DefaultShortNotification: FC<DefaultNotificationProps> = ({
  notification,
  handleLearnMoreClick,
  handleHideClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={theme('container')}>
      <Icon type="info" className={theme('info')} />
      <div className={theme('content')}>
        <Text is={Is.Regular} className={theme('title')}>
          {notification.title}
        </Text>
        <div className={theme('buttons')}>
          <Button appearance="filled" color="primary" onClick={handleLearnMoreClick}>
            {formatMessage(messages.learnMoreButton)}
          </Button>
          <Button appearance="text" color="default" onClick={handleHideClick}>
            {formatMessage(messages.hideButton)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DefaultShortNotification;
