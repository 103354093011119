import * as React from 'react';
import { Link } from 'react-router-dom';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import styles from 'components/SideMenu/SideMenu.scss';
import SideMenuDropdown from './SideMenuDropdown';

const theme = thematize(styles);

const sendAnalytics = (url: string) => {
  tracking.clickSideMenu(url);
};

interface Props {
  iconName: React.ComponentProps<typeof Icon>['type'];
  isUsingRouter: boolean;
  title: React.ReactElement;
  children?: Array<React.ReactElement> | React.ReactElement;
  forceOpened?: boolean;
  linkTo?: string;
}

const BottomItem = ({
  linkTo,
  iconName,
  isUsingRouter,
  title,
  children,
  ...props
}: Props): React.ReactElement => {
  const renderButton = (): React.ReactElement => {
    if (!linkTo) {
      return (
        <div>
          <div className={theme('icon')}>
            <Icon type={iconName} />
          </div>
          {title}
        </div>
      );
    }
    return isUsingRouter ? (
      <Link to={linkTo} {...props} onClick={() => sendAnalytics(linkTo)}>
        <div className={theme('icon')}>
          <Icon type={iconName} />
        </div>
        {title}
      </Link>
    ) : (
      <a href={linkTo} {...props} onClick={() => sendAnalytics(linkTo)}>
        <div className={theme('icon')}>
          <Icon type={iconName} />
        </div>
        {title}
      </a>
    );
  };

  return (
    <div className={theme('item')}>
      {children ? (
        <SideMenuDropdown inverted buttonContent={renderButton()} {...props}>
          {children}
        </SideMenuDropdown>
      ) : (
        renderButton()
      )}
    </div>
  );
};

BottomItem.defaultProps = {
  isUsingRouter: false,
};

export default BottomItem;
