import { flow, noop } from 'lodash';

import { useModal } from 'hooks';
import DeleteDialog, { Props as DialogProps } from 'components/Base/Dialog/DeleteDialog';

type Callback = (props: DialogProps) => void;

const useDeleteModal = (): Callback => {
  const { setModal, closeModal } = useModal();

  return ({ onDelete = noop, onCancel = noop, ...otherProps }) => {
    const handleDelete = flow([closeModal, onDelete]);
    const handleCancel = flow([closeModal, onCancel]);

    setModal(<DeleteDialog onDelete={handleDelete} onCancel={handleCancel} {...otherProps} />);
  };
};

export default useDeleteModal;
