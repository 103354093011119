import { CATEGORIES, SearchType } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const deleteQuery = (Search_Type: SearchType.history | SearchType.savedQueries): void => {
  sendEvent('Delete Query', {
    Location: LOCATIONS.home,
    Category: CATEGORIES.query,
    Search_Type,
  });
};

export const openQueryFilter = (Search_Type: SearchType, Location: LOCATIONS): void => {
  sendEvent('Open Query Filters', {
    Location,
    Category: CATEGORIES.query,
    Search_Type,
  });
};

export const getSearchResults = (Search_Profiles_Count: number, Page: number): void => {
  sendEvent('Get Search Results', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
    Search_Profiles_Count,
    Page,
  });
};

export const clickShowWithoutContacts = (): void => {
  sendEvent('Click Show Without Contacts', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
  });
};

export const searchProfilesSort = (Sorted_Value: string): void => {
  sendEvent('Sort Search Profiles', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
    Sorted_Value,
  });
};

export const changeSearchFilter = (
  Is_Excluded: boolean,
  Facet_ID: string,
  Filter_Value: string,
  Is_Default_Opened: boolean,
  Filter_Type: 'Checkbox' | 'Range' | 'Header',
  Is_Checked: boolean,
  Filter_Days?: number,
  Filter_By?: string
): void => {
  sendEvent('Change Checkbox Filter', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
    Is_Excluded,
    Facet_ID,
    Filter_Value,
    Filter_Type,
    Is_Default_Opened,
    Is_Checked,
    Filter_Days,
    Filter_By,
  });
};

export const clickResetSearchFilters = (): void => {
  sendEvent('Click Reset Search Filters', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
  });
};

export const startSearch = (Search_Type: SearchType, Location?: LOCATIONS): void => {
  sendEvent('Start Search', {
    Location: Location || LOCATIONS.home,
    Search_Type,
    Category: CATEGORIES.query,
  });
};

export const clickSourcingTips = (Value: string): void => {
  sendEvent('Click Sourcing Tips', {
    Location: LOCATIONS.home,
    Category: CATEGORIES.search,
    Value,
  });
};

export const openSynonyms = (Location: LOCATIONS): void => {
  sendEvent('Open Synonym', { Location, Category: CATEGORIES.query });
};

export const openSubSynonyms = (Location: LOCATIONS): void => {
  sendEvent('Open SubSynonym', {
    Location,
    Category: CATEGORIES.query,
  });
};

export const changeLocationRange = (Location: LOCATIONS): void => {
  sendEvent('Change Location Range', { Location, Category: CATEGORIES.query });
};

export const openMetaqueriesModal = (Search_Type: SearchType, Location?: LOCATIONS): void => {
  sendEvent('Open Metaqueries Modal', {
    Location: Location || LOCATIONS.home,
    Search_Type,
    Category: CATEGORIES.query,
  });
};

export const selectMetaquery = (
  Search_Type: SearchType,
  Metaquery_Name: string,
  Location?: LOCATIONS
): void => {
  sendEvent('Select Metaquery', {
    Location: Location || LOCATIONS.home,
    Search_Type,
    Category: CATEGORIES.query,
    Metaquery_Name,
  });
};

export const getRecommendations = (Profiles_Count: number): void => {
  sendEvent('Get Recommendations', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.search,
    Profiles_Count,
  });
};

export const includeTerm = (Location: LOCATIONS): void => {
  sendEvent('Include Term', { Location, Category: CATEGORIES.query });
};

export const excludeTerm = (Location: LOCATIONS): void => {
  sendEvent('Exclude Term', { Location, Category: CATEGORIES.query });
};

export const deleteTerm = (Location: LOCATIONS): void => {
  sendEvent('Delete Term', { Location, Category: CATEGORIES.query });
};
