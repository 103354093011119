import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const clickRequestDemo = (): void => {
  sendEvent('Request Demo', {
    Location: LOCATIONS.login,
    Category: CATEGORIES.login,
  });
};

export const clickInstallExtension = (): void => {
  sendEvent('Click Install Extension', {
    Location: LOCATIONS.login,
    Category: CATEGORIES.login,
  });
};
