import { MutableRefObject, useEffect, useState } from 'react';

interface Coordinates {
  height: number;
  left: number;
  top: number;
  width: number;
}

const useElementCoordinates = (elemenetRef: MutableRefObject<any>): Coordinates => {
  const [coordinates, setCoordinates] = useState({
    top: 9999,
    left: 9999,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (elemenetRef && elemenetRef.current) {
      const { top, left, width, height } = elemenetRef.current.getBoundingClientRect();
      const scrollLeft =
        window.pageXOffset || (document.documentElement ? document.documentElement.scrollLeft : 0);
      const scrollTop =
        window.pageYOffset || (document.documentElement ? document.documentElement.scrollTop : 0);

      if (
        top + scrollTop !== coordinates.top ||
        left + scrollLeft !== coordinates.left ||
        width !== coordinates.width ||
        height !== coordinates.height
      ) {
        setCoordinates({ top: top + scrollTop, left: left + scrollLeft, width, height });
      }
    }
  }, [elemenetRef, setCoordinates, coordinates]);

  return coordinates;
};

export default useElementCoordinates;
