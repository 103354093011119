import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, noop, omit } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise } from 'lib/abortablePromise';
import { getApiStatsByCompany } from 'lib/api';
import * as api from 'lib/apiV6';
import { camelKeys } from 'lib/object';
import { startLoading, stopLoading, transformDisplaySections } from 'lib/utils';
import { State } from 'stores/ReduxStore';
import { getErrorHandler, handleError, updateUserInCurrentCompany } from 'slices/app';
import { FetchingStatusesActionTypes } from './fetchingStatuses';
import { createStatusActions } from './utils';

type CompanyFormData =
  | {
      data: null | AH$CompanyFormData;
      status: (typeof FETCHING_STATUSES)['LOADING'] | (typeof FETCHING_STATUSES)['ERROR'];
    }
  | { data: AH$CompanyFormData; status: (typeof FETCHING_STATUSES)['SUCCESS'] };

type Company =
  | {
      data: null | API$Company;
      status: (typeof FETCHING_STATUSES)['LOADING'] | (typeof FETCHING_STATUSES)['ERROR'];
    }
  | { data: API$Company; status: (typeof FETCHING_STATUSES)['SUCCESS'] };

type EmployeeStats =
  | {
      data: null | AH$EmployeeStats;
      status: (typeof FETCHING_STATUSES)['LOADING'] | (typeof FETCHING_STATUSES)['ERROR'];
    }
  | { data: AH$EmployeeStats; status: (typeof FETCHING_STATUSES)['SUCCESS'] };

type Employee =
  | {
      data: null | API$Assignee;
      status: (typeof FETCHING_STATUSES)['LOADING'] | (typeof FETCHING_STATUSES)['ERROR'];
    }
  | { data: API$Assignee; status: (typeof FETCHING_STATUSES)['SUCCESS'] };

type CompanyStatistics =
  | {
      data: null | Array<AH$EmployeeInfo>;
      pages: null;
      status: Exclude<keyof typeof FETCHING_STATUSES, 'SUCCESS'>;
    }
  | {
      data: Array<AH$EmployeeInfo>;
      pages: API$Pages;
      status: Extract<keyof typeof FETCHING_STATUSES, 'SUCCESS'>;
    };

export type AdminPanelState = {
  readonly HMDisplaySections: {
    displaySections: {
      changeCandidate?: boolean;
      comments?: boolean;
      contacts?: boolean;
      exportAts?: boolean;
      exportCsv?: boolean;
      messagingInfo?: boolean;
      messagingMessages?: boolean;
      personalInfo?: boolean;
      resumes?: boolean;
      tags?: boolean;
    };
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly activeUsers: {
    status: $Values<typeof FETCHING_STATUSES>;
    users: AH$ActiveUsers;
  };
  readonly apiStats: {
    data: AH$ApiStats;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly companies: {
    data: Array<AH$CompanyShort>;
    pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly company: Company;
  readonly companyFormData: CompanyFormData;
  readonly companyStatistics: CompanyStatistics;
  readonly employee: Employee;
  readonly employeeStats: EmployeeStats;
  readonly employees: {
    data: Array<AH$Assignee>;
    pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly extensionNotifications: {
    data: Array<AH$ExtensionNotification>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly hiringManagers: {
    data: Array<AH$Assignee>;
    pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly metaQueries: {
    data: Array<API$MetaQuery>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly salesManagers: {
    data: Array<AH$Assignee>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly systemNotifications: {
    data: Array<AH$SystemNotification>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly viewsHistory: {
    data: AH$ViewsHistory;
    status: $Values<typeof FETCHING_STATUSES>;
  };
};

const SLICE_NAME = 'adminPanel';

let companiesRequest: null | AbortablePromise<{
  payload: API$PaginatedResponse<API$CompanyShort>;
}> = null;

export const getHMCompanyDisplaySections = createAsyncThunk(
  `${SLICE_NAME}/getHMCompanyDisplaySections`,
  async (companyId: string | null, { rejectWithValue }) => {
    try {
      if (companyId) {
        const { payload } = await api.getHMCompanyDisplaySectionsAsManager(companyId);

        return payload;
      }

      const { payload } = await api.getHMCompanyDisplaySectionsAsAdmin();

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getActiveUsers = createAsyncThunk(
  `${SLICE_NAME}/getActiveUsers`,
  async (filter: 'ext' | 'search' | undefined, { rejectWithValue }) => {
    try {
      const { payload } = await api.getActiveUsers({ system_part: filter });

      return payload.map(camelKeys) as AH$ActiveUsers;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getCompanyFormData = createAsyncThunk(
  `${SLICE_NAME}/getCompanyFormData`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.getCompanyFormData();

      return camelKeys(payload) as AH$CompanyFormData;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const createCompany = createAsyncThunk(
  `${SLICE_NAME}/createCompany`,
  async (
    {
      data,
      onSuccess = noop,
    }: {
      data: API$CompanyFormValues;
      onSuccess: (id: number) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.companyCreated
    );

    try {
      setLoading();
      const { payload } = await api.companyCreate(data);

      setSuccess();
      onSuccess(payload.id);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(error);
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getCompanies = createAsyncThunk(
  `${SLICE_NAME}/getCompanies`,
  async (params: API$CompaniesParams | undefined, { rejectWithValue }) => {
    if (companiesRequest && companiesRequest.abort) {
      companiesRequest.abort();
    }

    companiesRequest = abortable(api.companiesGet(params));
    try {
      const { payload } = await companiesRequest;

      return {
        data: payload.results.map(camelKeys) as Array<AH$CompanyShort>,
        pages: omit(payload, 'results'),
      };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);
      }

      return rejectWithValue(e);
    }
  }
);

export const getCompany = createAsyncThunk(
  `${SLICE_NAME}/getCompany`,
  async (id: string, { rejectWithValue }) => {
    try {
      const { payload } = await api.companyGet(id);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getCompanyStatistics = createAsyncThunk(
  `${SLICE_NAME}/getCompanyStatistics`,
  async (
    { id, params }: { id: number; params?: API$CompanyStatisticsQueryParams },
    { rejectWithValue }
  ) => {
    try {
      startLoading();
      const { payload } = await api.getCompanyStatistics(id, params);

      return {
        data: payload.results.map(camelKeys) as Array<AH$EmployeeInfo>,
        pages: omit(payload, 'results'),
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  `${SLICE_NAME}/deleteCompany`,
  async ({ id, onSuccess = noop }: { id: number; onSuccess: () => void }, { dispatch }) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.companyDeleted
    );

    try {
      setLoading();
      await api.companyDelete(id);
      setSuccess();
      onSuccess();
    } catch (e) {
      setError();
      getErrorHandler()(e);
    }
  }
);

export const editCompany = createAsyncThunk(
  `${SLICE_NAME}/editCompany`,
  async (
    {
      id,
      data,
      onSuccess = noop,
    }: { data: API$CompanyFormValues; id: number; onSuccess: () => void },
    { rejectWithValue, dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.companyCreated
    );

    try {
      setLoading();
      const { payload } = await api.companyEdit(id, data);

      setSuccess();
      onSuccess();

      return payload;
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(error);
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const getEmployeeStats = createAsyncThunk(
  `${SLICE_NAME}/getEmployeeStats`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const { payload } = await api.employeeStatsGet(companyId);

      return camelKeys(payload) as AH$EmployeeStats;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getEmployees = createAsyncThunk(
  `${SLICE_NAME}/getEmployees`,
  async (params: API$UsersParams | undefined, { rejectWithValue }) => {
    try {
      const { payload } = await api.usersGet({
        ...params,
        ...(!get(params, 'role') && { role: ['admin', 'worker'] }),
      });

      return {
        data: payload.results.map(camelKeys) as Array<AH$Assignee>,
        pages: omit(payload, 'results'),
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const addCompanyCredits = createAsyncThunk(
  `${SLICE_NAME}/addCompanyCredits`,
  async (
    {
      companyId,
      data,
      onSuccess = noop,
    }: { companyId: string; data: API$CompanyCreditsData; onSuccess: () => void },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.creditsAdded
    );

    try {
      setLoading();
      await api.companyCreditsAdd(companyId, data);

      setSuccess();
      onSuccess();
    } catch (e) {
      setError();
      getErrorHandler()(e);
    }
  }
);

export const getProfilesViews = createAsyncThunk(
  `${SLICE_NAME}/getProfilesViews`,
  async (params: { creator_id: string; date_range?: string }, { rejectWithValue }) => {
    try {
      const { payload } = await api.getProfilesViews(params);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getApiStats = createAsyncThunk(
  `${SLICE_NAME}/getApiStats`,
  async (
    { companyId, dateRange }: { companyId: number; dateRange?: string },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await getApiStatsByCompany(companyId, dateRange);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getEmployee = createAsyncThunk(
  `${SLICE_NAME}/getEmployee`,
  async (
    { id, role }: { id: string; role: 'employee' | 'admin' | 'hm' | 'manager' },
    { rejectWithValue }
  ) => {
    try {
      const getAPI = (): typeof api.employeeGet => {
        switch (role) {
          case 'admin':
            return api.adminGet;
          case 'hm':
            return api.hmGet;
          case 'manager':
            return api.managerGet;
          default:
            return api.employeeGet;
        }
      };

      startLoading();

      const { payload } = await getAPI()(id);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    } finally {
      stopLoading();
    }
  }
);

export const addEmployee = createAsyncThunk(
  `${SLICE_NAME}/addEmployee`,
  async (
    {
      data,
      role,
      onSuccess = noop,
      params,
    }: {
      data: API$EmployeeFormData;
      onSuccess: () => void;
      role: 'employee' | 'admin' | 'hm' | 'manager';
      params?: API$EmployeeFormParams;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.employeeSaved
    );

    try {
      const getAPI = (): typeof api.employeeAdd => {
        switch (role) {
          case 'admin':
            return api.adminAdd;
          case 'hm':
            return api.hmAdd;
          case 'manager':
            return api.managerAdd;
          default:
            return api.employeeAdd;
        }
      };

      setLoading();
      await getAPI()(data, params);
      setSuccess();
      onSuccess();
    } catch (err) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (e) => {
          if (e.non_field_errors) {
            handleError({ content: e.non_field_errors });
          }
          setError(e);
        },
      })(err);
    }
  }
);

export const editEmployee = createAsyncThunk(
  `${SLICE_NAME}/editEmployee`,
  async (
    {
      id,
      data,
      role,
      onSuccess = noop,
      params,
    }: {
      data: API$EmployeeFormData;
      id: string | number;
      onSuccess: () => void;
      role: 'employee' | 'admin' | 'hm' | 'manager';
      params?: API$EmployeeFormParams;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.employeeSaved
    );

    try {
      const getAPI = (): typeof api.employeeEdit => {
        switch (role) {
          case 'admin':
            return api.adminEdit;
          case 'hm':
            return api.hmEdit;
          case 'manager':
            return api.managerEdit;
          default:
            return api.employeeEdit;
        }
      };

      setLoading();
      await getAPI()(id, data, params);

      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(error);
        },
      })(e);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  `${SLICE_NAME}/deleteEmployee`,
  async ({
    id,
    role,
    onSuccess = noop,
  }: {
    id: number;
    role: 'employee' | 'admin' | 'hm' | 'manager';
    onSuccess?: () => void;
  }) => {
    try {
      const getAPI = (): typeof api.employeeDelete => {
        switch (role) {
          case 'admin':
            return api.adminDelete;
          case 'hm':
            return api.hmDelete;
          case 'manager':
            return api.managerDelete;
          default:
            return api.employeeDelete;
        }
      };

      startLoading();
      await getAPI()(id);
      stopLoading();
      onSuccess();
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const generateAPIToken = createAsyncThunk(
  `${SLICE_NAME}/generateAPIToken`,
  async ({
    id,
    role,
    onSuccess = noop,
  }: {
    id: string;
    role: 'employee' | 'admin';
    onSuccess?: () => void;
  }) => {
    try {
      startLoading();

      await (role === 'admin' ? api.adminGenerateAPIToken : api.employeeGenerateAPIToken)(id);
      stopLoading();
      onSuccess();
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const resendInvite = createAsyncThunk(
  `${SLICE_NAME}/resendInvite`,
  async ({ id, onSuccess = noop }: { id: number; onSuccess: () => void }) => {
    try {
      startLoading();
      await api.inviteResend(id);
      stopLoading();
      onSuccess();
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const switchEmployeeRole = createAsyncThunk(
  `${SLICE_NAME}/switchEmployeeRole`,
  async ({ id, onSuccess = noop }: { id: number; onSuccess: () => void }) => {
    try {
      startLoading();
      await api.employeeSwitchRole(id);
      stopLoading();
      onSuccess();
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const switchAdminRole = createAsyncThunk(
  `${SLICE_NAME}/switchAdminRole`,
  async ({ id, onSuccess = noop }: { id: number; onSuccess: () => void }) => {
    try {
      startLoading();
      await api.adminSwitchRole(id);
      stopLoading();
      onSuccess();
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const moveEmployeeToCompany = createAsyncThunk(
  `${SLICE_NAME}/moveEmployeeToCompany`,
  async (
    {
      id,
      companyId,
      role,
      onSuccess = noop,
    }: {
      companyId: number;
      id: string;
      role: 'employee' | 'admin';
      onSuccess?: () => void;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.movedToCompany
    );

    try {
      setLoading();
      await (role === 'admin' ? api.employeeMoveToCompany : api.adminMoveToCompany)(id, companyId);
      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          setError();
          handleError({ content: error[0] });
        },
      })(e);
    }
  }
);

export const getHiringManagers = createAsyncThunk(
  `${SLICE_NAME}/getHiringManagers`,
  async (params: API$UsersParams | undefined, { rejectWithValue }) => {
    try {
      const { payload } = await api.usersGet({
        ...params,
        role: 'hiring_manager',
        status: ['active', 'deleted', 'not_active'],
      });

      return {
        data: payload.results.map(camelKeys) as Array<AH$Assignee>,
        pages: omit(payload, 'results'),
      };
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getExtensionNotifications = createAsyncThunk(
  `${SLICE_NAME}/getExtensionNotifications`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.getExtensionNotifications();

      return payload.map(camelKeys) as Array<AH$ExtensionNotification>;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getSalesManagers = createAsyncThunk(
  `${SLICE_NAME}/getSalesManagers`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.managersGet();

      return payload.map(camelKeys) as Array<AH$Assignee>;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const createExtensionNotification = createAsyncThunk(
  `${SLICE_NAME}/createExtensionNotification`,
  async (
    { data, onSuccess }: { data: API$ExtensionNotification; onSuccess: () => void },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.extensionNotificationSaved
    );

    try {
      setLoading();
      await api.createExtensionNotification(data);
      onSuccess();
      setSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(camelKeys(error));
        },
      })(e);
    }
  }
);

export const getMetaQueries = createAsyncThunk(
  `${SLICE_NAME}/getMetaQueries`,
  async (params: API$MetaQueriesParams | undefined, { rejectWithValue }) => {
    try {
      const { payload } = await api.metaQueriesGet(params);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const addMetaQuery = createAsyncThunk(
  `${SLICE_NAME}/addMetaQuery`,
  async (
    { data, onSuccess = noop }: { data: Partial<API$MetaQuery>; onSuccess: () => void },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.metaQuerySaved
    );

    try {
      setLoading();
      await api.metaQueryAdd(data);
      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(error);
        },
      })(e);
    }
  }
);

export const deleteExtensionNotification = createAsyncThunk(
  `${SLICE_NAME}/deleteExtensionNotification`,
  async (deleteId: number, { rejectWithValue, getState }) => {
    try {
      await api.deleteExtensionNotification(deleteId);
      return (getState() as State).adminPanel.extensionNotifications.data.filter(
        ({ id }) => id !== deleteId
      );
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const editExtensionNotification = createAsyncThunk(
  `${SLICE_NAME}/editExtensionNotification`,
  async (
    {
      id,
      data,
      onSuccess = noop,
      onError = noop,
    }: {
      data: API$ExtensionNotification;
      id: number;
      onError: (props?: any) => void;
      onSuccess: () => void;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.extensionNotificationSaved
    );

    try {
      setLoading();
      await api.editExtensionNotification(id, data);
      onSuccess();
      setSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          setError(camelKeys(error));
          onError(error);
        },
      })(e);
    }
  }
);

export const editMetaQuery = createAsyncThunk(
  `${SLICE_NAME}/editMetaQuery`,
  async (
    {
      id,
      data,
      onSuccess = noop,
    }: { data: Partial<API$MetaQuery>; id: number; onSuccess: () => void },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.metaQuerySaved
    );

    try {
      setLoading();
      await api.metaQueryEdit(id, data);
      setSuccess();
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(error);
        },
      })(e);
    }
  }
);

export const deleteMetaQuery = createAsyncThunk(
  `${SLICE_NAME}/deleteMetaQuery`,
  async (id: number, { dispatch }) => {
    try {
      startLoading();
      await api.metaQueryDelete(id);
      stopLoading();
      dispatch(getMetaQueries());
    } catch (e) {
      stopLoading();
      getErrorHandler()(e);
    }
  }
);

export const updateCurrentCompany = createAsyncThunk(
  `${SLICE_NAME}/updateCurrentCompany`,
  async (data: API$CompanyUpdateData, { rejectWithValue, getState, dispatch }) => {
    try {
      startLoading();
      const { payload } = await api.currentCompanyUpdate(data);
      const { company } = (getState() as State).adminPanel;

      dispatch(updateUserInCurrentCompany(payload) as any);

      return { ...company.data, ...payload } as API$Company;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    } finally {
      stopLoading();
    }
  }
);

export const updateCompanyCustomSettings = createAsyncThunk(
  `${SLICE_NAME}/updateCompanyCustomSettings`,
  async (
    {
      companyId,
      data,
      onSuccess = noop,
    }: { companyId: number; data: Record<string, 0 | 1>; onSuccess: () => void },
    { rejectWithValue, dispatch, getState }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.customSettingsSaved
    );

    try {
      setLoading();
      const { payload } = await api.companyCustomSettingsUpdate(companyId, data);
      const { company } = (getState() as { adminPanel: AdminPanelState }).adminPanel;

      if (company.data) {
        setSuccess();
        onSuccess();
        return {
          ...company.data,
          custom_settings: { ...company.data.custom_settings, ...payload },
        } as API$Company;
      }
      setSuccess();
      onSuccess();

      return undefined;
    } catch (e) {
      getErrorHandler({ getError: () => setError() })(e);

      return rejectWithValue(e);
    }
  }
);

export const getSystemNotifications = createAsyncThunk(
  `${SLICE_NAME}/getSystemNotifications`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.getSystemNotifications();

      return payload.results.map(camelKeys<AH$SystemNotification>);
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const createSystemNotification = createAsyncThunk(
  `${SLICE_NAME}/createSystemNotification`,
  async (
    { data, onSuccess }: { data: Partial<API$SystemNotification>; onSuccess: () => void },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.systemNotificationSaved
    );

    try {
      setLoading();
      await api.createSystemNotification(data);
      onSuccess();
      setSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(camelKeys(error));
        },
      })(e);
    }
  }
);

export const deleteSystemNotification = createAsyncThunk(
  `${SLICE_NAME}/deleteSystemNotification`,
  async (deleteId: number, { dispatch }) => {
    try {
      await api.deleteSystemNotification(deleteId);
      dispatch(getSystemNotifications());
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const editSystemNotification = createAsyncThunk(
  `${SLICE_NAME}/editSystemNotification`,
  async (
    {
      id,
      data,
      onSuccess = noop,
      onError = noop,
    }: {
      data: Partial<API$SystemNotification>;
      id: number;
      onError: (args?: any) => void;
      onSuccess: () => void;
    },
    { dispatch }
  ) => {
    const { setLoading, setSuccess, setError } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.systemNotificationSaved
    );

    try {
      setLoading();
      await api.editSystemNotification(id, data);
      onSuccess();
      setSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        formatError: true,
        getError: (error) => {
          setError(camelKeys(error));
          onError(error);
        },
      })(e);
    }
  }
);

export const adminPanelInitialState: AdminPanelState = {
  HMDisplaySections: {
    status: FETCHING_STATUSES.LOADING,
    displaySections: {},
  },
  activeUsers: {
    users: [],
    status: FETCHING_STATUSES.LOADING,
  },
  companyFormData: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
  },
  companies: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
    pages: null,
  },
  company: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
  },
  companyStatistics: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
    pages: null,
  },
  employeeStats: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
  },
  employees: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
    pages: null,
  },
  viewsHistory: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },

  apiStats: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
  employee: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
  },
  hiringManagers: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
    pages: null,
  },
  extensionNotifications: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
  metaQueries: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
  salesManagers: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
  systemNotifications: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
};

export const adminPanelSlice = createSlice({
  name: SLICE_NAME,
  initialState: adminPanelInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHMCompanyDisplaySections.pending, (state) => {
      state.HMDisplaySections = {
        status: FETCHING_STATUSES.LOADING,
        displaySections: {},
      };
    });
    builder.addCase(getHMCompanyDisplaySections.fulfilled, (state, action) => {
      state.HMDisplaySections = {
        status: FETCHING_STATUSES.SUCCESS,
        displaySections: transformDisplaySections(action.payload.hm_display_sections),
      };
    });
    builder.addCase(getActiveUsers.pending, (state) => {
      state.activeUsers = {
        users: [],
        status: FETCHING_STATUSES.LOADING,
      };
    });
    builder.addCase(getActiveUsers.fulfilled, (state, action) => {
      state.activeUsers = {
        users: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getActiveUsers.rejected, (state) => {
      state.activeUsers = {
        users: [],
        status: FETCHING_STATUSES.ERROR,
      };
    });
    builder.addCase(getCompanyFormData.pending, (state) => {
      state.companyFormData = {
        data: null,
        status: FETCHING_STATUSES.LOADING,
      };
    });
    builder.addCase(getCompanyFormData.fulfilled, (state, action) => {
      state.companyFormData = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getCompanyFormData.rejected, (state) => {
      state.companyFormData.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getCompanies.pending, (state) => {
      state.companies.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = { ...action.payload, status: FETCHING_STATUSES.SUCCESS };
    });
    builder.addCase(getCompanies.rejected, (state) => {
      state.companies.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getCompany.pending, (state) => {
      state.company.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.company = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getCompany.rejected, (state) => {
      state.company.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getCompanyStatistics.pending, (state) => {
      state.companyStatistics.status = FETCHING_STATUSES.LOADING;
      state.companyStatistics.pages = null;
    });
    builder.addCase(getCompanyStatistics.fulfilled, (state, action) => {
      state.companyStatistics = {
        ...action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getCompanyStatistics.rejected, (state) => {
      state.companyStatistics = {
        status: FETCHING_STATUSES.ERROR,
        data: null,
        pages: null,
      };
    });
    builder.addCase(editCompany.fulfilled, (state, action) => {
      state.company = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getEmployeeStats.pending, (state) => {
      state.employeeStats.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getEmployeeStats.fulfilled, (state, action) => {
      state.employeeStats = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getEmployeeStats.rejected, (state) => {
      state.employeeStats.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getEmployees.pending, (state) => {
      state.employees.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employees = {
        ...action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getEmployees.rejected, (state) => {
      state.employees.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getProfilesViews.pending, (state) => {
      state.viewsHistory = {
        status: FETCHING_STATUSES.LOADING,
        data: [],
      };
    });
    builder.addCase(getProfilesViews.fulfilled, (state, action) => {
      state.viewsHistory = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(getProfilesViews.rejected, (state) => {
      state.viewsHistory = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
    builder.addCase(getApiStats.pending, (state) => {
      state.apiStats = {
        status: FETCHING_STATUSES.LOADING,
        data: [],
      };
    });
    builder.addCase(getApiStats.fulfilled, (state, action) => {
      state.apiStats = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(getApiStats.rejected, (state) => {
      state.apiStats = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
    builder.addCase(getEmployee.pending, (state) => {
      state.employee.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.employee = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getEmployee.rejected, (state) => {
      state.employee.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getHiringManagers.pending, (state) => {
      state.hiringManagers.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getHiringManagers.fulfilled, (state, action) => {
      state.hiringManagers = {
        ...action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getHiringManagers.rejected, (state) => {
      state.hiringManagers.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getExtensionNotifications.pending, (state) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.LOADING,
        data: [],
      };
    });
    builder.addCase(getExtensionNotifications.fulfilled, (state, action) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(getExtensionNotifications.rejected, (state) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
    builder.addCase(getSalesManagers.pending, (state) => {
      state.salesManagers.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getSalesManagers.fulfilled, (state, action) => {
      state.salesManagers = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getSalesManagers.rejected, (state) => {
      state.salesManagers.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getMetaQueries.pending, (state) => {
      state.metaQueries.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getMetaQueries.fulfilled, (state, action) => {
      state.metaQueries = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getMetaQueries.rejected, (state) => {
      state.metaQueries.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(deleteExtensionNotification.pending, (state) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.LOADING,
        data: [],
      };
    });
    builder.addCase(deleteExtensionNotification.fulfilled, (state, action) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(deleteExtensionNotification.rejected, (state) => {
      state.extensionNotifications = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
    builder.addCase(updateCurrentCompany.fulfilled, (state, action) => {
      state.company = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(updateCompanyCustomSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.company = {
          data: action.payload,
          status: FETCHING_STATUSES.SUCCESS,
        };
      }
    });
    builder.addCase(getSystemNotifications.pending, (state) => {
      state.systemNotifications = {
        status: FETCHING_STATUSES.LOADING,
        data: [],
      };
    });
    builder.addCase(getSystemNotifications.fulfilled, (state, action) => {
      state.systemNotifications = {
        status: FETCHING_STATUSES.SUCCESS,
        data: action.payload,
      };
    });
    builder.addCase(getSystemNotifications.rejected, (state) => {
      state.systemNotifications = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
    builder.addCase(deleteSystemNotification.rejected, (state) => {
      state.systemNotifications = {
        status: FETCHING_STATUSES.ERROR,
        data: [],
      };
    });
  },
});

export const adminPanel = adminPanelSlice.reducer;
