export default {
  smallFont: {
    id: 'messageEditor.smallFont',
    defaultMessage: 'Small',
  },
  normalFont: {
    id: 'messageEditor.normalFont',
    defaultMessage: 'Normal',
  },
  largeFont: {
    id: 'messageEditor.largeFont',
    defaultMessage: 'Large',
  },
  hugeFont: {
    id: 'messageEditor.hugeFont',
    defaultMessage: 'Huge',
  },
  size: {
    id: 'messageEditor.size',
    defaultMessage: 'Size',
  },
  bulletedList: {
    id: 'messageEditor.bulletedList',
    defaultMessage: 'Bulleted list',
  },
  numberedList: {
    id: 'messageEditor.numberedList',
    defaultMessage: 'Numbered list',
  },
  bold: {
    id: 'messageEditor.bold',
    defaultMessage: 'Bold',
  },
  italic: {
    id: 'messageEditor.italic',
    defaultMessage: 'Italic',
  },
  removeFormatting: {
    id: 'messageEditor.removeFormatting',
    defaultMessage: 'Remove formatting',
  },
  variables: {
    id: 'messageEditor.variables',
    defaultMessage: 'Variables',
  },
  insertLink: {
    id: 'messageEditor.insertLink',
    defaultMessage: 'Insert link',
  },
  visibleText: {
    id: 'messageEditor.visibleText',
    defaultMessage: 'Text to display:',
  },
  link: {
    id: 'messageEditor.link',
    defaultMessage: 'Link:',
  },
  insert: {
    id: 'messageEditor.insert',
    defaultMessage: 'Insert',
  },
  editLink: {
    id: 'messageEditor.editLink',
    defaultMessage: 'Change',
  },
  deleteLink: {
    id: 'messageEditor.deleteLink',
    defaultMessage: 'Remove',
  },
  cancel: {
    id: 'messageEditor.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'messageEditor.save',
    defaultMessage: 'Save',
  },
  addVariable: {
    id: 'messageEditor.addVariable',
    defaultMessage: 'add variable',
  },
  add: {
    id: 'messageEditor.add',
    defaultMessage: 'Create',
  },
  newVariable: {
    id: 'messageEditor.newVariable',
    defaultMessage: 'Create variable',
  },
  variableName: {
    id: 'messageEditor.variableName',
    defaultMessage: 'Variable name',
  },
  attachFiles: {
    id: 'messageEditor.attachFiles',
    defaultMessage: 'Attach files',
  },
  dropFiles: {
    id: 'messageEditor.dropFiles',
    defaultMessage: 'Drop files here',
  },
  addImage: {
    id: 'messageEditor.addImage',
    defaultMessage: 'Insert photo',
  },
  alignText: {
    id: 'messageEditor.alignText',
    defaultMessage: 'Align',
  },
};
