import * as React from 'react';
import { useIntl } from 'react-intl';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import messages from 'components/Messaging/Message/Editor/messages';
import styles from './AddImage.scss';

const theme = thematize(styles);

export const ACCEPTABLE_FILES = '.jpg,.jpeg,.gif,.png';

interface Props {
  onImageAdd: (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>,
    isInlineImage?: boolean
  ) => void;
  index?: number;
}

const AddImage = ({ onImageAdd, index }: Props): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip id={`add-image-tooltip-${index}`} message={formatMessage(messages.addImage)}>
      <label htmlFor={`add-image-${index}`} className={theme('container')} {...dataQa('add-image')}>
        <input
          multiple
          id={`add-image-${index}`}
          type="file"
          onChange={onImageAdd}
          accept={ACCEPTABLE_FILES}
        />
        <Icon type="image" />
      </label>
    </Tooltip>
  );
};

export default AddImage;
