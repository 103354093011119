export enum LOCATIONS {
  search = 'Search',
  folders = 'Folders',
  fullProfile = 'Full Profile',
  shortProfile = 'Short Profile',
  statistics = 'Statistics',
  home = 'Home',
  messaging = 'Messaging',
  sideMenu = 'Side Menu',
  ats = 'ATS',
  login = 'Login',
  apps = 'Apps',
  extension = 'Extension',
}
