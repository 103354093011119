import { compact, get, isEmpty, noop } from 'lodash';

import { getActiveSmartFolderId, parse } from 'lib/query';
import ReduxStore from 'stores/ReduxStore';
import { SearchType } from './categories';
import { LOCATIONS } from './locations';

const amplitudeKey: string | undefined = process.env.AMPLITUDE_KEY;
const amplitudeLicenseKey: string | undefined = process.env.AMPLITUDE_LICENSE_KEY;

function identifyAmplitudeUser(apiKey: string, userId: number, user: AH$User): void {
  const formData = new FormData();

  formData.append('api_key', apiKey);
  formData.append(
    'identification',
    JSON.stringify({
      user_id: userId,
      user_properties: user,
    })
  );

  fetch('https://api2.amplitude.com/identify', {
    method: 'POST',
    body: formData,
  });
}

export const initializeAmplitude = (user: AH$User): void => {
  if (amplitudeKey && !isEmpty(user)) {
    identifyAmplitudeUser(amplitudeKey, user.id, user);
  }
  if (amplitudeLicenseKey && !isEmpty(user) && user.licenceId) {
    identifyAmplitudeUser(amplitudeLicenseKey, user.licenceId, user);
  }
};

const getSearchType = (): Record<string, string> | undefined => {
  const isSearch =
    window.location.pathname === '/profiles-old/' && window.location.search.includes('?');
  const isSearchV2 =
    (window.location.pathname === '/' && window.location.search.includes('?')) ||
    window.location.pathname === '/';
  const isSmartFolders = !!getActiveSmartFolderId();
  const query = parse(window.location.search);
  const Search_Query = window.decodeURIComponent(window.location.search).toLowerCase();
  const Search_Form_Type = isSearchV2 ? 'v2' : 'v1';

  if (!isSearch && !isSearchV2) {
    return undefined;
  }
  if (isSmartFolders) {
    return { Search_Type: SearchType.smartFolders, Search_Query, Search_Form_Type };
  }
  if (query.q?.find(({ field }) => field === 'folder')) {
    return { Search_Type: SearchType.folder, Search_Query, Search_Form_Type };
  }
  if (query.q?.find(({ field, rawText }) => field === 'text' && rawText.startsWith('similar'))) {
    return { Search_Type: SearchType.similarProfiles, Search_Query, Search_Form_Type };
  }

  return { Search_Type: SearchType.searchForm, Search_Query, Search_Form_Type };
};

const eventServiceToken = process.env.DJANGO_EVENT_SERVICE_TOKEN;

// Amplitude
export const sendEvent = (
  name: string,
  data: Record<string, any> = {},
  destinations: Array<string> = ['amplitude']
): void => {
  const { user } = ReduxStore.getState().app;
  const userId = get(user, 'id');

  if (eventServiceToken) {
    fetch(`${window.location.origin}/api/events/`, {
      method: 'POST',
      headers: {
        'X-EVENT-SERVICE-TOKEN': eventServiceToken,
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        payload: {
          user_id: userId,
          licence_id: get(user, 'licenceId'),
          event_type: name,
          time: Date.now(),
          event_properties: { ...getSearchType(), ...data, Event_Name: name },
          user_properties: user,
        },
        destinations,
      }),
    }).then(noop, noop);
  }
};

export const getOpenerLocation = (referer: string | null | undefined): string => {
  if (!referer) {
    return 'Direct link';
  }
  if (referer.startsWith('ext')) {
    return LOCATIONS.extension;
  }
  if (referer.startsWith('search')) {
    return LOCATIONS.search;
  }
  if (referer.startsWith('folder')) {
    return LOCATIONS.folders;
  }

  return '';
};

type ProfileInfo = {
  Profile_Contacts: Array<string>;
  Profile_Locations: Array<string>;
  Profile_Skills: Array<string>;
};

export const extractProfileInfo = (profile: AH$Profile): ProfileInfo => {
  const contacts = [...profile.contactsIdByType.keys()].filter(
    (key) => key !== 'link' && !isEmpty(profile.contactsIdByType.get(key))
  );
  const skills = profile.skills.shortProfileSkills.map(({ skill }) => skill.toLowerCase());
  const locations = compact(
    profile.location.reduce<Array<string>>(
      (acc, loc) => [...acc, loc.city?.toLowerCase(), loc.country?.toLowerCase()],
      []
    )
  );

  return {
    Profile_Contacts: contacts,
    Profile_Skills: skills,
    Profile_Locations: locations,
  };
};
