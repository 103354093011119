import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { noop } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import * as apiV6 from 'lib/apiV6';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'telegramManager';

export type TelegramManagerState = {
  cards: { fetchingStatus: $Values<typeof FETCHING_STATUSES>; items: AH$TelegramManager[] };
  fetchingStatus: $Values<typeof FETCHING_STATUSES>;
  phone: string;
};

export const telegramManagerInitialState: TelegramManagerState = {
  phone: '+7',
  fetchingStatus: 'SUCCESS',
  cards: { fetchingStatus: 'SUCCESS', items: [] },
};

export const getCode = createAsyncThunk(
  `${SLICE_NAME}/getCode`,
  async (
    { phoneNumber, onSuccess = noop }: { phoneNumber: string; onSuccess?: () => void },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.telegramSendCode(phoneNumber);
      onSuccess();

      return phoneNumber;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const addManager = createAsyncThunk(
  `${SLICE_NAME}/addManager`,
  async (
    {
      phoneNumber,
      code,
      onSuccess = noop,
      onError = noop,
    }: {
      code: string;
      phoneNumber: string;
      onError?: (error: string) => void;
      onSuccess?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.telegramConnectAccount({ code, phone_number: phoneNumber });
      onSuccess();
    } catch (e) {
      getErrorHandler({
        parseError: true,
        silent: true,
        getError: ({ error }: { error: string }) => {
          onError(error);
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const getSimCards = createAsyncThunk(
  `${SLICE_NAME}/getSimCards`,
  async (params: AH$TelegramAccountsParams, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.getAllTelegramAccounts(params);

      return { simCards: payload.results };
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

const telegramManagerSlice = createSlice({
  name: SLICE_NAME,
  initialState: telegramManagerInitialState,
  reducers: {
    clearPhone: (state) => {
      state.phone = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCode.pending, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getCode.fulfilled, (state, action) => {
      state.fetchingStatus = FETCHING_STATUSES.SUCCESS;
      state.phone = action.payload;
    });
    builder.addCase(getCode.rejected, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(addManager.pending, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(addManager.fulfilled, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(addManager.rejected, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getSimCards.pending, (state) => {
      state.cards.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getSimCards.fulfilled, (state, action) => {
      state.cards.fetchingStatus = FETCHING_STATUSES.SUCCESS;
      state.cards.items = action.payload.simCards;
    });
    builder.addCase(getSimCards.rejected, (state) => {
      state.cards.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
  },
});

export const { clearPhone } = telegramManagerSlice.actions;
export const telegramManager = telegramManagerSlice.reducer;
