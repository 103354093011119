export default {
  more: {
    id: 'sidemenu.more',
    defaultMessage: 'More',
  },
  extension: {
    id: 'sidemenu.extension',
    defaultMessage: 'Extension',
  },
  search: {
    id: 'sidemenu.search',
    defaultMessage: 'Search',
  },
  searchV2: {
    id: 'sidemenu.searchV2',
    defaultMessage: 'Search 2.0',
  },
  vacancies: {
    id: 'sidemenu.vacancies',
    defaultMessage: 'Folders',
  },
  serviceOrders: {
    id: 'sidemenu.serviceOrders',
    defaultMessage: 'Orders',
  },
  settings: {
    id: 'sidemenu.settings',
    defaultMessage: 'Settings',
  },
  companies: {
    id: 'sidemenu.companies',
    defaultMessage: 'All companies',
  },
  activeUsers: {
    id: 'sidemenu.activeUsers',
    defaultMessage: 'Active users',
  },
  bannedLinks: {
    id: 'sidemenu.bannedLinks',
    defaultMessage: 'Banned links',
  },
  apiManagement: {
    id: 'sidemenu.apiManagement',
    defaultMessage: 'API management',
  },
  apps: {
    id: 'sidemenu.apps',
    defaultMessage: 'Integrations',
  },
  company: {
    id: 'sidemenu.company',
    defaultMessage: 'Company',
  },
  metaqueries: {
    id: 'sidemenu.metaqueries',
    defaultMessage: 'MetaQueries',
  },
  credits: {
    id: 'sidemenu.credits',
    defaultMessage: 'Credits',
  },
  help: {
    id: 'sidemenu.help',
    defaultMessage: 'Help',
  },
  logout: {
    id: 'sidemenu.logout',
    defaultMessage: 'Logout',
  },
  searchForm: {
    id: 'sidemenu.searchForm',
    defaultMessage: 'Search form',
  },
  talentPools: {
    id: 'sidemenu.talentPools',
    defaultMessage: 'AI sourcing',
  },
  dataEnrichment: {
    id: 'sidemenu.dataEnrichment',
    defaultMessage: 'Data Enrichment',
  },
  savedQueries: {
    id: 'sidemenu.savedQueries',
    defaultMessage: 'Saved queries',
  },
  history: {
    id: 'sidemenu.history',
    defaultMessage: 'History',
  },
  account: {
    id: 'sidemenu.account',
    defaultMessage: 'Account',
  },
  extensionUser: {
    id: 'sidemenu.extensionUser',
    defaultMessage: 'Extension user',
  },
  statuses: {
    id: 'sidemenu.statuses',
    defaultMessage: 'Statuses setting',
  },
  messaging: {
    id: 'sidemenu.messaging',
    defaultMessage: 'Messaging',
  },
  templates: {
    id: 'sidemenu.templates',
    defaultMessage: 'Templates',
  },
  sentMessages: {
    id: 'sidemenu.sentMessages',
    defaultMessage: 'Messages',
  },
  messagingSettings: {
    id: 'sidemenu.messagingSettings',
    defaultMessage: 'Settings',
  },
  sequences: {
    id: 'sidemenu.sequences',
    defaultMessage: 'Sequences',
  },
  ats: {
    id: 'sidemenu.ats',
    defaultMessage: 'ATS',
  },
  createProfile: {
    id: 'sidemenu.createProfile',
    defaultMessage: 'Create profile',
  },
  extensionNotifications: {
    id: 'sidemenu.extensionNotifications',
    defaultMessage: 'Extension notifications',
  },
  editedProfiles: {
    id: 'sideMenu.editedProfiles',
    defaultMessage: 'Edited profiles',
  },
  smartFolders: {
    id: 'sideMenu.smartFolders',
    defaultMessage: 'Folders',
  },
  managers: {
    id: 'sideMenu.managers',
    defaultMessage: 'Managers',
  },
  statistics: {
    id: 'sideMenu.statistics',
    defaultMessage: 'Statistics',
  },
  systemNotifications: {
    id: 'sidemenu.systemNotifications',
    defaultMessage: 'Notifications',
  },
  badgeNew: {
    id: 'sidemenu.badgeNew',
    defaultMessage: 'NEW',
  },
  badgeOld: {
    id: 'sidemenu.badgeOld',
    defaultMessage: 'OLD',
  },
  badgeOldV2: {
    id: 'sidemenu.badgeOldV2',
    defaultMessage: 'OLD',
  },
  telegramMessenger: {
    id: 'sidemenu.telegramMessenger',
    defaultMessage: 'Telegram messenger',
  },
  telegramMessengerMessages: {
    id: 'sidemenu.telegramMessengerMessages',
    defaultMessage: 'Messages',
  },
  telegramMessengerManager: {
    id: 'sidemenu.telegramMessengerManager',
    defaultMessage: 'SIM Card Manager',
  },
};
