import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { companyGet } from 'lib/api';
import { camelKeysRecursive } from 'lib/object';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'company';

export type CompanyState = {
  companyName: string;
  errorCode: number | null;
  info: AH$Company['info'] | null;
  show: AH$Company['show'];
};

export const companyInitialState: CompanyState = {
  info: {
    name: '',
    employeesMainSkills: [],
    transitionsTo: [],
    employeesCount: [],
    avgEmploymentPeriod: [],
    companyId: '',
    transitionsFrom: [],
  },
  show: false,
  companyName: '',
  errorCode: null,
};

export const receiveInfo = createAsyncThunk<
  AH$Company['info'],
  string,
  { rejectValue: { companyName: string; errorCode: number } }
>(`${SLICE_NAME}/receiveInfo`, async (companyName: string, { rejectWithValue }) => {
  try {
    const { payload } = await companyGet(companyName);

    return camelKeysRecursive(payload) as AH$Company['info'];
  } catch (e: any) {
    getErrorHandler()(e);
    return rejectWithValue({ errorCode: e.response.status, companyName });
  }
});

const companySlice = createSlice({
  name: SLICE_NAME,
  initialState: companyInitialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(receiveInfo.pending, (state) => {
        state.info = null;
      })
      .addCase(receiveInfo.fulfilled, (state, action) => {
        state.info = action.payload;
      })
      .addCase(receiveInfo.rejected, (state, action) => {
        if (action.payload) {
          state.errorCode = action.payload.errorCode;
          state.companyName = action.payload.companyName;
        }
      });
  },
});

export const { setVisible } = companySlice.actions;
export const company = companySlice.reducer;
