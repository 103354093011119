import { useCallback, useEffect } from 'react';

import { addRootEventListener, removeRootEventListener } from 'lib/utils';

const useEsc = (callback: () => void): void => {
  const handleEscapeKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    addRootEventListener('keydown', handleEscapeKeydown);

    return (): void => {
      removeRootEventListener('keydown', handleEscapeKeydown);
    };
  }, [handleEscapeKeydown]);
};

export default useEsc;
