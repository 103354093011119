import { metricsEventsMap, MetricsType } from 'metrics/types';
import { del, download, get, patch, post, put } from './request';

export function foldersGet(
  params: API$FoldersParams
): Promise<{ pages: AH$Pages; payload: Array<API$Folder> }> {
  return get('/api/v6/folders/', params);
}

export function folderPatch(
  folderId: number,
  data: API$FolderData
): Promise<{ payload: API$Folder }> {
  return patch(`/api/v6/folders/${folderId}/`, { data });
}

export function folderGet(
  folderId: number
): Promise<{ candidate_statuses: Array<API$Status>; payload: API$Folder; profileReferer: string }> {
  return get(`/api/v6/folders/${folderId}/`);
}

export function folderCreate(data: API$FolderData): Promise<{ payload: API$Folder }> {
  return post('/api/v6/folders/', { data });
}

export function folderDelete(folderId: number, params?: API$SaveMessagingHistory): Promise<void> {
  return del(`/api/v6/folders/${folderId}/`, params);
}

export function statusesGet(): Promise<{ payload: Array<API$Status> }> {
  return get('/api/v6/status_collection/statuses/');
}

export function statusEdit(
  statusId: number,
  data: API$StatusData
): Promise<{ payload: API$Status }> {
  return patch(`/api/v6/status_collection/statuses/${statusId}/`, { data });
}

export function statusDelete(statusId: number): Promise<void> {
  return del(`/api/v6/status_collection/statuses/${statusId}/`);
}

export function statusCreate(data: API$StatusData): Promise<{ payload: API$Status }> {
  return post('/api/v6/status_collection/statuses/', { data });
}

export function statusesOrderChange(data: Array<number>): Promise<{ payload: Array<API$Status> }> {
  return post('/api/v6/status_collection/change_order/', { data });
}

export function candidatesPost(
  data: Array<API$CandidateData>
): Promise<{ payload: { invalidCount: number; successCount: number } }> {
  return post('/api/v6/candidates/', { data });
}

export function candidatePatch(
  candidateId: number,
  statusId: number
): Promise<{ payload: API$Candidate }> {
  return patch(`/api/v6/candidates/${candidateId}/`, { data: { status: statusId } });
}

export function massCandidatesPatch(
  candidateIds: Array<number>,
  statusId: number
): Promise<{ payload: Array<API$Candidate> }> {
  return patch('/api/v6/candidates/', {
    params: { ids: candidateIds },
    data: { status: statusId },
  });
}

export function candidatesDelete(params: API$DeleteCandidatesParams): Promise<void> {
  return del('/api/v6/candidates/', params);
}

export function candidatesGet(params: API$CandidatesParams): Promise<{
  payload: API$PaginatedResponse<API$Candidate>;
}> {
  return get('/api/v6/candidates/', params);
}

export function folderCandidatesGet(
  folderId: number,
  params?: API$CandidatesParams
): Promise<{
  payload: API$PaginatedResponse<API$Candidate> & {
    candidate_statuses: Array<API$Status>;
    profiles_events: {
      [key: number]: API$ProfileEvent[];
    };
  };
}> {
  return get(`/api/v6/folders/${folderId}/candidates/`, params);
}

export function addAllCandidateToFolder(
  rawQuery: string
): Promise<{ payload: { candidates_count: number; id: number; name: string } }> {
  return get(`/api/v6/folders/from_search_query${rawQuery}`);
}

export function commentsGet(
  id: number,
  params?: { general?: boolean }
): Promise<{ payload: Array<API$Comment> }> {
  return get(`/api/v6/profiles/${id}/comments/`, params);
}

export function accountLinkGet(id: number, hash: string): Promise<{ payload: { link: string } }> {
  return get(`/api/profiles/${id}/links/${hash}/`);
}

export function commentsAdd(
  id: number,
  text: string,
  candidateId?: number | null
): Promise<{ payload: API$Comment }> {
  return post(`/api/v6/profiles/${id}/comments/`, {
    data: { text, ...(candidateId ? { candidate_id: candidateId } : undefined) },
  });
}

export function commentsUpdate(
  id: number,
  commentId: number,
  text: string
): Promise<{ payload: API$Comment }> {
  return patch(`/api/v6/profiles/${id}/comments/${commentId}/`, { data: { text } });
}

export function commentsDelete(id: number, commentId: number): Promise<void> {
  return del(`/api/v6/profiles/${id}/comments/${commentId}/`);
}

export function deUpload(formData: FormData): Promise<{ payload: API$DEImport }> {
  return post('/api/v6/enrichment_requests/', {
    dataType: 'formData',
    data: formData,
  });
}

export function getDeImports(): Promise<{ payload: Array<API$DEImport> }> {
  return get('/api/v6/enrichment_requests/');
}

export function attachDisplayTags(
  profileId: number,
  tags: Array<API$DisplayTag>
): Promise<{ payload: Array<API$DisplayTag> }> {
  return post(`/api/v6/profiles/${profileId}/tags/`, { data: { tags } });
}

export function templatesGet(params?: API$TemplatesParams): Promise<{
  payload: API$PaginatedResponse<API$Template> & { example_templates: Array<API$ExampleTemplate> };
}> {
  return get('/api/v6/mailing_templates/', { ...params, messaging_type: 'mailing' });
}

export function mailingVariablesGet(): Promise<{ payload: Array<API$MailingVariable> }> {
  return get('/api/v6/mailing_variables/');
}

export function templateEdit(
  id: number,
  name?: string,
  body?: string,
  custom_variables?: Array<string>,
  access_type?: API$Folder['access_type'],
  subject?: string
): Promise<{ payload: API$Template }> {
  return patch(`/api/v6/mailing_templates/${id}/`, {
    data: { name, subject, body, custom_variables, access_type },
  });
}

export function templateCreate(
  name: string,
  body: string,
  custom_variables: Array<string> = [],
  access_type?: API$Folder['access_type'],
  subject?: string
): Promise<{ payload: API$Template }> {
  return post('/api/v6/mailing_templates/', {
    data: { name, subject, body, custom_variables, access_type },
  });
}

export function templateDelete(id: number): Promise<void> {
  return del(`/api/v6/mailing_templates/${id}/`);
}

export function templateGet(id: number): Promise<{ payload: API$Template }> {
  return get(`/api/v6/mailing_templates/${id}/`);
}

export function sendSingleMessage(
  data: API$SingleMessageData,
  params?: { launch?: boolean }
): Promise<{ payload: API$MailingList }> {
  return post('/api/v6/mailing_lists/', { data: { ...data }, params });
}

export function sendTestMessage(data: API$PreviewMessagesData): Promise<void> {
  return post('/api/v6/messaging/send_test_email/', { data });
}

export function createMailingList(data: API$CreateMailingListData): Promise<{
  payload: API$MailingList;
}> {
  return put('/api/v6/mailing_lists/', { data: { ...data, messaging_type: 'mailing' } });
}

export function editMailingList(
  mailingListId: number,
  data: {
    access_type?: API$Folder['access_type'];
    custom_variables?: Array<string>;
    name?: string;
    templates?: Array<API$MessageTemplate>;
  }
): Promise<{
  payload: API$MailingList;
}> {
  return patch(`/api/v6/mailing_lists/${mailingListId}/`, { data });
}

export function launchMailingList(
  mailingListId: number,
  data?: API$SendDelayInfo,
  additionalMailingData?: Record<string, any>
): Promise<{
  payload: {
    error_relaunched: number;
    launched: number;
    relaunched: number;
    without_email: number;
    without_variable_values: number;
  };
}> {
  return post(`/api/v6/mailing_lists/${mailingListId}/launch/`, {
    data: { ...data, ...additionalMailingData },
  });
}

export function deleteMailingList(
  mailingListId: number,
  params?: API$SaveMessagingHistory
): Promise<void> {
  return del(`/api/v6/mailing_lists/${mailingListId}/`, params);
}

export function stopMailingList(mailingListId: number): Promise<{
  payload: {
    stopped: number;
  };
}> {
  return post(`/api/v6/mailing_lists/${mailingListId}/stop/`);
}

export function getMailingRecipients(params?: API$MailingRecipientsParams): Promise<{
  payload: API$PaginatedResponse<API$MailingRecipient>;
}> {
  return get('/api/v6/mailing_recipients/', { ...params, messaging_type: 'mailing' });
}

export function updateMailingRecipient(
  recipientId: number,
  data: { [key: string]: any }
): Promise<{
  payload: API$MailingRecipient;
}> {
  return patch(`/api/v6/mailing_recipients/${recipientId}/`, { data });
}

export function getRecipientDetailed(
  recipientId: number
): Promise<{ payload: API$RecipientDetailed }> {
  return get(`/api/v6/mailing_recipients/${recipientId}/`);
}

export function usersGet(params?: API$UsersParams): Promise<{
  payload: API$PaginatedResponse<API$Assignee>;
}> {
  return get('/api/v6/users/', params);
}

export function getMessagePreview(
  data: API$PreviewMessagesData
): Promise<{ payload: Array<API$MessagePreview> }> {
  return post('/api/v6/messaging/preview/', { data });
}

export function exportEnrichedProfiles(id: number, params: API$DownloadFileParams): Promise<void> {
  return download(`/api/v6/enrichment/${id}/results/`, { params });
}

export function getHMCompanyDisplaySectionsAsAdmin(): Promise<{
  payload: API$HMDisplaySections;
}> {
  return get('/api/v6/hiring_managers/settings/');
}

export function getHMCompanyDisplaySectionsAsManager(
  companyId: string
): Promise<{ payload: API$HMDisplaySections }> {
  return get(`/api/v6/companies/${companyId}/hiring_managers/settings/`);
}

export function updateHMCompanyDisplaySectionsAsAdmin(
  displaySections: Array<string>
): Promise<{ payload: API$HMDisplaySections }> {
  return patch(`/api/v6/hiring_managers/settings/`, {
    data: { hm_display_sections: displaySections },
  });
}

export function updateHMCompanyDisplaySectionsAsManager(
  companyId: string,
  displaySections: Array<string>
): Promise<{ payload: API$HMDisplaySections }> {
  return patch(`/api/v6/companies/${companyId}/hiring_managers/settings/`, {
    data: { hm_display_sections: displaySections },
  });
}

export function getProfileRealtimeDiff(
  profileId: number,
  companyId: number
): Promise<{ payload: API$ProfileRealtimeDiff }> {
  return get(`/api/v6/profiles/${profileId}/rt_diff/${companyId}`);
}

export function getMailingList(id: number): Promise<{ payload: API$MailingList }> {
  return get(`/api/v6/mailing_lists/${id}/`);
}

export function getRecipientPreview(
  recipientId: number
): Promise<{ payload: Array<API$MessagePreview> }> {
  return get(`/api/v6/mailing_recipients/${recipientId}/preview/`);
}

export function deleteRecipient(
  recipientId: number,
  params?: API$SaveMessagingHistory
): Promise<void> {
  return del(`/api/v6/mailing_recipients/${recipientId}/`, params);
}

export function addRecipientsToMailingList(
  mailingListId: number,
  candidateIds: Array<number>
): Promise<{
  payload: {
    added_candidate_ids: Array<number>;
    candidate_errors: { [key: number]: Array<string> };
    candidate_ids: Array<number>;
  };
}> {
  return put('/api/v6/mailing_recipients/', {
    data: { mailing_list_id: mailingListId, candidate_ids: candidateIds },
  });
}

export function resumeFragmentAdd(
  field: string,
  value: string,
  type: string,
  docid: number,
  orderId?: number
): Promise<{ payload: { resumeId: number; unitedProfile: API$Profile } }> {
  const html =
    field === 'userlinks'
      ? value
      : JSON.stringify({
          value,
          type: type.toUpperCase(),
        });
  const url = orderId === undefined ? `ah://fragment-${field}` : 'ah://ah-help';
  const data = { url, html, order_id: orderId };

  return post(`/api/v6/profiles/${docid}/private/`, { data });
}

export function resumeFragmentRemove(
  resume_id: string,
  docid: number,
  orderId?: number
): Promise<void> {
  const data = { order_id: orderId };

  return del(`/api/v6/profiles/${docid}/private/${resume_id}/`, null, data);
}

export function removeResume(id: string, profileId: number): Promise<void> {
  return del(`/api/v6/profiles/${profileId}/private/${id}/`);
}

export function getMailingListLaunchInfo(
  mailingListId: number
): Promise<{ payload: API$MailingListLaunchInfo }> {
  return get(`/api/v6/mailing_lists/${mailingListId}/launch_info/`);
}

export function changeMessagingSettings(
  data: API$MessagingSettingsData
): Promise<{ payload: API$MessagingSettingsData }> {
  return patch('/api/v6/messaging/settings/', { data });
}

export const saveProfile = (data: API$EditProfileData): Promise<{ payload: API$Profile }> =>
  post('/api/v6/profiles/private/', { data });

export function createProfileFromFile(file_id: number): Promise<{ payload: API$Profile }> {
  return put('/api/v6/profiles/private/', { data: { file_id } });
}

export function editProfile(
  profileId: number,
  data: API$EditProfileData
): Promise<{ payload: API$Profile }> {
  return patch(`/api/v6/profiles/private/${profileId}/`, { data });
}

export function changeCompanyMessagingSettings(data: {
  messaging_unsubscribe: boolean;
}): Promise<{ payload: { messaging_unsubscribe: boolean } }> {
  return patch('/api/v6/messaging/company_settings/', { data });
}

export function getSimilarProfiles(
  profileID: number
): Promise<{ payload: { count: number; profiles: Array<API$Profile> } }> {
  return get(`/api/v6/profiles/${profileID}/same_tech/`);
}

export function enrichByFile(
  profileID: number,
  resumeId: string
): Promise<{ payload: API$Profile }> {
  return put(`/api/v6/profiles/private/${profileID}/`, { data: { resume_id: resumeId } });
}

export function getEmailSources(
  profile_id: number
): Promise<{ payload: { [key: string]: string } }> {
  return get('/api/v6/mailing_variables/email_sources/', { profile_id });
}

export function updateUserSettings(
  data: API$DataUserSettings
): Promise<{ payload: API$UserSettings }> {
  return patch(`/api/v6/user/settings/`, { data });
}

export function getActiveUsers(params: {
  system_part?: 'ext' | 'search';
}): Promise<{ payload: API$ActiveUsers }> {
  return get(`/api/v6/active-users/`, params);
}

export function getCompanyFormData(): Promise<{ payload: Array<API$CompanyFormData> }> {
  return get('/api/v6/companies/dropdowns/');
}

export function companyCreate(
  data: Partial<API$CompanyFormValues>
): Promise<{ payload: API$Company }> {
  return post('/api/v6/companies/new/', { data });
}

export function companiesGet(params?: API$CompaniesParams): Promise<{
  payload: API$PaginatedResponse<API$CompanyShort>;
}> {
  return get('/api/v6/companies/', params);
}

export function companyGet(id: string): Promise<{ payload: API$Company }> {
  return get(`/api/v6/companies/${id}/`);
}

export function getCompanyStatistics(
  id: number,
  params?: API$CompanyStatisticsQueryParams
): Promise<{ payload: API$CompanyStatistics }> {
  return get(`/api/v6/companies/${id}/statistics/`, params);
}
export function companyDelete(id: number): Promise<void> {
  return del(`/api/v6/companies/${id}/`);
}

export function companyEdit(
  id: number,
  data: Partial<API$CompanyFormValues>
): Promise<{ payload: API$Company }> {
  return put(`/api/v6/companies/${id}/`, { data });
}

export function employeeStatsGet(companyId: string): Promise<{ payload: API$EmployeeStats }> {
  return get(`/api/v6/companies/${companyId}/employees/stats/`);
}

export function companyCreditsAdd(companyId: string, data: API$CompanyCreditsData): Promise<void> {
  return post(`/api/v6/companies/${companyId}/credits/`, { data });
}

export function getProfilesViews(params: {
  creator_id: string;
  date_range?: string;
}): Promise<{ payload: API$ViewsHistory }> {
  return get('/api/profiles_events/', { ...params, only_viewed: true });
}

export function employeeGet(id: string | number): Promise<{ payload: API$Assignee }> {
  return get(`/api/v6/workers/${id}/`);
}

export function employeeAdd(
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return post('/api/v6/workers/', { data, params });
}

export function employeeEdit(
  id: string | number,
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/workers/${id}/`, { data, params });
}

export function employeeDelete(id: string | number): Promise<void> {
  return del(`/api/v6/workers/${id}/`);
}

export function adminGet(id: string | number): Promise<{ payload: API$Assignee }> {
  return get(`/api/v6/admins/${id}/`);
}

export function adminAdd(
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return post('/api/v6/admins/', { data, params });
}

export function adminEdit(
  id: string | number,
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/admins/${id}/`, { data, params });
}

export function adminDelete(id: string | number): Promise<void> {
  return del(`/api/v6/admins/${id}/`);
}

export function hmGet(id: string | number): Promise<{ payload: API$Assignee }> {
  return get(`/api/v6/hiring_managers/${id}/`);
}

export function hmAdd(
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return post('/api/v6/hiring_managers/', { data, params });
}

export function hmEdit(
  id: string | number,
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/hiring_managers/${id}/`, { data, params });
}

export function hmDelete(id: string | number): Promise<void> {
  return del(`/api/v6/hiring_managers/${id}/`);
}

export function managersGet(): Promise<{ payload: Array<API$Assignee> }> {
  return get('/api/v6/managers/');
}

export function managerGet(id: string | number): Promise<{ payload: API$Assignee }> {
  return get(`/api/v6/managers/${id}/`);
}

export function managerAdd(
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return post('/api/v6/managers/', { data, params });
}

export function managerEdit(
  id: string | number,
  data: API$EmployeeFormData,
  params?: API$EmployeeFormParams
): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/managers/${id}/`, { data, params });
}

export function managerDelete(id: string | number): Promise<void> {
  return del(`/api/v6/managers/${id}/`);
}

export function inviteResend(id: number): Promise<{ payload: API$Invitation }> {
  return post(`/api/v6/invitations/${id}/resend/`);
}

export function employeeGenerateAPIToken(id: string): Promise<void> {
  return post(`/api/v6/workers/${id}/generate-api-token/`);
}

export function adminGenerateAPIToken(id: string): Promise<void> {
  return post(`/api/v6/admins/${id}/generate-api-token/`);
}

export function employeeSwitchRole(id: number): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/workers/${id}/switch/`);
}

export function adminSwitchRole(id: number): Promise<{ payload: API$Assignee }> {
  return patch(`/api/v6/admins/${id}/switch/`);
}

export function employeeMoveToCompany(id: number | string, companyId: number): Promise<void> {
  return post(`/api/v6/workers/${id}/move/`, { data: { company_id: companyId } });
}

export function adminMoveToCompany(id: number | string, companyId: number): Promise<void> {
  return post(`/api/v6/admins/${id}/move/`, { data: { company_id: companyId } });
}

export function getAtsFolders(): Promise<{ payload: Array<API$Folder> }> {
  return get('/api/v6/ats-folders/');
}

export function bannedLinksGet(): Promise<{ payload: Array<API$BannedLink> }> {
  return get('/api/v6/banned_links/');
}

export function bannedLinksPost(url: string): Promise<{ payload: API$BannedLink }> {
  return post('/api/v6/banned_links/', { data: { url } });
}

export function bannedLinksDelete(urlForDelete: string): Promise<void> {
  return del(`/api/v6/banned_links/?url=${encodeURIComponent(urlForDelete)}`);
}

export function getExtensionNotifications(): Promise<{
  payload: Array<API$ExtensionNotification>;
}> {
  return get('/api/v6/notifications/');
}

export function createExtensionNotification(
  data: API$ExtensionNotification
): Promise<{ payload: API$ExtensionNotification }> {
  return post('/api/v6/notifications/', { data });
}

export function deleteExtensionNotification(id: number): Promise<void> {
  return del(`/api/v6/notifications/${id}/`);
}

export function editExtensionNotification(
  id: number,
  data: API$ExtensionNotification
): Promise<void> {
  return put(`/api/v6/notifications/${id}/`, { data });
}

export function getSystemNotifications(): Promise<{
  payload: API$PaginatedResponse<API$SystemNotification[]>;
}> {
  return get('/api/v6/system-notifications/?limit=100');
}

export function createSystemNotification(
  data: Partial<API$SystemNotification>
): Promise<{ payload: API$SystemNotification }> {
  return post('/api/v6/system-notifications/', { data });
}

export function deleteSystemNotification(id: number): Promise<void> {
  return del(`/api/v6/system-notifications/${id}/`);
}

export function editSystemNotification(
  id: number,
  data: Partial<API$SystemNotification>
): Promise<void> {
  return put(`/api/v6/system-notifications/${id}/`, { data });
}

export function setSystemNotificationStatus(
  id: AH$SystemNotification['id'],
  status: 'read' | 'hidden'
): Promise<any> {
  return post(`/api/v6/system-notifications/${id}/${status}/`, { data: {} });
}

export function metaQueriesGet(
  params?: API$MetaQueriesParams
): Promise<{ payload: Array<API$MetaQuery> }> {
  return get('/api/v6/metaqueries/', params);
}

export function enabledMetaQueriesGet(): Promise<{ payload: Array<API$MetaQuery> }> {
  return get('/api/v6/metaqueries/enabled/');
}

export function metaQueryAdd(data: Partial<API$MetaQuery>): Promise<{ payload: API$MetaQuery }> {
  return post('/api/v6/metaqueries/', { data });
}

export function metaQueryEdit(
  id: number,
  data: Partial<API$MetaQuery>
): Promise<{ payload: API$MetaQuery }> {
  return patch(`/api/v6/metaqueries/${id}/`, { data });
}

export function metaQueryDelete(id: number): Promise<void> {
  return del(`/api/v6/metaqueries/${id}/`);
}

export function getStatistics(
  section: AH$StatisticsSection,
  companyId: string,
  params: Record<string, string | Array<string>>
): Promise<{ payload: API$Statistics }> {
  return get(`/api/v6/companies/${companyId}/statistics/${section}/`, params);
}

export function getStatisticsFilters(
  section: AH$StatisticsSection,
  companyId: string,
  params: Record<string, string | Array<string>>
): Promise<{ payload: Record<string, Array<string>> }> {
  return get(`/api/v6/companies/${companyId}/statistics/${section}/filters/`, params);
}

export function getStatisticsSettings(
  section: AH$StatisticsSection,
  companyId: string
): Promise<{ payload: Record<string, Record<string, boolean>> }> {
  return get(`/api/v6/companies/${companyId}/statistics/${section}/settings/`);
}

export function setStatisticsSettings(
  section: AH$StatisticsSection,
  companyId: string,
  data: Record<string, Record<string, boolean>>
): Promise<{ payload: Record<string, Record<string, boolean>> }> {
  return post(`/api/v6/companies/${companyId}/statistics/${section}/settings/`, { data });
}

export function recaptchaSitekeyGet(): Promise<{ payload: { key: string } }> {
  return get('/api/v6/recaptcha-key/');
}

export function passwordRecover(
  data: API$RecoverPasswordData
): Promise<{ payload: API$RecoverPasswordPayload }> {
  return post('/api/v6/recover-password/', { data });
}

export function passwordRecoveryEmailGet(token: string): Promise<{ payload: { email: string } }> {
  return get(`/api/v6/reset-password/${token}/`);
}

export function passwordReset(token: string, data: API$ResetPasswordData): Promise<void> {
  return post(`/api/v6/reset-password/${token}/`, { data });
}

export function currentCompanyUpdate(
  data: API$CompanyUpdateData
): Promise<{ payload: API$CompanyUpdateData }> {
  return patch(`/api/v6/company/`, { data });
}

export function smartFolderQueryGet(
  folderId: number
): Promise<{ payload: API$SearchHistoryQuery }> {
  return get(`/api/v6/folders/${folderId}/search_query/`);
}

export function smartFolderHistoryGet(
  folderId: number,
  params?: API$PaginationParams
): Promise<{
  payload: API$PaginatedResponse<API$SearchHistoryQuery>;
}> {
  return get(`/api/v6/folders/${folderId}/search_history/`, params);
}

export function folderRecommendationsGet(folderId: number): Promise<{
  payload: API$Profile[];
}> {
  return get(`/api/v6/folders/${folderId}/recommendations/`);
}

export function smartFoldersProfiles(
  params: string,
  data: API$SmartFoldersProfilesData
): Promise<{ pages: AH$Pages; payload: API$ProfilesPayload; profileReferer: string }> {
  return post(`/api/v6/smart_folders/profiles/?${params}`, { direct: true, data });
}

export function smartFoldersProfilesFilters(
  params: string,
  data: API$SmartFoldersProfilesData
): Promise<{
  gdpr: API$GdprFilters;
  payload: { [key: string]: Array<API$FilterValue> };
}> {
  return post(`/api/v6/smart_folders/profiles/filters/?${params}`, { direct: true, data });
}

export function leverVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/lever/vacancies/');
}

export function leverCandidateVacancies(
  ah_profile_id: string | number
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/lever/vacancies/', { ah_profile_id });
}

export function leverSelectCandidate(
  ah_profile_id: string | number,
  job_id?: string | number
): Promise<void> {
  return post('/api/lever/candidates/', { data: { ah_profile_id, job_id } });
}

export function leverGetUsers(): Promise<{ payload: Array<API$IntegrationUser> }> {
  return get('/api/lever/users/');
}

export function leverSelectUser(user_id: string): Promise<void> {
  return post('/api/lever/users/', { data: { user_id } });
}

export function companyCustomSettingsUpdate(
  companyId: number,
  data: Record<string, 0 | 1>
): Promise<{ payload: API$Company['custom_settings'] }> {
  return patch(`/api/v6/companies/${companyId}/custom_settings/`, { data });
}

export function connectGreenhouse(
  access_token: string,
  email: string
): Promise<{ payload: API$GreenhouseAuth }> {
  return post('/api/v6/greenhouse/auth/', { data: { access_token, email } });
}

export function connectGreenhouseImport(
  access_token: string,
  email: string
): Promise<{ payload: API$GreenhouseAuth }> {
  return post('/api/v6/greenhouse_import/auth/', { data: { access_token, email } });
}

export function connectTeamtailor(
  access_token: string,
  email: string
): Promise<{ payload: API$GreenhouseAuth }> {
  return post('/api/v6/teamtailor/auth/', { data: { access_token, email } });
}

export function connectSmartRecruitersImportProfiles(api_key: string): Promise<unknown> {
  return post('/api/smartrecruiters/import_candidates/', { data: { api_key } });
}

export function connectHunflowImportProfiles(
  connection_link: string,
  huntflow_premier: boolean,
  huntflow_api_url?: string
): Promise<unknown> {
  return post('/api/huntflow/import_candidates/', {
    data: { connection_link, huntflow_premier, huntflow_api_url },
  });
}

export function teamtailorVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/teamtailor/vacancies/');
}

export function teamtailorCandidateVacancies(
  ah_profile_id: string | number
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/teamtailor/vacancies/', { ah_profile_id });
}

export function teamtailorSelectCandidate(
  ah_profile_id: string | number,
  job_id?: string | number
): Promise<void> {
  return post('/api/teamtailor/candidates/', { data: { ah_profile_id, job_id } });
}

export function teamtailorUpdateCandidate(profileId: number): Promise<void> {
  return patch('/api/teamtailor/candidates/', { data: { ah_profile_id: profileId } });
}

export function marketInsightsGet(
  params: string,
  folderId?: number
): Promise<{ payload: API$InsightsPayload }> {
  return post(`/api/v6/market_insights/?${params}`, {
    direct: true,
    ...(folderId ? { data: { folderId } } : undefined),
  });
}

export function checkIsMailingConnected(): Promise<any> {
  return post('/api/v6/messaging/send_test_email/', { data: { check_permissions: true } });
}

export function registerUser(data: API$RegisterUserData): Promise<void> {
  return post('/api/v6/register/', { data });
}

export function activateUser(token: string): Promise<void> {
  return get(`/api/v6/activate/${token}/`);
}

// MetaBase
export function sendMetric<T extends MetricsType>(
  type: T,
  event: $Values<(typeof metricsEventsMap)[T]>,
  params?: Record<string, any>
): Promise<void> {
  return get(`/api/v6/metrics/${type}/${event}/`, params);
}

export function findContacts(profileId: number): Promise<{ payload: API$Profile }> {
  return get(`/api/v6/profiles/${profileId}/get-contacts/`);
}

export function sequenceCreate(data: API$FolderData): Promise<void> {
  return post('/api/v6/sequences/', { data });
}

export function sequenceEdit(id: number, data: API$FolderData): Promise<void> {
  return patch(`/api/v6/sequences/${id}/`, { data });
}

export function sequenceGet(id: number): Promise<{ payload: API$Folder }> {
  return get(`/api/v6/sequences/${id}/`);
}

export function sequenceDelete(id: number, saveMessagingHistory?: boolean): Promise<void> {
  return del(`/api/v6/sequences/${id}/`, { saveMessagingHistory });
}

export function sequencesGet(params?: API$PaginationParams): Promise<{
  payload: API$PaginatedResponse<API$Folder & { mailing_stats: AH$MailingList['stats'] }>;
}> {
  return get(`/api/v6/sequences/`, params);
}

export function sequenceRecipientsGet(
  sequenceId: number,
  params: AH$CandidatesParams
): Promise<{
  payload: API$PaginatedResponse<
    API$MailingRecipient & {
      profile_info: API$MiniProfile;
      source: string;
      stats: {
        error: {
          restartable: number;
          unrestartable: number;
        };
        total_sent: number;
      };
    }
  >;
}> {
  return get(`/api/v6/sequences/${sequenceId}/recipients/`, params);
}

export function sequenceAddRecipient(
  sequenceId: number,
  profileData: API$SequenceProfileData
): Promise<void> {
  return post(`/api/v6/sequences/${sequenceId}/add_profile/`, { data: profileData });
}

export function sequenceRelaunchFailedMessages(
  sequenceId: number
): Promise<{ payload: { error_relaunched: number } }> {
  return post(`/api/v6/sequences/${sequenceId}/relaunch_failed/`);
}

export function gptVacanciesGet(): Promise<{ payload: API$GptVacancy[] }> {
  return get('/api/v6/vacancies/description/');
}

export function gptVacancyCreate(
  data: API$GptVacancyCreateData
): Promise<{ payload: API$GptVacancy }> {
  return post('/api/v6/vacancies/description/', { data });
}

export function gptVacancyUpdate(
  id: number,
  data: Partial<API$GptVacancyCreateData>
): Promise<{ payload: API$GptVacancy }> {
  return patch(`/api/v6/vacancies/description/${id}/`, { data });
}

export function gptVacancyDelete(id: number): Promise<void> {
  return del(`/api/v6/vacancies/description/${id}/`);
}

export function gptVacancyLanguagesGet(): Promise<{ payload: API$GptVacancyLanguages }> {
  return get('/api/v6/vacancies/description/languages/');
}

export function gptGenerationStart(
  profileId: number,
  vacancyId: number
): Promise<{ payload: { id: number } }> {
  return post('/api/v6/messaging/generation/', {
    data: { job_description: vacancyId, profile_id: profileId },
  });
}

export function gptGenerationGet(id: number): Promise<{ payload: API$GptGeneration }> {
  return get(`/api/v6/messaging/generation/${id}/`);
}

export function specializationQueryGet(
  query: string
): Promise<{ payload: API$SpecializationQuery[] }> {
  return get(`/api/v6/specialization-query/${query}/`);
}

export function getSSOSettings(): Promise<{ payload: API$SSOFormValues }> {
  return get('/sso/settings/');
}

export function createSSOSettings(
  data: API$SSOFormValues
): Promise<{ payload: API$SSOFormValues }> {
  return post('/sso/settings/', { data });
}

export function updateSSOSettings(
  data: API$SSOFormValues
): Promise<{ payload: API$SSOFormValues }> {
  return patch('/sso/settings/', { data });
}

export function getQuotasCompany(companyId: number): Promise<{ payload: API$MonetizationQuota[] }> {
  return get(`/api/v6/companies/${companyId}/quotas/`);
}

export function deactivateQuotaCompany(companyId: number, quotaId: number): Promise<void> {
  return post(`/api/v6/companies/${companyId}/quotas/${quotaId}/deactivate/`);
}

export function addQuotaCompany(
  companyId: number,
  data?: { from_date: string; quota: number; till_date: string; used_quota: null | number }
): Promise<{ payload: API$MonetizationQuota }> {
  return post(`/api/v6/companies/${companyId}/quotas/`, { data: { ...data, is_active: true } });
}

export function verifyProfile(profileId: number): Promise<{
  payload: { companyName: string; eventDate: number; status: string; vacancyName: string };
}> {
  return get(`/api/v6/profiles/${profileId}/verifier/`);
}

export function telegramSendCode(phone_number: string): Promise<{ payload: AH$TelegramManager }> {
  return post('/api/internal/telegram/accounts/send_code_request/', { data: { phone_number } });
}

export function telegramConnectAccount({
  phone_number,
  code,
}: {
  code: string;
  phone_number: string;
}): Promise<{ payload: AH$TelegramManager }> {
  return post('/api/internal/telegram/accounts/', { data: { phone_number, code } });
}

export function getAllTelegramAccounts(params: AH$TelegramAccountsParams): Promise<{
  payload: { count: number; next: string; previous: string; results: AH$TelegramManager[] };
}> {
  return get('/api/internal/telegram/accounts/', params);
}

export function getTelegramAccount(id: string): Promise<{ payload: AH$TelegramManager }> {
  return get(`/api/internal/telegram/accounts/${id}`);
}

export function getTelegramDialogs(params: {
  created_by_id?: number;
  limit?: number;
  offset?: number;
  telegram_account_id?: number;
}): Promise<{
  payload: { count: number; next: string; previous: string; results: AH$TelegramDialog[] };
}> {
  return get('/api/internal/telegram/dialogs/', params);
}
