import * as React from 'react';

import thematize from 'lib/thematize';
import styles from './TokenAiTerm.scss';

const theme = thematize(styles);

interface Props {
  children: React.ReactNode;
  entityKey: string;
}

const TokenAiTerm = ({ children, entityKey }: Props): React.ReactElement => (
  <span id={entityKey} className={theme('container')}>
    {children}
  </span>
);

export default TokenAiTerm;
