import {
  AnyAction,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { difference, get, has, keys, mapValues, noop, omit, pick, union } from 'lodash';
import generateUuid from 'uuid/v4';

import { AH$RouterLocation } from 'types';
import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise, tryAbortPromise } from 'lib/abortablePromise';
import { profilesFilters } from 'lib/api';
import { marketInsightsGet, smartFoldersProfilesFilters } from 'lib/apiV6';
import { getActiveSmartFolderId, stringify } from 'lib/query';
import { NavigateFunction } from 'hooks/useNavigate';
import { getErrorHandler } from 'slices/app';
import { SearchFormState, setSmartFolder } from 'slices/searchForm';
import config, {
  constHoldedFacets,
  constUpdatedFacets,
  defaultOpenedFacets,
  isAndBetween,
  transformerFilters,
  valIdsForUrlFacetIds,
} from 'components/Filters/config';
import {
  capitalizeNames,
  convertFromUrl,
  convertToUrl,
  excludeBlankCompanySizeFilters,
  saveFiltersValues,
  transformLoadedFilters,
} from 'components/Filters/lib';
import { transformToUrl } from 'components/QueryEditor/lib';

const SLICE_NAME = 'filters';

let facetsRequest: null | AbortablePromise<any> = null; // TODO: сделать нормальный тип

export type FiltersState = {
  readonly checkedFilters: AH$Filters;
  readonly commentDays: {
    count: number;
  };
  readonly erroredFacets: Set<string>;
  readonly excludedFilters: AH$Filters;
  readonly extViewed: {
    by: 'my' | 'team';
    count: number;
  };
  readonly gdpr: AH$GdprFilters;
  readonly internalSearchId: string | null;
  readonly loadingFacets: Set<string>;
  readonly marketInsights: {
    readonly data: {
      [key: string]: Array<AH$FilterValue>;
    } | null;
    readonly status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly mltrDays: {
    count: number;
    type: 'all' | 'exact';
  };
  readonly openedFacets: Set<string>;
  readonly profilesCount: number | null;
  readonly realtimeCount: {
    readonly data: number | null;
    readonly status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly showOlyChecked: boolean;
  readonly values: Partial<Record<keyof AH$Filters, AH$FilterValue[]>>;
  readonly viewsTransformer: {
    by: 'my' | 'team' | 'folder';
    count: number;
    value: 'views' | 'shown' | 'folders' | 'contacted';
  };
};

type Dispatch = ThunkDispatch<{ filters: FiltersState }, unknown, AnyAction>;

export const filtersInitialState: FiltersState = {
  values: {},
  checkedFilters: {
    companySize: new Set(['curr']),
  },
  excludedFilters: {},
  loadingFacets: new Set<string>(),
  erroredFacets: new Set<string>(),
  openedFacets: defaultOpenedFacets,
  showOlyChecked: false,
  gdpr: {
    gender: false,
    diversity: false,
    age: false,
  },
  viewsTransformer: {
    value: 'shown',
    count: 30,
    by: 'my',
  },
  extViewed: {
    count: 30,
    by: 'my',
  },
  mltrDays: {
    count: 30,
    type: 'exact',
  },
  commentDays: {
    count: 30,
  },
  marketInsights: {
    data: null,
    status: FETCHING_STATUSES.LOADING,
  },
  realtimeCount: {
    data: null,
    status: FETCHING_STATUSES.SUCCESS,
  },
  profilesCount: null,
  internalSearchId: null,
};

const calculateOnlyChecked = (state: FiltersState, payload?: boolean): boolean => {
  const { checkedFilters, excludedFilters, viewsTransformer } = state;
  const isSmartFolders = !!getActiveSmartFolderId();

  const checkedNotDefaultFiltersCount = (
    Object.keys(checkedFilters) as Array<keyof AH$Filters>
  ).reduce<number>((acc, key) => {
    if (!checkedFilters[key]?.size) return acc;

    if (key === 'companySize' && checkedFilters.companySize!.has('curr')) {
      return acc + checkedFilters.companySize!.size - 1;
    }

    if (
      key === 'general' &&
      isSmartFolders &&
      excludedFilters.general?.has('shown') &&
      viewsTransformer.by === 'folder'
    ) {
      return acc + checkedFilters.general!.size - 1;
    }

    return acc + checkedFilters[key]!.size;
  }, 0);

  const isShowOnlyChecked = payload === false ? false : checkedNotDefaultFiltersCount > 0;

  return isShowOnlyChecked;
};

const getChangedFilters = (
  state: FiltersState,
  payload: ChangeFilterPayload
): {
  checkedFilters: FiltersState['checkedFilters'];
  excludedFilters: FiltersState['excludedFilters'];
} => {
  const { facetId, value: initValue, checked, excluded, replace } = payload;
  const value =
    transformerFilters.includes(initValue) && state[initValue as 'viewsTransformer']
      ? state[initValue as 'viewsTransformer'].value
      : initValue;

  const checkedFacetFilters = new Set(
    state.checkedFilters[facetId as keyof typeof state.checkedFilters] || []
  );
  const excludedFacetFilters = new Set(
    state.excludedFilters[facetId as keyof typeof state.excludedFilters] || []
  );

  if (replace) {
    checkedFacetFilters.clear();
  }
  if (checked) {
    checkedFacetFilters.add(value);
  } else {
    checkedFacetFilters.delete(value);
  }
  if (excluded) {
    excludedFacetFilters.add(value);
  } else {
    excludedFacetFilters.delete(value);
  }

  return {
    checkedFilters: {
      ...state.checkedFilters,
      [facetId]: checkedFacetFilters,
    },
    excludedFilters: {
      ...state.excludedFilters,
      [facetId]: excludedFacetFilters,
    },
  };
};

export const setFacets = createAction<FiltersState['values']>(`${SLICE_NAME}/setFacets`);

const getFacetsLoading = createAction<string[]>(`${SLICE_NAME}/getFacetsLoading`);

const getFacetsLoaded = createAction<{
  gdpr: FiltersState['gdpr'];
  values: FiltersState['values'];
}>(`${SLICE_NAME}/getFacetsLoaded`);

const getFacetsErrored = createAction<string[]>(`${SLICE_NAME}/getFacetsErrored`);

export type ChangeFilterPayload = {
  checked: boolean;
  facetId: string;
  value: any;
  excluded?: boolean;
  replace?: boolean;
};

const setFilters = createAction<{
  checkedFilters: FiltersState['checkedFilters'];
  excludedFilters: FiltersState['excludedFilters'];
}>(`${SLICE_NAME}/setFilters`);

export const trySetShowOnlyChecked = createAction<boolean | undefined>(
  `${SLICE_NAME}/trySetShowOnlyChecked`
);

const getRealtimeCountErrored = createAction(`${SLICE_NAME}/getRealtimeCountErrored`);

const getRealtimeCountLoaded = createAction<number | null>(`${SLICE_NAME}/getRealtimeCountLoaded`);

const setProfilesCount = createAction<{ count: number | null; id: string | null }>(
  `${SLICE_NAME}/setProfilesCount`
);

export const getRealtimeCount = createAsyncThunk(
  `${SLICE_NAME}/getRealtimeCount`,
  async (_, { dispatch, getState }) => {
    const {
      filters: filtersState,
      searchForm: { queries, suggestions },
    } = getState() as { filters: FiltersState; searchForm: SearchFormState };

    const smartFolderId = getActiveSmartFolderId() || undefined;
    const convertedF = convertToUrl({
      valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
      checkedFilters: filtersState.checkedFilters,
      excludedFilters: filtersState.excludedFilters,
      isAndBetween,
      extViewed: filtersState.extViewed,
      viewsTransformer: filtersState.viewsTransformer,
      mltrDays: filtersState.mltrDays,
      commentDays: filtersState.commentDays,
      smartFolderId,
    });
    const q: AH$SearchQueryParamQ = transformToUrl(queries, suggestions, true);
    const search = stringify({
      q,
      convertedF,
    });

    try {
      facetsRequest = abortable(
        smartFolderId
          ? smartFoldersProfilesFilters(search, {
              folderId: smartFolderId,
              viewedProfiles: [],
            })
          : profilesFilters(search)
      );
      const {
        payload: { count },
      } = await facetsRequest;

      dispatch(getRealtimeCountLoaded(count));
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          silent: true,
          getError: () => {
            dispatch(getRealtimeCountErrored());
          },
        })(e);
      }
    }
  }
);

export const getFacets = createAsyncThunk(
  `${SLICE_NAME}/getFacets`,
  async (
    {
      onSuccess = noop,
      onError = noop,
      viewedProfiles = [],
      forceSetFacets = true,
      setCount = true,
      ...payload
    }: {
      profilesQuery: AH$RouterLocation['query'];
      checkedFilters?: FiltersState['checkedFilters'];
      commentDays?: FiltersState['commentDays'];
      excludedFilters?: FiltersState['excludedFilters'];
      extViewed?: FiltersState['extViewed'];
      facets?: Array<string>;
      forceSetFacets?: boolean;
      isV2?: boolean;
      mltrDays?: FiltersState['mltrDays'];
      onError?: () => void;
      onSuccess?: (filtersValues: FiltersState['values']) => void;
      setCount?: boolean;
      viewedProfiles?: Array<number>;
      viewsTransformer?: FiltersState['viewsTransformer'];
    },
    { dispatch, getState }
  ) => {
    const { filters } = getState() as { filters: FiltersState };
    const openedFacets = Array.from(filters.openedFacets);
    const {
      facets = openedFacets,
      checkedFilters,
      excludedFilters,
      viewsTransformer,
      profilesQuery,
      extViewed,
      mltrDays,
      commentDays,
    } = payload || {};
    const facetsForUpdate = union(
      difference(facets, Array.from(constHoldedFacets)),
      Array.from(constUpdatedFacets)
    );
    const { folderId, ...query } = profilesQuery;
    const convertedF = convertToUrl({
      valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
      checkedFilters: checkedFilters || filters.checkedFilters,
      excludedFilters: excludedFilters || filters.excludedFilters,
      isAndBetween,
      extViewed: extViewed || filters.extViewed,
      viewsTransformer: viewsTransformer || filters.viewsTransformer,
      smartFolderId: folderId,
      mltrDays: mltrDays || filters.mltrDays,
      commentDays: commentDays || filters.commentDays,
    });
    const search = stringify({
      ...query,
      convertedF,
      facets: facetsForUpdate,
      rankingResource: query.s,
      s: null,
    });

    if (forceSetFacets) {
      dispatch(setFacets(pick(filters.values, openedFacets)));
    }

    dispatch(getFacetsLoading(facetsForUpdate));
    tryAbortPromise(facetsRequest);

    try {
      facetsRequest = abortable(
        folderId
          ? smartFoldersProfilesFilters(search, {
              folderId: parseInt(folderId, 10),
              viewedProfiles,
            })
          : profilesFilters(search)
      );
      const { payload, gdpr } = await facetsRequest;
      const { count, ...rest } = payload;
      const transformedFiltersValues = transformLoadedFilters(rest, config);

      onSuccess(transformedFiltersValues);

      if (setCount) {
        dispatch(setProfilesCount({ count, id: generateUuid() }));
      }

      dispatch(
        getFacetsLoaded({
          values: transformedFiltersValues,
          gdpr,
        })
      );
      dispatch(getRealtimeCountLoaded(count));
    } catch (e: any) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          getError: () => {
            dispatch(getFacetsErrored(facetsForUpdate));
            onError();
          },
        })(e);
      }
    }
  }
);

export const uncheckRangeNotSpecified = createAsyncThunk(
  `${SLICE_NAME}/uncheckRangeNotSpecified`,
  (facetId: string, { dispatch, getState }) => {
    const { filters } = getState() as { filters: FiltersState };
    const notSpecifiedId = config.get(facetId)!._notSpecifiedId as keyof AH$Filters | undefined;
    const isNotSpecifiedChecked = !!(
      notSpecifiedId && filters.checkedFilters[notSpecifiedId]?.size
    );

    if (isNotSpecifiedChecked) {
      dispatch(
        setFilters(
          getChangedFilters(filters, {
            facetId: notSpecifiedId,
            checked: false,
            replace: true,
            value: false,
          })
        )
      );
    }
  }
);

export const changeFilter = createAsyncThunk(
  `${SLICE_NAME}/changeFilter`,
  (
    {
      navigate,
      location,
      payload,
    }: { location: AH$RouterLocation; navigate: NavigateFunction; payload?: ChangeFilterPayload },
    { dispatch, getState }
  ) => {
    const { filters, searchForm } = getState() as {
      filters: FiltersState;
      searchForm: SearchFormState;
    };
    const newFilters = payload
      ? getChangedFilters(filters, payload)
      : { checkedFilters: filters.checkedFilters, excludedFilters: filters.excludedFilters };

    if (payload) {
      dispatch(setFilters(newFilters));
    }

    navigate({
      query: {
        ...omit(location.query, 'page'),
        f: convertToUrl({
          valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
          checkedFilters: newFilters.checkedFilters,
          excludedFilters: newFilters.excludedFilters,
          extViewed: filters.extViewed,
          isAndBetween,
          viewsTransformer: filters.viewsTransformer,
          mltrDays: filters.mltrDays,
          commentDays: filters.commentDays,
          smartFolderId: get(searchForm.activeSmartFolder, 'id'),
        }),
      },
    });

    if (filters.showOlyChecked) {
      dispatch(trySetShowOnlyChecked(true));
    }
  }
);

export type UpdateOpenedFacetsPayload = {
  checked: boolean;
  facetIds: string[];
};

export const updateOpenedFacets = createAsyncThunk(
  `${SLICE_NAME}/updateOpenedFacets`,
  (
    {
      location,
      payload: { facetIds, checked },
    }: {
      location: AH$RouterLocation;
      payload: UpdateOpenedFacetsPayload;
    },
    { dispatch, getState }
  ) => {
    const { openedFacets, values } = (getState() as { filters: FiltersState }).filters;
    const newOpenedFacets = new Set(openedFacets);
    const facetsToUpdate: string[] = [];

    if (checked) {
      facetIds.forEach((facet) => {
        newOpenedFacets.add(facet);
        if (!has(values, facet) && !constHoldedFacets.has(facet)) {
          facetsToUpdate.push(facet);
        }
      });

      if (facetsToUpdate.length) {
        dispatch(
          getFacets({
            facets: facetsToUpdate,
            profilesQuery: location.profilesQuery,
          })
        );
      }
    } else {
      facetIds.forEach((facet) => newOpenedFacets.delete(facet));
    }

    return newOpenedFacets;
  }
);

const setViewsTransformerAction = createAction<{
  checkedFilters: FiltersState['checkedFilters'];
  excludedFilters: FiltersState['excludedFilters'];
  viewsTransformer: FiltersState['viewsTransformer'];
}>(`${SLICE_NAME}/setViewsTransformer`);

export const setViewsTransformer =
  ({
    payload,
    navigate,
    location,
    isV2 = false,
  }: {
    location: AH$RouterLocation;
    navigate: NavigateFunction;
    payload: Partial<FiltersState['viewsTransformer']>;
    isV2?: boolean;
  }) =>
  (
    dispatch: Dispatch,
    getState: () => {
      filters: FiltersState;
      searchForm: SearchFormState;
    }
  ) => {
    const { filters, searchForm } = getState();
    const checkedGeneralFilters = new Set(filters.checkedFilters.general || []);
    const excludedGeneralFilters = new Set(filters.excludedFilters.general || []);
    const oldValue = filters.viewsTransformer.value;
    const { value } = payload;

    if (!checkedGeneralFilters.has(oldValue) && !excludedGeneralFilters.has(oldValue)) {
      checkedGeneralFilters.add(value || oldValue);
    } else if (value) {
      checkedGeneralFilters.delete(oldValue);
      checkedGeneralFilters.add(value);

      if (excludedGeneralFilters.has(oldValue)) {
        excludedGeneralFilters.delete(oldValue);
        excludedGeneralFilters.add(value);
      }
    }
    const checkedFilters = {
      ...filters.checkedFilters,
      general: checkedGeneralFilters,
    };
    const excludedFilters = {
      ...filters.excludedFilters,
      general: excludedGeneralFilters,
    };
    const viewsTransformer = { ...filters.viewsTransformer, ...payload };

    dispatch(setViewsTransformerAction({ checkedFilters, excludedFilters, viewsTransformer }));

    if (!isV2) {
      navigate({
        query: {
          ...location.query,
          f: convertToUrl({
            valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
            checkedFilters,
            excludedFilters,
            isAndBetween,
            extViewed: filters.extViewed,
            mltrDays: filters.mltrDays,
            commentDays: filters.commentDays,
            viewsTransformer,
            smartFolderId: get(searchForm.activeSmartFolder, 'id'),
          }),
        },
      });
    } else {
      dispatch(getRealtimeCount());
    }
  };

const setExtViewedTransformerAction = createAction<{
  checkedFilters: FiltersState['checkedFilters'];
  excludedFilters: FiltersState['excludedFilters'];
  extViewed: FiltersState['extViewed'];
}>(`${SLICE_NAME}/setExtViewedTransformer`);

export const setExtViewedTransformer =
  ({
    payload,
    navigate,
    location,
    isV2 = false,
  }: {
    location: AH$RouterLocation;
    navigate: NavigateFunction;
    payload: Partial<FiltersState['extViewed']>;
    isV2?: boolean;
  }) =>
  (
    dispatch: Dispatch,
    getState: () => {
      filters: FiltersState;
      searchForm: SearchFormState;
    }
  ) => {
    const { filters, searchForm } = getState();
    const checkedGeneralFilters = new Set(filters.checkedFilters.general || []);
    const excludedGeneralFilters = new Set(filters.excludedFilters.general || []);

    if (!checkedGeneralFilters.has('extViewed') && !excludedGeneralFilters.has('extViewed')) {
      checkedGeneralFilters.add('extViewed');
    }

    const checkedFilters = {
      ...filters.checkedFilters,
      general: checkedGeneralFilters,
    };
    const excludedFilters = {
      ...filters.excludedFilters,
      general: excludedGeneralFilters,
    };
    const extViewed = { ...filters.extViewed, ...payload };

    dispatch(setExtViewedTransformerAction({ checkedFilters, excludedFilters, extViewed }));

    if (!isV2) {
      navigate({
        query: {
          ...location.query,
          f: convertToUrl({
            valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
            checkedFilters,
            excludedFilters,
            isAndBetween,
            viewsTransformer: filters.viewsTransformer,
            mltrDays: filters.mltrDays,
            commentDays: filters.commentDays,
            extViewed,
            smartFolderId: get(searchForm.activeSmartFolder, 'id'),
          }),
        },
      });
    } else {
      dispatch(getRealtimeCount());
    }
  };

const setMLTRDaysAction = createAction<{
  checkedFilters: FiltersState['checkedFilters'];
  excludedFilters: FiltersState['excludedFilters'];
  mltrDays: FiltersState['mltrDays'];
}>(`${SLICE_NAME}/setMLTRDays`);

const setCommentDaysAction = createAction<{
  checkedFilters: FiltersState['checkedFilters'];
  commentDays: FiltersState['commentDays'];
  excludedFilters: FiltersState['excludedFilters'];
}>(`${SLICE_NAME}/setCommentDays`);

export const setMLTRDays =
  ({
    payload,
    navigate,
    location,
    isV2 = false,
  }: {
    location: AH$RouterLocation;
    navigate: NavigateFunction;
    payload: Partial<FiltersState['mltrDays']>;
    isV2?: boolean;
  }) =>
  (
    dispatch: Dispatch,
    getState: () => {
      filters: FiltersState;
      searchForm: SearchFormState;
    }
  ) => {
    const { filters, searchForm } = getState();
    const checkedGeneralFilters = new Set(filters.checkedFilters.general || []);
    const excludedGeneralFilters = new Set(filters.excludedFilters.general || []);

    if (!checkedGeneralFilters.has('mltrDays') && !excludedGeneralFilters.has('mltrDays')) {
      checkedGeneralFilters.add('mltrDays');
    }

    const checkedFilters = {
      ...filters.checkedFilters,
      general: checkedGeneralFilters,
    };
    const excludedFilters = {
      ...filters.excludedFilters,
      general: excludedGeneralFilters,
    };
    const mltrDays = { ...filters.mltrDays, ...payload };
    const commentDays = { ...filters.commentDays, ...payload };

    dispatch(setMLTRDaysAction({ checkedFilters, excludedFilters, mltrDays }));

    if (!isV2) {
      navigate({
        query: {
          ...location.query,
          f: convertToUrl({
            valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
            checkedFilters,
            excludedFilters,
            isAndBetween,
            viewsTransformer: filters.viewsTransformer,
            extViewed: filters.extViewed,
            smartFolderId: get(searchForm.activeSmartFolder, 'id'),
            mltrDays,
            commentDays,
          }),
        },
      });
    } else {
      dispatch(getRealtimeCount());
    }
  };

export const setCommentDays =
  ({
    payload,
    navigate,
    location,
    isV2 = false,
  }: {
    location: AH$RouterLocation;
    navigate: NavigateFunction;
    payload: Partial<FiltersState['commentDays']>;
    isV2?: boolean;
  }) =>
  (
    dispatch: Dispatch,
    getState: () => {
      filters: FiltersState;
      searchForm: SearchFormState;
    }
  ) => {
    const { filters, searchForm } = getState();
    const checkedGeneralFilters = new Set(filters.checkedFilters.general || []);
    const excludedGeneralFilters = new Set(filters.excludedFilters.general || []);

    if (!checkedGeneralFilters.has('commentDays') && !excludedGeneralFilters.has('commentDays')) {
      checkedGeneralFilters.add('commentDays');
    }

    const checkedFilters = {
      ...filters.checkedFilters,
      general: checkedGeneralFilters,
    };
    const excludedFilters = {
      ...filters.excludedFilters,
      general: excludedGeneralFilters,
    };
    const mltrDays = { ...filters.mltrDays, ...payload };
    const commentDays = { ...filters.commentDays, ...payload };

    dispatch(setCommentDaysAction({ checkedFilters, excludedFilters, commentDays }));

    if (!isV2) {
      navigate({
        query: {
          ...location.query,
          f: convertToUrl({
            valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
            checkedFilters,
            excludedFilters,
            isAndBetween,
            viewsTransformer: filters.viewsTransformer,
            extViewed: filters.extViewed,
            smartFolderId: get(searchForm.activeSmartFolder, 'id'),
            mltrDays,
            commentDays,
          }),
        },
      });
    } else {
      dispatch(getRealtimeCount());
    }
  };

export const getMarketInsights = createAsyncThunk(
  `${SLICE_NAME}/getMarketInsights`,
  async (
    {
      location,
      payload: { folderId, ...rest },
    }: {
      location: AH$RouterLocation;
      payload: { folderId?: string };
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { filters } = getState() as { filters: FiltersState };
    const { checkedFilters, excludedFilters, viewsTransformer, extViewed, mltrDays, commentDays } =
      convertFromUrl(valIdsForUrlFacetIds as Array<[string, string]>, location);
    const convertedF = convertToUrl({
      valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
      checkedFilters,
      excludedFilters,
      isAndBetween,
      extViewed: extViewed || filters.extViewed,
      viewsTransformer: viewsTransformer || filters.viewsTransformer,
      mltrDays: mltrDays || filters.mltrDays,
      commentDays: commentDays || filters.commentDays,
      smartFolderId: folderId,
    });
    const params = stringify({
      ...rest,
      convertedF,
    });

    if (!folderId) {
      dispatch(setSmartFolder(null));
    }
    try {
      const {
        payload: { insights, folder_id, folder_name },
      } = await marketInsightsGet(params, folderId ? parseInt(folderId, 10) : undefined);

      if (folder_id && folder_name) {
        dispatch(setSmartFolder({ id: folder_id, name: folder_name }));
      }

      return mapValues(insights, capitalizeNames);
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const resetProfilesCount = createAsyncThunk(
  `${SLICE_NAME}/resetProfilesCount`,
  (_, { dispatch }) => {
    dispatch(setProfilesCount({ count: null, id: null }));
    dispatch(getRealtimeCountLoaded(null));
  }
);

const filtersSlice = createSlice({
  name: SLICE_NAME,
  initialState: filtersInitialState,
  reducers: {
    fillFilters: (
      state,
      action: PayloadAction<{
        checkedFilters?: FiltersState['checkedFilters'];
        commentDays?: FiltersState['commentDays'];
        excludedFilters?: FiltersState['excludedFilters'];
        extViewed?: FiltersState['extViewed'];
        mltrDays?: FiltersState['mltrDays'];
        viewsTransformer?: FiltersState['viewsTransformer'];
      }>
    ) => {
      const {
        checkedFilters = {},
        excludedFilters = {},
        viewsTransformer = state.viewsTransformer,
        extViewed = state.extViewed,
        mltrDays = state.mltrDays,
        commentDays = state.commentDays,
      } = action.payload;

      state.checkedFilters = checkedFilters;
      state.excludedFilters = excludedFilters;
      state.viewsTransformer = viewsTransformer;
      state.extViewed = extViewed;
      state.mltrDays = mltrDays;
      state.commentDays = commentDays;
      state.openedFacets = new Set<string>([
        ...Array.from<string>(state.openedFacets),
        ...Object.keys(excludeBlankCompanySizeFilters(checkedFilters)),
      ]);
      state.showOlyChecked = calculateOnlyChecked(state, true);
    },
    openFacet: (state, action: PayloadAction<string>) => {
      const openedFacets = new Set([...state.openedFacets, action.payload]);

      state.openedFacets = openedFacets;
    },
    clearFilters: () => filtersInitialState,
    resetFilters: (state, action: PayloadAction<{ isSmartFolders?: boolean } | undefined>) => {
      const shownFilter = { general: new Set(['shown']) };

      return {
        ...filtersInitialState,
        marketInsights: state.marketInsights,
        ...(action.payload?.isSmartFolders
          ? {
              viewsTransformer: { ...filtersInitialState.viewsTransformer, by: 'folder' },
              checkedFilters: { ...filtersInitialState.checkedFilters, ...shownFilter },
              excludedFilters: shownFilter,
            }
          : undefined),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFacets, (state, action) => {
        state.values = action.payload || {};
        state.loadingFacets = new Set();
        state.erroredFacets = new Set();
      })
      .addCase(getFacetsLoading, (state, action) => {
        const loadingFacets = new Set(state.loadingFacets);
        const erroredFacets = new Set(state.erroredFacets);

        action.payload.forEach((facetId) => {
          loadingFacets.add(facetId);
          erroredFacets.delete(facetId);
        });

        state.loadingFacets = loadingFacets;
        state.erroredFacets = erroredFacets;
      })
      .addCase(getFacetsLoaded, (state, action) => {
        const prevLoadingFacets = Array.from(state.loadingFacets);
        const prevErroredFacets = Array.from(state.erroredFacets);
        const loadingFacets = difference(prevLoadingFacets, keys(action.payload.values));
        const erroredFacets = difference(prevErroredFacets, keys(action.payload.values));

        state.values = saveFiltersValues(state.values, action.payload.values);
        state.loadingFacets = new Set(loadingFacets);
        state.erroredFacets = new Set(erroredFacets);
        state.gdpr = action.payload.gdpr;
      })
      .addCase(getFacetsErrored, (state, action) => {
        const loadingFacets = new Set(state.loadingFacets);
        const erroredFacets = new Set(state.erroredFacets);

        action.payload.forEach((facetId) => {
          loadingFacets.delete(facetId);
          erroredFacets.add(facetId);
        });

        state.loadingFacets = loadingFacets;
        state.erroredFacets = erroredFacets;
      })
      .addCase(trySetShowOnlyChecked, (state, action) => {
        state.showOlyChecked = calculateOnlyChecked(state, action.payload);
      })
      .addCase(setFilters, (state, action) => {
        const { checkedFilters, excludedFilters } = action.payload;

        state.checkedFilters = checkedFilters;
        state.excludedFilters = excludedFilters;
      })
      .addCase(updateOpenedFacets.fulfilled, (state, action) => {
        state.openedFacets = action.payload;
      })
      .addCase(setViewsTransformerAction, (state, action) => {
        state.checkedFilters = action.payload.checkedFilters;
        state.excludedFilters = action.payload.excludedFilters;
        state.viewsTransformer = action.payload.viewsTransformer;
      })
      .addCase(setExtViewedTransformerAction, (state, action) => {
        state.checkedFilters = action.payload.checkedFilters;
        state.excludedFilters = action.payload.excludedFilters;
        state.extViewed = action.payload.extViewed;
      })
      .addCase(setMLTRDaysAction, (state, action) => {
        state.checkedFilters = action.payload.checkedFilters;
        state.excludedFilters = action.payload.excludedFilters;
        state.mltrDays = action.payload.mltrDays;
      })
      .addCase(setCommentDaysAction, (state, action) => {
        state.checkedFilters = action.payload.checkedFilters;
        state.excludedFilters = action.payload.excludedFilters;
        state.commentDays = action.payload.commentDays;
      })
      .addCase(getMarketInsights.pending, (state) => {
        state.marketInsights = {
          ...state.marketInsights,
          status: FETCHING_STATUSES.LOADING,
        };
      })
      .addCase(getMarketInsights.fulfilled, (state, action) => {
        state.marketInsights = {
          data: action.payload,
          status: FETCHING_STATUSES.SUCCESS,
        };
      })
      .addCase(getMarketInsights.rejected, (state) => {
        state.marketInsights = {
          ...state.marketInsights,
          status: FETCHING_STATUSES.ERROR,
        };
      })
      .addCase(getRealtimeCount.pending, (state) => {
        state.realtimeCount = { ...state.realtimeCount, status: FETCHING_STATUSES.LOADING };
      })
      .addCase(getRealtimeCountLoaded, (state, action) => {
        state.realtimeCount = { data: action.payload, status: FETCHING_STATUSES.SUCCESS };
      })
      .addCase(getRealtimeCountErrored, (state) => {
        state.realtimeCount = { ...state.realtimeCount, status: FETCHING_STATUSES.ERROR };
      })
      .addCase(setProfilesCount, (state, action) => {
        state.profilesCount = action.payload.count;
        state.internalSearchId = action.payload.id;
      });
  },
});

export const { fillFilters, openFacet, clearFilters, resetFilters } = filtersSlice.actions;
export const filters = filtersSlice.reducer;
