import { useEffect } from 'react';

import { FETCHING_STATUSES } from 'constants/globals';
import { FetchingStatusesActionTypes } from 'slices/fetchingStatuses';
import useDispatch from './useDispatch';
import useSelector from './useSelector';

const useFetchingStatus = (
  type: FetchingStatusesActionTypes,
  defaultStatus: $Values<typeof FETCHING_STATUSES> = FETCHING_STATUSES.SUCCESS
): {
  error: any;
  isLoading: boolean;
  status: $Values<typeof FETCHING_STATUSES>;
} => {
  const dispatch = useDispatch();
  const { status = defaultStatus, error } =
    useSelector(({ fetchingStatuses }) => fetchingStatuses[type]) || {};
  const isLoading = status === FETCHING_STATUSES.LOADING;

  useEffect(
    () => (): void => {
      // Reset state on unmount
      dispatch({
        type,
        payload: defaultStatus,
        error: null,
      });
    },
    []
  );

  return { isLoading, status, error };
};

export default useFetchingStatus;
