export default {
  'systemMessages.messagesDeleted': 'Цепочка писем удалена',
  'systemMessages.sequenceStopped': 'Цепочка писем остановлена',
  'systemMessages.mailingListDeleted': 'Рассылка удалена',
  'systemMessages.mailingListStopped': 'Рассылка остановлена',
  'systemMessages.fileUploadError':
    'Не удалось загрузить файл. Пожалуйста, повторите загрузку позднее.',
  'systemMessages.fileParsingError':
    'Не удалось создать профиль. Пожалуйста, попробуйте загрузить другой файл.',
  'systemMessages.fileUploaded': 'Файл {name} прикреплен к профилю',
  'systemMessages.bannedLinkCreated': 'Ссылка {url} забанена',
  'systemMessages.bannedLinkDeleted': 'Ссылка {url} разбанена',

  'topmenu.search': 'Поиск',
  'topmenu.vacancies': 'Папки',
  'topmenu.credits': 'Кредиты',
  'topmenu.serviceOrders': 'Заказы',
  'topmenu.managers': 'Менеджеры',
  'topmenu.companies': 'Все компании',
  'topmenu.activeUsers': 'Активные пользователи',
  'topmenu.bannedLinks': 'Забаненные ссылки',
  'topmenu.employees': 'Сотрудники',
  'topmenu.token': 'Управление API',
  'topmenu.apps': 'Приложения',
  'topmenu.settings': 'Настройки',
  'topmenu.logout': 'Выйти',
  'topmenu.help': 'Помощь',
  'topmenu.metaQueries': 'Метазапросы',

  'sidemenu.more': 'Ещё',
  'sidemenu.extension': 'Расширение',
  'sidemenu.search': 'Поиск',
  'sidemenu.searchV2': 'Поиск 2.0',
  'sidemenu.vacancies': 'Папки',
  'sidemenu.serviceOrders': 'Заказы',
  'sidemenu.settings': 'Настройки',
  'sidemenu.companies': 'Все компании',
  'sidemenu.activeUsers': 'Активные пользователи',
  'sidemenu.bannedLinks': 'Забаненные ссылки',
  'sidemenu.apiManagement': 'Управление API',
  'sidemenu.apps': 'Интеграции',
  'sidemenu.company': 'Компания',
  'sidemenu.metaqueries': 'Метазапросы',
  'sidemenu.credits': 'Кредиты',
  'sidemenu.help': 'Помощь',
  'sidemenu.logout': 'Выйти',
  'sidemenu.searchForm': 'Поисковая форма',
  'sidemenu.talentPools': 'AI поиск',
  'sidemenu.savedQueries': 'Сохраненные запросы',
  'sidemenu.history': 'История',
  'sidemenu.account': 'Аккаунт',
  'sidemenu.extensionUser': 'Пользователь расширения',
  'sidemenu.statuses': 'Настройка статусов',
  'sidemenu.messaging': 'Сообщения',
  'sidemenu.templates': 'Шаблоны',
  'sidemenu.sentMessages': 'Письма',
  'sidemenu.messagingSettings': 'Настройки',
  'sidemenu.ats': 'ATS',
  'sidemenu.createProfile': 'Создать профиль',
  'sidemenu.extensionNotifications': 'Уведомления в расширении',
  'sidemenu.systemNotifications': 'Уведомления',
  'sidemenu.sequences': 'Цепочки',
  'sideMenu.editedProfiles': 'Отредактированные профили',
  'sideMenu.smartFolders': 'Папки',
  'sideMenu.managers': 'Менеджеры',
  'sideMenu.statistics': 'Статистика',
  'sidemenu.badgeNew': 'НОВЫЙ',
  'sidemenu.badgeOld': 'СТАРЫЙ',
  'sidemenu.badgeOldV2': 'СТАРАЯ',
  'sidemenu.telegramMessenger': 'Телеграм рассыльщик',
  'sidemenu.telegramMessengerMessages': 'Сообщения',
  'sidemenu.telegramMessengerManager': 'Менеджер сим-карт',

  'login.signup': 'Регистрация',
  'login.signup.description': `Пользователя расширения {whiteLabelName, select,
    amazinghiring {AmazingHiring}
    matchy {Мэтчи}
    other {}}`,
  'login.signup.after':
    'На указанную вами почту будет отправлено письмо со ссылкой активации аккаунта.',
  'login.toSignup': 'Зарегистрироваться',
  'login.signUpWithGoogle': 'Зарегистрироваться через Google',
  'login.first_name': 'Имя',
  'login.last_name': 'Фамилия',
  'login.company': 'Компания',
  'login.login': 'Войти',
  'login.continue': 'Продолжить',
  'login.confirm': 'Подтвердить',
  'login.haveAccount': 'У вас уже есть аккаунт?',
  'login.demo': 'Запросить демо',
  'login.extension': 'Установить Chrome-расширение',
  'login.extension.title': 'Chrome-расширение',
  'login.extension.description': 'сокращает трудозатраты на сорсинг',
  'login.extension.features': `
    <ul>
      <li>находит аккаунты одного и того же человека на разных сайтах</li>
      <li>предварительная оценка навыков</li>
      <li>120+ млн профилей</li>
      <li>бесплатно</li>
    </ul>
  `,
  'login.email.description':
    'Для продолжения укажите емейл. Если у вас уже есть аккаунт на нашем сайте, то введите почту, которую вы указывали при регистрации.',
  'login.confirm.description': 'Подтвердите создание нового аккаунта для <b>{email}</b>.',
  'login.confirm.features': `
    <ul>
      <li>бесплатно</li>
      <li>только для работы с <a>Chrome-расширением</a></li>
    </ul>
  `,
  'login.password.description': `Введите пароль от аккаунта в {whiteLabelName, select,
    matchy {Мэтчи}
    amazinghiring {AmazingHiring}
    other {AmazingHiring}}, чтобы продолжить.`,
  'login.createAccount': 'создать аккаунт',
  'login.forgotPassword': 'Забыли пароль?',
  'login.signInWithGoogle': 'Войти через Google',
  'login.email': 'Емейл',
  'login.password': 'Пароль',
  'login.or': 'или',
  'login.gdpr': ' ',
  'login.phone': 'Телефон',
  'login.position': 'Должность',
  'login.job_openings': 'Количество технических вакансий в вашей компании',
  'login.learned_about_ah_from': `Как вы узнали об {whiteLabelName, select,
    amazinghiring {AmazingHiring}
    matchy {Мэтчи}
    other {AmazingHiring}}?`,
  'login.colleagueRecommendation': 'Рекомендация коллеги',
  'login.chatRecommendation': `Рекомендация в групповом чате рекрутеров`,
  'login.communityDeal': `Увидели ваше предложение в сообществе рекрутеров`,
  'login.opinionLeader': 'Рекомендация лидера мнений',
  'login.previousWorkplace': 'Использовали на предыдущем месте работы',
  'login.trialed': 'Был пробный доступ',
  'login.googleSearch': 'Нашли с помощью Google',
  'login.followSocials': 'Подписаны на ваши аккаунты в социальных сетях',
  'login.subscribed': `Подписаны на вашу рассылку`,
  'login.webinar': `На мероприятии или вебинаре`,
  'login.dontRemember': `Не помню`,
  'login.haveQuestions': 'Все еще остались вопросы?',
  'login.helpcenter': `
    Загляните в раздел <a>Помощь</a>
  `,
  'login.contactUs': 'или напишите нам на',
  'login.addToChrome': 'УСТАНОВИТЬ РАСШИРЕНИЕ',
  'login.createAcc': 'СОЗДАТЬ АККАУНТ',
  'login.startSurfing': 'НАЧАТЬ ПОИСК',
  'login.socialNetworks': 'по социальным сетям и профессиональным сообществам',
  'login.preparedWith': 'Cделано с',
  'login.by': `командой {whiteLabelName, select,
    amazinghiring {AmazingHiring}
    matchy {Мэтчи}
    other {AmazingHiring}}`,
  'login.feedback': 'Свяжитесь с нами:',
  'login.redirect': 'AmazingHiring меняет название на Мэтчи!',

  'sso.back': 'Назад',
  'sso.signIn': 'Войти по SSO',
  'sso.continue': 'Продолжить подключение по SSO',
  'sso.sso_settings': 'Настройки SSO',
  'sso.idp_url': 'IdP URL',
  'sso.provider': 'Провайдер',
  'sso.idp_url_example': 'https://sso.amazinghiring.com',
  'sso.client_id': 'Идентификатор клиента',
  'sso.client_secret': 'Секретный ключ',
  'sso.audience': 'Аудитория',
  'sso.trust_id': 'Доверительный идентификатор',
  'sso.domains': 'Домены корпоративной почты',
  'sso.domains_example': 'Enter the domains separated by commas',
  'sso.claim_mapping': 'Сопоставления',
  'sso.provider_config_updating_interval': 'Интервал обновления конфигурации сервера',
  'sso.provider_config_updating_interval_example': 'Введите значение в секундах',
  'sso.is_active': 'Настройки SSO',
  'sso.updateSettings': 'Редактировать настройки',
  'sso.is_active_on': 'Вкл.',
  'sso.is_active_off': 'Выкл.',
  'sso.save': 'Сохранить',
  'sso.cancel': 'Отмена',
  'sso.add': 'Создать',
  'sso.createSettings': 'Вы еще не создали настройки для SSO',
  'sso.email': 'E-mail',
  'sso.first_name': 'Имя',
  'sso.last_name': 'Фамилия',

  'form.or': 'или',
  'form.synonyms': 'Синонимы',
  'form.addCondition': 'Добавить условие',
  'form.excludeCondition': 'Исключить условие',
  'form.newCondition': 'Новое условие',
  'form.selectCondition': 'Выбрать условие',
  'form.cancelCondition': 'Отменить условие',
  'form.cancel': 'Отмена',
  'form.find': 'Найти',
  'form.newSearch': 'Новый поиск',
  'form.history': 'История',
  'form.back': 'Назад',
  'form.notice.features': 'Удобное редактирование.<br/>Полная поддержка булевских запросов.',
  'form.form.pro': 'PRO',
  'form.search': 'Искать',
  'form.fieldTips.metaqueries': `
    Выберите специализацию или стек -
    <br></br>система проанализирует и покажет кандидатов,
    <br></br>исходя из их текущей должности и основного навыка
   `,
  'form.fieldTips.companySearch': `
    Укажите компанию или исключите ее из поиска.
    <br></br>В зависимости от положения слайдера можно выбрать
    <br></br>текущее место работы или любую из компаний в опыте
   `,
  'form.fieldTips.position': `
    Укажите конкретную должность.
    <br></br>В зависимости от положения слайдера можно выбрать
    <br></br>как текущую должность, так и любую должность в опыте
   `,
  'form.fieldTips.skill': `
    Укажите конкретный стек.
    <br></br>Измените положение слайдера, чтобы
    <br></br>выбрать только тех кандидатов,
    <br></br>кто применяет данный навык в настоящее время
   `,
  'form.fieldTips.location': `
    Укажите локацию или исключите ее из поиска.
    <br></br>Измените положение слайдера, чтобы добавить поиск
    <br></br>в радиусе до 160 км / 100 миль от города
   `,
  'form.fieldTips.educationSearch': `
    Укажите наименование
    <br></br>учебного заведения
   `,
  'form.fieldTips.name': `
    Укажите имя или ник
    <br></br>кандидата из соцсети
   `,
  'form.fieldTips.comment': `
    Найдите кандидата по комментарию,
    <br></br>который вы и ваша команда
    <br></br>добавили к его профилю
   `,
  'form.fieldTips.folder': `
    Укажите конкретную папку для поиска
    <br></br>или исключите, чтобы добавленные в нее профили
    <br></br>не отображались в выдаче
   `,
  'form.fieldTips.text': `
    Укажите любую дополнительную информацию, например,
    <br></br>ПО, сертификаты, и т.п. или запустите поиск по тегам,
    <br></br>добавленным вами или вашей командой
    <br></br>к профилю кандидата
   `,

  'query.text': 'Дополнительно',
  'query.metaqueries': 'Специализация',
  'query.ai': ' AI поиск',
  'query.companySearch': 'Компания',
  'query.lastCompanySearch': 'Текущая компания',
  'query.position': 'Должность',
  'query.lastPosition': 'Текущая должность',
  'query.skill': 'Навыки',
  'query.mainSkillHigh': 'Основные навыки',
  'query.location': 'Город или страна',
  'query.educationSearch': 'Учебное заведение',
  'query.name': 'Имя или ник',
  'query.comment': 'Комментарий',
  'query.folder': 'Папка',
  'query.any': 'любая',
  'query.all': 'любые',
  'query.current': 'текущая',
  'query.main': 'основные',
  'query.exclude': 'Исключить из поиска',
  'query.switchToAdvanced': 'Тип формы запроса будет изменён на «PRO»',
  'query.formTypeChanged': 'Тип формы запроса изменён на «PRO»',
  'query.km': 'км',
  'query.mi': 'миль',
  'query.notSelected': 'не выбрано',
  'query.radius': 'Настроить радиус поиска',
  'query.range': 'радиус',
  'query.onlyCities': 'Доступно только для населенных пунктов',

  'queryEditor.exactSuggestion': 'по точному совпадению',
  'queryEditor.locationRangeHelp': `
    Хотите искать в нескольких локациях? <btn>Воспользуйтесь поиском без радиуса.</btn>
  `,

  'term.synonyms': 'Синонимы',
  'term.variants': 'Варианты',

  'appliedTerm.exclude': 'Исключить',
  'appliedTerm.include': 'Включить',
  'appliedTerm.remove': 'Удалить',
  'appliedTerm.reset': 'Сбросить',
  'appliedTerm.years': 'от {skillRange} лет',
  'appliedTerm.yearsHint': 'Настроить количество лет опыта',

  'history.showFull': 'Вся история запросов',
  'history.fullHistory': 'Полная история',
  'history.empty': 'История запросов пуста. Поищите кого-нибудь, чтобы ее заполнить.',
  'history.filters': 'Фильтры',
  'history.privateFolder': 'Поиск по приватной папке',

  'resultsSort.label': 'Сортировать',
  'resultsSort.default': 'по общему рейтингу',
  'resultsSort.githubCommit': 'по коммитам в Github',
  'resultsSort.stackoverflowTags': 'по ответам на Stackoverflow',
  'resultsSort.kaggle': 'по рейтингу на Kaggle',
  'resultsSort.googlePlay': 'по рейтингу на Google Play',
  'resultsSort.diversityUncertainty': 'по неуверенности в многообразии',

  'selectCandidate.selectCandidate': 'Добавить в папку',
  'selectCandidate.myVacancies': 'Мои папки',
  'selectCandidate.notSelected': 'Этот кандидат ещё не отобран',
  'selectCandidate.createVacancy': 'Создать новую папку',
  'selectCandidate.cancel': 'Отмена',
  'selectCandidate.delete': 'Удалить',
  'selectCandidate.folders': 'Папки',
  'selectCandidate.allFolders': 'Все папки',
  'selectCandidate.deleteConfirm': 'Удалить кандидата из папки {name}?',
  'selectCandidate.moreFolders': `и ещё {value, plural,
    one {1 папка}
    few {# папки}
    many {# папок}
    other {# папки}
  }`,

  'search.downloadProfiles': 'Скачать',
  'search.shortDescription': `{candidatesFormatted} {candidates, plural,
        one {профиль}
        few {профиля}
        many {профилей}
        other {профилей}
      }`,
  'search.expandedDescription': `{startCount} — {endCount} профилей из {candidatesFormatted}`,
  'search.smartFoldersProfilesCountDescription': `50 профилей из {candidatesFormatted}`,
  'search.hideViewedProfiles': 'Скрывать профили, просмотренные {daysAgo}',
  'search.total': 'всего<br></br>профилей',
  'search.withContacts': 'с указанием<br></br>контактов',
  'search.withoutContacts': 'без указания<br></br>контактов',
  'search.souringOnDemand.modalText': `Доверьтесь нашей профессиональной команде - мы отберем самых подходящих кандидатов и проактивно свяжемся с ними. В вашей папке внутри {whiteLabelName, select,
      matchy {Мэтчи}
      jointalent {Join Talent}
      amazinghiring {AmazingHiring}
      other {}} будут только “теплые” кандидаты.`,
  'search.sourcingOnDemand.learnMore': 'Узнать больше',
  'search.sourcingOnDemand.mainTitle': 'Нет времени на сорсинг?',
  'search.gmate.learnMore': 'Разместить вакансию',
  'search.gmate.text': `<strong>20,000+ инженеров </strong> уровня <strong> middle+ </strong> от наших партнёров GMS. {br}`,
  'search.aiAIHeader.title': 'Умные папки',

  'filters.showAllFilters': 'показать все',
  'filters.daysAgo': `{days, plural,
      one {день}
      few {дня}
      many {дней}
      other {дня}
    }`,
  'filters.title': 'Фильтры',
  'filters.reset': 'Очистить',
  'filters.instantSearch': 'Моментальный поиск',
  'filters.age': 'Возраст',
  'filters.lastCompany': 'Место работы',
  'filters.contacts': 'Контакты',
  'filters.language': 'Язык',
  'filters.education': 'Образование',
  'filters.educationLevel': 'Уровень образования',
  'filters.experience': 'Опыт работы',
  'filters.mainSkillHigh': 'Основные навыки',
  'filters.industry': 'Индустрия',
  'filters.gender': 'Пол',
  'filters.diversity': 'Многообразие',
  'filters.location': 'Город или страна',
  'filters.onLastPosition': 'Опыт работы на последнем месте',
  'filters.graduation': 'Года выпуска',
  'filters.seniorityLevel': 'Уровень позиции',
  'filters.site': 'Социальные профили',
  'filters.specialization': 'Текущая позиция',
  'filters.male': 'Мужчина',
  'filters.female': 'Женщина',
  'filters.asian': 'Азиат',
  'filters.africanAmerican': 'Чернокожий',
  'filters.white': 'Белокожий',
  'filters.middle-east': 'Западноазиат',
  'filters.hispanic': 'Латиноамериканец',
  'filters.indian': 'Индиец',
  'filters.email': 'Емейл',
  'filters.personalEmail': 'Личный',
  'filters.corporateEmail': 'Корпоративный',
  'filters.skype': 'Skype',
  'filters.link': 'Ссылка',
  'filters.phone': 'Телефон',
  'filters.icq': 'ICQ',
  'filters.messengers': 'Мессенджеры',
  'filters.monthsShort': 'м',
  'filters.notFound': 'Не найдено',
  'filters.notSpecified.default': 'Без указания',
  'filters.switchTooltip': 'Выберите значение в слайдере',
  'filters.switchInputsTooltip': 'Измените значения в полях',
  'filters.inputsFrom': 'От',
  'filters.inputsTo': 'До',
  'filters.any': 'С указанием любого',
  'filters.any.contacts': 'С указанием контактов',
  'filters.any.company': 'С указанием места работы',
  'filters.any.education': 'С указанием образования',
  'filters.any.seniorityLevel': 'С указанием уровня позиции',
  'filters.no': 'Без указания',
  'filters.no.contacts': 'Контакты не указаны',
  'filters.no.company': 'Место работы не указано',
  'filters.no.education': 'Образование не указано',
  'filters.no.seniorityLevel': 'Уровень позиции не указан',
  'filters.no.age': 'Возраст не указан',
  'filters.no.years': 'Опыт не указан',
  'filters.general': 'Общие',
  'filters.excludeMultipleLocations': 'Профили без спорных локаций',
  'filters.hiddenStars': 'Скрытые звезды',
  'filters.availableForHiring': 'Открыты для предложений',
  'filters.hasComment': 'Профили без комментариев',
  'filters.moreLikelyToRespond': 'Активные кандидаты 🔥',
  'filters.smartRecruitersImport': 'Профили SmartRecruiters',
  'filters.remoteFilter': 'Готов к удаленной работе',
  'filters.greenhouse': 'Профили Greenhouse',
  'filters.greenhouseInWork': 'Профили Greenhouse в работе',
  'filters.workable': 'Профили Workable',
  'filters.workableInWork': 'Профили Workable в работе',
  'workableInWork.description': 'Показывает или скрывает кандидатов на всех активных этапах найма',
  'greenhouseInWork.description':
    'Показывает или скрывает кандидатов на всех активных этапах найма',
  'filters.huntflow': 'Профили Huntflow',
  'filters.huntflowInWork': 'Профили Huntflow в работе',
  'huntflowInWork.description': 'Показывает или скрывает кандидатов на всех активных этапах найма',
  'filters.freelancers': 'Фрилансеры',
  'filters.isFrequentJobChanger': 'Профили с "рваным" опытом',
  'filters.company': 'Место работы',
  'filters.skill': 'Навыки',
  'filters.exclusions': 'Исключения',
  'filters.exclusions.hasComment': 'Профили с комментариями',
  'filters.companySize': 'Размер компании',
  'filters.companySize.any': 'Компания',
  'filters.companySize.curr': 'Текущая',
  'filters.companySize.prev': 'Предыдущая',
  'filters.companySize.size': 'Размер',
  'filters.sizeTooltip': 'На основе количества профилей в AmazingHiring',
  'filters.profiles': 'Профили',
  'filters.candidates': 'Кандидаты',
  'filters.hints.seniorityLevel':
    'Опыт работы:<br></br> Junior - до 1,5 лет,<br></br> Middle - 1,5 - 5 лет,<br></br> Senior - более 5 лет<br></br>',
  'filters.hints.graduation':
    'Мы можем искать кандидатов, которые закончат учиться в ближайшие семь лет',

  'searchFormV2.search': 'Найти',
  'searchFormV2.createFolder': 'Создать папку из выдачи',
  'searchFormV2.companySearchAdd': 'Добавить предыдущую компанию',
  'searchFormV2.positionAdd': 'Добавить предыдущую должность',
  'searchFormV2.mainSkillHighAdd': 'Добавить основной навык',
  'searchFormV2.toggleCurrent': 'Текущая',
  'searchFormV2.toggleAny': 'За всё время',
  'searchFormV2.toggleCompanyTitle': 'Компания',
  'searchFormV2.togglePositionTitle': 'Должность',
  'searchFormV2.toggleSkillAll': 'Все',
  'searchFormV2.toggleSkillAny': 'Один из',
  'searchFormV2.mainSkillHigh': 'Основной навык',
  'searchFormV2.skill': 'Обязательные навыки',
  'searchFormV2.preferableSkill': 'Дополнительные навыки',
  'searchFormV2.advanced': 'Дополнительно',
  'searchFormV2.searchInRadius': 'Искать в радиусе',
  'searchFormV2.general': 'Общие',
  'searchFormV2.experience': 'Опыт работы',
  'searchFormV2.contacts': 'Доступные контакты',
  'searchFormV2.education': 'Образование и языки',
  'searchFormV2.folders': 'Папки и совместная работа',
  'searchFormV2.diversity': 'Многообразие',
  'searchFormV2.saveQuery': 'Сохранить запрос',
  'searchFormV2.queryName': 'Название',
  'searchFormV2.queryDescription': 'Описание',
  'searchFormV2.save': 'Сохранить',
  'searchFormV2.cancel': 'Отменить',
  'searchFormV2.show': 'Показать',
  'searchFormV2.reset': 'Сбросить',
  'searchFormV2.searchBySpecialization': 'Быстрый выбор специализаций',
  'searchFormV2.fill': 'Заполните поисковую форму',
  'searchFormV2.searchBy': 'Добавить',
  'searchFormV2.name': 'Поиск по email, telegram, ссылке, имени, нику',
  'searchFormV2.savedQueries': 'Сохраненные',
  'searchFormV2.history': 'История',
  'searchFormV2.addCondition': '+ Добавить условие',
  'searchFormV2.hintAddSkillsCondition': 'Вы можете добавить только три дополнительных условия',
  'searchFormV2.hintAddTitleCondition': 'Вы можете добавить только одно дополнительное условие',
  'searchFormV2.showMoreSpecialization': `{show, select,
    true {Скрыть}
    other {Показать ещё}} {count} {count, plural,
    one {специализацию}
    few {специализации}
    other {специализаций}}`,
  'searchFormV2.fieldTips.metaqueries': `
    Выберите специализацию или стек -
    система проанализирует и покажет кандидатов,
    исходя из их текущей должности и основного навыка
   `,
  'searchFormV2.fieldTips.companySearch': `
    Укажите предыдущее место работы или исключите его из поиска
   `,
  'searchFormV2.fieldTips.lastCompanySearch': `
    Укажите текущее место работы или исключите его из поиска
   `,
  'searchFormV2.fieldTips.position': `
    Укажите конкретную предыдущую должность
   `,
  'searchFormV2.fieldTips.lastPosition': `
    Укажите должность, мы уже учли все синонимы
   `,
  'searchFormV2.fieldTips.skill': `
    Укажите конкретный стек
   `,
  'searchFormV2.fieldTips.mainSkillHigh': `
    Укажите конкретный стек, чтобы выбрать только тех кандидатов,
    кто применяет данный навык в настоящее время
   `,
  'searchFormV2.fieldTips.preferableSkill': `
    Укажите навыки, которые не обязательны, но предпочтительны в кандидате`,
  'searchFormV2.fieldTips.location': `
    Укажите локацию или исключите ее из поиска.
   `,
  'searchFormV2.fieldTips.educationSearch': `
    Укажите наименование учебного заведения
   `,
  'searchFormV2.fieldTips.name': `
    Укажите имя, ник или контакт кандидата
   `,
  'searchFormV2.fieldTips.comment': `
    Найдите кандидата по комментарию,
    который вы и ваша команда
    добавили к его профилю
   `,
  'searchFormV2.fieldTips.folder': `
    Укажите конкретную папку для поиска
    или исключите, чтобы добавленные в нее профили
    не отображались в выдаче
   `,
  'searchFormV2.fieldTips.text': `
    Укажите любую дополнительную информацию, например,
    ПО, сертификаты, и т.п. или запустите поиск по тегам,
    добавленным вами или вашей командой
    к профилю кандидата
   `,
  'searchFormV2.inputError': 'Нельзя использовать операторы AND/OR',

  'filters.viewsTransformer.profiles': 'Профили,',
  'filters.viewsTransformer.action':
    '{value, select, views {просмотренные} shown {показанные} folders {отобранные} contacted {прописанные} other {}}',
  'filters.viewsTransformer.viewsBy': '{value, select, my {мной} team {командой} other {}}',
  'filters.viewsTransformer.shownBy':
    '{value, select, my {мне} team {команде} folder {в папке} other {}}',
  'filters.viewsTransformer.foldersBy': '{value, select, my {мной} team {командой} other {}}',
  'filters.viewsTransformer.within': 'за',

  'filters.extViewed.profile': 'Профили, просмотренные',
  'filters.extViewed.shownBy': '{value, select, my {мной} team {командой} other {}}',
  'filters.extViewed.within': 'на LinkedIn за',

  'filters.mltrDaysProfiles.profile': `за`,
  'filters.mltrDaysProfiles.type':
    '{value, select, all {Могут быть заинтересованы} exact {Ищут работу} other {}}',
  'filters.commentDaysProfiles.profile': `Содержит комментарии за`,
  'filters.commentDays': `Содержит комментарии за`,

  'filterExpand.more': 'больше',
  'filterExpand.less': 'меньше',

  'survey.title': 'Уточнение критериев поиска',
  'survey.description':
    'AmazingHiring обучается и корректирует стратегию поиска после каждой итерации кандидатов.<br></br>Вот некоторые выводы, сделанные на основе того, как вы выбирали кандидатов.<br></br>Вы можете вручную изменить важность представленных факторов, это будет учтено в следующих итерациях.',
  'survey.notChecked': 'этот фактор не имеет значения',
  'survey.checked': 'показывать таких кандидатов чаще',
  'survey.excluded': 'показывать таких кандидатов реже',
  'survey.send': 'Обучить',
  'survey.skip': 'Пропустить',

  'achievement.github_repo': `
    Входит в {percent} лучших {skill}-разработчиков на GitHub,
    автор {skill}-репозитория, активно используемого другими разработчиками.
  `,
  'achievement.github_commit': `
    Входит в {percent} лучших {skill}-разработчиков на GitHub,
    участник популярных {skill}-проектов с открытым исходным кодом.
  `,
  'achievement.stackoverflow_tags': `
    Входит в {percent} лучших {skill}-разработчиков на Stack Overflow,
    автор многих ответов на вопросы, связанные с {skill}, и высоко оцененных другими разработчиками.
  `,
  'achievement.ru.stackoverflow_tags': `
    Входит в {percent} лучших {skill}-разработчиков на русском Stack Overflow,
    автор многих ответов на вопросы, связанные с {skill}, и высоко оцененных другими разработчиками.
  `,
  'achievement.kaggle': `
    Входит в {percent} лучших специалистов по {skill} на Kaggle.
  `,
  'achievement.google_play': `
    Приложения кандидата входят в {percent} самых популярных на Google Play.
  `,
  'achievement.itunes': `
    Приложения кандидата входят в {percent} самых популярных на iTunes.
  `,

  'skillSource.source': 'Источник',
  'skillSource.githubCommits': 'Github',
  'skillSource.githubRepo': 'Github',
  'skillSource.stackoverflowTags': 'Stack Overflow',
  'skillSource.stackoverflowTagsRu': 'Russian Stack Overflow',
  'skillSource.googlePlay': 'Google Play',
  'skillSource.itunes': 'iTunes',
  'skillSource.kaggle': 'Kaggle',
  'skillSource.hh': 'HH.ru',
  'skillSource.hhResumeJson': 'HH.ru',
  'skillSource.ats': 'добавлено вручную',
  'skillSource.linkedin': 'LinkedIn',
  'skillSource.facebook': 'Facebook',
  'skillSource.resume-file': 'Файл-резюме',
  'skillSource.e-staff': 'E-Staff',
  'skillSource.gms': 'getmatch',
  'skillSource.huntflow': 'Huntflow',
  'skillSource.smartRecruiters': 'SmartRecruiters',
  'skillSource.workable': 'Workable',
  'skillSource.greenhouse': 'Greenhouse',

  'paginator.next': '→',
  'paginator.prev': '←',

  'hiddenStars.description': `
    Опция скрывает кандидатов с&nbsp;хорошо заполненными профилями LinkedIn, оставляя только тех,
    у&nbsp;кого либо нет профиля на LinkedIn, либо он не&nbsp;содержит слов из&nbsp;поискового
    запроса.
  `,
  'withContacts.title': 'Только профили с контактами',
  'excludeMultipleLocations.description': `
    Кандидаты, в социальных профилях которых указаны разные локации.
  `,
  'availableForHiring.title': 'Открытые для предложений',
  'hasComment.description': `
    Опция скрывает кандидатов, профили которых прокомментировали вы или ваша команда.
  `,

  'profiles.notFoundTitle': 'Ничего не найдено',
  'profiles.notFoundDesc': 'Попробуйте поменять запрос или порядок сортировки',
  'profiles.errorTitle': 'Извините! Произошла ошибка',
  'profiles.errorDesc': 'Повторите запрос позже.',
  'profiles.prev': 'назад',
  'profiles.next': 'дальше',
  'profiles.withoutContacts': `Показать <b>{count, plural,
    one {# профиль}
    few {# профиля}
    many {# профилей}
    other {# профиля}
  }</b> без контактов`,
  'profiles.emptyForm': 'Заполните поисковую форму, чтобы найти профили',

  'profile.open': 'Открыть',
  'profile.selectCandidate': 'Добавить в папку',
  'profile.edit': 'Изменить',
  'profile.matchingHistory': 'История склейки',
  'profile.insert': 'Вклеить в таблицу',
  'profile.age': `{age, plural,
    one {# год}
    few {# года}
    many {# лет}
    other {# года}
  }`,
  'profile.ageDesc': 'Возраст вычислен на основании года поступления в ВУЗ',
  'profile.earlier': 'Ранее',
  'profile.otherLocations': `и ещё {value, plural,
    one {1 возможная локация}
    few {# возможных локации}
    many {# возможных локаций}
    other {# возможных локаций}
  }`,
  'profile.lessLocations': 'меньше',
  'profile.otherCurrentPositions': `и ещё {value, plural,
    one {1 текущее место работы}
    few {# текущих места работы}
    many {# текущих мест работы}
    other {# текущих места работы}
  }`,
  'profile.otherPositions': `Ещё {value, plural,
    one {# место}
    few {# места}
    other {# мест}} работы`,
  'profile.lessPositions': 'меньше',
  'profile.otherSkills': `+{value}`,
  'profile.lessSkills': 'меньше',
  'profile.phone': 'Телефон',
  'profile.email': 'Емейл',
  'profile.skype': 'Скайп',
  'profile.loading': 'Загружается...',
  'profile.moreComments': `+ {comments, plural,
    one {1 комментарий}
    few {# комментария}
    many {# комментариев}
    other {# комментария}
  }`,
  'profile.currentPosition': 'Текущая должность',
  'profile.earlierPosition': 'Предыдущие места работы',
  'profile.less': 'меньше',
  'profile.achievements': 'Достижения',
  'profile.skills': 'Навыки',
  'profile.principalSkills': 'Навыки',
  'profile.experience': 'Опыт работы',
  'profile.education': 'Образование',
  'profile.schools': 'Образование',
  'profile.activity': 'Активность',
  'profile.notIt': 'Не IT опыт',
  'profile.positionSearchHint': '{name} нашли на {link}',
  'profile.displayLevel': `Почему кандидат <b>{displayLevel}</b>:`,
  'profile.experienceSearchHint': `{years, plural,
    =0 {}
    one {# г}
    few {# г}
    other {# л}
  } {months, plural,
    =0 {}
    one {# мес}
    few {# мес}
    other {# мес}
  }`,
  'profile.experienceSearchHintCurrentPosition': `Указал <b>{position}</b> на текущем месте работы`,
  'profile.experienceSearchHintPreviousPosition': `Указал <b>{position}</b> на прошлом месте работы`,
  'profile.experienceSearchHintRatingOnResources': `<b>{position}</b> на основании рейтинга на ресурсах`,
  'profile.experienceSearchHintExperience': `{years, plural,
    =0 {}
    one {<b># год</b>}
    few {<b># года</b>}
    other {<b># лет</b>}
  } {months, plural,
    =0 {}
    one {<b># месяц</b>}
    few {<b># месяца</b>}
    other {<b># месяцев</b>}
  } опыта в IT`,
  'profile.certificates': 'Курсы и сертификаты',
  'profile.editProfile': 'Изменить профиль',
  'profile.unitedProfile': 'Объединенный профиль',
  'profile.profileCopied': 'Анкета скопирована',
  'profileClipboard.linkToProfile': 'Ссылка на этого кандидата:',

  'profile.event.open': 'Открыт профиль',
  'profile.event.get_contacts': 'Открыты контакты',
  'profile.event.add_comment': 'Добавлен комментарий',
  'profile.event.update_comment': 'Обновлен комментарий',
  'profile.event.delete_comment': 'Удален комментарий',
  'profile.event.added_to_folder': 'Добавлен в папку',
  'profile.event.mail_sent': 'Отправлено письмо',
  'profile.event.deletedUser': 'Пользователь',

  'profile.integrations.title': 'Интеграции',
  'profile.integartions.show': 'Подключить...',
  'profile.integartions.integrations': 'Интеграции',
  'profile.integartions.reconnect': 'Переподключить',

  'profile.skill.showAll': 'Показать все',
  'profile.skill.showLess': 'Показать меньше',

  'profile.mailingList.heading': 'Рассылки',
  'profile.mailingList.eventUnopened': 'Рассылка отправлена {date}',
  'profile.mailingList.eventOpened': 'Открыли',
  'profile.mailingList.eventReplied': 'Ответили',
  'profile.mailingList.times': `{events, plural,
    one {# раз}
    few {# раза}
    many {# раз}
    other {# раза}
  }`,
  'profile.showDetails': 'подробнее',
  'profile.hideDetails': 'скрыть',
  'profile.defaultLessMessage': 'меньше',
  'profile.defaultMoreMessage': 'ещё',
  'profile.mailingList.lastEvent': 'Последний раз: {date}',
  'profile.comments': 'Комментарии',
  'profile.contacts': 'Контакты',
  'profile.resumes': 'Вложения',
  'profile.folders': 'Папки',
  'profile.messages': 'Письма',
  'profile.hints': 'Подсказки по поиску',
  'profile.contactsPrivate': 'Приватные контакты',
  'profile.yearsExperience': 'Опыт',
  'profile.generalInfo': 'Общая информация',
  'profile.languages': 'Языки',
  'profile.socialAccountsUpdates': 'Обновление социальных профилей',
  'profile.daysAgo': `Обновлен {days} {days, plural,
    one {день}
    few {дня}
    many {дней}
    other {дня}
  } назад`,
  'profile.moreLikelyToRespond': 'Активный кандидат',
  'profile.recentlyUpdated': 'Недавно обновлен',
  'profile.popularityPenalty': 'Понижен в выдаче',
  'profile.remote': 'Готов к удаленной работе',
  'profile.huntflow': 'Huntflow',
  'profile.huntflowDescription': 'Профиль уже есть в Huntflow',
  'profile.greenhouse': 'Greenhouse',
  'profile.greenhouseDescription': 'Профиль уже есть в Greenhouse',
  'profile.workable': 'Workable',
  'profile.workableDescription': 'Профиль уже есть в Workable',
  'profile.source': 'Источник',
  'profile.jobReviewerMtsName': 'МТС',
  'profile.jobReviewerMts': 'Сверить кандидата',
  'profile.jobReviewerMtsError': 'Кандидат не найден',

  'profile.proxyLink.redirecting': 'Перенаправление на профиль пользователя...',
  'profile.proxyLink.error': 'Что-то пошло не так. Попробуйте ещё раз позже.',

  'profileContact.source.is': ' ',
  'profileContact.source.was': 'был',
  'profileContact.source.on': 'на',
  'profileContact.source.from': 'из',
  'profileContact.source.resume': 'резюме',
  'profileContact.source.hh': 'HH',
  'profileContact.source.hhJson': 'HH',
  'profileContact.source.linkedin': 'Linkedin',
  'profileContact.source.e-staff': 'E-Staff',
  'profileContact.source.resumeFile': 'файла', // из файла-резюме
  'profileContact.source.getmatch': 'getmatch', // из файла-резюме
  'profileContact.source.enrich-from-file': 'файла',
  'profileContact.source.repository': 'репозитория', // из <a>ИМЯ</a> репозитория
  'profileContact.source.manual': 'добавлено вручную',
  'profileContact.source.mailingList': 'из почтовой рассылки',
  'profileContact.source.homesite': 'домашнего сайта',
  'profileContact.source.gravatar': 'на основании данных', // На основании данных <a>gravatar</a>
  'profileContact.source.sent': 'отправлен',
  'profileContact.source.inProgress': 'взят в работу',
  'profileContact.source.externalSource': 'из внешнего источника',
  'profileContact.phone': 'Телефон',
  'profileContact.new.phone': 'новый телефон',
  'profileContact.email': 'Почта',
  'profileContact.new.email': 'новая почта',
  'profileContact.skype': 'Skype',
  'profileContact.new.skype': 'новый Skype',
  'profileContact.icq': 'ICQ',
  'profileContact.new.icq': 'новый ICQ',
  'profileContact.link': 'Ссылка',
  'profileContact.new.link': 'новая ссылка',
  'profileContact.telegram': 'Telegram',
  'profileContact.new.telegram': 'новый Telegram',
  'profileContact.edit.title.start': 'Редактировать контакты',
  'profileContact.edit.title.end': 'Завершить редактирование',
  'profileContact.copyToClipboard': 'Скопировать в буфер обмена',
  'profileContact.copiedToClipboard': 'Скопировано в буфер обмена',
  'profileContact.requestContacts': 'Запросить контакты',
  'profileContact.invalidContact': 'Введен некорректный контакт. Пожалуйста, попробуйте снова.',
  'profileContact.contactAdded': 'Контакт успешно сохранен',
  'profileContact.error': 'Извините! Произошла ошибка. Пожалуйста, попробуйте ещё раз!',
  'profileContact.secondary.email': 'Рабочие емейлы',
  'profileContact.secondary.default': 'Рабочие контакты',
  'profileContact.dataEnrichment': 'Data enrichment',
  'profileContact.notPublic': 'непубличный',
  'profileContact.bounce':
    'Почта не существует или не принимает сообщения. Отправьте письмо на другой имейл.',
  'profileContact.matchesWith': `совпадает с {type, select,
    skype {аккаунтом Skype}
    nick {никнеймом на {source}}
    email {емейлом}
    phone {телефоном {source}}
    other {}
  }`,
  'profileContact.gmate': 'getmatch',
  'profileContact.smartRecruitersImport': 'из SmartRecruiters',
  'profileContact.huntflowImport': 'из Huntflow',
  'profileContact.greenhouseImport': 'из Greenhouse',
  'profileContact.workable': 'из Workable',

  'shortProfile.contacts.corporate': 'Рабочий',
  'shortProfile.contacts.showAll': 'Открыть контакты',
  'shortProfile.contacts.copiedToClipboard': 'Контакт скопирован в буфер обмена',
  'shortProfile.contacts.noCredits': 'У Вас недостаточно лимитов для открытия контактов',
  'shortProfile.contacts.publicContactsOpened': `{opened, plural,
    one {контакт открыт}
    few {контакта открыто}
    many {контактов открыто}
    other {контакта открыто}
  }`,
  'shortProfile.contacts.noContacts': 'В профиле нет доступных контактов',

  'shortProfile.addToFolder.add': 'Добавить в папку',
  'shortProfile.addToFolder.choose': 'Выбрать папку',
  'shortProfile.addToFolder.create': 'Создать папку',

  'similarProfiles.similarCandidates': 'Рекомендуемые профили',
  'similarProfiles.notFound': 'не найдены',
  'similarProfiles.reload': 'запросить повторно',
  'similarProfiles.showAllSimilar': `≈ {total, plural,
    one {# рекомендуемый профиль}
    few {# рекомендуемых профиля}
    many {# рекомендуемых профилей}
    other {# рекомендуемых профилей}
  }`,
  'similarProfiles.experience': `{experience, plural,
    one {# год опыта}
    few {# года опыта}
    other {# лет опыта}
  }`,

  'possibleProfiles.possibleCandidates': 'Вероятные профили',
  'possibleProfiles.showAllPossible': `≈ {total, plural,
    one {# вероятный профиль}
    few {# вероятных профиля}
    many {# вероятных профилей}
    other {# вероятных профиля}
  }`,

  'profilePositions.companyNameClickInfo': 'Показать статистику',
  'profilePositions.notIt': 'Не IT опыт',

  'profileViews.veryLow': 'Профиль просматривается очень редко',
  'profileViews.low': 'Профиль просматривается редко',
  'profileViews.high': 'Профиль просматривается часто',
  'profileViews.veryHigh': 'Профиль просматривается очень часто',
  'profileViews.viewsData': 'Просмотры',
  'profileViews.viewsHeading': 'История просмотров',
  'profileViews.showMore': 'Еще',
  'profileViews.showLess': 'Скрыть',
  'profileViews.notFound': 'не найдены',

  'merging.title': 'Редактирование профиля',
  'merging.subtitle': `Состоит из {profiles, plural,
    one {1 профиля}
    other {# профилей}
  }.`,
  'merging.openProfile': 'Открыть профиль',
  'merging.insertLink': 'Вставьте ссылку на профиль кандидата',
  'merging.submit': 'Добавить ссылку',
  'merging.clear': 'Clear',
  'merging.remove': 'Удалить',
  'merging.url': 'URL',
  'merging.name': 'Имя',
  'merging.pictures': 'Фото',
  'merging.nick': 'Никнейм',
  'merging.location': 'Город',
  'merging.positions': 'Места работы',
  'merging.skills': 'Навыки',
  'merging.educations': 'Образование',
  'merging.interests': 'Интересы',
  'merging.links': 'Ссылки на другие сайты',
  'merging.jobSeek': 'Ищет работу?',
  'merging.industry': 'Сфера',
  'merging.yes': 'Да',
  'merging.no': 'Нет',
  'merging.from': 'с',
  'merging.to': 'по',
  'merging.showAll': 'Показать все',
  'merging.collapse': 'Скрыть',
  'merging.searchBy': 'Искать по:',
  'merging.searchByName': 'имени',
  'merging.searchByNameAndCompany': 'имени и компании',
  'merging.searchByNick': 'нику',
  'merging.detachProfile': 'Удалить эту колонку?',
  'merging.insertProfile': 'Вы уверены, что хотите вклеить этот профиль в таблицу?',

  'manageToken.heading': 'Управление API',
  'manageToken.accessToken': 'Токен',
  'manageToken.description': `
    <p>Amazing Hiring API работает по <b>токену доступа</b>.</p>
    <p>Храните токен в <b>секрете</b>, потому что он <b>бессрочный</b> и, если злоумышленники
    воспользуются ключом, то это приведёт к лишним тратам за ваш счёт.</p>
    <p>Если вы обнаружили подозрительные запросы, отзовите токен и сгенерируйте новый.</p>
  `,
  'manageToken.generateText': 'Нажмите на кнопку, чтобы <b>создать токен</b>.',
  'manageToken.generateAction': 'Создать токен',
  'manageToken.tokenLabel': 'Токен доступа',
  'manageToken.revokeToken': 'Отозвать токен',
  'manageToken.revokeWarning': `
    Это действите необратимо. Если вы отзовёте ключ, то доступ по АПИ
    со старым токеном перестанет работать.
  `,
  'manageToken.revokeCancel': 'Отмена',
  'manageToken.confirmationHeading': 'Вы уверены?',
  'manageToken.stats': 'Статистика',
  'manageToken.statsHeading': 'Статистика использования AH API за',
  'manageToken.statsResource': 'Ресурс',
  'manageToken.statsCount': 'Количество обращений',
  'manageToken.statsLoading': 'Загрузка...',
  'manageToken.statsNoData': 'Нет данных за выбранный период',

  'ats.integration.add':
    'Добавить в {vendor, select, lever {Lever} greenhouse {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} workable {Workable} other {}}',
  'ats.integration.loading': 'Загрузка...',
  'ats.integration.empty': 'Кандидат ещё не отобран на вакансию',
  'ats.integration.filter': 'поиск',
  'ats.integration.title.greenhouse': 'Greenhouse',
  'ats.integration.title.lever': 'Lever',
  'ats.integration.title.smartrecruiters': 'SmartRecruiters',
  'ats.integration.title.teamtailor': 'Teamtailor',
  'ats.integration.title.workable': 'Workable',
  'ats.integration.update': 'Обновить',
  'ats.integration.autoUpdate': 'Автообновление',
  'ats.integration.setAutoUpdate': 'Включить "автообновление"',
  'ats.integration.updated':
    'Кандидат в {vendor, select, lever {Lever} greenhouse {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} workable {Workable} other {}} обновлен',
  'ats.integration.updateHint':
    'Обновить всех кандидатов в {vendor, select, lever {Lever} greenhouse {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} workable {Workable} other {}} данными из профиля',
  'ats.integration.autoUpdateHint': `Автоматически обновлять у всех кандидатов в {vendor, select, lever {Lever} greenhouse {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} other {}
    } информацию об изменениях в папках, письмах и тегах`,
  'ats.integration.massAutoUpdateHint':
    'При включенном чекбоксе “автообновление” будет включено для всех экспортируемых кандидатов, в обратном случае изменений в настройках автообновления не произойдет',
  'ats.integration.exportHint':
    'Будет создан новый кандидат или обновлен существующий, если найдено совпадение по email в рамках выбранной вакансии',
  'ats.integration.noEmail': `У профиля нет емейлов, поэтому невозможно найти совпадение с кандидатами из {
    vendor, select, lever {Lever} greenhouse {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} workable {Workable} other {}}`,
  'ats.integration.noVacancies': 'Нет вакансий',
  'ats.connect.greenhouse': 'Подключить Greenhouse',
  'ats.connect.smartrecruiters': 'Подключить SmartRecruiters',
  'ats.connect.lever': 'Подключить Lever',
  'ats.connect.teamtailor': 'Подключить Teamtailor',
  'ats.connect.workable': 'Подключить Workable',

  'ats.select.prospect': 'As prospect',
  'ats.select.withoutVacancy': 'No Jobs (As prospect)',
  'ats.select.cancel': 'Отменить',
  'ats.select.add': 'Добавить',

  'ats.integration.prospectFor': 'Prospect',
  'ats.integration.dateHint': 'Дата добавления кандидата на вакансию',

  'smartrecruiters.connect': 'Подключить SmartRecruiters',
  'smartrecruiters.add': 'Отобрать кандидата на вакансию',
  'smartrecruiters.addWithoutJob': 'Отобрать кандидата',
  'smartrecruiters.empty': 'Кандидат ещё не отобран на вакансию',
  'smartrecruiters.prospect': 'Без вакансии',
  'smartrecruiters.filter': 'поиск',
  'smartrecruiters.title': 'SmartRecruiters',
  'smartrecruiters.description': 'Профиль уже есть в SmartRecruiters',

  'apps.apps': 'Приложения',
  'apps.fallback': 'Если хотите узнать о возможных интеграциях, обратитесь к вашему менеджеру',
  'apps.connect': 'Подключить',
  'apps.reconnect': 'Переподключить',
  'apps.del': 'Удалить',
  'apps.insertToken': 'Вставить API токен',
  'apps.website': 'Сайт {name}',
  'apps.help': 'Как подключить?',
  'apps.requestManager':
    'Данная интеграция не доступна для вашей компании. Обратитесь к менеджеру.',
  'apps.onlyOneVendor': 'Возможно подключение только одного почтового сервиса',
  'apps.messaging': 'Письма',
  'apps.contactManager': 'Свяжитесь с вашим менеджером для интеграции с этим сервисом.',
  'apps.betaTip': 'Интеграция с {integrationName} находится в beta-тестировании',
  'apps.connectSmartRecruiters':
    'Пожалуйста, свяжитесь с вашим администратором, чтобы включить интеграцию',
  'apps.disconnectSmartRecruiters':
    'Пожалуйста, свяжитесь с вашим администратором, чтобы отключить интеграцию',
  'apps.reconnectSmartRecruiters':
    'Пожалуйста, свяжитесь с вашим администратором, чтобы переподключить интеграцию',

  'profileActions.copy': 'Скопировать в буфер обмена',
  'profileActions.saveAsWord': 'Сохранить в Word',
  'profileActions.saveAsPDF': 'Сохранить в PDF',
  'profileActions.uploadFile': 'Прикрепить файл',
  'profileActions.uploadErrMsg': 'Не удалось загрузить резюме. Повторите загрузку позднее.',
  'profileActions.uploadErrMsgSize': 'Размер файла больше 10МБ. Выберите файл поменьше.',
  'profileActions.uploadErrMsgType': 'Мы принимаем DOC-, DOCX-, RTF-, PDF-, TXT-файлы',
  'profileActions.enrichQuestion': 'Хотите обновить профиль из {filename}?',
  'profileActions.confirmEnrich': 'Да',
  'profileActions.cancelEnrich': 'Нет',

  needActivation: 'Активируйте аккаунт',
  'needActivation.didNotReceive': 'Не пришло письмо?',
  'needActivation.reSubmit': 'Отправить ещё раз',
  'needActivation.submitted': 'Письмо отправлено',
  'needActivation.checkSpam': `
    Возможно, вам понадобится проверить папку нежелательной почты
    или разблокировать адрес {email}.`,
  'needActivation.message': `
    Чтобы начать пользоваться расширением {app}, перейдите по ссылке, отправленной вам на {email}.`,

  'needVerification.verify': 'Подтвердите учетную запись.',
  'needVerification.didNotReceive': 'Не пришло письмо?',
  'needVerification.reSubmit': 'Отправить повторно',
  'needVerification.submitted': 'Письмо отправлено',
  'needVerification.wait': 'Дождитесь пока придёт сообщение с верификацией',
  'needVerification.checkSpam': `
    Рекомендуем проверить папку “Спам” и убедиться, что рассылка от {whiteLabelNoReplyEmail} не заблокирована.`,
  'needVerification.message': `
    Мы заметили подозрительную активность с твоим аккаунтом. На адрес {email} была отправлена ссылка для входа. Она будет действовать в течение 15 минут после получения.`,

  'extensionCompany.allSet': 'Всё готово!',
  'extensionCompany.mainText': `
     У тебя есть лучший друг по поиску
  `,
  'extensionCompany.feedback': 'Напишите нам:',
  'extensionCompany.checkTitle': 'Проверьте, все ли работает хорошо:',
  'extensionCompany.checkDescription': `
    Перейдите в <a>профиль LinkedIn</a> одного из самых влиятельных программистов по версии Business Insider:
    узнайте их навыки и ссылки на общедоступные профили.
  `,
  'extensionCompany.preparedWith': 'Cделано с',
  'extensionCompany.by': 'командой {whiteLabelFormattedName}',

  save: 'Сохранить',
  saved: 'Сохранён',

  'duration.years': `{years, plural,
    one {# год}
    few {# года}
    other {# лет}
  }`,
  'duration.months': `{months, plural,
    one {# месяц}
    few {# месяца}
    other {# месяцев}
  }`,

  'duration.yearsShort': `{years, plural,
    one {# г.}
    few {# г.}
    other {# л.}
  }`,
  'duration.monthsShort': `{months, plural,
    other {# мес.}
  }`,
  'duration.lessThanYear': '{lt} 1 года',
  'duration.lessThanYearShort': '{lt} 1 г.',
  'duration.lessThanMonth': '{lt} 1 месяца',
  'duration.lessThanMonthShort': '{lt} 1 мес.',

  'dateRange.present': 'н.в.',
  'dateRange.from': 'С',
  'dateRange.until': 'По',
  'dateRange.month': `{month, select,
    1 {Январь}
    2 {Февраль}
    3 {Март}
    4 {Апрель}
    5 {Май}
    6 {Июнь}
    7 {Июль}
    8 {Август}
    9 {Сентябрь}
    10 {Октябрь}
    11 {Ноябрь}
    12 {Декабрь}
    other {}
  }`,

  'formattedError.defaultMessage': 'Данные не удалось отобразить',
  'formattedError.errorSorryMessage': 'Извините, произошла ошибка',
  'formattedError.badRequest': 'Плохой запрос',
  'formattedError.unauthorized': 'Необходима авторизация',
  'formattedError.forbidden': 'Доступ запрещен',
  'formattedError.notFound': 'Страница не найдена',
  'formattedError.internalServerError': 'Внутренняя ошибка сервера',
  'formattedError.badGateway': 'Недопустимый ответ сервера',
  'formattedError.serviceUnavailable': 'Сервис недоступен',
  'formattedError.gatewayTimeout': 'Время ожидания ответа сервера истекло',
  'formattedError.deletedProfile': 'Профиль удален по просьбе его владельца',
  'formattedError.helpcenter': `<ru>Помощь</ru>`,
  'formattedError.accessRestriction': 'Поиск по данной локации недоступен для вашего аккаунта',
  'formattedError.ageErrorFilter': `Фильтр "Возраст" недоступен для данной локации`,
  'formattedError.genderErrorFilter': `Фильтр "Пол" недоступен для данной локации`,
  'formattedError.diversityErrorFilter': `Фильтр "Многообразие" недоступен для данной локации`,

  'errorPage.deletedProfile': 'deletedProfileRU',

  'errorBoundary.title': 'Что-то пошло не так',
  'errorBoundary.main': 'На главную',

  'ahhelp.balance': `На счету компании: {availableCredits, plural,
    one {<b>#</b> кредит}
    few {<b>#</b> кредита}
    other {<b>#</b> кредитов}
  }`,
  'ahhelp.price': `Cтоимость: {price, plural,
    one {<b>#</b> кредит}
    few {<b>#</b> кредита}
    other {<b>#</b> кредитов}
  }`,
  'ahhelp.send': 'Отправить запрос',
  'ahhelp.more': 'подробнее',
  'ahhelp.title': 'AH-помощь',
  'ahhelp.mining.title': 'Запрос на поиск контакта',
  'ahhelp.mining.personal_email': 'персональный емейл',
  'ahhelp.mining.work_email': 'рабочий емейл',
  'ahhelp.mining.skype': 'скайп',
  'ahhelp.mining.phone': 'телефон',
  'ahhelp.mining.description': 'Найдем для вас контакты кандидатов',
  'ahhelp.success': 'Запрос отправлен',
  'ahhelp.inProgress': 'Запрос взят в работу',
  'ahhelp.cancel': 'Отменить запрос',
  'ahhelp.or': 'или',
  'ahhelp.deadline': `Срок исполнения: {deadline, plural,
    one {<b>#</b> час}
    few {<b>#</b> часа}
    other {<b>#</b> часов}
  }`,
  'ahhelp.resultsTo': `Результаты будут отправлены вам на емейл <b>{userEmail}</b>`,
  'ahhelp.refill': 'пополнить',
  'ahhelp.guarantees': 'В случае полного отсутствия результата — оплата возвращается.',

  'SearchingStat.title': `Статистика поиска`,

  'manageOrders.heading': 'Заказы',
  'manageOrders.createDate': 'Дата',
  'manageOrders.service': 'Услуга',
  'manageOrders.description': 'Пояснение',
  'manageOrders.customer': 'Заказчик',
  'manageOrders.company': 'Компания',
  'manageOrders.status': 'Статус',
  'manageOrders.sourceContact': 'Поиск контакта',
  'manageOrders.personalEmail': 'Персональный емейл',
  'manageOrders.workEmail': 'Рабочий емейл',
  'manageOrders.phone': 'Телефон',
  'manageOrders.skype': 'Скайп',
  'manageOrders.open': 'Новый',
  'manageOrders.cancelled': 'Отказ',
  'manageOrders.inProgress': 'В работе',
  'manageOrders.done': 'Выполнен',
  'manageOrders.failed': 'Возврат',
  'manageOrders.start': 'Взять в работу',
  'manageOrders.notFound': 'Заказ не найден',
  'manageOrders.showAllOrders': 'Показать все заказы',

  'paginationTailless.prev': 'назад',
  'paginationTailless.next': 'дальше',

  'matchingHistory.heading': `История склейки.`,
  'matchingHistory.profile': `Профиль {profileId, number}`,
  'matchingHistory.emptyItems': `Пустые «items»`,
  'matchingHistory.unknownSourceTo': `Неизвестный источник «match»`,
  'matchingHistory.unknownSourceMerge': `Неизвестный источник «merge»`,
  'matchingHistory.description':
    'Вы можете добавить в URL get-параметр company_id для просмотра истории под другой компанией.',
  'matchingHistory.matchedProfiles': 'Склеенные профили',
  'matchingHistory.mergedTo': 'Подклеены в главный профиль',
  'matchingHistory.matchBy': 'Склеились по',
  'matchingHistory.errors': 'Ошибки',

  'profileReport.report': 'Сообщите об ошибке',
  'profileReport.placeholder': 'Подробнее',
  'profileReport.submit': 'Отправить',
  'profileReport.cancel': 'Отменить',
  'profileReport.sended': 'Спасибо за вашу помощь!',
  'profileReport.err': 'Пожалуйста, попробуйте ещё раз!',
  'profileReport.empty': 'Пожалуйста, опишите проблему',
  'profileReport.title': 'Что не так с профилем?',
  'profileReport.queryMismatch': 'Кандидат не подходит под запрос',
  'profileReport.notUpdated': 'Профиль не обновлен',
  'profileReport.incorrectBio': 'Некорректная биография/имя',
  'profileReport.wrongContacts': 'Контакты другого человека',
  'profileReport.wrongLocation': 'Некорректное место расположение кандидата',
  'profileReport.other': 'Другое',

  'metaqueries.title': 'Быстрый поиск',
  'metaqueries.subtitle': 'Готовые поисковые запросы по специализациям',
  'metaqueries.help_text':
    'Наш поиск стал еще проще со списками кандидатов, отобранных по специализациям.',
  'metaqueries.developers': 'Разработка ПО',
  'metaqueries.specialists': 'Cмежные IT специализации',
  'metaqueries.companies': 'Списки компаний',
  'metaqueries.companies_description': 'Только для российского рынка на время бета-тестирования',
  'metaqueries.locations_help_text':
    'Используйте оператор OR, если хотите искать по нескольким локациям, и оператор NOT, чтобы исключить локации из поиска.',
  'metaqueries.experience_tab_title': 'Опыт',
  'metaqueries.experience_title': 'Выберите продолжительность работы',
  'metaqueries.companies_title': 'Компании',
  'metaqueries.next': 'Далее',
  'metaqueries.back': 'Назад',
  'metaqueries.notSelected': 'не выбрана',
  'metaqueries.selectSpecialization': 'Выберите специализацию',
  'metaqueries.remove': 'Удалить',

  'inviteEmployee.title.ClientCompanyWorker': 'Приглашение сотрудников',
  'inviteEmployee.title.ClientCompanyAdmin': 'Приглашение администраторов',
  'inviteEmployee.title.HiringManager': 'Приглашение нанимающих менеджеров',
  'inviteEmployee.invite.ClientCompanyWorker': 'Пригласить сотрудников',
  'inviteEmployee.invite.ClientCompanyAdmin': 'Пригласить администраторов',
  'inviteEmployee.invite.HiringManager': 'Пригласить нанимающих менеджеров',
  'inviteEmployee.description':
    'Чтобы добавить новых пользователей, укажите их емейлы и отправьте им приглашения.',
  'inviteEmployee.saved': 'Приглашения отправлены на указанные емейлы.',
  'inviteEmployee.errored': 'Некоторые приглашения не были отправлены.',
  'inviteEmployee.avalibleInnvites': `Доступно {countOfAvailableInvites, plural,
      one {# приглашение}
      few {# приглашения}
      many {# приглашений}
      other {# приглашений}
    }.`,
  'inviteEmployee.avalibleInnvitesWithSearch': `Доступно {countOfAvailableInvitesWithSearch, plural,
      one {# приглашение}
      few {# приглашения}
      many {# приглашений}
      other {# приглашений}
    } с поиском.`,
  'inviteEmployee.cancel': 'Отмена',
  'inviteEmployee.send': 'Отправить приглашения',
  'inviteEmployee.newEmail': 'Емейл нового пользователя',
  'inviteEmployee.language': 'Язык интерфейса',
  'inviteEmployee.ru': 'рус',
  'inviteEmployee.en': 'англ',
  'inviteEmployee.canSearch': 'поиск',
  'inviteEmployee.isApiManager': 'менеджер api',

  'clientUpdate.description': 'Заполните личные данные<br></br>для начала работы с системой',
  'clientUpdate.first_name': 'Имя',
  'clientUpdate.last_name': 'Фамилия',
  'clientUpdate.password': 'Пароль',
  'clientUpdate.confirm_password': 'Подтверждение пароля',
  'clientUpdate.phone': 'Телефон',
  'clientUpdate.email': 'Емейл',
  'clientUpdate.language': 'Язык',
  'clientUpdate.ru': 'Русский',
  'clientUpdate.en': 'Английский',
  'clientUpdate.send': 'Сохранить',
  'clientUpdate.isEmpty': 'Поле обязательно для заполнения',
  'clientUpdate.isUnconfirmed': 'Значение отличается от пароля',

  'home.proSearch': 'Поисковая форма',
  'home.metaqueries': 'AI поиск',
  'home.history': 'История',
  'home.savedQueries': 'Сохраненные запросы',
  'home.metaqueriesHelp':
    'Технология машинного обучения автоматически определяет наиболее подходящих кандидатов, исходя из вашего запроса.',
  'home.smartFolders': 'Папки',
  'home.more': 'Ещё',
  'home.lineOff': `С 30 апреля старая поисковая форма больше не будет доступна. <email>Напишите нам</email>, мы поможем вам эффективно перейти на <a>новую поисковую форму</a>`,

  'resultsFolders.title': `{candidates, plural,
      one {профиль}
      few {профиля}
      many {профилей}
      other {профиля}
    }`,
  'resultsFolders.addAllCandidatesToFolder': 'Добавить всех кандидатов в папку',
  'resultsFolders.successAddAllCandidatesToFolder':
    'Все кандидаты успешно добавлены в папку «{folderName}»',
  'resultsFolders.successMaxCandidateAddedToFolder':
    'Первые 50 000 кандидатов успешно добавлены в папку «{folderName}»',
  'resultsFolders.foldersLoading': 'Загрузка...',
  'resultsFolders.newFolder': 'Новая папка',
  'resultsFolders.allFolders': 'Все папки',
  'resultsFolders.myFolders': 'Мои папки',
  'resultsFolders.noFolders': `
    У вас ещё нет папок. Создайте свою первую папку и добавляйте профили.
    Работайте с профилями внутри папки.
    `,
  'resultsFolders.learnMoreLink': 'Подробнее',
  'resultsFolders.openFolder': 'Открыть',
  'resultsFolders.hint': `
    Нажмите на папку, чтобы добавить {candidates, plural,
    one {профиль}
    other {профили}
    }`,
  'resultsFolders.notification': `{number} {candidates, plural,
      one {профиль добавлен}
      few {профиля добавлены}
      many {профилей добавлены}
      other {профиля добавлены}
    } в папку {name}`,
  'resultsFolders.resetCandidates': 'Сбросить',
  'resultsFolders.badRequest': 'Данный профиль уже добавлен в выбранную папку',
  'resultsFolders.successRequest': 'Профиль добавлен в папку',
  'resultsFolders.selectCandidates': 'Выберите профили и добавьте их в папку',
  'resultsFolders.folders': 'Папки',

  'foldersTab.noFolders': 'Профиль пока не добавлен ни в одну папку',
  'foldersTab.exclude': 'Исключить',

  'messagingTab.empty': 'Отправленных писем нет',

  'slideblock.open': 'Открыть "Папки"',
  'slideblock.close': 'Скрыть "Папки"',

  'commentsTab.edit': 'Изменить',
  'commentsTab.comments': 'Комментарии',
  'commentsTab.newComment': 'Новый комментарий',
  'commentsTab.addComment': 'Добавить комментарий',

  'contactsTab.disclaimer': `При общении с кандидатом, пожалуйста, ссылайтесь на первоисточник контакта.
     <a>Почему?</a>`,

  'resumeTab.noResumes': 'К профилю пока не добавлено ни одного вложения',

  'confirmDelete.delete': 'Удалить',
  'confirmDelete.cancel': 'Отмена',

  'savedQuery.name': 'Название',
  'savedQuery.description': 'Описание',
  'savedQuery.save': 'Сохранить',
  'savedQuery.saveQuery': 'Сохранить запрос',

  'savedQueries.noQueries': 'Нет сохраненных запросов',

  'limitsInfo.openContacts': 'Открыть публичные контакты',
  'limitsInfo.opened': `{opened, plural,
    one {публичный контакт открыт}
    few {публичных контакта открыто}
    many {публичных контактов открыто}
    other {публичных контакта открыто}
  }`,
  'limitsInfo.noCredits': 'У Вас недостаточно лимитов для открытия контактов',

  'sourcingTips.show': 'Показать подсказки',
  'sourcingTips.hide': 'Скрыть подсказки',
  'sourcingTips.title': 'Подсказки',

  'profileResumes.file': `{count, plural,
    one {вложение}
    few {вложения}
    many {вложений}
    other {вложения}
  }`,
  'profileResumes.latest': 'последний —',
  'profileResumes.title': 'Резюме',
  'profileResumes.fileTitle': 'Вложение',
  'profileResumes.source': 'Источник',
  'profileResumes.delete': 'Удалить',
  'profileResumes.notFound': 'Вложение не найдено',
  'profileResumes.age': 'Возраст',
  'profileResumes.birthDate': 'Дата рождения',
  'profileResumes.location': 'Локация',
  'profileResumes.email': 'Емейл',
  'profileResumes.mobilePhone': 'Мобильный телефон',
  'profileResumes.homePhone': 'Домашний телефон',
  'profileResumes.gender': 'Пол',
  'profileResumes.male': 'мужчина',
  'profileResumes.female': 'женщина',
  'profileResumes.skype': 'Skype',
  'profileResumes.enrichSuccess': 'Профиль успешно обновлен',
  'profileResumes.enrichError': 'Ошибка в обновлении профиля из файла',
  'profileResumes.enrichBtn': 'Обновить профиль',
  'profileResumes.deleteMessage': 'Вы точно хотите удалить этот файл из профиля?',
  'profileResumes.alreadyEnriched': 'Профиль уже обновлен из этого файла',
  'profileResumes.enrichDisabled': 'Обновление профиля невозможно из этого файла',

  'dataEnrichment.title': 'Data Enrichment',
  'dataEnrichment.name': 'Название импорта',
  'dataEnrichment.description': `<p>Загрузите файл CSV. После его обработки на ваш email придет уведомление.</p>
    <p>Все найденные профили будут добавлены в папку с названием импорта.</p>`,
  'dataEnrichment.namePlaceholder': 'Хакатон #1',
  'dataEnrichment.file': 'Файл CSV',
  'dataEnrichment.required': 'Это поле обязательно к заполнению',
  'dataEnrichment.createImport': 'Импортировать',
  'dataEnrichment.found': 'Найдено',
  'dataEnrichment.foundCount': '{totalFound} из {total}',
  'dataEnrichment.emailsErrorsCount': 'Ошибок',
  'dataEnrichment.refreshList': 'Обновить список',
  'dataEnrichment.uploadSuccessMsg':
    'Импорт успешно создан. По завершению обработки, вам будет отправлено письмо-уведомление.',
  'dataEnrichment.uploadErrMsg': 'Не удалось загрузить файл. Повторите загрузку позднее.',
  'dataEnrichment.uploadErrMsgSize': 'Размер файла больше 10МБ. Выберите файл поменьше.',
  'dataEnrichment.uploadErrMsgType': 'Мы принимаем CSV-файлы',
  'dataEnrichment.quotaAvailable': 'Количество профилей, доступных к поиску: ',
  'dataEnrichment.errorLoadingImports': 'Не удалось загрузить предыдущие импроты.',
  'dataEnrichment.badge':
    'Автоматический поиск кандидатов по их емейлам из CSV файла и размещение их в отдельную папку',
  'dataEnrichment.fieldTooltip': 'Вы сможете выбрать типы полей после загрузки CSV файла.',
  'dataEnrichment.importedFile': 'Загруженный файл:',
  'dataEnrichment.clear': 'Очистить',
  'dataEnrichment.fieldType': 'Тип поля',
  'dataEnrichment.importCSV': 'Загрузить CSV',
  'dataEnrichment.email': 'Емейл',
  'dataEnrichment.phone': 'Телефон',
  'dataEnrichment.link': 'Ссылка',
  'dataEnrichment.telegram': 'Телеграм',
  'dataEnrichment.ignore': 'Игнорировать',
  'dataEnrichment.chooseFieldType': 'Выберете тип хотя бы для одного поля.',
  'dataEnrichment.quotaExceeded': 'Слишком много профилей для поиска.',
  'dataEnrichment.download': 'Скачать',
  'dataEnrichment.cancel': 'Отмена',
  'dataEnrichment.noProfiles': 'Профилей не найдено',
  'dataEnrichment.downloadReport': 'Скачать отчет',

  'privateMerge.newFeature': 'НОВОЕ',
  'privateMerge.youCan': 'Вы можете',
  'privateMerge.boldMerge': ' объединить ',
  'privateMerge.whatToMerge': 'данные основного и вероятных профилей',
  'privateMerge.mergeProfiles': 'Объединить…',

  'folders.modalMoveConfirmTitle': 'Переместить профиль кандидата?',
  'folders.title': 'Папки',
  'folders.updated': 'Обновлена',
  'folders.all': 'Все папки',
  'folders.name': 'Название',
  'folders.candidates': 'Кандидатов',
  'folders.assignees': 'Назначена',
  'folders.addFolder': 'Добавить папку',
  'folders.noFolders': `Нет папок по выбранным параметрам.`,
  'folders.createFolder': 'Создать папку',
  'folders.addVacancy': 'Добавить папку',
  'folders.noVacancies': `Нет папок по выбранным параметрам.`,
  'folders.createVacancy': 'Создать папку',
  'folders.active': 'Открытые папки',
  'folders.closed': 'Архивные папки',
  'folders.recruiters': 'Все рекрутеры',
  'folders.notFound': 'Не найден',
  'folders.status': 'Статус',
  'folders.havePermissions': 'Имеют доступ',
  'folders.recruiter': 'Рекрутер',
  'folders.creationDate': 'Дата создания',
  'folders.lastModificationDate': 'Изменена',
  'folders.folderMassActions': 'Действия с папкой',
  'folders.candidatesMassActions': `Действия с {count, plural,
    one {# кандидатом}
    other {# кандидатами}
  }`,
  'folders.activeStatus': 'Открытая',
  'folders.archivedStatus': 'Архивная',
  'folders.chosenCandidates': `{chosenCandidatesCount, plural,
      one {Выбран #}
      other {Выбрано #}} из {profilesCount, plural,
        one {# профиля}
        other {# профилей}
      }`,
  'folders.selectCandidates': 'Выберите кандидатов',
  'folders.noCandidates': 'Нет кандидатов',
  'folders.massActions': 'Действия',
  'folders.delete': 'Удалить',
  'folders.editFolder': 'Редактировать',
  'folders.export': 'Экспортировать',
  'folders.moveCandidates': 'Переместить',
  'folders.copyCandidates': 'Копировать',
  'folders.moving': 'Перемещение профилей',
  'folders.copying': 'Копирование профилей',
  'folders.movingDescription': 'Выберите папку, чтобы перенести в неё выбранные профили.',
  'folders.cancel': 'Отменить',
  'folders.move': 'Переместить',
  'folders.copy': 'Копировать',
  'folders.disabledActionTooltip': 'Вы не выбрали ни одного кандидата',
  'folders.massStatusChange': 'Сменить статус',
  'folders.messaging': 'Написать',
  'folders.copyComments': 'Копировать комментарии из {folderName} вместе с кандидатом',
  'folders.moveComments': 'Переместить комментарии из {folderName} вместе с кандидатом',
  'folders.selectFolder': 'Выберите папку',

  'folders.mailingLists.total': 'Всего',
  'folders.mailingLists.draft': 'Черновики',
  'folders.mailingLists.pending': 'Отправляется',
  'folders.mailingLists.error': 'Не отправлено',
  'folders.mailingLists.sent': 'Отправлено',
  'folders.mailingLists.opened': 'Просмотрено',
  'folders.mailingLists.replied': 'Отвечено',
  'folders.mailingLists.manage': 'управлять',
  'folders.mailingLists.mailingList': 'Рассылка',
  'folders.mailingLists.trackingInfo': 'Обновление статуса прочтения',
  'folders.mailingLists.previousUpdate': 'предыдущеe обновление',
  'folders.mailingLists.nextUpdate': 'следующее обновление',
  'folders.mailingLists.createMailingList': 'Создать рассылку',
  'folders.mailingLists.addToMailingList': 'Добавить в рассылку',
  'folders.mailingLists.addingToMailingListIsNotAvailable': `{count, plural, one {Выбран} other {Выбраны}} {count} {count, plural, one {кандидат} few {кандидата} other {кандидатов}} со <b>скрытыми контактами.
  </b><br></br>Вы не можете добавить в рассылку этих кандидатов, т.к. в этом месяце у Вас осталось только {quota}
  {quota, plural,
    one {просмотр}
    few {просмотра}
    other {просмотров}} контактов.`,
  'folders.mailingLists.candidatesAdded': `{count} {count, plural,
      one {кандидат добавлен}
      few {кандидата добавлены}
      many {кандидатов добавлены}
      other {кандидата добавлены}
    } в рассылку`,

  'folders.mailingLists.noCandidatesAdded': 'Выбранные кандидаты уже добавлены в рассылку',
  'folders.mailingLists.errors.bounced': 'Email не существует',
  'folders.mailingLists.errors.badRequest': 'Что-то пошло не так',
  'folders.mailingLists.errors.integrationDisabled': 'Интеграция отключена',
  'folders.mailingLists.errors.unsubscribed': 'Отписан от рассылок',
  'folders.mailingLists.errors.maxRetries': 'Превышены лимиты на отправку',
  'folders.mailingLists.errors.unexpected': 'Неизвестная ошибка',
  'folders.mailingLists.errors.previousHasError': 'Ошибка в цепочке сообщений',
  'folders.mailingLists.errors.bouncedDescription':
    'Попробуйте использовать другой email получателя: измените email и запустите рассылку.',
  'folders.mailingLists.errors.badRequestDescription':
    'Переподключите почтовую интеграцию и запустите рассылку заново, письма с ошибками будут автоматически переотправлены. При повторном возникновении ошибки, пожалуйста, обратитесь в поддержку.',
  'folders.mailingLists.errors.integrationDisabledDescription': `{vendor} автоматически отключает интеграцию, если она длительное время не использовалась или была отключена пользователем.
  Заново подключите интеграцию в Настройках, затем запустите рассылку.`,
  'folders.mailingLists.errors.maxRetriesDescription':
    'Превышены лимиты от {vendor}. Пожалуйста, подождите, а затем заново запустите рассылку.',
  'folders.mailingLists.errors.unexpectedDescription':
    'Переподключите почтовую интеграцию и запустите рассылку заново, письма с ошибками будут автоматически переотправлены. При повторном возникновении ошибки, пожалуйста, обратитесь в поддержку.',
  'folders.mailingLists.telegram': 'Telegram',

  'folders.messaging.title': 'Рассылка',
  'folders.messaging.message': 'Сообщение',
  'folders.messaging.personalization': 'Персонализация',
  'folders.messaging.start': 'Запуск',
  'folders.messaging.mailingListSaved': 'Рассылка сохранена',
  'folders.messaging.readOnly': 'Остановите рассылку для возможности редактирования',
  'folders.messaging.preview': 'Предпросмотр',
  'folders.messaging.email': 'Кому',
  'folders.messaging.removeMessage': 'Удалить сообщение?',
  'folders.messaging.removeAllMessages': 'Все последующие сообщения также будут удалены.',
  'folders.messaging.deleteRecipient': 'Удалить',
  'folders.messaging.confirmDelete': 'Удалить получателя?',
  'folders.messaging.changeStatusAfterLaunch': 'Изменить статус получателей\nпосле старта рассылки',
  'folders.messaging.dontChange': 'Не изменять',
  'folders.messgaing.unsubscribed': 'Отписан',
  'folders.messaging.mailingListLaunched': `Рассылка запущена для {count} {count, plural,
    one {получателя}
    few {получателей}
    many {получателей}
    other {получателей}
  }`,
  'folders.messaging.scheduling': 'Планирование',

  'folder.editing': 'Редактирование папки',
  'folder.edit': 'Редактировать',
  'folder.delete': 'Удалить папку',
  'folder.creation': 'Создание папки',
  'folder.cancel': 'Отменить',
  'folder.save': 'Сохранить',
  'folder.create': 'Создать',
  'folder.title': 'Название',
  'folder.description':
    'Укажите полное описание вакансии, чтобы улучшить качество поиска и рекомендаций',
  'folder.active': 'Открытая',
  'folder.closed': 'Архивная',
  'folder.status': 'Статус',
  'folder.recruiters': 'Рекрутеры',
  'folder.selectRecruiter': 'Выберите рекрутера',
  'folder.permissions': 'Доступ',
  'folder.privateFolderHint': `Доступна только создателю{hm, select, true {, рекрутерам и нанимающим менеджерам}
    false { и рекрутерам} other {}}, назначенным на папку`,
  'folder.privateFolder': 'Приватная папка',
  'folder.privateMessages': 'Приватные письма',
  'folder.privateTemplate': 'Приватный шаблон',
  'folder.publicFolder': 'Все пользователи вашей комании',
  'folder.selectUser': 'Выберите пользователя',
  'folder.hiringManagers': 'Нанимающие менеджеры',
  'folder.searchInFolder': 'Искать в папке',
  'folder.aiSearch': 'Искать новых кандидатов',
  'folder.inviteHiringManager': 'Пригласить нанимающего менеджера',
  'folder.hmNotAccepted': 'Приглашение еще не принято',
  'folder.currentStatuses': 'Используемые статусы',
  'folder.availableStatuses': 'Доступные статусы',
  'folder.folderDeleted': 'Папка {name} удалена',
  'folder.everyone': 'Все',
  'folder.candidatesDeleteConfirm': `Удалить {count, plural,
    one {выбранного кандидата}
    other {выбранных кандидатов}}?`,
  'folder.emptyFolderDeleteWarning': 'Папка будет безвозвратно удалена.',
  'folder.folderDeleteWarning': `<nowrap>Папка и {count, plural,
    one {находящийся в ней <b>кандидат</b>}
    few {находящиеся в ней <b># кандидата</b>}
    other {находящиеся в ней <b># кандидатов</b>}} будут удалены.</nowrap>`,
  'folder.candidatesDeleteWarning': `<nowrap>{count, plural,
    one {<b>Кандидат</b> будет удален из папки}
    few {<b># кандидата</b> будут удалены из папки}
    other {<b># кандидатов</b> будут удалены из папки}}.</nowrap>`,
  'folder.mailingDeleteWarning': `Ранее запланированная рассылка для {count, plural,
    one {кандидата}
    other {кандидатов}} будет отменена.`,
  'folder.saveMailingHistory': `Сохранить историю переписки в {count, plural, one {профиле кандидата} other {профилях кандидатов}}`,
  'folder.expand': 'раскрыть',
  'folder.hide': 'скрыть',
  'folder.editDescription': 'Редактировать',
  'folder.deleteConfirm': 'Удалить папку?',
  'folder.copyCandidatesTo': `{count, plural, one {# кандидат скопирован} few {# кандидата скопировано} other {# кандидатов скопировано}} в папку {folderName}`,
  'folder.moveCandidatesTo': `{count, plural, one {# кандидат перемещен} few {# кандидата перемещено} other {# кандидатов перемещено}} в папку {folderName}`,
  'folder.alreadyContained': `Выбранные кандидаты уже отобраны на папку {folderName}`,
  'folder.downloadContacts': 'Экспортировать в CSV',
  'folder.exportAvailable': `В папке находится {count} {count, plural, one {кандидат} few {кандидата} other {кандидатов}} со <b>скрытыми контактами.
    </b><br></br>При экспорте кандидатов контакты будут открыты автоматически и месячная квота уменьшится на {count}.`,
  'folder.exportIsNotAvailable': `В папке находится {count} {count, plural, one {кандидат} few {кандидата} other {кандидатов}} со <b>скрытыми контактами.
    </b><br></br>Вы не можете экспортировать кандидатов, т.к. в этом месяце у Вас осталось только {quota}
    {quota, plural,
      one {просмотр}
      few {просмотра}
      other {просмотров}} контактов.`,
  'folder.cancelExport': 'Отмена',
  'folder.exportHeader': 'Экспорт',
  'folder.selectFields': 'Поля',
  'folder.settings': 'Настройки',
  'folder.encoding': 'Кодировка',
  'folder.delimiter': 'Разделитель',
  'folder.excel': 'Совместимость с Excel',
  'folder.csvTitle': 'CSV',
  'folder.greenhouseTitle': 'Greenhouse',
  'folder.smartRecruitersTitle': 'SmartRecruiters',
  'folder.leverTitle': 'Lever',
  'folder.teamtailorTitle': 'Teamtailor',
  'folder.workableTitle': 'Workable',
  'folder.exportGreenHouse': 'Экспортировать в Greenhouse',
  'folder.exportLever': 'Экспортировать в Lever',
  'folder.exportSmartrecruiters': 'Экспортировать в SmartRecruiters',
  'folder.exportTeamtailor': 'Экспортировать в Teamtailor',
  'folder.exportWorkable': 'Экспортировать в Workable',
  'folder.chooseJobsGreenHouse': 'Выберите вакансию Greenhouse',
  'folder.chooseJobsSmartRecruiters': 'Выберите вакансию SmartRecruiters',
  'folder.chooseJobsLever': 'Выберите вакансию Lever',
  'folder.chooseJobsTeamtailor': 'Выберите вакансию Teamtailor',
  'folder.chooseJobsWorkable': 'Выберите вакансию Workable',
  'folder.asProspects': 'Без вакансии',
  'folder.someProfilesWontBeExported': `{count} {count, plural,
    one {Профиль}
    few {Профиля}
    other {Профилей}} без email не будут экспортированы в {vendor, select,
    workable {Workable}
    smartrecruiters {SmartRecruiters}
    lever {Lever}
    teamtailor {Teamtailor}
    greenhouse {Greenhouse}
    other {}}:`,
  'folder.exportAndMore': 'И еще {count}.',
  'folder.exportShowAll': 'Показать всех',
  'folder.exportSuccess': 'Профили будут экспортированы в ближайшее время',
  'folder.exportFailure': 'Профили не удалось экспортировать',
  'folder.aiError': 'Невозможно начать поиск',
  'folder.hiringManagersEdit': 'Редактировать',
  'folder.more': 'Ещё',
  'folder.archive': 'Архивировать',
  'folder.activate': 'Активировать',
  'folder.candidates': 'Кандидаты в папке',
  'folder.recommendations': 'Рекомендованные кандидаты',
  'folder.recommendationsInfo':
    'Добавляйте кандидатов в папку из рекомендаций AmazingHiring на основе описания вакансии и отобранных ранее профилей.',
  'folder.recommendationsButton': `Показать {count} {count, plural,
    one {кандидата}
    other {кандидатов}}`,
  'folder.recommendationsTitle': 'Рекомендованные кандидаты',
  'folder.recommendationsAddToFolder': 'Добавить в папку',
  'folder.recommendationsSelectProfiles': 'Выберите профили',
  'folder.recommendationsProfilesAdded': 'Профили добавлены в папку',

  'statuses.cantMove': 'Перемещение запрещено. В папке есть кандидаты с данным статусом.',
  'statuses.default': 'Статусы по умолчанию',
  'statuses.available': 'Доступные статусы',
  'statuses.defaultSubtitle': 'Базовый набор статусов для всех создаваемых папок.',
  'statuses.availableSubtitle': 'Набор статусов, доступный для использования в папках.',
  'statuses.addStatus': 'Добавить статус',
  'statuses.deleteConfirm': 'Удалить статус?',
  'statuses.cantDelete': 'Удаление запрещено. Статус используется в папках.',
  'statuses.onlyStatus': 'Это поле не может быть пустым',
  'statuses.cantChangeMixmax':
    'Редактирование запрещено. Статус используется для интеграции с Mixmax.',
  'statuses.cantDeleteMixmax': 'Удаление запрещено. Статус используется для интеграции с Mixmax.',
  'statuses.warningMixmax': 'Внимание! Статус необходим для интеграции с Mixmax.',
  'statuses.hint':
    'Для создания и редактирования статусов обратитесь к администратору вашей компании.',

  'profileTags.chooseTags': 'Выберите теги',
  'profileTags.manageTags': 'Редактировать теги',
  'profileTags.addTag': 'Добавить тег',
  'profileTags.save': 'Сохранить',
  'profileTags.cancel': 'Отменить',
  'profileTags.loading': 'Загрузка...',
  'profileTags.clearValue': 'Очистить значение',
  'profileTags.createTag': 'Создать тег «#{value}»',
  'profileTags.error':
    'Не удалось добавить теги. Попробуйте позднее ещё раз или обратитесь в службу поддержки',

  'messaging.emailAliases': 'Псевдонимы эл. почты',
  'messaging.renewEmailAliases': 'Перезапросить псевдонимы эл. почты',
  'messaging.reloadEmailAliases': 'Не удалось получить псевдонимы эл. почты. Попробовать ещё раз?',
  'messaging.mailing': 'Письма',
  'messaging.notAvailableForStart': 'Недоступно для старта',
  'messaging.willBeSentNow': 'будет отправлено сейчас',
  'messaging.writeMessage': 'Написать письмо',
  'messaging.connect': 'Подключить',
  'messaging.newMessage': 'Новое сообщение',
  'messaging.cancel': 'Отменить',
  'messaging.confirm': 'Подтвердить',
  'messaging.confirmDeleteMessage': 'Вы уверены, что хотите удалить сообщение?',
  'messaging.from': 'От:',
  'messaging.to': 'Кому:',
  'messaging.subject': 'Тема',
  'messaging.template': 'Шаблон',
  'messaging.send': 'Отправить',
  'messaging.sendTest': 'Отправить тестовое сообщение',
  'messaging.quotaWarning':
    'Отправка письма на этот емейл приведет к открытию контактов и списанию квоты.',
  'messaging.blank': 'Пустой',
  'messaging.errorWhileSending': 'Произошла ошибка',
  'messaging.messageSent': 'Сообщение отправлено',
  'messaging.usedVars': 'Используемые переменные',
  'messaging.fieldCantBeEmpty': 'Это поле не может быть пустым',
  'messaging.sent': 'Отправлено',
  'messaging.opened': 'Просмотрено',
  'messaging.replied': 'Отвечено',
  'messaging.pending': 'Отправляется',
  'messaging.draft': 'Черновик',
  'messaging.error': 'Ошибка',
  'messaging.planned': 'Запланировано',
  'messaging.activity': 'Активность',
  'messaging.message': 'Сообщение',
  'messaging.noContacts': `У данного профиля нет ни одного {messagingType, select,
      mailing {емейла}
      telegram {Telegram контакта}
      other {}
    }.`,
  'messaging.willLeadToOpenContacts': 'Это приведет к открытию контактов у данного профиля',
  'messaging.preview': 'Предпросмотр',
  'messaging.saveTemplate': 'Сохранить как шаблон',
  'messaging.emptyContact': 'Нет емейла',
  'messaging.addContact': 'Добавить емейл',
  'messaging.after': 'Через',
  'messaging.delayDays': `{days, plural,
      one {{daysType, select, business {рабочий день} other {день}}}
      few {{daysType, select, business {рабочих дня} other {дня}}}
      many {{daysType, select, business {рабочих дней} other {дней}}}
      other {{daysType, select, business {рабочих дней} other {дней}}}
    }`,
  'messaging.at': 'в',
  'messaging.hours': `{hours, plural,
    one {час}
    few {часа}
    many {часов}
    other {часов}
  }`,
  'messaging.mailingList': 'Рассылка',
  'messaging.sequence': 'Цепочка',
  'messaging.confirm.title': `
    Хотите сохранить {type, select, mass {рассылку} single {черновик} template {шаблон} changes {изменения} sequence {цепочку сообщений} other {}}?`,
  'messaging.confirm.description': 'Все изменения будут утрачены, если их не сохранить.',
  'messaging.confirm.save': 'Сохранить',
  'messaging.confirm.decline': 'Не сохранять',
  'messaging.saveHistory.description': `История переписки будет утрачена, если {type, select,
    mailing {удалить рассылку}
    recipient {удалить получателя}
    folder {удалить папку}
    move {переместить кандидата в другую папку}
    candidate {удалить кандидата из папки}
    candidates {удалить кандидатов из папки}
    other {}}.`,
  'messaging.myTemplates': 'Мои',
  'messaging.teamTemplates': 'Команды',
  'messaging.exampleTemplates': `{whiteLabel, select,
    matchy {Мэтчи}
    jointalent {Join Talent}
    amazinghiring {AmazingHiring}
    other {}}`,
  'messaging.personal': 'Личные',
  'messaging.corporate': 'Корпоративные',
  'messaging.draftSaved': 'Черновик сохранен',
  'messaging.delayDaysHelp': `Сообщение будет отправлено {days, selectordinal,
    =1 {на следующий {daysType, select, business {рабочий день} other {день}}}
    other {на #-й {daysType, select, business {рабочий день} other {день}}}
  } после отправки предыдущего письма, если на него не было ответа`,
  'messaging.messageStarted': 'Цепочка писем запущена',
  'messaging.changesSaved': 'Изменения сохранены',
  'messaging.start': 'Старт',
  'messaging.stopToEdit': 'Остановите цепочку сообщений для возможности редактирования',
  'messaging.mailingListStart':
    'Запустить {type, select, mass {рассылку} single {цепочку сообщений} other {}}',
  'messaging.numRecipients': `{count, plural,
    one {получатель}
    few {получателя}
    many {получателей}
    other {получателей}
  }`,
  'messaging.newRecipients': `{count, plural,
    one {новый}
    other {новые}
  }`,
  'messaging.errorRelaunchHelp':
    'Письма с ошибками будут отправлены после запуска {type, select, mass {рассылки} single {цепочки сообщений} other {}}',
  'messaging.newRecipientsHelp':
    'Письма будут отправлены после запуска {type, select, mass {рассылки} single {цепочки сообщений} other {}}',
  'messagign.overdueEmails': `просрочена отправка\nзапланированных писем`,
  'messaging.overdueEmailsHelp':
    'Письма будут отправлены после запуска {type, select, mass {рассылки} single {цепочки сообщений} other {}}',
  'messaging.relaunchMailing': 'возобновление отправки писем',
  'messaging.errorRelaunch': 'переотправка писем с ошибками',
  'messaging.noContact': 'без email ',
  'messaging.help': 'Письма не будут отправлены',
  'messaging.now': 'Сейчас',
  'messaging.plan': 'Запланировать',
  'messaging.planInfo': `{fromDay}{hasToDay, select, true { — {toDay}} false {} other {}},
    в\xa0{time}`,
  'messaging.date': 'Дата',
  'messaging.time': 'Время',
  'messaging.sender': 'Отправитель',
  'messaging.candidatesStatus': 'Статус кандидатов в папке',
  'messaging.dateAndTimeLaunching':
    'Дата и время запуска {type, select, mass {рассылки} single {цепочки сообщений} other {}}',
  'messaging.unsubscribed': 'Отписался от сообщений',
  'messaging.start.unsubscribed': `{count, plural,
    one {отписан}
    other {отписаны}
  }`,
  'messaging.error.inetgrationDisabled': 'Интеграция с почтовым клиентом отключена',
  'messaging.error.badRequest': 'Внутренняя ошибка',
  'messaging.error.unsubscribed': 'Отписан от рассылок',
  'messaging.error.maxRetries': 'Превышен лимит сообщений',
  'messaging.error.unexpected': 'Что-то пошло не так',
  'messaging.error.notAllAccessToMail': 'Проверьте, предоставлены ли все права доступа к почте.',
  'messaging.error.failedIntegrationConnect': 'Не удалось подключить интеграцию с ',
  'messaging.error.reconnect': 'Переподключить',
  'messaging.error.bounce': 'Почта не существует или не принимает сообщения',
  'messaging.error.previousHasError': 'Ошибка отправки предыдущего письма',
  'messaging.messaging': 'Сообщения',
  'messaging.advancedSettings': 'дополнительные настройки',
  'messaging.files.wrongType':
    'Мы принимаем DOC, DOCX, PDF, ODT, ODS, CSV, ZIP, JPEG, PNG, SVG, GIF файлы',
  'messaging.files.tooLarge': 'Размер файла больше {size}МБ. Выберите файл поменьше.',
  'messaging.files.massError': 'Некоторые файлы не были прикреплены.',
  'messaging.files.messageSizeError': 'Общий размер всех файлов не должен превышать {size}МБ.',
  'messaging.files.messagesTooLarge': 'В некоторых письмах есть ошибки.',
  'messaging.gptMessage': 'GPT письма с персонализацией',
  'messaging.generateMessages': 'Сгенерировать сообщения',
  'messaging.generatingMessageSuccess': 'Все сообщения сгенерированы',
  'messaging.generatingMessagesError': 'Не удалось сгенерировать сообщения',
  'messaging.gptDescription': 'Используйте возможности GPT для создания писем с персонализацией',
  'messaging.selectVacancy': 'Выберите вакансию',
  'messaging.createVacancy': 'Создать новую вакансию',
  'messaging.generatingMessage':
    'Генерируем {index, select, 1 {первое} 2 {второе} 3 {третье} other {}} сообщение…',

  'templates.title': 'Коммуникация / Шаблоны',
  'templates.deleteConfirm': 'Удалить шаблон?',
  'templates.create': 'Создать',
  'templates.name': 'Название',
  'templates.updated': 'Обновлён',
  'templates.created': 'Создан',
  'templates.newTemplate': 'Новый шаблон',
  'templates.editTemplate': 'Редактирование шаблона',
  'templates.save': 'Сохранить',
  'templates.cancel': 'Отменить',
  'templates.subject': 'Тема',
  'templates.copy': 'Копировать',
  'templates.delete': 'Удалить',
  'templates.noTemplates': 'Нет шаблонов по выбранным параметрам.',
  'templates.templateDeleted': 'Шаблон {name} удален',
  'templates.templateCreated': 'Шаблон {name} создан',
  'templates.templateChanged': 'Шаблон {name} изменен',
  'templates.createdBy': 'Создатель',
  'templates.recruiters': 'Рекрутеры',
  'templates.all': 'Все',
  'templates.copyName': 'копия',
  'templates.template': 'Шаблон',

  'footer.delete': 'Удалить',
  'footer.stop': 'Приостановить',
  'footer.manage': 'Управлять',

  'sentMessages.title': 'Коммуникация',
  'sentMessages.lastActivity': 'Последняя активность',
  'sentMessages.message': 'Сообщение',
  'sentMessages.to': 'Кому',
  'sentMessages.noMessages': 'Нет писем',

  'base.showMore': 'Показать все',
  'base.showLess': 'Показать меньше',

  'messageEditor.smallFont': 'Небольшой',
  'messageEditor.normalFont': 'Обычный',
  'messageEditor.largeFont': 'Крупный',
  'messageEditor.hugeFont': 'Огромный',
  'messageEditor.size': 'Размер',
  'messageEditor.bulletedList': 'Маркированный список',
  'messageEditor.numberedList': 'Нумерованный список',
  'messageEditor.bold': 'Полужирный',
  'messageEditor.italic': 'Курсив',
  'messageEditor.removeFormatting': 'Очистить форматирование',
  'messageEditor.variables': 'Переменные',
  'messageEditor.insertLink': 'Вставить ссылку',
  'messageEditor.visibleText': 'Отображаемый текст:',
  'messageEditor.link': 'Ссылка:',
  'messageEditor.insert': 'Вставить',
  'messageEditor.editLink': 'Изменить',
  'messageEditor.deleteLink': 'Удалить',
  'messageEditor.cancel': 'Отменить',
  'messageEditor.save': 'Сохранить',
  'messaging.messageStopConfirm': 'Остановить цепочку писем?',
  'messaging.messageDeleteConfirm':
    'Удалить {type, select, single {цепочку писем} mass {рассылку} other {}}?',
  'messaging.mailingListStopConfirm': 'Приостановить рассылку?',
  'messaging.mailingListDeleteConfirm': 'Удалить рассылку?',
  'messageEditor.addVariable': 'добавить переменную',
  'messageEditor.add': 'Создать',
  'messageEditor.newVariable': 'Создать переменную',
  'messageEditor.variableName': 'Название переменной',
  'messageEditor.attachFiles': 'Прикрепить файлы',
  'messageEditor.dropFiles': 'Поместите файлы сюда',
  'messageEditor.addImage': 'Добавить фото',
  'messageEditor.alignText': 'Выравнивание',

  'adminPanel.HMSettings': 'Начальные настройки нанимающего менеджера',
  'adminPanel.HM.contacts': 'Доступ к контактам',
  'adminPanel.HM.tags': 'Доступ к тегам',
  'adminPanel.HM.resumes': 'Доступ к приватным резюме',
  'adminPanel.HM.changeCandidate': 'Доступ к изменению статусов',
  'adminPanel.HM.comments': 'Доступ к комментариям внутри папки',
  'adminPanel.HM.personalInfo': 'Доступ к личным данным (фото, имя, ссылки)',
  'adminPanel.HM.save': 'Сохранить',
  'adminPanel.HM.cancel': 'Отменить',
  'adminPanel.resetFilters': 'Сбросить',

  'profileRealtimeDiff.beforeUpdate': 'До обновления',
  'profileRealtimeDiff.afterUpdate': 'После обновления',

  'linkedInResume.skills': 'Навыки',
  'linkedInResume.experience': 'Опыт работы',
  'linkedInResume.schools': 'Образование',
  'linkedInResume.birthВate': 'Дата рождения: ',

  'mailingListTypeFilter.all': 'Все',
  'mailingListTypeFilter.single': 'Единичные письма',
  'mailingListTypeFilter.mass': 'Рассылки',
  'mailingListTypeFilter.sequence': 'Цепочки',
  'mailingListTypeFilter.title': 'Тип',
  'messageStatusFilter.all': 'Все',
  'messageStatusFilter.opened': 'Просмотрено',
  'messageStatusFilter.sent': 'Отправлено',
  'messageStatusFilter.replied': 'Отвечено',
  'messageStatusFilter.draft': 'Черновик',
  'messageStatusFilter.pending': 'Запланировано',
  'messageStatusFilter.error': 'Ошибка',
  'messageStatusFilter.title': 'Статус сообщения',
  'messaging.settings.title': 'Коммуникация / Настройки',
  'messaging.settings.signature': 'Подпись',
  'messaging.settings.save': 'Сохранить',
  'messaging.settings.noSignature': 'Без подписи',
  'messaging.settings.saved': 'Изменения сохранены',
  'messaging.setting.unsubscribe': 'Не отправлять письма кандидатам, отписавшимся от рассылки',
  'messaging.setting.requestAdmin': 'Обратитесь к администратору для изменения этой настройки',
  'messaging.setting.unsubscribeTitle': 'Настройки отписки',
  'messaging.setting.unsubscribeWarning':
    'Если настройка включена, то к сообщениям будет прикрепляться ссылка на отписку',

  'messaging.setting.messageViewTrackingTitle': 'Отслеживание сообщений',
  'messaging.setting.messageViewTrackingLabel':
    'Отследить и показать, прочитано ли сообщение кандидатом',
  'messaging.setting.messageViewTrackingSubtitle':
    'Если функция отключена, статус «Просмотрено» в блоке «Рассылка» не отображается.',

  'profileEditor.h': 'Создание профиля',
  'profileEditor.editing': 'Редактирование профиля',
  'profileEditor.name': 'Имя',
  'profileEditor.add.phone': 'добавить телефон',
  'profileEditor.add.email': 'добавить почту',
  'profileEditor.add.skype': 'добавить Skype',
  'profileEditor.add.icq': 'добавить ICQ',
  'profileEditor.add.telegram': 'добавить Telegram',
  'profileEditor.add.link': 'добавить ссылку',
  'profileEditor.add.education': 'добавить образование',
  'profileEditor.add.course': 'добавить курс/сертификат',
  'profileEditor.add.skill': 'добавить навык',
  'profileEditor.add.location': 'добавить локацию',
  'profileEditor.add.position': 'добавить опыт работы',
  'profileEditor.add.name': 'сбросить на «{publicValue}»',
  'profileEditor.new.phone': 'новый телефон',
  'profileEditor.new.email': 'новая почта',
  'profileEditor.new.skype': 'новый Skype',
  'profileEditor.new.icq': 'новый ICQ',
  'profileEditor.new.telegram': 'новый Telegram',
  'profileEditor.new.link': 'новая ссылка',
  'profileEditor.new.education': 'новое образование',
  'profileEditor.new.course': 'новый курс/сертификат',
  'profileEditor.new.skill': 'новый навык',
  'profileEditor.new.location': 'новая локация',
  'profileEditor.new.position': 'новый опыт работы',
  'profileEditor.new.title': 'новая должность',
  'profileEditor.new.company': 'новая компания',
  'profileEditor.new.degree': 'новая степень',
  'profileEditor.new.name': 'новое название',
  'profileEditor.new.profileName': 'новое имя',
  'profileEditor.new.organization': 'новая организация',
  'profileEditor.title.profileName': 'Имя',
  'profileEditor.title.name': 'Название',
  'profileEditor.title.positions': 'Опыт работы',
  'profileEditor.title.skills': 'Навыки',
  'profileEditor.title.educations': 'Образование',
  'profileEditor.title.courses': 'Курсы/сертификаты',
  'profileEditor.title.locations': 'Локации',
  'profileEditor.title.contacts': 'Контакты',
  'profileEditor.title.period': 'Период',
  'profileEditor.title.title': 'Должность',
  'profileEditor.title.company': 'Компания',
  'profileEditor.title.degree': 'Степень',
  'profileEditor.title.date': 'Дата',
  'profileEditor.title.organization': 'Организация',
  'profileEditor.save': 'Сохранить',
  'profileEditor.cancel': 'Отменить',
  'profileEditor.confirmCancel': 'Вы уверены?',
  'profileEditor.confirmCancelContent': 'Все изменения будут утрачены, если их не сохранить.',
  'profileEditor.saved': 'Профиль сохранён',
  'profileEditor.error': 'Профиль не сохранён',
  'profileEditor.date.day': 'день',
  'profileEditor.date.dd': 'дд',
  'profileEditor.date.month': 'месяц',
  'profileEditor.date.mm': 'мм',
  'profileEditor.date.year': 'год',
  'profileEditor.date.yyyy': 'гггг',
  'profileEditor.file.import': 'Загрузить файл',
  'profileEditor.file.err': 'Мы принимаем DOC, DOCX, PDF, ODT, ODS, CSV, файлы',
  'profileEditor.file.uploading': 'Файл <filename>{name}</filename> обрабатывается...',
  'profileEditor.file.size': 'Размер файла больше 10МБ. Выберите файл поменьше.',
  'profileEditor.deleteTooltip': 'Удалить',
  'profileEditor.hideTooltip': 'Скрыть',
  'profileEditor.restoreTooltip': 'Показать',
  'profileEditor.editProfile': 'Редактировать профиль',
  'profileEditor.publicData': 'Публичные данные',
  'profileEditor.privateData': 'Приватные данные',

  'dialog.ok': 'OK',
  'dialog.delete': 'Удалить',
  'dialog.cancel': 'Отмена',
  'dialog.no': 'Нет',

  'aiSearch.mainSkills': 'Основные навыки',
  'aiSearch.additionalSkills': 'Дополнительные навыки',
  'aiSearch.next': 'Далее',
  'aiSearch.back': 'Назад',
  'aiSearch.find': 'Найти',
  'aiSearch.locationHelpText':
    'Используйте оператор OR, если хотите искать по нескольким локациям, и оператор NOT, чтобы исключить локации из поиска.',
  'aiSearch.profilesFound': `{number, plural,
    one {Найден <n></n> релевантный профиль}
    few {Найдено <n></n> релевантных профиля}
    many {Найдено <n></n> релевантных профилей}
    other {Найдено <n></n> релевантных профиля}
  }.`,
  'aiSearch.profilesProcessed': 'Отбираем наиболее подходящих кандидатов…',
  'aiSearch.updatingStrategies': 'Обновляем поисковую модель…',

  'aiRating.dislike': 'Не нравится',
  'aiRating.like': 'Нравится',
  'aiRating.withoutAdding': 'но не добавлять в папку',

  'userSettings.title': 'Настройки',
  'userSettings.firstName': 'Имя',
  'userSettings.lastName': 'Фамилия',
  'userSettings.email': 'Емейл',
  'userSettings.oldPassword': 'Старый пароль',
  'userSettings.password': 'Новый пароль',
  'userSettings.language': 'Язык',
  'userSettings.save': 'Сохранить',
  'userSettings.cancel': 'Отменить',
  'userSettings.ru': 'Русский',
  'userSettings.en': 'Английский',
  'userSettings.success': 'Настройки сохранены',
  'userSettings.km': 'Километры',
  'userSettings.mi': 'Мили',
  'userSettings.rangeMeasure': 'Единица измерения радиуса поиска',
  'userSettings.limitsExceeded':
    'Слишком много попыток изменить пароль. Пожалуйста, попробуйте позже.',

  'activeUsers.all': 'Все',
  'activeUsers.ext': 'Расширение',
  'activeUsers.search': 'Поиск',
  'activeUsers.title': 'Активные пользователи',
  'activeUsers.user': 'Пользователь',
  'activeUsers.company': 'Компания',
  'activeUsers.latestActivity': 'Последняя активность',
  'activeUsers.systemPart': 'Часть системы',
  'activeUser.empty': 'Нет активных пользователей',

  'companyForm.template': 'Шаблон',
  'companyForm.templatePlaceholder': 'Выберите шаблон',
  'companyForm.title': 'Новая компания',
  'companyForm.save': 'Сохранить',
  'companyForm.cancel': 'Отменить',
  'companyForm.name': 'Название',
  'companyForm.site': 'Сайт',
  'companyForm.active': 'Активна',
  'companyForm.accessType': 'Доступ',
  'companyForm.pay': 'Платный',
  'companyForm.demo': 'Демо',
  'companyForm.ext': 'Расширение',
  'companyForm.accountManager': 'Аккаунт Менеджер',
  'companyForm.accountManagerPlaceholder': 'Выберите Аккаунт Менеджера',
  'companyForm.industry': 'Индустрия',
  'companyForm.industryPlaceholder': 'Выберите индустрию',
  'companyForm.segment': 'Сегмент',
  'companyForm.segmentPlaceholder': 'Выберите сегмент',
  'companyForm.paidFrom': 'Доступ с',
  'companyForm.paidTill': 'Оплачено до',
  'companyForm.paidTillInfinite': 'Без ограничений',
  'companyForm.paidSum': 'Текущая сумма оплаты',
  'companyForm.maxWorkers': 'Число сотрудников с поиском',
  'companyForm.maxWorkersInfinie': 'Без ограничений',
  'companyForm.sendEmailAt': 'Время отправки письма об окончании демо-доступа',
  'companyForm.languageOptions': 'Доступные языки',
  'companyForm.en': 'en',
  'companyForm.ru': 'ru',
  'companyForm.tosLocation': 'Локация TOS',
  'companyForm.usa': 'Соединенные Штаты',
  'companyForm.rus': 'Россия',
  'companyForm.off': 'Выкл',
  'companyForm.genderFilter': 'Показывать фильтр по полу',
  'companyForm.diversityFilter': 'Показывать фильтр по многообразию',
  'companyForm.genderFilterHelp':
    'Видимость фильтра по полу в UI. Фильтр будет скрыт, если запрос делается по Европе.',
  'companyForm.diversityFilterHelp':
    'Видимость фильтра по многообразию в UI. Фильтр будет скрыт, если запрос делается по Европе.',
  'companyForm.maxOpeningContactsPerMonth':
    'Количество контактов доступных к показу для пользователя (в месяц)',
  'companyForm.totalOpeningContactsPerMonth':
    'Количество контактов доступных к показу для компании (в месяц)',
  'companyForm.search': 'Поиск',
  'companyForm.searchInEu': 'Поиск по EU',
  'companyForm.ageFilter': 'Показывать фильтр по возрасту',
  'companyForm.ageFilterHelp':
    'Видимость фильтра по возрасту в UI. Фильтр будет скрыт, если запрос делается по Европе. Также является индикатором показа возраста в краткой/полной карточке.',
  'companyForm.gdpr': 'GDPR',
  'companyForm.enableGDPRAge': 'Фильтр по возрасту',
  'companyForm.enableGDPRAgeHelp':
    'Доступность фильтра/отображение возраста в GDPR условиях (даже на запросах по Европе).',
  'companyForm.enableGDRPGender': 'Фильтр по полу',
  'companyForm.enableGDPRGenderHelp':
    'Доступность фильтра по полу в GDPR условиях (даже на запросах по Европе).',
  'companyForm.enableGDPRDiversity': 'Фильтр по многообразию',
  'companyForm.enableGDPRDiversityHelp':
    'Доступность фильтра по многообразию в GDPR условиях (даже на запросах по Европе).',
  'companyForm.integrations': 'Интеграции',
  'companyForm.addingPrivateData': 'Добавление приватных данных',
  'companyForm.exportCSV': 'Экспорт CSV',
  'companyForm.greenhouseAccess': 'Greenhouse',
  'companyForm.greenhouseImportAccess': 'Импорт профилей Greenhouse',
  'companyForm.smartrecruitersAccess': 'SmartRecruiters',
  'companyForm.smartrecruitersImportAccess': 'Импорт профилей SmartRecruiters',
  'companyForm.mixmaxAccess': 'Mixmax',
  'companyForm.zapierAccess': 'Zapier',
  'companyForm.leverAccess': 'Lever',
  'companyForm.teamtailorAccess': 'Teamtailor',
  'companyForm.workableAccess': 'Workable',
  'companyForm.gmateAccess': 'Getmatch',
  'companyForm.huntflowWebhook': 'Huntflow',
  'companyForm.huntflowApiToken': 'Huntflow API Token',
  'companyForm.apiAccess': 'Доступ к API',
  'companyForm.maxProfilesPerDay': 'Доступно профилей в день',
  'companyForm.sections': 'Разделы',
  'companyForm.messaging': 'Mailing',
  'companyForm.dataEnrichment': 'Data Enrichment',
  'companyForm.dataEnrichmentCredits': 'Квота Data Enrichment',
  'companyForm.hiringManagerAdding': 'Добавление Hiring Managers',
  'companyForm.hmMaxCount': 'Максимальное количество HM',
  'companyForm.hmMaxFolders': 'Количество папок на одного HM',
  'companyForm.hmAllowedDomains': 'Доступные для приглашения HM домены',
  'companyForm.ats': 'Функциональность ATS',
  'companyForm.atsRegion': 'Регион хранения файлов',
  'companyForm.atsRegionPlaceholder': 'Выберите регион хранения файлов',
  'companyForm.atsInformation': 'Информация по АТС',
  'companyForm.creditAccess': 'Доступ к кредитам',
  'companyForm.giftCreditsCount': 'Размер подарочного пакета',
  'companyForm.metaqueriesEnabled': 'AI запросы',
  'companyForm.showEvents': 'Секция "Просмотры"',
  'companyForm.example': 'Пример',
  'companyForm.nextWeek': 'Следующая неделя',
  'companyForm.nextYear': 'Следующий год',
  'companyForm.unlimited': 'безлимитный',
  'companyForm.employeeStats': `Уже добавлено: администраторов с поиском — {adminsWithSearch},
    администраторов без поиска — {adminsWithoutSearch}, сотрудников с поиском — {workersWithSearch},
    сотрудников без поиска — {workersWithoutSearch}.
  `,
  'companyForm.on': 'Вкл',
  'companyForm.locationRangeQuery': 'Показывать радиус поиска',
  'companyForm.publicStatistics': 'Общедоступная статистика',
  'companyForm.publicStatisticsHelp': 'У сотрудников есть доступ к статистике всей компании',
  'companyForm.privateEntities': 'Приватность по умолчанию',
  'companyForm.marketInsights': 'Аналитика рынка',
  'companyForm.mfaEnabled': 'MFA',
  'companyForm.productRegion': 'Регион компании',
  'companyForm.productRegionPlaceholder': 'Выберите регион компании',
  'companyForm.hubspotUrl': 'Ссылка на Hubspot',
  'companyForm.comment': 'Комментарий',
  'companyForm.mltr': 'Высокая вероятность отклика',
  'companyForm.commentDays': 'Содержит комментарий за ххх дней',
  'companyForm.closingContacts': 'Закрытие контактов',
  'companyForm.contactsWithoutSources': 'Контакты без источника',
  'companyForm.remoteFilter': 'Показать фильтр "Готов к удаленной работе"',
  'companyForm.sequences': 'Цепочки',
  'companyForm.linkedinFilter': 'Фильтр LinkedIn',
  'companyForm.gpt': 'GPT',
  'companyForm.huntflow': 'Импорт профилей Huntflow',
  'companyForm.model_monetization': 'Новая модель монетизации',
  'companyForm.is_enabled_quota_pricing_model': 'Новая модель монетизации',
  'companyForm.current_quota': 'Текущая квота',
  'companyForm.current_quota_empty': 'Нет активных квот',
  'companyForm.current_quota_manage': 'Управление квотами',
  'companyForm.current_quota_from_date': 'Дата начала',
  'companyForm.current_quota_id': 'ID',
  'companyForm.current_quota_is_active': 'Активность',
  'companyForm.current_quota_quota': 'Квота',
  'companyForm.current_quota_till_date': 'Дата окончания',
  'companyForm.current_quota_used_quota': 'Использовано',
  'companyForm.current_quota_error': 'Квоты не загрузились. Попробуйте позже',
  'companyForm.current_quota_add': 'Добавить квоту',
  'companyForm.current_quota_deactivate': 'Деактивировать квоту',
  'companyForm.current_quota_button_add': 'Добавить',
  'companyForm.current_quota_button_cancel': 'Отмена',

  'companies.title': 'Компании',
  'companies.all': 'Все',
  'companies.activity': 'Активность',
  'companies.active': 'Активные',
  'companies.addCompany': 'Добавить компанию',
  'companies.export': 'Экспортировать в CSV…',
  'companies.exportCompanies': 'Компании',
  'companies.exportClients': 'Клиентов',
  'companies.search': 'Поиск…',
  'companies.empty': 'Нет компаний по выбранным параметрам.',
  'companies.id': 'ID',
  'companies.company': 'Компания',
  'companies.website': 'Сайт',
  'companies.paidTill': 'Оплачено до',
  'companies.employees': 'Число сотрудников',
  'companies.accountManager': 'Аккаунт Менеджер',
  'companies.inactive': 'неактивна',
  'companies.noName': 'Нет названия',
  'companies.ssoInfo': 'Настройки SSO',

  'company.title': 'Компания',
  'company.sections': 'Разделы',
  'company.filters': 'Фильтры',
  'company.info': 'Общая информация',
  'company.employees': 'Сотрудники',
  'company.hiringManagers': 'Нанимающие менеджеры',
  'company.statistics': 'Статистика по сотрудникам',
  'company.apiStatistics': 'Статистика по API',

  'dateRangePicker.custom': 'Пользовательский период',
  'dateRangePicker.day': 'День',
  'dateRangePicker.week': 'Неделя',
  'dateRangePicker.month': 'Месяц',
  'dateRangePicker.year': 'Год',

  'company.info.edit': 'Редактировать',
  'company.info.delete': 'Удалить',
  'company.info.deleteConfirm': 'Удалить компанию {name}?',
  'company.info.upTo': 'До',
  'company.info.integrationWith': 'Интеграция с',
  'company.info.huntflowSecret': 'Webhook секрет',
  'company.info.huntflowAddress': 'Webhook адрес',
  'company.info.availableCredits': 'Количество кредитов',
  'company.info.availableQuota': 'Доступная квота на открытие контактов',
  'company.info.deCredits': 'Остаток кредитов Data Enrichment',
  'company.info.yes': 'Да',
  'company.info.no': 'Нет',
  'company.info.addCredits': 'Добавить/списать кредиты',
  'company.info.accessHistory': 'История доступов',
  'company.info.present': 'настоящее время',
  'company.info.publicStatistics': 'У сотрудников есть доступ к статистике всей компании',
  'company.info.privateEntities':
    'При создании новых папок, сообщений и шаблонов опция приватности будет выбрана по умолчанию',
  'company.info.customSettings': 'Кастомные настройки',
  'company.info.serviceAccountLogin': 'Войти в аккаунт сервис менеджера',
  'company.info.more': 'Еще',

  'company.customSettings.save': 'Сохранить',
  'company.customSettings.cancel': 'Отменить',

  'company.addCredits.title': 'Добавление/списание кредитов',
  'company.addCredits.deTitle': 'Добавление/списание кредитов Data Enrichment',
  'company.addCredits.availableCredits': 'Количество кредитов',
  'company.addCredits.add': 'Добавить/списать',
  'company.addCredits.hint': 'Для списания укажите отрицительное значение',
  'company.addCredits.apply': 'Применить',
  'company.addCredits.cancel': 'Отменить',

  'profilesViews.title': 'Просмотры профилей',
  'profilesViews.name': 'Имя',
  'profilesViews.date': 'Дата просмотра',

  'apiStats.dateRange': 'Период',
  'apiStats.noData': 'Нет данных за выбранный период',
  'apiStats.count': 'Количество запросов',
  'apiStats.path': 'Ресурс',

  'employeeForm.title': `Новый {role, select,
    employee {сотрудник}
    admin {администратор}
    hm {нанимающий менеджер}
    manager {менеджер}
    other {}
  }`,
  'employeeForm.firstName': 'Имя',
  'employeeForm.lastName': 'Фамилия',
  'employeeForm.email': 'Емейл',
  'employeeForm.password': 'Пароль',
  'employeeForm.language': 'Язык',
  'employeeForm.save': 'Сохранить',
  'employeeForm.cancel': 'Отменить',
  'employeeForm.ru': 'Русский',
  'employeeForm.en': 'Английский',
  'employeeForm.sendPassword': 'Выслать пароль по почте',
  'employeeForm.search': 'Поиск',
  'employeeForm.phone': 'Телефон',
  'employeeForm.additionalPhone': 'Дополнительный телефон',
  'employeeForm.ext': 'Доб.',
  'employeeForm.apiManager': 'Менеджер API',
  'employeeForm.availableFunctionality': 'Доступная функциональность',
  'employeeForm.defaultHMSections': 'Отличается от настроек компании',
  'employeeForm.apiKey': 'API ключ',
  'employeeForm.generateAPIKey': 'Сгенерировать API ключ',
  'employeeForm.rangeMeasure': 'Единица измерения радиуса поиска',
  'employeeForm.emailNotification': 'При изменении email пользователю придёт уведомление на почту',
  'employeeForm.comment': 'Комментарий',

  'employees.empty': 'Нет сотрудников',
  'employees.add': 'Добавить…',
  'employees.addEmployee': 'Сотрудника',
  'employees.addEmployeeFull': 'Добавить сотрудника',
  'employees.addAdmin': 'Администратора',
  'employees.invite': 'Пригласить…',
  'employees.inviteEmployees': 'Сотрудников',
  'employees.inviteEmployeesFull': 'Пригласить сотрудников',
  'employees.inviteAdmins': 'Администраторов',
  'employees.name': 'Имя',
  'employees.email': 'Емейл',
  'employees.role': 'Роль',
  'employees.search': 'Поиск',
  'employees.invitation': 'Приглашение',
  'employees.apiManager': 'Менеджер API',
  'employees.worker': 'Сотрудник',
  'employees.admin': 'Администратор',
  'employees.on': 'Вкл',
  'employees.off': 'Выкл',
  'employees.none': 'Нет',
  'employees.accepted': 'Принято',
  'employees.switchToAdmin': 'Сделать администратором',
  'employees.switchToEmployee': 'Сделать сотрудником',
  'employees.delete': 'Удалить',
  'employees.deleteConfirm': 'Удалить {name}?',
  'employees.moveToCompany': 'Перевести в компанию',
  'employees.loginAs': 'Войти как',
  'employees.deleted': 'Удаленный',
  'employees.resendInvite': 'Повторно отправить приглашение',
  'employees.inviteSent': 'Приглашение отправлено',
  'employees.phone': 'Телефон',
  'employees.searchLimit':
    'В компании уже {employeesWithSearch} {employeesWithSearch, plural, one {сотрудник} few {сотрудника} other {сотрудников}} с поиском, отключите поиск для другого сотрудника, чтобы включить для этого.',
  'employees.cantSwitchSearch':
    'Пожалуйста, обратитесь к вашему аккаунт менеджеру для изменения настроек поиска',
  'employees.all': 'Все',
  'employees.withSearch': 'C поиском',
  'employees.withoutSearch': 'Без поиска',
  'employees.active': 'Активный',
  'employees.notActive': 'Неактивный',
  'employees.dateRange': 'Период',
  'employees.userRole': 'Роль пользователя',
  'employees.status': 'Статус пользователя',
  'employees.integrations': 'Интеграции',
  'employees.folderType': 'Тип',
  'employees.sequence': 'Цепочка',
  'employees.mailingList': 'Рассылка',

  'employees.moveToCompany.title': 'Перевод в компанию',
  'employees.moveToCompany.save': 'Перевести',
  'employees.moveToCompany.cancel': 'Отменить',
  'employees.moveToCompany.name': 'ФИО',
  'employees.moveToCompany.selectCompany': 'Выберите компанию',
  'employees.moveToCompany.loading': 'Загрузка...',
  'employees.moveToCompany.noCompanies': 'Ничего не найдено',

  'hiringManagers.add': 'Добавить нанимающего менеджера',
  'hiringManagers.invite': 'Пригласить нанимающих менеджеров',
  'hiringManagers.defaultSettings': 'Начальные настройки нанимающего менеджера',
  'hiringManagers.empty': 'Нет нанимающих менеджеров',

  'bannedLinks.title': 'Забаненные ссылки',
  'bannedLinks.ban': 'Забанить ссылку',
  'bannedLinks.delete': 'Удалить',
  'bannedLinks.addLink': 'Вставьте ссылку для бана',

  'aiComment.title': 'Поделитесь вашим мнением',
  'aiComment.description':
    'Здесь можно оставить любые комментарии, замечания и предложения по "Умным папкам"',

  'bannedLinks.empty': 'Нет забаненных ссылок',

  'extensionNotifications.create': 'Создать уведомление',
  'extensionNotifications.notificationTitle': 'Уведомления',
  'extensionNotifications.form.title': 'Заголовок',
  'extensionNotifications.form.description': 'Описание',
  'extensionNotifications.form.text': 'Текст',
  'extensionNotifications.form.ru': 'Русский',
  'extensionNotifications.form.en': 'Английский',
  'extensionNotifications.form.sites': 'Отображается на сайтах',
  'extensionNotifications.form.versions': 'Отображается в версиях расширения',
  'extensionNotifications.form.hideVersions': 'Скрыто в версиях расширения',
  'extensionNotifications.form.displaySettings': 'Настройки отображения',
  'extensionNotifications.form.weight': 'Вес',
  'extensionNotifications.form.cancel': 'Отменить',
  'extensionNotifications.form.save': 'Сохранить',
  'extensionNotifications.form.required': 'Это поле обязательно',
  'extensionNotifications.form.descriptionError':
    'Описание должно быть либо пустым, либо заполненным для двух языков',
  'extensionNotifications.form.invalidValue': 'Введите корректное значение',
  'extensionNotifications.form.edit': 'Редактировать',
  'extensionNotifications.form.accessType': 'Отображается для компаний с доступом',
  'extensionNotifications.form.all': 'Все',
  'extensionNotifications.form.create': 'Создать',
  'extensionNotifications.form.sitesHelp': `Список доменов, разделенный запятыми. При пустом значении уведомление будет отображаться на всех сайтах.
    <br></br>Пример: github.com, linkedin.com`,
  'extensionNotifications.form.versionsHelp': `Список версий, разделенный запятыми. При пустом значении уведомление будет отображаться во всех версиях.
    <br></br>'c' в конце означает кастомную сборку (если расширение не из магазина). Пример: 12.1.27, 12.1.28c`,
  'extensionNotifications.form.hideVersionsHelp': `Список версий, разделенный запятыми. При пустом значении никакая версия не будет исключена.
    <br></br>'c' в конце означает кастомную сборку (если расширение не из магазина). Пример: 12.1.27, 12.1.28c`,
  'extensionNotifications.id': 'Id уведомления',
  'extensionNotifications.titleEn': 'Английский заголовок',
  'extensionNotifications.titleRu': 'Русский заголовок',
  'extensionNotifications.descriptionEn': 'Английское описание',
  'extensionNotifications.descriptionRu': 'Русское описание',
  'extensionNotifications.textEn': 'Английский текст',
  'extensionNotifications.textRu': 'Русский текст',
  'extensionNotifications.displayOnWebsites': 'Отображается на сайтах',
  'extensionNotifications.displayExtVersions': 'Отображается в версиях расширения',
  'extensionNotifications.hideExtVersions': 'Скрыто в версиях расширения',
  'extensionNotifications.weight': 'Вес',
  'extensionNotifications.edit': 'Редактировать',
  'extensionNotifications.delete': 'Удалить',
  'extensionNotifications.empty': 'Пока нет уведомлений.',
  'extensionNotifications.accessType': 'Отображается для компаний с доступом',

  'notificationCenter.maintenance.title': 'Технические работы на платформе AmazingHiring',
  'notificationCenter.learnMore': 'Узнать больше',
  'notificationCenter.hide': 'Скрыть',
  'notificationCenter.ok': 'ОК',

  'metaQueries.title': 'Метазапросы',
  'metaQueries.all': 'Все',
  'metaQueries.developers': 'Разработка ПО',
  'metaQueries.companies': 'Списки компаний',
  'metaQueries.add': 'Добавить Метазапрос',
  'metaQueries.newMetaQuery': 'Новый Метазапрос',
  'metaQueries.group': 'Группа',
  'metaQueries.nameEN': 'Название EN',
  'metaQueries.nameRU': 'Название RU',
  'metaQueries.nameENFull': 'Название на английском языке',
  'metaQueries.nameRUFull': 'Название на русском языке',
  'metaQueries.link': 'Ссылка',
  'metaQueries.enabled': 'Включен',
  'metaQueries.language': 'Язык',
  'metaQueries.edit': 'Редактировать',
  'metaQueries.enable': 'Включить',
  'metaQueries.disable': 'Выключить',
  'metaQueries.delete': 'Удалить',
  'metaQueries.deleteConfirm': 'Удалить {name}?',
  'metaQueries.ru': 'Русский',
  'metaQueries.en': 'Английский',
  'metaQueries.weight': 'Вес',
  'metaQueries.weightHelp': 'Чем ниже вес, тем выше будет запрос',
  'metaQueries.keyword': 'Ключевое слово',
  'metaQueries.url': 'URL',
  'metaQueries.save': 'Сохранить',
  'metaQueries.cancel': 'Отменить',

  'managers.title': 'Менеджеры',
  'managers.add': 'Добавить менеджера',
  'managers.empty': 'Нет менеджеров',

  'passwordValidator.length': 'Минимум 12 символов',
  'passwordValidator.lowercase': 'Минимум 1 символ нижнего регистра',
  'passwordValidator.uppercase': 'Минимум 1 символ верхнего регистра',
  'passwordValidator.digits': 'Минимум 1 цифра',
  'passwordValidator.special': 'Минимум 1 спецсимвол',

  'licenseExpired.title': 'Период действия вашей лицензии закончился',
  'licenseExpired.description':
    'Пожалуйста, свяжитесь с администратором вашей линцензии или <br></br>с нами по почте <mail></mail>.',
  'licenseExpired.logout': 'Выйти',

  'companyInfo.employeeCount': `{count, plural,
      one {сотрудник}
      few {сотрудника}
      other {сотрудников}
    } работает на данный момент`,
  'companyInfo.companiesFrom': 'Топ-5 компаний, из которых приходят',
  'companyInfo.companiesFor': 'Топ-5 компаний, куда уходят',
  'companyInfo.averagePeriod': 'Среднее время работы в компании',

  'licenseNotStarted.title': 'Период действия вашей лицензии еще не наступил',

  'statistics.title': 'Статистика',
  'statistics.search': 'Поиск',
  'statistics.ats': 'Воронка',
  'statistics.messaging': 'Письма',
  'statistics.sections': 'Разделы',
  'statistics.filters': 'Фильтры',
  'statistics.actions.settings': 'Настройки',
  'statistics.actions.csv': 'Экспорт в CSV',
  'statistcis.actions.users': 'Пользователи',
  'statistcis.actions.folders': 'Папки',
  'statistcis.actions.templates': 'Шаблоны',
  'statistics.charts.barChartTitle': 'Всего',
  'statistics.charts.byDays': 'По дням',
  'statistics.charts.byHours': 'По часам',
  'statistics.charts.byMonth': 'По месяцам',
  'statisctics.sections.search': 'Поиск',
  'statistics.sections.ats': 'Воронка',
  'statistics.sections.messaging': 'Письма',
  'statistics.settings.cancel': 'Отменить',
  'statistics.settings.save': 'Сохранить',
  'statistics.settings.ats': 'Статистика по воронке: настройки',
  'statistics.settings.messaging': 'Статистика писем: настройки',
  'statistics.settings.search': 'Статистика поиска: настройки',
  'statistics.settings.csv': 'CSV',
  'statistics.settings.table': 'Таблица',
  'statistics.settings.folders_table': 'Таблица',
  'statistics.settings.templates_table': 'Таблица по шаблонам',
  'statistics.settings.user_table': 'Таблица',
  'statistics.settings.chart': 'График',
  'statistics.charts.empty': 'Нет данных',
  'statistics.filters.user': 'Пользователь',
  'statistics.filters.template': 'Шаблон',
  'statistics.filters.folder': 'Папка',
  'statistics.filters.filtersChanged': 'Фильтры изменились',
  'statistics.filters.filterDisabled': 'Нет пользователей в текущей выборке с этим параметром',
  'statistics.filters.sequences': 'Цепочки',
  'statistics.search.exportedGreenhouse': 'Экспортировано в Greenhouse',
  'statistics.search.profilesViewed': 'Профилей просмотрено',
  'statistics.search.sourcedCandidated': 'Добавлено в папку',
  'statistics.search.exportedSmartrecruiters': 'Экспортировано в SmartRecruiters',
  'statistics.search.exportedLever': 'Экспортировано в Lever',
  'statistics.search.exportedTeamtailor': 'Экспортировано в Teamtailor',
  'statistics.search.exportedWorkable': 'Экспортировано в Workable',
  'statistics.search.extOpens': 'Открытий расширения',
  'statistics.search.username': 'Имя',
  'statistics.search.email': 'Почта',
  'statistics.search.enrichedCandidates': 'Обогащено кандидатов',
  'statistics.search.id': 'Id пользователя',
  'statistics.search.search': 'Поиск',
  'statistics.search.role': 'Роль',
  'statistics.search.dateJoined': 'Дата создания',
  'statistics.search.lastActivity': 'Последняя активность',
  'statistics.search.seacrh_queries': 'Поисковые запросы',
  'statistics.search.contactsOpened': 'Открыто контактов',
  'statistics.search.savedResumes': 'Сохраненных резюме',
  'statistics.search.insightsQueries': 'Запросы на аналитику',
  'statistics.messaging.byUser': 'Пользователи',
  'statistics.messaging.byFolder': 'Папки',
  'statistics.messaging.byTemplate': 'Шаблоны',
  'statistics.messaging.folder': 'Папка',
  'statistics.messaging.template': 'Шаблон',
  'statistics.messaging.replied': 'Отвечено',
  'statistics.messaging.sent': 'Отправлено',
  'statistics.messaging.contacted': 'Получатели',
  'statistics.messaging.opened': 'Открыто',
  'statistics.messaging.total': 'Всего',
  'statistics.messaging.pending': 'Ожидание',
  'statistics.messaging.id': 'Id папки',
  'statistics.messaging.email': 'Почта',
  'statistics.messaging.folderId': 'Id папки',
  'statistics.messaging.templateId': 'Id шаблона',
  'statistics.messaging.userId': 'Id пользователя',
  'statistics.messaging.user': 'Имя пользователя',
  'statistics.messaging.error': 'Ошибка',
  'statistics.messaging.dateJoined': 'Дата создания',
  'statistics.messaging.lastActivity': 'Последняя активность',
  'statistics.messaging.folderType': 'Тип',
  'statistics.ats.folder': 'Папка',
  'statistics.filters.apply': 'Применить',
  'statistics.filters.reset': 'Сбросить',
  'statistics.misc.empty': 'В текущей выборке нет кандидатов.',
  'statistics.misc.changeFilters': 'Попробуйте поменять значения <btn>фильтров</btn>',
  'statistics.misc.changeSettings': ' или статусы в <link>настройках</link>',
  'statistics.table.percent': 'Процент считаетстя относительно количества ',
  'statistics.table.atsPercent': 'кандидатов в папке',
  'statistics.table.messagingPercent': 'получателей писем',
  'statistics.table.admin': 'Администратор',
  'statistics.table.worker': 'Сотрудник',
  'statistics.table.deletedUser': 'Пользователь удален',
  'statistics.table.on': 'Вкл',
  'statistics.table.off': 'Выкл',
  'statistics.table.noActivity': 'Нет активности',
  'statistics.table.sequence': 'Цепочка',
  'statistics.table.mailingList': 'Рассылка',

  'recoverPassword.title': 'Забыли пароль?',
  'recoverPassword.description': `
    Введите адрес электронной почты, чтобы сбросить пароль. Возможно, вам понадобится
    проверить папку нежелательной почты или разблокировать адрес {whiteLabelNoReplyEmail}.
  `,
  'recoverPassword.submit': 'Отправить',
  'recoverPassword.email': 'Емейл',
  'recoverPassword.sent': 'Запрос на восстановление пароля отправлен',
  'recoverPassword.sentDescription': `
    Письмо с инструкциями по восстановлению пароля было отправлено на <email></email> (если этот емейл зарегистрирован).
    Ссылка будет действительна до <date></date>.
  `,

  'resetPassword.title': 'Забыли пароль?',
  'resetPassword.description':
    'Пожалуйста, введите новый пароль для своего <email></email> аккаунта.',
  'resetPassword.submit': 'Отправить',
  'resetPassword.newPassword': 'Новый пароль',
  'resetPassword.retypePassword': 'Повторите пароль',
  'resetPassword.error': 'Что-то пошло не так. Пожалуйста, попробуйте ещё раз позже.',

  'smartFolders.updated': 'Обновлена',
  'smartFolders.candidates': 'Кандидатов',
  'smartFolders.newFolder': 'Новая',
  'smartFolders.addToFolder': 'Добавить в папку',
  'smartFolders.addedToFolder': 'Добавлен',
  'smartFolders.history': 'История запросов',
  'smartFolders.openFolder': 'Открыть папку',
  'smartFolders.next': 'Далее',
  'smartFolders.showMore': 'показать другие',
  'smartFolders.searchCandidates': 'Искать кандидатов',
  'smartFolders.folderName': 'название папки',
  'smartFolders.settings': 'Настройки',
  'smartFolders.back': 'Назад',
  'smartFolders.continue': 'Продолжить',
  'smartFolders.selectDescription':
    'Выберите или создайте папку, в которую вы будете отбирать понравившихся кандидатов',
  'smartFolders.selectDescriptionV2': `
    Организуйте работу над вакансией с помощью папок. Возвращайтесь к заданному поиску,
    сохраняйте подходящих кандидатов и управляйте их статусом, делитесь данными с коллегами.
  `,
  'smartFolders.formDescription': 'Укажите критерии поиска кандидатов',
  'smartFolders.nextTooltip': `Показанные ранее профили исключаются из результатов поиска.<br></br>
      Это поведение можно изменить в блоке «Фильтры»`,
  'smartFolders.remove': 'Удалить',
  'smartFolders.selectProfiles': 'Выберите профили',
  'smartFolders.profilesAdded': 'Профили добавлены в папку',
  'smartFolders.viewedProfiles': `{count, plural,
    one {исключен <b>1 уже просмотренный</b> профиль}
    few {исключено <b>{viewedProfilesCount} уже просмотренных</b> профиля}
    many {исключено <b>{viewedProfilesCount} уже просмотренных</b> профилей}
    other {исключено <b>{viewedProfilesCount} уже просмотренных</b> профиля}
  } `,
  'smartFolders.folders': 'Папки для кандидатов',
  'smartFolders.addToThisFolder': 'Добавить в папку',
  'smartFolders.removeFromThisFolder': 'Удалить из папки',

  'atsTokenForm.title':
    'Подключить {vendor, select, lever {Lever} greenhouse {Greenhouse} greenhouse_import {Greenhouse Import} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} other {}}',
  'atsTokenForm.connect': 'Подключить',
  'atsTokenForm.cancel': 'Отменить',
  'atsTokenForm.email':
    'Почта пользователя {vendor, select, lever {Lever} greenhouse {Greenhouse} greenhouse_import {Greenhouse} smartrecruiters {SmartRecruiters} other {}}',
  'atsTokenForm.token':
    'API ключ {vendor, select, lever {Lever} greenhouse {Greenhouse} greenhouse_import {Greenhouse} smartrecruiters {SmartRecruiters} teamtailor {Teamtailor} other {}}',
  'atsTokenForm.hfUrl': 'Ссылка на Huntflow',
  'atsTokenForm.hfUrlHint':
    'Ссылка выглядит так https://huntflow.ru/token_request/ или так https://ats.company.ru/token_request/',
  'atsTokenForm.hfApi': 'Ссылка на Huntflow API',
  'atsTokenForm.hfApiHint': 'Если ваш Huntflow находится на ваших серверах, то включите эту опцию',
  'atsTokenForm.hfCustomLink': 'У меня Huntflow Premier',
  'atsTokenForm.hfCustomLinkHint':
    'Укажите ссылку на API Huntflow, развернутого на вашем сервере (https://api.ats.company.ru/ или https://huntflow-api.company.ru/)',
  'atsSubdomainForm.title': 'Подключить {vendor, select, workable {Workable} other {}}',
  'atsSubdomainForm.connect': 'Подключить',
  'atsSubdomainForm.cancel': 'Отменить',
  'atsSubdomainForm.subdomain': 'Поддомен в Workable',
  'atsSubdomainForm.invalidSubdomain': 'Некорректный поддомен',
  'atsSubdomainForm.tip': 'Введите название поддомена ({subdomain}) или полную ссылку ({link})',
  'atsHuntflowForm.title': 'Подключить Huntflow',

  'folders.settings.title': 'Настройка статусов',
  'folders.settings.description': 'Общая настройка для всех папок вашей компании.',

  'folders.privacyIcon.private':
    'Приватн{type, select, folder {ая} message {ое} messages {ые} template {ый} other {}}',
  'folders.privacyIcon.public':
    'Публичн{type, select, folder {ая} message {ое} messages {ые} template {ый} other {}}',
  'folders.privacyIcon.noAccess': `У вас нет доступа к {type, select, folder {этой папке} mailingList {этой рассылке} message {этому сообщению} template {этому шаблону} sequence {этой цепочке} other {}}`,

  'gmateResume.links': 'Ссылки',
  'gmateResume.contacts': 'Контакты',
  'gmateResume.experience': 'Опыт работы',
  'gmateResume.education': 'Образование',
  'gmateResume.telegram': 'Telegram',
  'gmateResume.email': 'Имейл',
  'gmateResume.phone': 'Телефон',
  'gmateResum.summary': 'Дополнительно',
  'gmateResume.candidate': 'О кандидате',
  'gmateResume.salary': 'Зарплатные ожидания',
  'gmateResume.screening': 'Результаты скрининга',
  'gmateResume.skills': 'Основные навыки',

  'diversity.save': 'Сохранить',
  'diversity.saved': 'Сохранён',
  'diversity.enter': 'Enter',
  'diversity.unknown': 'неизвестно',

  'limitExceeded.title': 'Вы превысили лимит запросов в сутки',
  'limitExceeded.description':
    'Доступ будет возобновлен завтра в 00:00. В случае возникновения вопросов, свяжитесь с менеджерами {{name}}:',
  'limitExceeded.email': 'Емейл',
  'limitExceeded.phone': 'Телефон',

  'ats.userSelect.select': 'Выбрать',
  'ats.userSelect.selectUser': 'Выберите пользователя',
  'ats.userSelect.description':
    'Пожалуйста, выберите пользователя {vendor, select, lever {Lever} other {}}',

  'menu.settings': 'Настройки',
  'menu.logout': 'Выйти',
  'menu.statistics': 'Статистика',
  'menu.description': `
    Этот аккаунт компании <company></company> без поискового функционала.<br></br>
    Для доступа к полной версии, пожалуйста, обратитесь к администратору вашей компании или к нашей команде на почту <a></a>.
  `,

  'marketInsights.intelligence': 'Аналитика',
  'marketInsights.profiles': 'Профили',
  'marketInsights.more': 'больше',
  'marketInsights.less': 'меньше',
  'marketInsights.excludedHint': 'Значение исключено',
  'marketInsights.notIncludedHint': 'Значение не выбрано',
  'marketInsights.showProfiles': `Показать <b></b> {count, plural,
    one {профиль}
    few {профиля}
    many {профилей}
    other {профиля}
  }`,
  'marketInsights.experience': 'Сколько лет технического опыта у выбранных кандидатов?',
  'marketInsights.onLastPosition': 'Как долго работают кандидаты в текущей компании?',
  'marketInsights.seniorityLevel': 'Какой уровень позиции у этих кандидатов?',
  'marketInsights.companySize':
    'В компаниях какого размера предпочитают работать выбранные кандидаты?',
  'marketInsights.location': 'Как территориально распределены эти кандидаты?',
  'marketInsights.company': 'В каких компаниях предпочитают работать кандидаты?',
  'marketInsights.skills': 'Какими навыками владеют кандидаты?',
  'marketInsights.skillsTitle': 'Навыки',
  'marketInsights.industry': 'В каких сферах работают кандидаты?',
  'marketInsights.exactLastPosition': 'Какие позиции занимают кандидаты?',
  'marketInsights.education': 'Какие учебные заведения заканчивают кандидаты?',
  'marketInsights.gender': 'Каково гендерное распределение у этих кандидатов?',
  'marketInsights.diversity': 'Насколько многообразно распределены эти кандидаты?',
  'marketInsights.notSpecified': 'Не указан',
  'marketInsights.female': 'Женщины',
  'marketInsights.other': 'Остальные',

  'marketInsights.pdf.download': 'Скачать отчет по аналитике',
  'marketInsights.pdf.marketInsights': 'Аналитика рынка',
  'marketInsights.pdf.query': 'поисковому запросу',
  'marketInsights.pdf.page': 'Страница',
  'marketInsights.pdf.createdOn': 'Создано',
  'marketInsights.pdf.basedOn': 'по',
  'marketInsights.pdf.selected': 'выбрано',
  'marketInsights.pdf.notSelected': 'не выбрано',
  'marketInsights.pdf.excluded': 'исключено',

  'contactFinder.requestContacts': 'Запросить контакты',
  'contactFinder.requesting': 'Запрашивается',
  'contactFinder.tooltip': 'Вы можете запросить емейл и телефон для профиля',
  'contactFinder.error': 'Извините, нам не удалось найти контакты',
  'contactFinder.publicContactsOpened': `{opened, plural,
    one {контакт открыт}
    few {контакта открыто}
    many {контактов открыто}
    other {контакта открыто}
  }`,

  'systemNotifications.notificationTitle': 'Уведомления',
  'systemNotifications.create': 'Создать уведомление',
  'systemNotifications.empty': 'Нет уведомлений',
  'systemNotifications.all': 'Все',
  'systemNotifications.active': 'Активные',
  'systemNotifications.disabled': 'Неактивные',
  'systemNotifications.form.title': 'Заголовок',
  'systemNotifications.form.content': 'Содержание',
  'systemNotifications.form.short': 'Сокращенный',
  'systemNotifications.form.full': 'Полный',
  'systemNotifications.form.showFormat': 'Формат показа',
  'systemNotifications.form.editNotification': 'Редактировать уведомление',
  'systemNotifications.form.create': 'Создать уведомление',
  'systemNotifications.form.cancel': 'Отменить',
  'systemNotifications.form.save': 'Сохранить',
  'systemNotifications.form.type': 'Тип',
  'systemNotifications.form.buttonText': 'Текст кнопки',
  'systemNotifications.form.buttonLink': 'Ссылка кнопки',
  'systemNotifications.form.startAt': 'Дата начала',
  'systemNotifications.form.stopAt': 'Дата окончания',
  'systemNotifications.form.active': 'Активное',
  'systemNotifications.form.example': 'Пример:',
  'systemNotifications.form.settings': 'Настройки',
  'systemNotifications.form.role': 'Роль пользователя',
  'systemNotifications.form.worker': 'Сотрудник',
  'systemNotifications.form.admin': 'Администратор',
  'systemNotifications.form.all': 'Все',
  'systemNotifications.form.enabled': 'Включен',
  'systemNotifications.form.disabled': 'Выключен',
  'systemNotification.form.canSearch': 'Поиск',
  'systemNotification.form.companyId': 'Id компаний',
  'systemNotifications.form.ru': 'Россия',
  'systemNotifications.form.other': 'Остальной мир',
  'systemNotifications.form.edit': 'Редактировать',
  'systemNotifications.form.disable': 'Отключить',
  'systemNotifications.form.emojiHelper': 'Шпаргалка для emoji',
  'systemNotifications.form.preview': 'Предпросмотр',
  'systemNotifications.form.moscowTime': 'Московское время',
  'systemNotifications.form.clone': 'Клонировать',
  'systemNotifications.form.companyIdsHint': 'Id через запятую. Пример: 3,4,820',
  'systemNotifications.form.companySettingsHint': 'Компании выбраны по Id',

  'sequences.title': 'Цепочки',
  'sequences.create': 'Создать новую цепочку',
  'sequences.start': 'Начать',
  'sequences.emptyMessage':
    'Создайте цепочку сообщений, чтобы привлечь больше потенциальных кандидатов',
  'sequences.noCreated': 'Вы ещё не создали цепочку',
  'sequences.sequenceName': 'Название цепочки',
  'sequences.sequenceCopy': 'копия',
  'sequences.selectTemplate': 'Шаблон',
  'sequences.saveTemplate': 'Сохранить как шаблон',
  'sequences.blankTemplate': 'Пустой',
  'sequences.variables': 'Переменные',
  'sequences.addVariable': 'Добавить переменную',
  'sequences.sendTest': 'Отправить тестовое сообщение',
  'sequences.addStep': 'Добавить шаг',
  'sequences.step': 'Шаг',
  'sequences.sendMessage': 'Отправить сообщение',
  'sequences.in': 'через',
  'sequences.now': 'Сейчас',
  'sequences.nextDay': 'На ближайший рабочий день',
  'sequences.save': 'Сохранить цепочку',
  'sequences.cancel': 'Отменить',
  'sequences.sequenceSaved': 'Цепочка сообщений сохранена',
  'sequences.sequence': 'Цепочка',
  'sequences.to': 'Кому',
  'sequences.test': 'Отправить тестовое сообщение',
  'sequences.testSent': 'Тестовое сообщение отправлено',
  'sequences.connect': 'Подключить почтовую интеграцию и добавить',
  'sequences.send': 'Добавить в цепочку',
  'sequences.tableName': 'Название',
  'sequences.tableRecipients': 'Получатели',
  'sequences.tableSent': 'Отправлено',
  'sequences.tableOpened': 'Открыли',
  'sequences.tableReplied': 'Ответили',
  'sequences.tableFailed': 'С ошибкой',
  'sequences.tableAuthor': 'Автор',
  'sequences.tableCreated': 'Дата создания',
  'sequences.tableUpdated': 'Дата обновления',
  'sequences.tooltipEdit': 'Редактировать',
  'sequences.tooltipCopy': 'Копировать',
  'sequences.tooltipDelete': 'Удалить',
  'sequences.deleteItem': 'Удалить цепочку?',
  'sequences.deletedItem': 'Цепочка удалена',
  'sequences.emptySequenceDeleteWarning': 'Цепочка будет удалена',
  'sequences.sequenceDeleteWarning': 'Ранее запланированная отправка будет отменена',
  'sequences.recipientsDeleteWarning': `<nowrap>{count, plural,
    one {<b>получатель</b> будет удален из цепочки}
    few {<b># получателя</b> будут удалены из цепочки}
    other {<b># получателей</b> будут удалены из папки}}.</nowrap>`,
  'sequences.recipientsDeleteConfirm': `Удалить {count, plural,
    one {выбранного получателя}
    other {выбранных получателей}}?`,
  'sequences.removeRecipients': 'Удалить',
  'sequences.exportRecipients': 'Экспортировать',
  'sequences.recipientsTableHeaderActivity': 'Последняя активность',
  'sequences.recipientsTableHeaderProfiles': `{chosenCandidatesCount, plural,
      one {Выбран #}
      other {Выбрано #}} из {profilesCount, plural,
        one {# профиля}
        other {# профилей}
      }`,
  'sequences.recipientsTableHeaderTo': 'Кому',
  'sequences.recipientsTableHeaderSource': 'Источник',
  'sequences.recipientsTableHeaderMessagesSent': 'Отправлено сообщений',
  'sequences.recipientsTableNoData': 'Нет получателей',
  'sequences.recipientsStatsTitle': 'Получатели',
  'sequences.willBeSent': 'Будет отправлено',
  'sequences.at': 'в',
  'sequences.immediately': 'сразу',
  'sequences.recipientsResendText': `Не удалось отправить сообщения {count, plural,
      one {получателю}
      few {получателям}
      other {получателям}
     }. Вы можете удалить этих получателей или попробовать повторно отправить сообщения.`,
  'sequences.recipientsResendButton': 'Повторно отправить сообщения',
  'sequences.recipientsResendError': 'Не удалось отправить',
  'sequences.customDataError': 'Пожалуйста, заполните все значения переменных',
  'sequences.meetSequences': 'Встречайте Цепочки!',
  'sequences.description': `
    Писать пассивным кандидатам стало еще проще!
    <a>Создавайте цепочки писем</a> в вашем аккаунте и связывайтесь с кандидатами,
    где бы вы ни были, через наше расширение.`,
  'sequences.helpcenter': '<ru>Помощь</ru>',
  'sequences.relaunchFailedMessagesSuccess': `{count} {count, plural,
  one {сообщение отправлено}
  few {сообщения отправлены}
  other {сообщений отправлены}} снова`,
  'sequences.connectMailing': 'Пожалуйста, подключите почтовую интеграцию',

  'gpt.generate': 'Сгенерировать',
  'gpt.cancel': 'Отменить',
  'gpt.deleteVacancy': 'Удалить',
  'gpt.vacancy': 'Вакансия',
  'gpt.position': 'Название позиции',
  'gpt.positionPlaceholder': 'Укажите должность',
  'gpt.companyName': 'Название компании',
  'gpt.companyNamePlaceholder': 'Как называется ваша компания',
  'gpt.responsibilities': 'Обязанности',
  'gpt.responsibilitiesPlaceholder': 'Чем предстоит заниматься кандидату',
  'gpt.requirements': 'Требования',
  'gpt.requirementsPlaceholder': 'Навыки и опыт, необходимые для этой позиции',
  'gpt.additionalInfo': 'Дополнительная информация',
  'gpt.additionalInfoPlaceholder': 'Любая информация о вакансии, которая может потребоваться',
  'gpt.language': 'Язык сообщений',

  'pageNotFound.description': 'Запрошенная страница не найдена',
  'pageNotFound.helpLink': '<a>Помощь</a>',

  'updateContacts.buttonText': 'Обновить контакты',

  'telegramManager.manager': 'Менеджер sim-карт',
  'telegramManager.addSimCard': 'Добавить sim-карту',
  'telegramManager.addSimCardSuccess': 'Sim-карта успешно добавлена',
  'telegramManager.code': 'Введите код из сообщения',
  'telegramManager.done': 'Готово',
  'telegramManager.number': 'Введите номер',
  'telegramManager.next': 'Далее',
  'telegramManager.phone': 'Номер',
  'telegramManager.addedBy': 'ФИО добавившего',
  'telegramManager.dateAdded': 'Дата добавления',
  'telegramManager.messagesSent': 'Отправлено сообщений',
  'telegramManager.messagesAvailable': 'Доступно сообщений',
  'telegramManager.viewAllChats': 'Посмотреть все чаты этой sim-карты',
  'telegramManager.noAdded': 'Не добавлено ни одной сим карты',
};
