import { DragEvent, FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import { useDnD } from 'hooks';
import messages from 'components/Messaging/Message/Editor/messages';
import styles from './DndWrapper.scss';

const theme = thematize(styles);

interface DndWrapperProps {
  children: ReactElement;
  onFileDrop?: (e: DragEvent<HTMLElement>) => void;
}

const DndWrapper: FC<DndWrapperProps> = ({ onFileDrop, children }) => {
  const { isDragging, dndHandlers } = useDnD(onFileDrop || noop);

  return onFileDrop ? (
    <div className={theme('container')} {...dndHandlers}>
      {children}
      {isDragging && (
        <>
          <div className={theme('background')} />
          <div className={theme('zone')}>
            <FormattedMessage {...messages.dropFiles} />
          </div>
        </>
      )}
    </div>
  ) : (
    children
  );
};

export default DndWrapper;
