import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { every, get, isEmpty, some } from 'lodash';
import moment from 'moment';

import { initializeAmplitude } from 'tracking/lib';
import { whiteLabelName } from 'lib/whitelabel';
import { useDispatch, useUser } from 'hooks';
import { getCurrentUser } from 'slices/app';
import DialogModal from 'components/DialogModal';
import Layout from 'components/Layout';
import MessagingModals from 'components/Messaging/Modals';
import NotificationCenter from 'components/NotificationCenter';
import SideMenu from 'components/SideMenu';
import Snackbar from 'components/Snackbar';
import Modal from '../Modal';
import SystemMessages from '../SystemMessages';
import Wootric from './Wootric';
import styles from './App.scss';

interface Props {
  children: ReactElement;
}

const isProduction = process.env.NODE_ENV === 'production';
const noSideMenuPaths = [
  /\/login/,
  /\/registration/,
  /\/need-activation\//,
  /\/need-verification\//,
  /\/invitations\//,
  /\/oauth/,
  /\/license-expired/,
  /\/license-not-started/,
  /\/users\/activate/,
  /\/users\/recover/,
  /\/users\/reset/,
  /\/limit-exceeded/,
  /\/lever\/set/,
  /\/apps_extension/,
  /\/messaging\/sequences\/\d+\/profile\/\d+/,
  /\/profiles\/\d+\/links\/[a-f0-9]+\/?/,
  // TODO: next path is a fallback for old proxy links URLs. remove it after 01.05.2024
  /\/l\/\d+\/[a-f0-9]+\/?/,
];
const noUserPaths = [
  /\/login/,
  /\/registration/,
  /\/invitations\//,
  /\/oauth/,
  /\/users\/recover/,
  /\/users\/reset/,
  /\/limit-exceeded/,
];

const App = ({ children }: Props): ReactElement => {
  const user = useUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const isSideMenuHidden = some(noSideMenuPaths, (path) => path.test(location.pathname));
  const isUserNecessary = every(noUserPaths, (path) => !path.test(location.pathname));

  useEffect(() => {
    if (isUserNecessary && isEmpty(user)) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, isUserNecessary, user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      moment.locale(user.settings.language);
      if (isProduction) {
        Sentry.configureScope((scope) => {
          scope.setExtra('User', user);
        });
      }
    }
    initializeAmplitude(user);
  }, [user]);

  return (
    <div className={`${styles.container} ${whiteLabelName === 'matchy' ? 'matchy' : ''}`}>
      {(!isEmpty(user) || !isUserNecessary) && (
        <>
          <SystemMessages />
          <Modal />
          <Layout side={isSideMenuHidden ? null : <SideMenu />}>{children}</Layout>
          <MessagingModals />
          <DialogModal />
          <Snackbar />
          <NotificationCenter />
        </>
      )}
      {!isEmpty(user) && isProduction && get(user, 'company.accessType') === 'pay' && (
        <Wootric user={user} />
      )}
    </div>
  );
};

export default App;
