import { FC, MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ContentState, Entity } from 'draft-js';
import { Map } from 'immutable';
import { get, isBoolean, noop } from 'lodash';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import { addRootEventListener, removeRootEventListener } from 'lib/utils';
import { useSelector } from 'hooks';
import useDispatch from 'hooks/useDispatch';
import { changeField } from 'slices/searchForm';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import { enrichEntitiesData, getCurrentTextSuggestion } from 'components/QueryEditor/lib';
import { LocationRangeAction } from 'components/TokenAppliedTerm/LocationRangeAction';
import { SkillRangeAction } from 'components/TokenAppliedTerm/SkillRangeAction';
import messages from './messages';
import styles from './TokenAppliedTerm.scss';

const theme = thematize(styles);

interface TokenAppliedTermProps {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
}

const TokenAppliedTerm: FC<TokenAppliedTermProps> = ({ contentState, entityKey, children }) => {
  const queries = useSelector(({ searchForm }) => searchForm.queries);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const entity = contentState.getEntity(entityKey);
  const {
    field,
    text,
    isExcluded: isExcludedProp,
    isReadOnly,
    isMainSkill,
    isKnown: isKnownEntity,
  } = entity.getData();

  const isLocationRange = field.get('type') === 'locationRange';
  const isLocation = field.get('type') === 'location';
  const isSkillAll = field.get('type') === 'skillAll';
  const query = queries.find((query) => query?.field.get('type') === field.get('type'));
  const queryKey = queries.findIndex((query) => query?.field.get('type') === field.get('type'));
  const enrichEntitiesLocationsLength =
    (isLocationRange || isLocation) && queryKey >= 0
      ? enrichEntitiesData(query.editorState).filter(({ type }) => type === 'APPLIED_TERM').length
      : 0;
  const isExcludable = field.get('type') !== 'preferableSkill';
  const [open, setOpen] = useState(false);
  const [isExcluded, setExcluded] = useState<boolean>(isExcludedProp);
  const blockData = contentState.getFirstBlock().getData();
  const forceIsKnown = blockData.get('forceIsKnown');
  const suggestions = blockData.get('suggestions') || Map();
  const focused = blockData.get('focused');
  const suggestion = getCurrentTextSuggestion(suggestions, text, field.get('suggest'));
  const isKnown = get(suggestion, 'isKnown') || forceIsKnown || isKnownEntity;
  const isCity = !!get(suggestion, 'isCity');
  const hasError =
    field.get('hasError') &&
    (text.toUpperCase().trim() === 'AND' || text.toUpperCase().trim() === 'OR');

  const handleClick = useCallback(
    (isOpen: KeyboardEvent | boolean = false): void => {
      const opened = isBoolean(isOpen) ? !isOpen : open;
      const isChanged = opened !== !open;

      if (isChanged) {
        const onSynonymsToggle =
          contentState.getFirstBlock().getData().get('onSynonymsToggle') || noop;

        if (!opened) {
          tracking.openSynonyms(tracking.LOCATIONS.search);
        }
        setOpen((open) => !open);
        onSynonymsToggle(entityKey);
      }
    },
    [open, contentState, entityKey]
  );

  useEffect(() => {
    if (open) {
      addRootEventListener('keydown', handleClick);
    } else {
      removeRootEventListener('keydown', handleClick);
    }

    return () => {
      removeRootEventListener('keydown', handleClick);
    };
  }, [open, handleClick]);

  useEffect(() => {
    // Фикс при удалении из поля
    setExcluded(isExcludedProp);
  }, [isExcludedProp]);

  useEffect(() => {
    if (enrichEntitiesLocationsLength > 1 && isLocationRange) {
      dispatch(
        changeField({
          queryKey,
          excluded: false,
          field: field.set('type', 'location').set('locationRange', 0).toJS(),
          isV2: true,
        })
      );
    }
  }, [enrichEntitiesLocationsLength, queryKey, field, isLocationRange]);

  const handleExcludedClick = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.preventDefault();
      if (isExcludable && !isReadOnly) {
        setExcluded((isExcluded) => {
          const toggleExcluded =
            contentState.getFirstBlock().getData().get('toggleExcluded') || noop;

          Entity.mergeData(entityKey, { isExcluded: !isExcluded });
          toggleExcluded();

          if (isExcluded) {
            tracking.includeTerm(tracking.LOCATIONS.search);
          } else {
            tracking.excludeTerm(tracking.LOCATIONS.search);
          }

          return !isExcluded;
        });
      }
    },
    [entityKey, isExcludable, contentState]
  );

  const handleDelete = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.preventDefault();
      const deleteTerm = contentState.getFirstBlock().getData().get('deleteTerm') || noop;

      deleteTerm(entityKey);
      tracking.deleteTerm(tracking.LOCATIONS.search);
    },
    [contentState, entityKey]
  );

  const renderActions = (): ReactElement => (
    <span className={theme('actions')}>
      {isCity && enrichEntitiesLocationsLength === 1 && !isExcludedProp && (
        <LocationRangeAction field={field} queryKey={queryKey} />
      )}
      {isSkillAll && isMainSkill && (
        <SkillRangeAction field={field} contentState={contentState} entityKey={entityKey} />
      )}
      {isExcludable && !isLocationRange && (
        <Tooltip
          placement="top"
          id="excludeTerm"
          message={formatMessage(messages[isExcluded ? 'include' : 'exclude'])}
        >
          <span className={theme('actions__icon-container')}>
            <Icon
              className={theme('actions__icon', {
                small: true,
                with_margin: isCity,
                ban: !isExcluded,
              })}
              type={isExcluded ? 'plus' : 'ban'}
              onMouseDown={handleExcludedClick}
            />
          </span>
        </Tooltip>
      )}
      <Tooltip placement="top" id="removeTerm" message={formatMessage(messages.remove)}>
        <span className={theme('actions__icon-container')}>
          <Icon
            className={theme('actions__icon', { with_margin: true })}
            type="cross"
            onMouseDown={handleDelete}
          />
        </span>
      </Tooltip>
    </span>
  );

  const isCollocation = text.startsWith('"');

  return (
    <span id={entityKey} className={theme('name', { focused, isCollocation })}>
      <span
        className={theme('name__content', {
          known: isKnown,
          excluded: isExcluded,
          error: hasError,
        })}
      >
        {isCollocation ? <span className={theme('term')}>{children}</span> : children}
        {renderActions()}
      </span>
    </span>
  );
};

export default TokenAppliedTerm;
