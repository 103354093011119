import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Set } from 'immutable';
import { filter, flow, get, isEmpty, isNumber, mapValues, noop, omit, size } from 'lodash';

import { DUPLICATE_STATUS_ERROR_CODES, FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise, tryAbortPromise } from 'lib/abortablePromise';
import * as api from 'lib/api';
import * as apiV6 from 'lib/apiV6';
import { camelKeys, camelKeysRecursive } from 'lib/object';
import { addProfileEvents, isHiringManager, transformProfile } from 'lib/utils';
import { State } from 'stores/ReduxStore';
import { getErrorHandler, handleError, setProfileReferer } from 'slices/app';
import { getMailingRecipients } from 'slices/messaging';
import { profileMainActions } from 'slices/profile';
import { profilesFoldersActions } from 'slices/profiles';
import {
  convertMessagingStatusesFromUrl,
  convertMessagingStatusesToUrl,
  convertValidationMessages,
  getIncludedStatuses,
  transformCandidate,
  transformCompanyStatuses,
  transformFolder,
  transformMailingList,
  transformStatuses,
} from 'components/Folders/lib';

export enum FOLDERS_STATUSES {
  OPENED = 'active',
  ARCHIVED = 'closed',
}

export enum Permissions {
  Public = 'public',
  Private = 'private',
}

let foldersRequest: null | AbortablePromise<{ pages: AH$Pages; payload: Array<API$Folder> }> = null;
let candidatesRequest: null | CandidatesPromise | RecommendationsPromise = null;
let folderRecommendations: null | RecommendationsPromise = null;

let folderRequest: null | AbortablePromise<{
  candidate_statuses: Array<API$Status>;
  payload: API$Folder;
  profileReferer: string;
}> = null;

type CandidatesPromise = AbortablePromise<{
  payload: API$PaginatedResponse<API$Candidate> & {
    candidate_statuses: Array<API$Status>;
    profiles_events: {
      [key: number]: API$ProfileEvent[];
    };
  };
}>;

type RecommendationsPromise = AbortablePromise<{
  payload: API$Profile[];
}>;

export type FoldersState = {
  readonly assigneeId: number | null | undefined;
  readonly assignees: Array<API$Assignee>;
  readonly candidateStatuses: Array<AH$Status>;
  readonly candidates: AH$Candidates;
  readonly chosenCandidatesIds: Set<number>;
  readonly companyStatuses: AH$CompanyStatuses;
  readonly creditLimits: {
    data: API$CreditLimits | Record<string, number>;
    isDownloadUnavailable: boolean;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly csvFields: {
    data: Array<AH$CsvCheckbox>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly editingFolderStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingAddAllCandidatesStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingAssigneesStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingCandidatesStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingFolderStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingFoldersStatus: $Values<typeof FETCHING_STATUSES>;
  readonly fetchingStatusesStatus: $Values<typeof FETCHING_STATUSES>;
  readonly folder: AH$Folder;
  readonly folderPage: number;
  readonly folders: Array<AH$Folder>;
  readonly hiringManagers: {
    data: Array<AH$Assignee>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly invalidFields: AH$InvalidFields;
  mailingListLaunchInfo: AH$MailingListLaunchInfo | null;
  readonly movingFolderId: number | null;
  readonly movingStatus: $Values<typeof FETCHING_STATUSES>;
  readonly page: number;
  readonly pages: AH$Pages | null | undefined;
  readonly foldersStatus?: FOLDERS_STATUSES;
};

export const foldersInitialState: FoldersState = {
  assigneeId: null,
  foldersStatus: FOLDERS_STATUSES.OPENED,
  assignees: [],
  fetchingAssigneesStatus: FETCHING_STATUSES.LOADING,
  folders: [],
  fetchingFoldersStatus: FETCHING_STATUSES.LOADING,
  pages: null,
  chosenCandidatesIds: Set([]),
  movingFolderId: null,
  movingStatus: FETCHING_STATUSES.SUCCESS,
  page: 1,
  folder: {} as AH$Folder,
  candidateStatuses: [],
  fetchingFolderStatus: FETCHING_STATUSES.LOADING,
  candidates: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  fetchingCandidatesStatus: FETCHING_STATUSES.LOADING,
  invalidFields: {} as AH$InvalidFields,
  editingFolderStatus: FETCHING_STATUSES.SUCCESS,
  companyStatuses: {
    defaultStatuses: [],
    availableStatuses: [],
    allStatuses: [],
  },
  fetchingStatusesStatus: FETCHING_STATUSES.LOADING,
  folderPage: 1,
  creditLimits: {
    status: FETCHING_STATUSES.LOADING,
    data: {},
    isDownloadUnavailable: false,
  },
  csvFields: {
    status: FETCHING_STATUSES.LOADING,
    data: [],
  },
  hiringManagers: {
    status: FETCHING_STATUSES.SUCCESS,
    data: [],
  },
  mailingListLaunchInfo: null,
  fetchingAddAllCandidatesStatus: FETCHING_STATUSES.SUCCESS,
};

const SLICE_NAME = 'folders';

const foldersLoading = createAction<{
  assigneeId?: number | null;
  foldersStatus?: FOLDERS_STATUSES;
  page?: number;
}>(`${SLICE_NAME}/foldersLoading`);
const folderNotSaved = createAction<AH$InvalidFields>(`${SLICE_NAME}/folderNotSaved`);
const companyStatusesLoading = createAction(`${SLICE_NAME}/companyStatusesLoading`);
const companyStatusesLoaded = createAction<AH$CompanyStatuses | undefined>(
  `${SLICE_NAME}/companyStatusesLoaded`
);
const companyStatusesErrored = createAction(`${SLICE_NAME}/companyStatusesErrored`);

export const applyMessagingFilter = createAction(`${SLICE_NAME}/applyMessagingFilter`);

export const getFolders = createAsyncThunk(
  `${SLICE_NAME}/getFolders`,
  async (
    {
      foldersStatus,
      assigneeId,
      page,
      onlyAccessible,
      perPage,
      onSuccess = noop,
    }: {
      assigneeId?: number | null | undefined;
      foldersStatus?: FOLDERS_STATUSES;
      onSuccess?: () => void;
      onlyAccessible?: boolean;
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      tryAbortPromise(foldersRequest);

      dispatch(foldersLoading({ foldersStatus, assigneeId, page }));

      foldersRequest = abortable(
        apiV6.foldersGet({
          page,
          status: foldersStatus,
          assignees: assigneeId,
          only_accessible: onlyAccessible,
          per_page: perPage,
        })
      );

      const { payload, pages } = await foldersRequest;

      onSuccess();

      return {
        folders: payload.map(camelKeys) as Array<AH$Folder>,
        pages,
        page,
      };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

export const getAssignees = createAsyncThunk(
  `${SLICE_NAME}/getAssignees`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        payload: { results },
      } = await apiV6.usersGet({
        is_assignee: true,
        exclude_self: true,
        role: ['admin', 'worker'],
        limit: 1000,
      });

      const { user } = (getState() as State).app;

      return isHiringManager(user.role)
        ? results
        : [{ id: user.id, name: user.name } as API$Assignee, ...results];
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getFolder = createAsyncThunk(
  `${SLICE_NAME}/getFolder`,
  async (
    { id, onSuccess = noop }: { id: number; onSuccess?: (folder: AH$Folder) => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      tryAbortPromise(folderRequest);
      folderRequest = abortable(apiV6.folderGet(id));

      const { payload, profileReferer } = await folderRequest;

      dispatch(setProfileReferer(profileReferer) as any);
      const { folder, candidateStatuses } = transformFolder(payload) as {
        candidateStatuses: Array<AH$Status>;
        folder: AH$Folder;
      };

      onSuccess(folder);

      return {
        folder,
        candidateStatuses: candidateStatuses.map((status) => ({
          ...status,
          candidatesCount: 0,
        })),
      };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

export const getCandidates = createAsyncThunk(
  `${SLICE_NAME}/getCandidates`,
  async (
    {
      folderId,
      params,
      withIncludedStatuses = true,
    }: { folderId: number; params?: AH$CandidatesParams; withIncludedStatuses?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const getWithIncludedStatuses = flow([
        convertMessagingStatusesFromUrl,
        (statuses) =>
          statuses.reduce(
            (
              acc: Array<{ mailingListId: number; status: API$MailingRecipientsStatus }>,
              messStatus: { mailingListId: number; status: API$MailingRecipientsStatus }
            ) => [
              ...acc,
              messStatus,
              ...getIncludedStatuses(messStatus.status).map((status) => ({
                status,
                mailingListId: messStatus.mailingListId,
              })),
            ],
            [] as Array<{ mailingListId: number; status: string }>
          ),
        convertMessagingStatusesToUrl,
      ]);

      tryAbortPromise(candidatesRequest);
      dispatch(profilesFoldersActions.profilesLoading());
      candidatesRequest = abortable(
        apiV6.folderCandidatesGet(
          folderId,
          params && {
            ...omit(params, ['statuses, messaging_status']),
            status: params.statuses,
            messaging_status: withIncludedStatuses
              ? getWithIncludedStatuses(params.messaging_status)
              : params.messaging_status,
          }
        )
      ) as CandidatesPromise;

      const { payload } = await candidatesRequest;

      if (isEmpty(payload.results) && payload.previous) {
        const { payload } = await apiV6.folderCandidatesGet(
          folderId,
          params && {
            ...omit(params, ['statuses, messaging_status', 'offset']),
            status: params.statuses,
            messaging_status: withIncludedStatuses
              ? getWithIncludedStatuses(params.messaging_status)
              : params.messaging_status,
          }
        );
        const profiles = filter(payload.results, ({ profile }) => !isNumber(profile)).map(
          transformCandidate
        );

        const updateProfiles = addProfileEvents({
          profiles,
          profiles_events: payload.profiles_events,
        });

        dispatch(profilesFoldersActions.profilesLoaded({ profiles: updateProfiles, pages: null }));

        return {
          ...payload,
          results: updateProfiles.map(transformProfile),
          candidateStatuses: transformStatuses(payload.candidate_statuses),
        };
      }

      const profiles = filter(payload.results, ({ profile }) => !isNumber(profile)).map(
        transformCandidate
      );

      const updateProfiles = addProfileEvents({
        profiles,
        profiles_events: payload.profiles_events,
      });

      dispatch(profilesFoldersActions.profilesLoaded({ profiles: updateProfiles, pages: null }));

      return {
        ...payload,
        results: updateProfiles.map(transformProfile),
        candidateStatuses: transformStatuses(payload.candidate_statuses),
      };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          getError: () => {
            dispatch(profilesFoldersActions.profilesLoaded({ isError: true, error: e }));
          },
        })(e);

        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

export const deleteFolder = createAsyncThunk(
  `${SLICE_NAME}/deleteFolder`,
  async (
    {
      id,
      params,
      onSuccess = noop,
    }: {
      id: number;
      onSuccess?: () => void;
      params?: AH$SaveMessagingHistory;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.folderDelete(id, params);
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const editFolder = createAsyncThunk(
  `${SLICE_NAME}/editFolder`,
  async (
    {
      id,
      data,
      onSuccess = noop,
      onError = noop,
    }: {
      data: AH$FolderData;
      id: number;
      onError?: () => void;
      onSuccess?: (folder: AH$Folder) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { payload } = await apiV6.folderPatch(id, data);
      const folder = transformFolder(payload);

      onSuccess(folder.folder);
      dispatch(
        getFolders({
          assigneeId: get(payload.assignees[0], 'id'),
        })
      );

      return folder;
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error: { [key: string]: Array<string> }) => {
          dispatch(folderNotSaved(convertValidationMessages(error)));
          onError();
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const createFolder = createAsyncThunk(
  `${SLICE_NAME}/createFolder`,
  async (
    {
      data,
      onSuccess,
      onError = noop,
    }: {
      data: AH$FolderData;
      onError?: () => void;
      onSuccess?: (folder: AH$Folder) => Promise<void>;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { payload } = await apiV6.folderCreate(data);
      const folder = transformFolder(payload);

      await onSuccess?.(folder.folder);
      dispatch(
        getFolders({
          assigneeId: get(payload.assignees[0], 'id'),
          foldersStatus: FOLDERS_STATUSES.OPENED,
        })
      );

      return folder;
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error: { [key: string]: Array<string> }) => {
          dispatch(folderNotSaved(convertValidationMessages(error)));
          onError();
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const changeCandidateStatus = createAsyncThunk(
  `${SLICE_NAME}/changeCandidateStatus`,
  async (
    {
      candidateId,
      statusId,
      onSuccess = noop,
    }: { candidateId: number; statusId: number; onSuccess?: () => void },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.candidatePatch(candidateId, statusId);
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const massChangeCanditatesStatus = createAsyncThunk(
  `${SLICE_NAME}/massChangeCanditatesStatus`,
  async (
    {
      candidatesIds,
      statusId,
      onSuccess = noop,
    }: { candidatesIds: Array<number>; statusId: number; onSuccess?: () => void },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.massCandidatesPatch(candidatesIds, statusId);
      onSuccess();
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const moveCandidatesToFolder = createAsyncThunk(
  `${SLICE_NAME}/moveCandidatesToFolder`,
  async (
    {
      folderId,
      destinationFolderId,
      candidateIds,
      onSuccess,
      isMoving,
      copyComments,
      saveMessagingHistory,
      type = 'folder',
    }: {
      candidateIds: Set<number>;
      copyComments: boolean;
      destinationFolderId: number;
      folderId: number;
      isMoving: boolean;
      onSuccess: (copied: number) => void;
      saveMessagingHistory?: boolean;
      type?: 'folder' | 'sequence';
    },
    { rejectWithValue }
  ) => {
    try {
      const request = (isMoving ? api.moveCandidates : api.copyCandidates)(
        type,
        folderId,
        destinationFolderId,
        candidateIds.toArray(),
        copyComments,
        saveMessagingHistory
      );

      const { payload } = await request;

      onSuccess(payload.copied);
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const downloadCSV = createAsyncThunk(
  `${SLICE_NAME}/downloadCSV`,
  async (
    {
      folderId,
      chosenCandidatesIds,
      params,
      type = 'folder',
    }: {
      chosenCandidatesIds: Array<number>;
      folderId: number;
      params: AH$DownloadFileParams;
      type: 'folder' | 'sequence';
    },
    { rejectWithValue }
  ) => {
    try {
      await api.downloadFile(folderId, chosenCandidatesIds, params, type);
    } catch (e) {
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const selectCandidates = createAsyncThunk(
  `${SLICE_NAME}/selectCandidates`,
  async (
    {
      profiles,
      folderId,
      assigneeId,
      searchQuery,
      onlyAccessible,
      onSuccess = noop,
    }: {
      assigneeId: number;
      folderId: number;
      profiles: Array<number>;
      onSuccess?: (payload?: { invalidCount: number; successCount: number }) => void;
      onlyAccessible?: boolean;
      searchQuery?: number | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const candidates = profiles.map((profile) => ({
        profile,
        folder: folderId,
        ...(searchQuery ? { search_query: searchQuery } : undefined),
      }));

      const { payload } = await apiV6.candidatesPost(candidates);

      dispatch(getFolders({ foldersStatus: FOLDERS_STATUSES.OPENED, assigneeId, onlyAccessible }));
      onSuccess(payload);
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const deleteCandidates = createAsyncThunk(
  `${SLICE_NAME}/deleteCandidates`,
  async (
    {
      candidates,
      profileId,
      folderId,
      saveMessagingHistory,
      onSuccess = noop,
    }: {
      candidates: Array<number>;
      folderId?: number;
      onSuccess?: () => void;
      profileId?: number;
      saveMessagingHistory?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await apiV6.candidatesDelete({ ids: candidates, saveMessagingHistory });
      onSuccess();
      if (folderId) {
        dispatch(getFolder({ id: folderId }));
      } else if (profileId) {
        dispatch(profileMainActions.receiveProfileFolders(profileId));
        if (saveMessagingHistory) {
          dispatch(getMailingRecipients({ params: { profile: profileId } }));
        }
      }
    } catch (e) {
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const getCompanyStatuses = createAsyncThunk(
  `${SLICE_NAME}/getCompanyStatuses`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(companyStatusesLoading());
      const { payload } = await apiV6.statusesGet();

      dispatch(companyStatusesLoaded(transformCompanyStatuses(payload)));
    } catch (e) {
      getErrorHandler({});

      rejectWithValue(e);
    }
  }
);

export const editStatus = createAsyncThunk(
  `${SLICE_NAME}/editStatus`,
  async (
    {
      statusId,
      data,
      onEdit,
      onSuccess = noop,
    }: { data: API$StatusData; statusId: number; onEdit?: () => void; onSuccess?: () => void },
    { rejectWithValue, dispatch }
  ) => {
    const onInvalidRequest = (content: string): void => {
      dispatch(companyStatusesLoaded());
      handleError({ content });
    };

    try {
      await apiV6.statusEdit(statusId, data);
      onSuccess();
      if (onEdit) {
        onEdit();
      } else {
        dispatch(getCompanyStatuses());
      }
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error: any) => {
          if (get(error, 'name')) {
            onInvalidRequest(get(error, 'name', [])[0]);
          } else if (DUPLICATE_STATUS_ERROR_CODES.includes(get(error, 'status.code'))) {
            onInvalidRequest(get(error, 'status.message'));
          } else {
            dispatch(companyStatusesErrored());
          }
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const deleteStatus = createAsyncThunk(
  `${SLICE_NAME}/deleteStatus`,
  async (statusId: number, { rejectWithValue, dispatch }) => {
    try {
      await apiV6.statusDelete(statusId);
      dispatch(getCompanyStatuses());
    } catch (e) {
      getErrorHandler({
        getError: () => dispatch(companyStatusesErrored()),
      })(e);
      rejectWithValue(e);
    }
  }
);

export const createStatus = createAsyncThunk(
  `${SLICE_NAME}/createStatus`,
  async (
    { data, onSuccess = noop }: { data: API$StatusData; onSuccess?: () => void },
    { rejectWithValue, dispatch }
  ) => {
    const onInvalidRequest = (content: string): void => {
      dispatch(companyStatusesLoaded());
      handleError({ content });
    };

    try {
      await apiV6.statusCreate(data);
      onSuccess();
      dispatch(getCompanyStatuses());
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (error: any) => {
          if (get(error, 'name')) {
            onInvalidRequest(get(error, 'name', [])[0]);
          } else if (DUPLICATE_STATUS_ERROR_CODES.includes(get(error, 'status.code'))) {
            onInvalidRequest(get(error, 'status.message'));
          } else {
            dispatch(companyStatusesErrored());
          }
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const changeStatusesOrder = createAsyncThunk(
  `${SLICE_NAME}/changeStatusesOrder`,
  async (ids: Array<number>, { rejectWithValue, dispatch }) => {
    try {
      dispatch(companyStatusesLoading());
      const { payload } = await apiV6.statusesOrderChange(ids);

      dispatch(companyStatusesLoaded(transformCompanyStatuses(payload)));
    } catch (e) {
      getErrorHandler({
        getError: () => dispatch(companyStatusesErrored()),
      })(e);
      rejectWithValue(e);
    }
  }
);

export const canOpenHiddenContacts = createAsyncThunk(
  `${SLICE_NAME}/canOpenHiddenContacts`,
  async (
    {
      folderId,
      chosenCandidatesIds,
      onSuccess = noop,
      onError = noop,
      type = 'folder',
    }: {
      chosenCandidatesIds: number[];
      folderId: number;
      onError?: (creditLimits: API$CreditLimits) => void;
      onSuccess?: () => void;
      type?: 'folder' | 'sequence';
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await api.canOpenHiddenContacts(folderId, chosenCandidatesIds, type);

      onSuccess();
      return payload;
    } catch (e: any) {
      getErrorHandler({
        getError: (error) => {
          if (e.response.status === 400) {
            onError(error);
          }
        },
      })(e);

      if (e.response.status === 400) {
        const data = await e.response.json();

        return rejectWithValue({ data, isDownloadUnavailable: true });
      }

      return rejectWithValue(undefined);
    }
  }
);

export const getCSVFields = createAsyncThunk(
  `${SLICE_NAME}/getCSVFields`,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await api.getCSVFields();

      return payload.data;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getHiringManagers = createAsyncThunk(
  `${SLICE_NAME}/getHiringManagers`,
  async (
    { onSuccess = noop }: { onSuccess?: (assignees: AH$Assignee[]) => void },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.usersGet({
        role: ['hiring_manager'],
        limit: 1000,
        include_pending_invites: true,
      });
      const hiringManagers = camelKeysRecursive(payload.results) as Array<AH$Assignee>;

      onSuccess(hiringManagers);

      return hiringManagers;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getMailingListLaunchInfo = createAsyncThunk(
  `${SLICE_NAME}/getMailingListLaunchInfo`,
  async (
    {
      mailingListId,
      onSuccess = noop,
      onError = noop,
    }: { mailingListId: number; onError?: () => void; onSuccess?: () => void },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.getMailingListLaunchInfo(mailingListId);
      const launchInfo = camelKeysRecursive(payload) as Omit<AH$MailingListLaunchInfo, 'total'>;
      const {
        readyToLaunch = 0,
        readyToRelaunch = {} as AH$MailingListLaunchInfo['readyToRelaunch'],
        withoutEmail = 0,
        errorRelaunch = 0,
      } = launchInfo;
      const { withNextMessageOccurred = 0, withNextMessageAsPlanned = 0 } = readyToRelaunch;
      const total =
        readyToLaunch +
        withoutEmail +
        withNextMessageOccurred +
        withNextMessageAsPlanned +
        errorRelaunch;

      onSuccess();
      return {
        ...launchInfo,
        total,
      } as AH$MailingListLaunchInfo;
    } catch (e) {
      onError();
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getAtsFolders = createAsyncThunk(
  `${SLICE_NAME}/getAtsFolders`,
  async (
    {
      onSuccess,
      onError = noop,
    }: { onSuccess: (atsFolders: Array<AH$Folder>) => void; onError?: () => void },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.getAtsFolders();
      const atsFolders = payload.map(camelKeys) as Array<AH$Folder>;

      onSuccess(atsFolders);
    } catch (e) {
      onError();
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const getFolderRecommendations = createAsyncThunk(
  `${SLICE_NAME}/getFolderRecommendations`,
  async (folderId: number, { rejectWithValue, dispatch }) => {
    try {
      if (folderRecommendations && folderRecommendations.abort) {
        folderRecommendations.abort();
      }

      if (candidatesRequest && candidatesRequest.abort) {
        candidatesRequest.abort();
      }
      dispatch(profilesFoldersActions.profilesLoading());

      folderRecommendations = abortable(apiV6.folderRecommendationsGet(folderId));

      const { payload } = await folderRecommendations;

      dispatch(
        profilesFoldersActions.profilesLoaded({
          profiles: payload,
          pages: null,
        })
      );
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          getError: () => {
            dispatch(profilesFoldersActions.profilesLoaded({ isError: true, error: e }));
          },
        })(e);
        rejectWithValue(e);
      }
    }
  }
);

export const getWidgetFolderRecommendations = createAsyncThunk(
  `${SLICE_NAME}/getWidgetFolderRecommendations`,
  async (folderId: number, { rejectWithValue, dispatch }) => {
    try {
      if (folderRecommendations && folderRecommendations.abort) {
        folderRecommendations.abort();
      }

      dispatch(profilesFoldersActions.profilesFoldersRecommendationsLoading());

      folderRecommendations = abortable(apiV6.folderRecommendationsGet(folderId));
      const { payload } = await folderRecommendations;

      dispatch(
        profilesFoldersActions.profilesFoldersRecommendationsLoaded(payload.map(transformProfile))
      );
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          getError: () => {
            dispatch(profilesFoldersActions.profilesFoldersRecommendationsError());
          },
        })(e);
        rejectWithValue(e);
      }
    }
  }
);

export const createMailingListByFolder = createAsyncThunk(
  `${SLICE_NAME}/createMailingListByFolder`,
  async (
    {
      data,
      onSuccess = noop,
      onError = noop,
    }: {
      data: {
        candidates: Array<number>;
        folder: number;
        name: string;
        templates: Array<API$MessageTemplate>;
        custom_variables?: Array<string>;
      };
      onError: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess: (id: number) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.createMailingList(data);

      onSuccess(payload.id);

      return transformMailingList(payload);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: ({
          templates,
          folder,
        }: {
          folder: Array<string>;
          templates: Array<{ [key: string]: Array<string> }>;
        }) => {
          onError(
            (templates || []).map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );

          if (folder) handleError({ content: folder[0] });
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const editMailingListByFolder = createAsyncThunk(
  `${SLICE_NAME}/editMailingListByFolder`,
  async (
    {
      mailingListId,
      data,
      onSuccess = noop,
      onError = noop,
    }: {
      data: { templates: Array<API$MessageTemplate>; custom_variables?: Array<string> };
      mailingListId: number;
      onError: (invalidFields: Array<AH$MessageInvalidFields>) => void;
      onSuccess: (id: number) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.editMailingList(mailingListId, data);

      onSuccess(payload.id);

      return transformMailingList(payload);
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: ({ templates }: { templates: Array<{ [key: string]: Array<string> }> }) => {
          onError(
            templates.map((template) =>
              mapValues(camelKeysRecursive(template), (message) => message[0])
            )
          );
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const getMailingList = createAsyncThunk(
  `${SLICE_NAME}/getMailingList`,
  async (
    { mailingListId, onSuccess = noop }: { mailingListId: number; onSuccess?: () => void },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.getMailingList(mailingListId);

      onSuccess();
      return transformMailingList(payload);
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const deleteMailingList = createAsyncThunk(
  `${SLICE_NAME}/deleteMailingList`,
  async (
    {
      mailingListId,
      onSuccess = noop,
      onError = noop,
      isMass = false,
      params,
    }: {
      mailingListId: number;
      isMass?: boolean;
      onError?: () => void;
      onSuccess?: () => void;
      params?: AH$SaveMessagingHistory;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiV6.deleteMailingList(mailingListId, params);
      onSuccess();

      return { mailingListId, isMass };
    } catch (e) {
      onError();
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const addAllCandidateToFolder = createAsyncThunk(
  `${SLICE_NAME}/addAllCandidateToFolder`,
  async (
    {
      rawQuery,
      onSuccess,
      onError = noop,
    }: {
      onSuccess: (folderName: string, count: number) => void;
      rawQuery: string;
      onError?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.addAllCandidateToFolder(rawQuery);

      onSuccess(payload.name, payload.candidates_count);
    } catch (e) {
      onError();
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const foldersSlice = createSlice({
  name: SLICE_NAME,
  initialState: foldersInitialState,
  reducers: {
    removeInvalidFields: (state, action: PayloadAction<AH$InvalidFields>) => {
      state.invalidFields = action.payload;
    },
    chooseCandidates: (state, action: PayloadAction<Array<number>>) => {
      state.chosenCandidatesIds = state.chosenCandidatesIds.union(action.payload);
    },
    removeCandidates: (state, action: PayloadAction<Array<number> | null>) => {
      state.chosenCandidatesIds = state.chosenCandidatesIds.subtract(
        action.payload ? action.payload : []
      );
    },
    setMoveFolder: (state, action: PayloadAction<number | null>) => {
      state.movingFolderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(foldersLoading, (state, action) => {
      state.assigneeId = action.payload.assigneeId;
      state.foldersStatus = action.payload.foldersStatus;
      state.page = action.payload.page || state.page;
      state.fetchingFoldersStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getFolders.fulfilled, (state, action) => {
      if (action.payload) {
        state.folders = action.payload.folders;
        state.page = action.payload.page || state.page;
        state.pages = action.payload.pages;
        state.fetchingFoldersStatus = FETCHING_STATUSES.SUCCESS;
      }
    });
    builder.addCase(getFolders.rejected, (state) => {
      state.fetchingFoldersStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getAssignees.pending, (state) => {
      state.fetchingAssigneesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getAssignees.fulfilled, (state, action) => {
      state.assignees = action.payload;
      state.fetchingAssigneesStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(getAssignees.rejected, (state) => {
      state.fetchingAssigneesStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getFolder.pending, (state) => {
      state.chosenCandidatesIds = Set([]);
      state.candidateStatuses = [];
      state.fetchingFolderStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getFolder.fulfilled, (state, action) => {
      const { folder, candidateStatuses } = action.payload as {
        candidateStatuses: Array<AH$Status>;
        folder: AH$Folder;
      };

      state.folder = { ...state.folder, ...folder };
      state.candidateStatuses = !size(state.candidateStatuses)
        ? candidateStatuses
        : state.candidateStatuses;
      state.fetchingFolderStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(getFolder.rejected, (state) => {
      state.fetchingFolderStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getCandidates.pending, (state) => {
      state.fetchingCandidatesStatus = FETCHING_STATUSES.LOADING;
      state.folderPage = 1;
    });
    builder.addCase(getCandidates.fulfilled, (state, action) => {
      if (action.payload) {
        state.candidates = { ...omit(action.payload, 'candidateStatuses') };
        state.fetchingCandidatesStatus = FETCHING_STATUSES.SUCCESS;
        state.candidateStatuses = action.payload.candidateStatuses;
      }
    });
    builder.addCase(getCandidates.rejected, (state) => {
      state.fetchingCandidatesStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(folderNotSaved, (state, action) => {
      state.invalidFields = action.payload;
      state.editingFolderStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(editFolder.pending, (state) => {
      state.editingFolderStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(editFolder.fulfilled, (state, action) => {
      state.folder = action.payload.folder;
      state.invalidFields = {};
      state.editingFolderStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(createFolder.pending, (state) => {
      state.editingFolderStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      state.folder = action.payload.folder;
      state.invalidFields = {};
      state.editingFolderStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(moveCandidatesToFolder.pending, (state) => {
      state.movingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(moveCandidatesToFolder.fulfilled, (state) => {
      state.movingStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(moveCandidatesToFolder.rejected, (state) => {
      state.movingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(editStatus.pending, (state) => {
      state.fetchingStatusesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(companyStatusesLoading, (state) => {
      state.fetchingStatusesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(companyStatusesLoaded, (state, action) => {
      state.companyStatuses = action.payload || state.companyStatuses;
      state.fetchingStatusesStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(companyStatusesErrored, (state) => {
      state.fetchingStatusesStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(deleteStatus.pending, (state) => {
      state.fetchingStatusesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(createStatus.pending, (state) => {
      state.fetchingStatusesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(canOpenHiddenContacts.pending, (state) => {
      state.creditLimits.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(canOpenHiddenContacts.fulfilled, (state, action) => {
      state.creditLimits = {
        data: action.payload,
        isDownloadUnavailable: false,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(canOpenHiddenContacts.rejected, (state, action) => {
      const payload = {
        ...(action.payload as { data: any; isDownloadUnavailable: boolean } | undefined),
      };

      state.creditLimits = {
        data: payload && payload.data ? payload.data : {},
        isDownloadUnavailable: payload
          ? (payload.isDownloadUnavailable as boolean)
          : state.creditLimits.isDownloadUnavailable,
        status: FETCHING_STATUSES.ERROR,
      };
    });
    builder.addCase(getCSVFields.pending, (state) => {
      state.csvFields.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getCSVFields.fulfilled, (state, action) => {
      state.csvFields = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getCSVFields.rejected, (state) => {
      state.csvFields.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getHiringManagers.pending, (state) => {
      state.hiringManagers.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getHiringManagers.fulfilled, (state, action) => {
      state.hiringManagers = {
        data: action.payload,
        status: FETCHING_STATUSES.SUCCESS,
      };
    });
    builder.addCase(getHiringManagers.rejected, (state) => {
      state.hiringManagers.status = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(applyMessagingFilter, (state) => {
      state.candidateStatuses = [];
      state.chosenCandidatesIds = Set([]);
    });
    builder.addCase(getMailingListLaunchInfo.fulfilled, (state, action) => {
      state.mailingListLaunchInfo = action.payload;
    });
    builder.addCase(createMailingListByFolder.fulfilled, (state, action) => {
      state.folder.mailingLists = [action.payload];
    });
    builder.addCase(editMailingListByFolder.fulfilled, (state, action) => {
      state.folder.mailingLists = [action.payload];
    });
    builder.addCase(getMailingList.fulfilled, (state, action) => {
      state.folder.mailingLists = [action.payload];
    });
    builder.addCase(deleteMailingList.fulfilled, (state, action) => {
      state.folder.mailingLists = filter(
        state.folder.mailingLists,
        ({ id }) => id !== action.payload.mailingListId
      );
    });
    builder.addCase(addAllCandidateToFolder.pending, (state) => {
      state.fetchingAddAllCandidatesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(addAllCandidateToFolder.fulfilled, (state) => {
      state.fetchingAddAllCandidatesStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(addAllCandidateToFolder.rejected, (state) => {
      state.fetchingAddAllCandidatesStatus = FETCHING_STATUSES.ERROR;
    });
  },
});

export const { removeInvalidFields, removeCandidates, chooseCandidates, setMoveFolder } =
  foldersSlice.actions;
export const folders = foldersSlice.reducer;
