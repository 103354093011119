import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import CustomTooltip from 'components/CustomTooltip';
import messages from './messages';
import styles from './Events.scss';

export const RECIPIENT_STATUS = {
  draft: 'draft',
  replied: 'replied',
  pending: 'pending',
  sent: 'sent',
  error: 'error',
  opened: 'opened',
  planned: 'planned',
} as const;

const theme = thematize(styles);

interface Props {
  status: AH$MessagingEventStatus;
  tooltipPlacement?: 'top' | 'right' | 'left' | 'bottom';
}

export const ActivityIcon = ({ status, tooltipPlacement = 'top' }: Props): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <CustomTooltip
      id="event_message_tooltip"
      message={formatMessage(messages[status])}
      placement={tooltipPlacement}
    >
      <span className={theme('activity-icon')}>
        <Icon type={status} />
      </span>
    </CustomTooltip>
  );
};

export const ActivityText = ({
  status,
}: {
  status: AH$MessagingEventStatus;
}): React.ReactElement => (
  <span className={theme('activity-text')}>
    <FormattedMessage {...messages[status]} />
  </span>
);
