import * as React from 'react';
import { useMemo } from 'react';
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';

import { useCustomRouterLocation } from 'hooks';
import AiTerm from 'components/TokenAiTerm';
import TokenAppliedTerm from 'components/TokenAppliedTerm';
import Term from 'components/TokenTerm';

function findEntityByType(
  type: AH$FormEntityType,
  contentState: ContentState,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
  }, callback);
}

function handleOperator(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('OPERATOR', contentState, contentBlock, callback);
}

function handleTerm(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('TERM', contentState, contentBlock, callback);
}

function handleAI(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('AI', contentState, contentBlock, callback);
}

function handleAppliedTerm(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('APPLIED_TERM', contentState, contentBlock, callback);
}

function handleSpace(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('SPACE', contentState, contentBlock, callback);
}

function handleParen(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  findEntityByType('PAREN', contentState, contentBlock, callback);
}

const NoTerm = ({ children, entityKey }: { children: React.ReactElement; entityKey: string }) => {
  const location = useCustomRouterLocation();
  const searchFormType = useMemo(
    () => (location.pathname.includes('-old') ? 'v1' : 'v2'),
    [location.pathname]
  );

  return (
    <span id={entityKey} style={searchFormType === 'v2' ? undefined : { color: '#848891' }}>
      {children}
    </span>
  );
};

const Decorator = new CompositeDecorator([
  {
    strategy: handleOperator,
    component: NoTerm,
  },
  {
    strategy: handleTerm,
    component: Term,
  },
  {
    strategy: handleAI,
    component: AiTerm,
  },
  {
    strategy: handleAppliedTerm,
    component: TokenAppliedTerm,
  },
  {
    strategy: handleSpace,
    component: NoTerm,
  },
  {
    strategy: handleParen,
    component: NoTerm,
  },
]);

export default Decorator;
