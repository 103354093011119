export default {
  createdAt: {
    id: 'messaging.sent',
    defaultMessage: 'Sent',
  },
  opened: {
    id: 'messaging.opened',
    defaultMessage: 'Viewed',
  },
  replied: {
    id: 'messaging.replied',
    defaultMessage: 'Replied',
  },
  events: {
    id: 'messaging.activity',
    defaultMessage: 'Activity',
  },
  sent: {
    id: 'messaging.sent',
    defaultMessage: 'Sent',
  },
  pending: {
    id: 'messaging.pending',
    defaultMessage: 'Pending',
  },
  draft: {
    id: 'messaging.draft',
    defaultMessage: 'Draft',
  },
  error: {
    id: 'messaging.error',
    defaultMessage: 'Error',
  },
  planned: {
    id: 'messaging.planned',
    defaultMessage: 'Planned',
  },
  activity: {
    id: 'messaging.activity',
    defaultMessage: 'Activity',
  },
  integration_disabled: {
    id: 'messaging.error.inetgrationDisabled',
    defaultMessage: 'Integration disabled',
  },
  bad_request: {
    id: 'messaging.error.badRequest',
    defaultMessage: 'Internal error',
  },
  unsunscribed: {
    id: 'messaging.error.unsubscribed',
    defaultMessage: 'Unsubscribed from emails',
  },
  max_retries: {
    id: 'messaging.error.maxRetries',
    defaultMessage: 'Exceeded the sending limit',
  },
  unexpected: {
    id: 'messaging.error.unexpected',
    defaultMessage: 'Something went wrong',
  },
  bounce: {
    id: 'messaging.error.bounce',
    defaultMessage: 'Email does not exist or does not accept messages',
  },
  previous_has_error: {
    id: 'messaging.error.previousHasError',
    defaultMessage: 'Previous message has error',
  },
};
