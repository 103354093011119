import { createAction, createSlice } from '@reduxjs/toolkit';

import messages from 'components/SystemMessages/messages';
import { createBannedLink, deleteBannedLink } from './bannedLinks';
import { deleteMailingList } from './folders';
import { stopMailingListAction } from './messaging';
import { profileMainActions } from './profile';

const SLICE_NAME = 'systemMessage';

export type SystemMessageState = AH$SystemMessage;

export const systemMessageInitialState: SystemMessageState = {
  data: {
    content: null,
    delay: null,
    isVisible: false,
    isClosingByDelay: true,
    formatted: true,
  },
  error: false,
  warning: false,
};

export const systemMessageReceived = createAction<AH$SystemMessage>(
  `${SLICE_NAME}/systemMessageReceived`
);

export const systemMessageCleared = createAction(`${SLICE_NAME}/systemMessageCleared`);

const systemMessageSlice = createSlice({
  name: SLICE_NAME,
  initialState: systemMessageInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(systemMessageReceived, (_, action) => ({
        ...systemMessageInitialState,
        ...action.payload,
      }))
      .addCase(systemMessageCleared, (state) => {
        state.data.isVisible = false;
      })
      .addCase(deleteMailingList.fulfilled, (_, action) => ({
        ...systemMessageInitialState,
        data: {
          content: [
            messages[
              `${
                action.payload.isMass ? 'mailingList' : 'messages'
              }Deleted` as keyof typeof messages
            ],
          ],
          formatted: false,
        },
      }))
      .addCase(stopMailingListAction, (_, action) => ({
        ...systemMessageInitialState,
        data: {
          content: [
            messages[
              `${
                action.payload.isMass ? 'mailingList' : 'sequence'
              }Stopped` as keyof typeof messages
            ],
          ],
          formatted: false,
        },
      }))
      .addCase(profileMainActions.fileUploadError, () => ({
        ...systemMessageInitialState,
        error: true,
        data: {
          content: [messages.fileUploadError],
          formatted: false,
        },
      }))
      .addCase(profileMainActions.fileParsingError, () => ({
        ...systemMessageInitialState,
        error: true,
        data: {
          content: [messages.fileParsingError],
          formatted: false,
        },
      }))
      .addCase(profileMainActions.fileUploaded, (_, action) => ({
        ...systemMessageInitialState,
        data: {
          content: [messages.fileUploaded, { name: action.payload }],
          formatted: false,
        },
      }))
      .addCase(createBannedLink.fulfilled, (_, action) => ({
        ...systemMessageInitialState,
        data: {
          content: [messages.bannedLinkCreated, { url: action.payload.url }],
          formatted: false,
        },
      }))
      .addCase(deleteBannedLink.fulfilled, (_, action) => ({
        ...systemMessageInitialState,
        data: {
          content: [messages.bannedLinkDeleted, { url: action.payload.url }],
          formatted: false,
        },
      }));
  },
});

export const systemMessage = systemMessageSlice.reducer;
