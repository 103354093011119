import { useEffect, useState } from 'react';
import * as React from 'react';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  RawDraftEntity,
} from 'draft-js';
import { capitalize, find, flow, isEmpty } from 'lodash';

import { AH$MessageEditor } from 'types';
import { useDispatch } from 'hooks';
import { getMailingVariables } from 'slices/messaging';
import Variable from 'components/Messaging/Message/Editor/Entity/Variable';
import { updateImages } from 'components/Messaging/Message/Editor/lib';
import { Link } from '../Editor/InsertLink';

const TEXT_ALIGN_REGEX = /text-align:(\w*)(;.*)?$/;
const WIDTH_REGEX = /width:(\d*)px(;.*)?$/;
const HEIGHT_REGEX = /height:(\d*)px(;.*)?$/;

const sizes = [
  { size: '1', style: 'SMALL' },
  { size: '4', style: 'LARGE' },
  { size: '6', style: 'HUGE' },
];
const htmlToStyle = (nodeName: string, node: HTMLElement, currentStyle: any): any => {
  if (nodeName === 'font') {
    const { style } = find(sizes, { size: (node as HTMLFontElement).size }) || {};

    if (style) {
      return currentStyle.add(style);
    }
  }
  if (nodeName === 'span' && node.getAttribute('data-editor') === 'signature') {
    return currentStyle.add('SIGNATURE');
  }
  return currentStyle;
};
const htmlToEntity =
  (readOnly: boolean) =>
  (
    nodeName: string,
    node: HTMLElement,
    createEntity: (type: string, mutability: string, data?: Record<string, any>) => string
  ): // eslint-disable-next-line
  string | undefined => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', {
        url: node.getAttribute('href'),
        readOnly,
      });
    }
    if (nodeName === 'img') {
      const id = node.getAttribute('data-file-id');
      const size = node.getAttribute('data-file-size');
      const alignStyleMatch = node.parentElement?.getAttribute('style')?.match(TEXT_ALIGN_REGEX);
      const imgStyle = node.getAttribute('style');
      const widthMatch = imgStyle?.match(WIDTH_REGEX);
      const heightMatch = imgStyle?.match(HEIGHT_REGEX);

      return createEntity('IMAGE', 'IMMUTABLE', {
        src: node.getAttribute('src'),
        id: id && parseInt(id, 10),
        name: node.getAttribute('alt'),
        size: size && parseInt(size, 10) * 1024,
        align: alignStyleMatch ? alignStyleMatch[1] : 'left',
        width: widthMatch && parseInt(widthMatch[1], 10),
        height: heightMatch && parseInt(heightMatch[1], 10),
      });
    }
  };
// eslint-disable-next-line
const htmlToBlock = (nodeName: string, node: HTMLElement): string | undefined => {
  if (nodeName === 'figure') {
    return 'atomic';
  }
  if (node.getAttribute('style')?.includes('text-align')) {
    const alignStyleMatch = node.getAttribute('style')?.match(TEXT_ALIGN_REGEX);

    return `align${alignStyleMatch ? capitalize(alignStyleMatch[1]) : 'Left'}`;
  }
};
// eslint-disable-next-line
const styleToHTML = (style: string): React.ReactElement | undefined => {
  const { size } = find(sizes, { style }) || {};

  if (size) {
    // @ts-ignore
    return <font size={size} />;
  }
  if (style === 'SIGNATURE') {
    return <span data-editor="signature" />;
  }
};
// eslint-disable-next-line
const blockToHTML = ({ type, text }: { type: string; text: string }) => {
  if (type === 'unstyled') {
    return isEmpty(text) ? <br /> : <div />;
  }
  if (type === 'atomic') {
    return <figure style={{ margin: 0 }} />;
  }
  if (type.includes('align')) {
    return <div style={{ textAlign: type.split('align')[1].toLowerCase() as 'left' }} />;
  }
};

const textToEntity = (
  text: string,
  createEntity: (type: string, mutability: string, data?: Record<string, any>) => string
) => {
  const result: Array<{ entity: string; length: number; offset: number; result: string }> = [];

  text.replace(/{{.*?}}/g, (match: string, offset: number) => {
    const entityKey = createEntity('VAR', 'IMMUTABLE', {
      variable: match.replaceAll(/{/g, '').replaceAll(/}/g, ''),
    });

    result.push({
      entity: entityKey,
      offset,
      length: match.length,
      result: match,
    });

    return '';
  });

  return result;
};

const entityToHTML = (entity: RawDraftEntity, originalText: string): React.ReactNode => {
  if (entity.type === 'LINK') {
    const { url } = entity.data;

    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <a
        href={url.startsWith('http') ? url : `http://${url}`}
        target="_blank"
        rel="noopener noreferrer"
      />
    );
  }
  if (entity.type === 'IMAGE') {
    const { src, name, id, size, align = 'left', width, height } = entity.data;

    return (
      <div style={{ textAlign: align }}>
        <img
          src={src}
          data-file-size={size && Math.floor(size / 1024)}
          alt={name}
          data-file-id={id}
          style={{ width: `${width}px`, height: `${height}px` }}
        />
      </div>
    );
  }
  return originalText;
};

export const findEntities =
  (entityType: string) =>
  (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ): void => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();

      return entityKey !== null && contentState.getEntity(entityKey).getType() === entityType;
    }, callback);
  };

const decorator = new CompositeDecorator([
  {
    strategy: findEntities('LINK'),
    component: Link,
  },
  {
    strategy: findEntities('VAR'),
    component: Variable,
  },
]);

export const getEditorStateFromHTML = ({ readOnly = false }: { readOnly?: boolean }) =>
  flow([
    convertFromHTML({
      htmlToStyle,
      htmlToEntity: htmlToEntity(readOnly),
      textToEntity,
      htmlToBlock,
    }),
    (contentState) => EditorState.createWithContent(contentState, decorator),
  ]);

const getHTMLFromEditorState = () =>
  flow([
    (editorState) => editorState.getCurrentContent(),
    convertToHTML({
      styleToHTML,
      blockToHTML,
      entityToHTML,
    }),
  ]);

export const getHTMLOrText = (editorState: EditorState): string =>
  isEmpty(editorState.getCurrentContent().getPlainText())
    ? ''
    : getHTMLFromEditorState()(editorState);

const useEditor = ({
  initText = '',
  readOnly = false,
}: {
  initText?: string;
  readOnly?: boolean;
}): AH$MessageEditor => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMailingVariables());
  }, []);

  const initEditorState = updateImages(
    getEditorStateFromHTML({ readOnly })(initText),
    []
  ).editorState;
  const [editorState, setEditorState] = useState<EditorState>(initEditorState);
  const onChangeText = flow([getEditorStateFromHTML({ readOnly }), setEditorState]);
  const text = editorState.getCurrentContent().getPlainText();

  return {
    editorState,
    onChangeText,
    text,
    onChange: setEditorState,
    getHTML: (): string => getHTMLOrText(editorState),
    getText: (): string => editorState.getCurrentContent().getPlainText(),
  };
};

export default useEditor;
