import * as React from 'react';
import type { OverlayTriggerProps } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface Props extends Omit<OverlayTriggerProps, 'overlay'> {
  children: React.ReactElement;
  id: string;
  message: React.ReactElement | string;
  show?: boolean;
}

const CustomTooltip = ({
  message,
  children,
  show = true,
  id,
  trigger = ['focus', 'hover'],
  delay,
  placement = 'bottom',
  ...otherProps
}: Props): React.ReactElement =>
  show ? (
    <OverlayTrigger
      delay={delay}
      placement={placement}
      {...otherProps}
      trigger={trigger}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [5, 5],
            },
          },
        ],
      }}
      overlay={(props) => (
        <Tooltip id={id} {...props}>
          {message}
        </Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );

export default CustomTooltip;
