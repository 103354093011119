import { ComponentType } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { config } from '@fortawesome/fontawesome-svg-core';
import * as Sentry from '@sentry/browser';

import { SENTRY_DSN } from 'constants/globals';
import { createBodyResizeObserver } from 'lib/resizeObserver';
import ReduxStore from 'stores/ReduxStore';
import { hideModal, setBodyRect } from 'slices/app';
import { setVisible } from 'slices/company';
import PasswordValidator from 'components/PasswordValidator';
import App from './containers/App';
import messagesRU from './i18n/ru';

import '@babel/polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/ru';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ru';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import 'element-closest';
import 'draft-js/dist/Draft.css';

config.autoAddCss = false;

const locales = { ru: messagesRU };
const appNode = document.getElementById('app');

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    beforeSend(event) {
      return {
        ...event,
        environment: process.env.NODE_ENV,
      };
    },
  });
}

export { parse as parseQuery } from './lib/query';

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    ReduxStore.dispatch(setVisible(false));
    ReduxStore.dispatch(hideModal());
  }
});

createBodyResizeObserver((body: HTMLBodyElement): void => {
  ReduxStore.dispatch(setBodyRect(body.getBoundingClientRect()));
});

if (appNode) {
  const render = (Component: ComponentType<{ locales: typeof locales }>): void => {
    const root = createRoot(appNode);

    root.render(
      <Provider store={ReduxStore}>
        <Component locales={locales} />
      </Provider>
    );
  };

  render(App);

  if (module.hot) {
    module.hot.accept('./containers/App', () => {
      render(App);
    });
  }
}

const passwordValidatorNode = document.getElementById('password-validation');

if (passwordValidatorNode) {
  const root = createRoot(passwordValidatorNode);

  root.render(<PasswordValidator forElementWithId="password" />);
}
