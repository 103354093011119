import { useEffect, useState } from 'react';
import { find, get } from 'lodash';

import { isHiringManager } from 'lib/utils';
import { useDispatch, useSelector } from 'hooks';
import { getAtsFolders } from 'slices/folders';

const useAtsFolders = () => {
  const dispatch = useDispatch();
  const [atsFolders, setAtsFolders] = useState<null | Array<AH$Folder>>(null);
  const isAtsEnabled = useSelector(
    ({ app }) =>
      get(app, 'user.company.ats', false) && !isHiringManager(app.user.role) && app.user.canSearch
  );
  const onSuccess = (folders: Array<AH$Folder>) => {
    setAtsFolders(folders);
  };

  useEffect(() => {
    if (isAtsEnabled) dispatch(getAtsFolders({ onSuccess }));
  }, [dispatch]);

  return {
    atsFolders,
    companyFolderId: get(find(atsFolders, { atsType: 'company' }), 'id', null),
  };
};

export default useAtsFolders;
