export default {
  exclude: {
    id: 'appliedTerm.exclude',
    defaultMessage: 'Exclude',
  },
  include: {
    id: 'appliedTerm.include',
    defaultMessage: 'Include',
  },
  remove: {
    id: 'appliedTerm.remove',
    defaultMessage: 'Remove',
  },
  reset: {
    id: 'appliedTerm.reset',
    defaultMessage: 'Reset',
  },
  km: {
    id: 'query.km',
    defaultMessage: 'km',
  },
  mi: {
    id: 'query.mi',
    defaultMessage: 'mi',
  },
  notSelected: {
    id: 'query.notSelected',
    defaultMessage: 'not selected',
  },
  radius: {
    id: 'query.radius',
    defaultMessage: 'Set the search radius',
  },
  years: {
    id: 'appliedTerm.years',
    defaultMessage: 'From {skillRange} years old',
  },
  yearsHint: {
    id: 'appliedTerm.yearsHint',
    defaultMessage: 'Set the number of years of experience',
  },
};
