import React, { FC, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { ContentState, Entity } from 'draft-js';
import { Map } from 'immutable';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import useDispatch from 'hooks/useDispatch';
import { getRealtimeCount } from 'slices/filters';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import { SKILL_RANGE_OPTIONS } from 'components/TokenAppliedTerm/constants';
import messages from 'components/TokenAppliedTerm/messages';
import Button from 'components/TokenTerm/LocationRangeButton';
import styles from './TokenAppliedTerm.scss';

const theme = thematize(styles);

type SkillRangeActionProps = {
  contentState: ContentState;
  entityKey: string;
  field: Map<string, any>;
};

export const SkillRangeAction: FC<SkillRangeActionProps> = ({ entityKey, contentState, field }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isSkillRange, setIsSkillRange] = useState(false);
  const entity = contentState.getEntity(entityKey);
  const { skillRange } = entity.getData();
  const isSkillAll = field.get('type') === 'skillAll';

  const handleClickDropdownSkillRange = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSkillRange(!isSkillRange);
  };

  const handleCloseDropdownSkillRange = () => {
    setIsSkillRange(false);
  };

  const handleSetSkillRange = (range: number) => {
    const changeSkillRange = contentState.getFirstBlock().getData().get('changeSkillRange') || noop;

    Entity.mergeData(entityKey, { skillRange: range === -1 ? 0 : range });

    changeSkillRange();

    dispatch(getRealtimeCount());
  };

  const handleOptionClick = (e: MouseEvent<HTMLElement>, range: number) => {
    e.preventDefault();
    e.stopPropagation();

    handleSetSkillRange(range);
    handleCloseDropdownSkillRange();
  };

  return (
    <Dropdown
      disableAnimation
      menuShouldBlockScroll
      sticky
      placement="left"
      bodyStyles={{ left: '-30px' }}
      onButtonClick={handleClickDropdownSkillRange}
      onFocusLoss={handleCloseDropdownSkillRange}
      bodyClassName={theme('location-range-body')}
      optionsOpened={isSkillRange}
      button={
        <div className={theme('location-range')}>
          <Tooltip id="locationRange" message={formatMessage(messages.yearsHint)} placement="top">
            <span className={theme('actions__icon-container', { location: false })}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={theme('actions__icon', { active: isSkillRange })}
              >
                <path
                  d="M1.92498 10.9C1.56064 10.9 1.24873 10.7702 0.989226 10.5107C0.729726 10.2513 0.599976 9.93941 0.599976 9.575V4.1C0.599976 3.73566 0.729726 3.42375 0.989226 3.16425C1.24873 2.90475 1.56064 2.775 1.92498 2.775H3.64998V1.775C3.64998 1.41066 3.77973 1.09875 4.03923 0.839247C4.29864 0.579747 4.61056 0.449997 4.97498 0.449997H7.02498C7.38931 0.449997 7.70123 0.579747 7.96073 0.839247C8.22023 1.09875 8.34998 1.41066 8.34998 1.775V2.775H10.075C10.4393 2.775 10.7512 2.90475 11.0107 3.16425C11.2702 3.42375 11.4 3.73566 11.4 4.1V9.575C11.4 9.93941 11.2702 10.2513 11.0107 10.5107C10.7512 10.7702 10.4393 10.9 10.075 10.9H1.92498ZM1.92498 9.575H10.075V4.1H1.92498V9.575ZM4.97498 2.775H7.02498V1.775H4.97498V2.775Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </Tooltip>
          {isSkillAll && !!skillRange && (
            <Button
              range={skillRange}
              format={formatMessage(messages.years, { skillRange })}
              className={theme('location-range-wrapper')}
              isHideValue
            />
          )}
        </div>
      }
    >
      <Dropdown.Option
        optionClassName={theme('location-range-option')}
        onOptionClick={(_, e): void => {
          handleOptionClick(e, -1);
        }}
        style={{ zIndex: 2080, position: 'relative' }}
      >
        {formatMessage(messages.notSelected)}
        {isSkillAll && skillRange === 0 && (
          <Icon type="check" className={theme('location-range-success')} />
        )}
      </Dropdown.Option>
      {SKILL_RANGE_OPTIONS.map((range) => (
        <Dropdown.Option
          key={range}
          optionClassName={theme('location-range-option')}
          onOptionClick={(_, e): void => {
            handleOptionClick(e, range);
          }}
        >
          {`${formatMessage(messages.years, { skillRange: range })}`}
          {isSkillAll && skillRange === range && (
            <Icon type="check" className={theme('location-range-success')} />
          )}
        </Dropdown.Option>
      ))}
    </Dropdown>
  );
};
