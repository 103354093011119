/**
 * @link https://gist.github.com/ilfroloff/5c507469ae2b953089969bb40d6e5d4f
 */

/**
 * @const
 * @type {string}
 */
const ABORTABLE_ERROR_KEY = '__abortablePromise';

function noop() {}

export interface AbortablePromise<T> extends Promise<T> {
  abort?: ((reason?: string) => () => void) | null;
}

/**
 * @typedef {Promise.<*>} AbortablePromise
 * @property {function} abort Additional method for abort original promise
 */

/**
 *
 * @param {Promise} promise
 * @returns {AbortablePromise}
 */
export default function abortablePromise(promise: AbortablePromise<any>): AbortablePromise<any> {
  /**
   * Promise never will be resolved, but can be rejected
   * @type function
   */
  let abort = null;

  /**
   * Promise never will be resolved, but can be rejected
   * @type Promise
   */
  const abortPromise: AbortablePromise<any> = new Promise((resolve, reject) => {
    abort = (): void => {
      reject(ABORTABLE_ERROR_KEY);
    };
  });

  abortPromise.catch(noop);

  const promises: AbortablePromise<any> = Promise.race([promise, abortPromise]).catch((err) =>
    // eslint-disable-next-line
    err === ABORTABLE_ERROR_KEY ? Promise.reject('ABORTED_PROMISE') : Promise.reject(err)
  );

  promises.abort = abort;

  return promises;
}

export function tryAbortPromise(promise: any): void {
  if (promise) {
    promise.abort();
  }
}
