import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { noop } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import * as api from 'lib/api';
import * as apiV6 from 'lib/apiV6';
import { camelKeysRecursive } from 'lib/object';
import { startLoading, stopLoading } from 'lib/utils';
import { getErrorHandler, handleError } from 'slices/app';
import { socialAccountCreated, socialAccountsLoading } from './app';

export enum ATS {
  Lever = 'lever',
  GreenHouse = 'greenhouse',
  GreenHouseImport = 'greenhouse_import',
  SmartRecruiters = 'smartrecruiters',
  SmartRecruitersProfileImport = 'smartrecruiters_import',
  Teamtailor = 'teamtailor',
  Workable = 'workable',
  Huntflow = 'huntflow_import',
}

export type ATSWithVacancies = Exclude<ATS, ATS.SmartRecruitersProfileImport | ATS.Huntflow>;

export type ATSState = Record<
  ATSWithVacancies,
  {
    candidateVacancies: AH$IntegrationCandidateVacancies;
    fetchingCandidateVacanciesStatus: $Values<typeof FETCHING_STATUSES>;
    fetchingVacanciesStatus: $Values<typeof FETCHING_STATUSES>;
    vacancies: Array<AH$IntegrationVacancy>;
    fetchingUsersStatus?: $Values<typeof FETCHING_STATUSES>;
    users?: Array<API$IntegrationUser>;
  }
>;

const SLICE_NAME = 'ats';

const getVacanciesApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return api.greenhouseVacancies;
  if (vendor === ATS.SmartRecruiters) return api.smartrecruitersVacancies;
  if (vendor === ATS.Lever) return apiV6.leverVacancies;
  if (vendor === ATS.Teamtailor) return api.teamtailorVacancies;
  if (vendor === ATS.Workable) return api.workableVacancies;
  return null;
};

const getCandidateVacanciesApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return api.greenhouseCandidateVacancies;
  if (vendor === ATS.SmartRecruiters) return api.smartrecruitersCandidateVacancies;
  if (vendor === ATS.Lever) return apiV6.leverCandidateVacancies;
  if (vendor === ATS.Teamtailor) return api.teamtailorCandidateVacancies;
  if (vendor === ATS.Workable) return api.workableCandidateVacancies;
  return null;
};

const getSelectCandidateApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return api.greenhouseSelectCandidate;
  if (vendor === ATS.SmartRecruiters) return api.smartrecruitersSelectCandidate;
  if (vendor === ATS.Lever) return apiV6.leverSelectCandidate;
  if (vendor === ATS.Teamtailor) return api.teamtailorSelectCandidate;
  if (vendor === ATS.Workable) return api.workableSelectCandidate;
  return null;
};

const getExportFolderToATSApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return api.exportGreenhouseVacancy;
  if (vendor === ATS.SmartRecruiters) return api.exportSmartrecruitersVacancy;
  if (vendor === ATS.Lever) return api.exportFolderToLever;
  if (vendor === ATS.Teamtailor) return api.exportFolderToTeamtailor;
  if (vendor === ATS.Workable) return api.exportFolderToWorkable;

  return null;
};

const getConnectATSApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return apiV6.connectGreenhouse;
  if (vendor === ATS.GreenHouseImport) return apiV6.connectGreenhouseImport;
  if (vendor === ATS.Teamtailor) return apiV6.connectTeamtailor;
  if (vendor === ATS.SmartRecruitersProfileImport)
    return apiV6.connectSmartRecruitersImportProfiles;
  return null;
};

const getUpdateCandidateApi = (vendor: ATS) => {
  if (vendor === ATS.GreenHouse) return api.greenhouseUpdateCandidate;
  if (vendor === ATS.SmartRecruiters) return api.smartrecruitersUpdateCandidate;
  if (vendor === ATS.Teamtailor) return api.teamtailorUpdateCandidate;
  if (vendor === ATS.Workable) return api.workableUpdateCandidate;
  return null;
};

const getAutoUpdateCandidateApi = (vendor: ATS, enable: boolean) => {
  if (vendor === ATS.GreenHouse)
    return enable ? api.greenhouseEnableAutoUpdate : api.greenhouseDisableAutoUpdate;
  if (vendor === ATS.Teamtailor)
    return enable ? api.teamtailorEnableAutoUpdate : api.teamtailorDisableAutoUpdate;
  return null;
};

const getUsersApi = (vendor: ATS) => {
  if (vendor === ATS.Lever) return apiV6.leverGetUsers;
  return null;
};

const getSelectUserApi = (vendor: ATS) => {
  if (vendor === ATS.Lever) return apiV6.leverSelectUser;
  return null;
};

const vacanciesLoading = createAction<ATSWithVacancies>(`${SLICE_NAME}/vacanciesLoading`);
const vacanciesErrored = createAction<ATSWithVacancies>(`${SLICE_NAME}/vacanciesErrored`);
const candidateVacanciesLoading = createAction<ATSWithVacancies>(
  `${SLICE_NAME}/candidateVacanciesLoading`
);
const candidateVacanciesErrored = createAction<ATSWithVacancies>(
  `${SLICE_NAME}/candidateVacanciesErrored`
);
const atsUserLoading = createAction<ATSWithVacancies>(`${SLICE_NAME}/atsUserLoading`);
const atsUserErrored = createAction<ATSWithVacancies>(`${SLICE_NAME}/atsUserErrored`);

export const receiveVacancies = createAsyncThunk(
  `${SLICE_NAME}/receiveVacancies`,
  async (
    {
      vendor,
      onSuccess,
    }: { vendor: ATSWithVacancies; onSuccess?: (vacancies: Array<AH$IntegrationVacancy>) => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const getVacancies = getVacanciesApi(vendor);

      if (getVacancies) {
        dispatch(vacanciesLoading(vendor));
        const { payload } = await getVacancies();

        if (onSuccess) {
          onSuccess(payload);
        }

        return { vendor, data: payload };
      }

      return undefined;
    } catch (e) {
      getErrorHandler({
        getError: () => {
          dispatch(vacanciesErrored(vendor));
        },
      })(e);

      return rejectWithValue(vendor);
    }
  }
);

export const receiveCandidateVacancies = createAsyncThunk(
  `${SLICE_NAME}/receiveCandidateVacancies`,
  async (
    {
      vendor,
      profileId,
      onSuccess,
    }: {
      profileId: number | string;
      vendor: ATSWithVacancies;
      onSuccess?: (vacancies: AH$IntegrationCandidateVacancies) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const getCandidateVacancies = getCandidateVacanciesApi(vendor);

      if (getCandidateVacancies) {
        dispatch(candidateVacanciesLoading(vendor));

        const { payload } = await getCandidateVacancies(profileId);
        const candidateVacancies = {
          duplicates:
            payload.duplicates &&
            payload.duplicates.map(({ applications, candidate_id, candidate_name, url }) => ({
              applications: applications.map<AH$IntegrationCandidateVacancy>(camelKeysRecursive),
              candidateId: candidate_id,
              candidateName: candidate_name,
              url,
            })),
          newVacancies: payload.new_vacancies.map<AH$IntegrationVacancy>(camelKeysRecursive),
          updatesSubscription: payload.updates_subscription,
          companyId: payload.company_id,
        };

        if (onSuccess) {
          onSuccess(candidateVacancies);
        }

        return {
          vendor,
          data: candidateVacancies,
        };
      }

      return undefined;
    } catch (e) {
      getErrorHandler({
        getError: () => dispatch(candidateVacanciesErrored(vendor)),
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const selectCandidate = createAsyncThunk(
  `${SLICE_NAME}/selectCandidate`,
  async (
    {
      vendor,
      profileId,
      jobId,
      asProspect,
    }: {
      profileId: number;
      vendor: ATSWithVacancies;
      asProspect?: boolean;
      jobId?: number | string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const selectCandidate = getSelectCandidateApi(vendor);

      if (selectCandidate) {
        dispatch(candidateVacanciesLoading(vendor));
        dispatch(vacanciesLoading(vendor));

        await selectCandidate(profileId, jobId, asProspect);
        dispatch(receiveCandidateVacancies({ vendor, profileId }));
      }
    } catch (e) {
      getErrorHandler({
        getError: () => {
          dispatch(candidateVacanciesErrored(vendor));
          dispatch(vacanciesErrored(vendor));
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const exportFolderToATS = createAsyncThunk(
  `${SLICE_NAME}/exportFolderToATS`,
  async (
    {
      vendor,
      folderId,
      asProspect,
      candidateIds,
      autoUpdate,
      onSuccess,
      onError,
      jobId = null,
      type = 'folder',
    }: {
      asProspect: boolean;
      autoUpdate: boolean;
      candidateIds: Array<number> | null;
      folderId: number;
      jobId: number | string | null;
      onError: () => void;
      onSuccess: () => void;
      type: 'folder' | 'sequence';
      vendor: ATSWithVacancies;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const exportFolderToATS = getExportFolderToATSApi(vendor);

      if (exportFolderToATS) {
        dispatch(vacanciesLoading(vendor));
        await exportFolderToATS(type, folderId, jobId, candidateIds, asProspect, autoUpdate);
        dispatch(receiveVacancies({ vendor }));
        onSuccess();
      }
    } catch (e) {
      getErrorHandler({
        getError: () => {
          dispatch(vacanciesErrored(vendor));
          onError();
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const connectATS = createAsyncThunk(
  `${SLICE_NAME}/connectATS`,
  async (
    {
      vendor,
      token,
      email,
      onSuccess,
      onError,
    }: {
      email: string;
      token: string;
      vendor: ATS;
      onError?: (err: Partial<Record<ATS | 'email', string>>) => void;
      onSuccess?: () => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(socialAccountsLoading(vendor));
      startLoading();
      const connect = getConnectATSApi(vendor);

      if (connect) {
        await connect(token, email);
        const createTime = new Date().toISOString();

        stopLoading();

        if (onSuccess) {
          onSuccess();
        }

        dispatch(
          socialAccountCreated({
            vendor,
            active: true,
            create_date: createTime,
            last_refresh_date: createTime,
          })
        );
      }

      return undefined;
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (err: Record<string, string | string[] | Record<string, string>>) => {
          if (err.detail && typeof err.detail === 'string')
            handleError({ content: JSON.parse(err.detail).message });
          else if (err.error) handleError({ content: err.error });
          else if (onError) {
            onError(
              Object.keys(err).reduce((acc, key) => {
                const currentVal = err[key];

                if (Array.isArray(currentVal)) {
                  return {
                    ...acc,
                    [key === 'email' ? key : vendor]: currentVal[0],
                  };
                }
                if (typeof currentVal !== 'string' && 'message' in currentVal) {
                  return {
                    ...acc,
                    [vendor]: currentVal.message,
                  };
                }
                throw err;
              }, {} as Record<string, string>)
            );
          }
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const connectHuntflowImportProfiles = createAsyncThunk(
  `${SLICE_NAME}/connectHuntflowImportProfiles`,
  async (
    {
      connectionLink,
      huntflowPremier,
      connectionAPI,
      onSuccess,
      onError,
    }: {
      connectionLink: string;
      huntflowPremier: boolean;
      connectionAPI?: string;
      onError?: (err: Partial<Record<'connection_link' | 'huntflow_api_url', string>>) => void;
      onSuccess?: () => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    const vendor = ATS.Huntflow;

    try {
      startLoading();

      await apiV6.connectHunflowImportProfiles(connectionLink, huntflowPremier, connectionAPI);
      const createTime = new Date().toISOString();

      stopLoading();

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        socialAccountCreated({
          vendor,
          active: true,
          create_date: createTime,
          last_refresh_date: createTime,
        })
      );
    } catch (e) {
      getErrorHandler({
        parseError: true,
        getError: (err: Record<string, string | string[] | Record<string, string>>) => {
          if (err.detail && typeof err.detail === 'string')
            handleError({ content: JSON.parse(err.detail).message });
          else if (err.error) handleError({ content: err.error });
          else if (onError) {
            onError(
              Object.keys(err).reduce((acc, key) => {
                const currentVal = err[key];

                if (Array.isArray(currentVal)) {
                  return {
                    ...acc,
                    [key]: currentVal[0],
                  };
                }
                if (typeof currentVal !== 'string' && 'message' in currentVal) {
                  return {
                    ...acc,
                    [vendor]: currentVal.message,
                  };
                }
                throw err;
              }, {} as Record<string, string>)
            );
          }
        },
      })(e);

      rejectWithValue(e);
    }
  }
);

export const updateCandidate = createAsyncThunk(
  `${SLICE_NAME}/updateCandidate`,
  async (
    { vendor, profileId, onSuccess }: { onSuccess: () => void; profileId: number; vendor: ATS },
    { rejectWithValue }
  ) => {
    try {
      startLoading();
      const update = getUpdateCandidateApi(vendor);

      if (update) {
        await update(profileId);
        stopLoading();
        onSuccess();
      }
    } catch (e) {
      getErrorHandler()(e);
      rejectWithValue(e);
    }
  }
);

export const toggleAutoUpdate = createAsyncThunk(
  `${SLICE_NAME}/toggleAutoUpdate`,
  async (
    { vendor, profileId, enable }: { enable: boolean; profileId: number; vendor: ATSWithVacancies },
    { rejectWithValue, dispatch }
  ) => {
    try {
      startLoading();
      const autoUpdate = getAutoUpdateCandidateApi(vendor, enable);

      if (autoUpdate) {
        await autoUpdate(profileId);
        dispatch(receiveCandidateVacancies({ vendor, profileId, onSuccess: () => stopLoading() }));
      }
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const getUsers = createAsyncThunk(
  `${SLICE_NAME}/getUsers`,
  async (vendor: ATSWithVacancies, { rejectWithValue, dispatch }) => {
    try {
      const usersApi = getUsersApi(vendor);

      if (usersApi) {
        dispatch(atsUserLoading(vendor));
        const { payload } = await usersApi();

        return {
          data: payload,
          vendor,
        };
      }

      return undefined;
    } catch (e) {
      getErrorHandler({
        getError: () => {
          dispatch(atsUserErrored(vendor));
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const selectUser = createAsyncThunk(
  `${SLICE_NAME}/selectUser`,
  async (
    { vendor, userId, onSuccess = noop }: { onSuccess: () => void; userId: string; vendor: ATS },
    { rejectWithValue }
  ) => {
    try {
      const selectUserApi = getSelectUserApi(vendor);

      if (selectUserApi) {
        startLoading();

        await selectUserApi(userId);
        stopLoading();
        onSuccess();
      }
    } catch (e) {
      getErrorHandler()(e);

      rejectWithValue(e);
    }
  }
);

export const atsInitialState: ATSState = {
  greenhouse: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
  },
  greenhouse_import: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
  },
  smartrecruiters: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
  },
  lever: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
    users: [],
    fetchingUsersStatus: FETCHING_STATUSES.LOADING,
  },
  teamtailor: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
  },
  workable: {
    vacancies: [],
    candidateVacancies: {} as AH$IntegrationCandidateVacancies,
    fetchingVacanciesStatus: FETCHING_STATUSES.LOADING,
    fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
  },
};

export const atsSlice = createSlice({
  name: SLICE_NAME,
  initialState: atsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(vacanciesLoading, (state, action) => {
      state[action.payload].fetchingVacanciesStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(vacanciesErrored, (state, action) => {
      state[action.payload] = {
        ...state[action.payload],
        fetchingVacanciesStatus: FETCHING_STATUSES.ERROR,
      };
    });
    builder.addCase(receiveVacancies.fulfilled, (state, action) => {
      if (action.payload) {
        const { vendor } = action.payload;

        state[vendor] = {
          ...state[vendor],
          vacancies: action.payload.data,
          fetchingVacanciesStatus: FETCHING_STATUSES.SUCCESS,
        };
      }
    });
    builder.addCase(candidateVacanciesLoading, (state, action) => {
      state[action.payload] = {
        ...state[action.payload],
        fetchingCandidateVacanciesStatus: FETCHING_STATUSES.LOADING,
      };
    });
    builder.addCase(candidateVacanciesErrored, (state, action) => {
      state[action.payload] = {
        ...state[action.payload],
        fetchingCandidateVacanciesStatus: FETCHING_STATUSES.ERROR,
      };
    });
    builder.addCase(receiveCandidateVacancies.fulfilled, (state, action) => {
      if (action.payload) {
        const { vendor } = action.payload;

        state[vendor] = {
          ...state[vendor],
          candidateVacancies: action.payload.data,
          fetchingCandidateVacanciesStatus: FETCHING_STATUSES.SUCCESS,
        };
      }
    });
    builder.addCase(atsUserLoading, (state, action) => {
      state[action.payload] = {
        ...atsInitialState[action.payload],
        fetchingUsersStatus: FETCHING_STATUSES.LOADING,
      };
    });
    builder.addCase(atsUserErrored, (state, action) => {
      state[action.payload] = {
        ...state[action.payload],
        fetchingUsersStatus: FETCHING_STATUSES.ERROR,
      };
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state[action.payload.vendor] = {
          ...state[action.payload.vendor],
          users: action.payload.data,
          fetchingUsersStatus: FETCHING_STATUSES.SUCCESS,
        };
      }
    });
  },
});

export const ats = atsSlice.reducer;
