import { ComponentProps, ReactNode } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  ContentBlock,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from 'draft-js';
import { get, isNumber } from 'lodash';

import * as tracking from 'tracking';
import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import AddImage from './AddImage';
import AlignText from './AlignText';
import DndWrapper from './DndWrapper';
import FontControl, { FONT_STYLES, fontStyleMap } from './FontControl';
import InsertLink from './InsertLink';
import {
  alignWithImages,
  deleteImage,
  getSelectedBlocks,
  insertVariable,
  mergeEntityData,
  modifyImages,
  parseVariables,
  transformEditorSelection,
} from './lib';
import mediaBlockRenderer from './Media';
import { ImageContext } from './Media/Image';
import messages from './messages';
import UploadFile from './UploadFile';
import VarControl from './VarControl';
import styles from './MessageEditor.scss';

const theme = thematize(styles);
const BLOCK_TYPES = [
  { label: 'bulletedList', style: 'unordered-list-item', iconType: 'unorderedList' },
  { label: 'numberedList', style: 'ordered-list-item', iconType: 'orderedList' },
] as const;
const INLINE_STYLES = [
  { label: 'bold', style: 'BOLD', iconType: 'bold' },
  { label: 'italic', style: 'ITALIC', iconType: 'italic' },
] as const;

const getBlockStyle = (block: ContentBlock): string => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'alignLeft':
      return 'RichEditor-align-left';
    case 'alignCenter':
      return 'RichEditor-align-center';
    case 'alignRight':
      return 'RichEditor-align-right';
    default:
      return '';
  }
};

interface Props {
  barClassName: string;
  className: string;
  editorState: EditorState;
  isMassMailing: boolean;
  mailingVariables: Array<AH$MailingVariable>;
  onChange: (editorState: EditorState) => void;
  readOnly: boolean;
  type: tracking.MESSAGE_EDITOR_TYPES;
  accessType?: AH$MailingList['accessType'];
  aside?: React.ReactElement;
  attachments?: React.ReactElement;
  index?: number;
  mod?: 'editor' | 'subject' | 'signature' | 'notification';
  onAddVar?: (text: string) => void;
  onFileUpload?: (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>,
    isInlineImage?: boolean
  ) => void;
  onFocusChange?: (isFocused: boolean) => void;
  placeholder?: string;
  preStyleBar?: ReactNode;
  transparentStyleBar?: boolean;
}

interface State {
  focusedImageId: number | null;
  openDropdownCount: number;
}

class Index extends React.Component<Props, State> {
  private editorBodyRef: React.RefObject<HTMLDivElement>;

  private editorContainerRef: React.RefObject<HTMLDivElement>;

  static defaultProps = {
    mod: 'editor',
    readOnly: false,
    className: '',
    barClassName: '',
    isMassMailing: false,
    type: tracking.MESSAGE_EDITOR_TYPES.message,
  };

  editor: Editor | null = null;

  constructor(props: Props) {
    super(props);
    this.editorBodyRef = React.createRef();
    this.editorContainerRef = React.createRef();
  }

  state: State = {
    openDropdownCount: 0,
    focusedImageId: null,
  };

  componentDidMount(): void {
    document.body.addEventListener('click', this.handleDocumentClick);
    document.body.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener('click', this.handleDocumentClick);
    document.body.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (e: KeyboardEvent): void => {
    if (e.key === 'Backspace' && isNumber(this.state.focusedImageId)) {
      this.props.onChange(deleteImage(this.props.editorState, this.state.focusedImageId));
      this.setState({ focusedImageId: null });
    }
  };

  handleDocumentClick = (e: MouseEvent): void => {
    if (
      isNumber(this.state.focusedImageId) &&
      (!this.editorContainerRef ||
        !this.editorContainerRef.current ||
        (e.target instanceof Node && !this.editorContainerRef.current.contains(e.target)))
    ) {
      this.setState({ focusedImageId: null });
    }
  };

  focus = (): void => {
    if (isNumber(this.state.focusedImageId)) {
      this.setState(
        {
          focusedImageId: null,
        },
        () => {
          (this.editor as any).focus();
        }
      );
    } else {
      (this.editor as any).focus();
    }
  };

  handleDropdownOpen = (): void => {
    this.setState(({ openDropdownCount }) => ({ openDropdownCount: openDropdownCount + 1 }));
  };

  handleDropdownClose = (): void => {
    if (this.state.openDropdownCount !== 0)
      this.setState(({ openDropdownCount }) => ({ openDropdownCount: openDropdownCount - 1 }));
  };

  handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.props.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handlePastedText = (text: string): DraftHandleValue => {
    if (this.props.mod === 'subject') {
      this.props.onChange(
        EditorState.push(
          this.props.editorState,
          Modifier.replaceText(
            this.props.editorState.getCurrentContent(),
            this.props.editorState.getSelection(),
            text.replace(/\n/g, ' ')
          ),
          'change-block-data'
        )
      );
      return 'handled';
    }

    return 'not-handled';
  };

  handleVarInsert = (text: string): void => {
    tracking.insertMessageEditorVariable(
      this.props.mod === 'subject' ? 'Subject' : 'Body',
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(insertVariable(this.props.editorState, text));
  };

  handleAddVar = (text: string): void => {
    if (this.props.onAddVar) {
      tracking.createMessageEditorVariable(
        this.props.mod === 'subject' ? 'Subject' : 'Body',
        this.props.isMassMailing,
        this.props.type,
        this.props.accessType
      );
      this.props.onAddVar(text);
      this.handleVarInsert(`{{${text}}}`);
    }
  };

  mapKeyToEditorCommand = (e: React.KeyboardEvent): string | null => {
    const KEY_CODES = {
      TAB: 9,
    };

    if (e.keyCode === KEY_CODES.TAB) {
      const newEditorState = RichUtils.onTab(e, this.props.editorState, 4 /* maxDepth */);

      if (newEditorState !== this.props.editorState) {
        this.props.onChange(newEditorState);
      }
      return null;
    }
    // eslint-disable-next-line
    return getDefaultKeyBinding(e) as string;
  };

  toggleBlockType = (blockType: string): void => {
    tracking.toggleMessageEditorStyling(
      blockType.toLowerCase(),
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle: string): void => {
    const prevSelection = this.props.editorState.getSelection();
    const editorState = transformEditorSelection(this.props.editorState);
    const formattedEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    const currentEditorState =
      !editorState.getSelection().isCollapsed() && prevSelection.isCollapsed()
        ? EditorState.acceptSelection(formattedEditorState, prevSelection)
        : formattedEditorState;

    tracking.toggleMessageEditorStyling(
      inlineStyle.toLowerCase(),
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(currentEditorState);
  };

  clearFormatting = (): void => {
    const { editorState } = this.props;
    const prevSelection = editorState.getSelection();
    const transformedEditorState = transformEditorSelection(editorState);
    const currentSelection = prevSelection.isCollapsed()
      ? prevSelection
      : transformedEditorState.getSelection();
    const contentWithoutEntities = Modifier.applyEntity(
      editorState.getCurrentContent(),
      currentSelection,
      null
    );
    const unstyledBlocksContent = Modifier.setBlockType(
      contentWithoutEntities,
      currentSelection,
      'unstyled'
    );
    const unstyledContent = [...INLINE_STYLES, ...FONT_STYLES].reduce(
      (contentState, { style }) =>
        Modifier.removeInlineStyle(contentState, currentSelection, style),
      unstyledBlocksContent
    );

    tracking.clearMessageEditorFormatting(
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(EditorState.push(editorState, unstyledContent, 'change-inline-style'));
  };

  handleChange = (editorState: EditorState): void => {
    const usedVariables = this.props.mailingVariables.map(({ name }) => `{{${name}}}`);
    const newEditorState = parseVariables(editorState, usedVariables);

    this.props.onChange(modifyImages(newEditorState));
  };

  handleFontSizeChange = (editorState: EditorState): void => {
    tracking.toggleMessageEditorStyling(
      'font-size',
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(editorState);
  };

  handleInsertLink = (editorState: EditorState): void => {
    tracking.insertLinkInMessageEditor(
      this.props.isMassMailing,
      this.props.type,
      this.props.accessType
    );
    this.props.onChange(editorState);
  };

  handleAddImage = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>
  ): void => {
    if (this.props.onFileUpload) {
      this.props.onFileUpload(e, true);
    }
  };

  handleAlignment = (type: string): void => {
    const selection = this.props.editorState.getSelection();
    const isSelectionCollapsed = selection.isCollapsed();

    if (!isSelectionCollapsed) {
      const selectedBlocks = getSelectedBlocks(
        this.props.editorState.getCurrentContent(),
        selection.getStartKey(),
        selection.getEndKey()
      );

      if (selectedBlocks.some((block) => block.getType() === 'atomic')) {
        this.props.onChange(alignWithImages(this.props.editorState, type));
        tracking.toggleMessageEditorStyling(
          type.toLowerCase(),
          this.props.isMassMailing,
          this.props.type,
          this.props.accessType
        );
        return;
      }
    }

    this.toggleBlockType(type);
  };

  handleImageFocus = (id: number | null): void => {
    this.setState({ focusedImageId: id });
  };

  handleImagePropertiesChange = (entityKey: string, props: Record<string, any>): void => {
    this.props.onChange(mergeEntityData(this.props.editorState, entityKey, props));
    if (props.width)
      tracking.resizeImage(this.props.isMassMailing, this.props.type, this.props.accessType);
  };

  renderEditor = (): React.ReactElement => {
    const { mod, placeholder, editorState, readOnly, className, onFocusChange } = this.props;

    return (
      <ImageContext.Provider
        value={{
          focusedImageId: this.state.focusedImageId,
          editorWidth: (this.editor as any)?.editor.clientWidth || 0,
          onFocus: this.handleImageFocus,
          onPropertiesChange: this.handleImagePropertiesChange,
        }}
      >
        <div
          className={`${theme('editor', { mod })} ${className}`}
          onClick={this.focus}
          ref={this.editorContainerRef}
          {...dataQa(mod as string)}
        >
          <Editor
            stripPastedStyles={mod === 'subject'}
            // @ts-ignore
            className={theme('editor')}
            placeholder={placeholder}
            readOnly={readOnly || isNumber(this.state.focusedImageId)}
            blockStyleFn={getBlockStyle}
            customStyleMap={fontStyleMap}
            editorState={editorState}
            handlePastedText={this.handlePastedText}
            handleKeyCommand={this.handleKeyCommand}
            handleReturn={(): DraftHandleValue => (mod === 'subject' ? 'handled' : 'not-handled')}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.handleChange}
            ref={(ref) => {
              this.editor = ref;
            }}
            blockRendererFn={mediaBlockRenderer}
            onFocus={() => onFocusChange?.(true)}
            onBlur={() => onFocusChange?.(false)}
            spellCheck
          />
        </div>
      </ImageContext.Provider>
    );
  };

  render(): React.ReactElement {
    const {
      mod,
      editorState,
      mailingVariables,
      readOnly,
      type,
      barClassName,
      transparentStyleBar,
      preStyleBar,
    } = this.props;
    const { openDropdownCount } = this.state;
    const isAttachmentsEnabled =
      mod === 'editor' && type !== tracking.MESSAGE_EDITOR_TYPES.template;

    return (
      <div className={theme('container', { mod })} ref={this.editorBodyRef}>
        {mod !== 'subject' ? (
          <DndWrapper onFileDrop={this.props.onFileUpload}>{this.renderEditor()}</DndWrapper>
        ) : (
          this.renderEditor()
        )}
        {isAttachmentsEnabled && this.props.attachments}
        {preStyleBar}
        {!readOnly && (mod !== 'subject' || mailingVariables.length > 0) && (
          <div
            className={`${theme('style-bar', {
              mod,
              visible: this.props.editorState.getSelection().getHasFocus() || !!openDropdownCount,
              transparent: transparentStyleBar,
            })} ${barClassName}`}
          >
            <div className={theme('style-bar-content')}>
              {['editor', 'signature', 'notification'].includes(mod as string) && (
                <>
                  <FontControl
                    editorState={editorState}
                    onChange={this.handleFontSizeChange}
                    onDropdownOpen={this.handleDropdownOpen}
                    onDropdownClose={this.handleDropdownClose}
                  />
                  <AlignText
                    editorState={editorState}
                    onChange={this.handleAlignment}
                    onDropdownOpen={this.handleDropdownOpen}
                    onDropdownClose={this.handleDropdownClose}
                  />
                  <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
                  <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                  />
                  {mod !== 'notification' && (
                    <InsertLink
                      editorState={editorState}
                      onChange={this.handleInsertLink}
                      focusEditor={this.focus}
                      isAnyDropdownOpen={!!openDropdownCount}
                    />
                  )}
                  <ClearFormatting onClick={this.clearFormatting} />
                </>
              )}
              {mailingVariables.length > 0 && mod !== 'notification' && (
                <VarControl
                  mod={mod}
                  onChange={this.handleVarInsert}
                  onAddVar={this.props.onAddVar ? this.handleAddVar : undefined}
                  mailingVariables={mailingVariables}
                  onDropdownOpen={this.handleDropdownOpen}
                  onDropdownClose={this.handleDropdownClose}
                  focusEditor={this.focus}
                  containerHeight={get(this.editorBodyRef.current, 'offsetHeight', null)}
                />
              )}
              {isAttachmentsEnabled && this.props.onFileUpload && (
                <UploadFile onFileUpload={this.props.onFileUpload} index={this.props.index} />
              )}
              {(mod === 'editor' || mod === 'signature') && this.props.onFileUpload && (
                <AddImage onImageAdd={this.handleAddImage} index={this.props.index} />
              )}
            </div>
            {this.props.aside}
          </div>
        )}
      </div>
    );
  }
}

interface StyleButtonProps {
  active: boolean;
  iconType: ComponentProps<typeof Icon>['type'];
  label: string;
  onToggle: (val: string) => void;
  style: string;
}

const StyleButton = ({
  style,
  label,
  active,
  onToggle,
  iconType,
}: StyleButtonProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      id={`${label}-tooltip`}
      message={formatMessage(messages[label as keyof typeof messages])}
    >
      <Button
        {...dataQa(label)}
        className={theme('button')}
        color={COLORS.default}
        appearance={active ? APPEARANCES.filled : APPEARANCES.text}
        onMouseDown={(e): void => {
          e.preventDefault();
          onToggle(style);
        }}
      >
        <Icon type={iconType} />
      </Button>
    </Tooltip>
  );
};

interface BlockStyleControlsProps {
  editorState: EditorState;
  onToggle: (val: string) => void;
}

const BlockStyleControls = ({
  editorState,
  onToggle,
}: BlockStyleControlsProps): React.ReactElement => {
  const isActive = (editorState: EditorState, style: string): boolean =>
    style ===
    editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();

  return (
    <div>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={isActive(editorState, type.style)}
          label={type.label}
          iconType={type.iconType}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

interface InlineStyleControlsProps {
  editorState: EditorState;
  onToggle: (val: string) => void;
}

const InlineStyleControls = ({
  editorState,
  onToggle,
}: InlineStyleControlsProps): React.ReactElement => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          iconType={type.iconType}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

interface ClearFormattingProps {
  onClick: () => void;
}

const ClearFormatting = ({ onClick }: ClearFormattingProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Tooltip id="formatting-tooltip" message={formatMessage(messages.removeFormatting)}>
        <Button
          className={theme('button')}
          color={COLORS.default}
          appearance={APPEARANCES.text}
          onMouseDown={(e): void => {
            e.preventDefault();
            onClick();
          }}
          {...dataQa('clear-formatting')}
        >
          <Icon type="removeFormat" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Index;
