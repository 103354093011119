import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

const Category = CATEGORIES.ats;
const Location = LOCATIONS.ats;

export enum ProfileCreateTypes {
  fileClick = 'File Click',
  fileDnD = 'File DnD',
  form = 'Form',
}

export const createProfile = (
  Profile_Create_Type: ProfileCreateTypes,
  Profile_Form_Sections: Array<string>
): void => {
  sendEvent('Create Profile', { Location, Category, Profile_Create_Type, Profile_Form_Sections });
};

export const editProfile = (Profile_Form_Sections: Array<string>): void => {
  sendEvent('Edit Profile', { Location, Category, Profile_Form_Sections });
};
