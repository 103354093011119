import { FC, useCallback } from 'react';

import { setSystemNotificationStatus } from 'lib/apiV6';
import { useDispatch } from 'hooks';
import { getCurrentUser } from 'slices/app';
import { metrics } from '../metrics';
import DefaultShortNotification from './DefaultShortNotification';

type ShortNotificationProps = {
  handleClose: () => void;
  handleLearnMoreClick: () => void;
  notification: AH$SystemNotification;
};

const ShortNotification: FC<ShortNotificationProps> = ({
  notification,
  handleLearnMoreClick,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const handleHideClick = useCallback(() => {
    setSystemNotificationStatus(notification.id, 'hidden').then(() => {
      dispatch(getCurrentUser());
    });
    handleClose();
    metrics.clickOnHide();
  }, [handleClose, notification.id]);

  switch (notification.type) {
    default:
      return (
        <DefaultShortNotification
          notification={notification}
          handleHideClick={handleHideClick}
          handleLearnMoreClick={handleLearnMoreClick}
        />
      );
  }
};

export default ShortNotification;
