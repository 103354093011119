import { createContext, useContext } from 'react';

export type SequenceProfileViewContextType = {
  customData: Record<string, AH$TransformedMailingVariable>;
  setCustomData?: (data: Record<string, AH$TransformedMailingVariable>) => void;
};

const defaultContext = {
  customData: {},
  setCustomData: undefined,
};

const SequenceProfileViewContext = createContext<SequenceProfileViewContextType>(defaultContext);

export const useSequenceProfileViewContext = (): SequenceProfileViewContextType =>
  useContext(SequenceProfileViewContext);

export default SequenceProfileViewContext;
