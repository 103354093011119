import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import thematize from 'lib/thematize';
import { useDidMount } from 'hooks';
import Button from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Input from 'components/Base/Input';
import messages from './messages';
import styles from './AddVar.scss';

const theme = thematize(styles);

const varRegex = /^[A-ZА-Я0-9_]+$/;

interface AddVarProps {
  onAdd: (value: string, e: MouseEvent<HTMLElement>) => void;
  onCancel: (e: MouseEvent<HTMLElement>) => void;
}

const AddVar: FC<AddVarProps> = ({ onAdd, onCancel }) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const didMount = useDidMount();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.toUpperCase();

    if (isEmpty(value) || (varRegex.test(value) && value.length <= 50)) {
      setValue(value);
      setIsInvalid(false);
    }
  };

  const handleAdd = (e: MouseEvent<HTMLElement>): void => {
    if (isEmpty(value)) {
      setIsInvalid(true);
    } else {
      onAdd(value, e);
    }
  };

  useEffect(() => {
    if (ref && ref.current && didMount) {
      ref.current.focus();
    }
  }, [didMount]);

  return (
    <div className={theme('container')}>
      <div className={theme('title')}>
        <FormattedMessage {...messages.newVariable} />
      </div>
      <Input
        ref={ref}
        invalid={isInvalid}
        value={value}
        onChange={handleChange}
        id="var"
        appearance="lined"
        className={theme('input')}
        placeholder={formatMessage(messages.variableName)}
      />
      <div className={theme('buttons')}>
        <Button block className={theme('button')} color={COLORS.primary} onClick={handleAdd}>
          <FormattedMessage {...messages.add} />
        </Button>
        <Button block className={theme('button')} color={COLORS.grey} onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
    </div>
  );
};

export default AddVar;
