import { Modal } from 'react-bootstrap';
import { noop } from 'lodash';

import { useDispatch, useSelector } from 'hooks';
import { setDialogModal } from 'slices/app';

const DialogModal = () => {
  const dispatch = useDispatch();
  const dialogModal: AH$Modal | null = useSelector(({ app }) => app.dialogModal);

  if (!dialogModal) {
    return null;
  }

  const { show, content, className = '' } = dialogModal;
  const handleExited = (): void => {
    dispatch(setDialogModal());
  };

  return (
    <Modal
      onHide={noop}
      show={show}
      onExited={handleExited}
      backdrop="static"
      enforceFocus={false}
      dialogClassName={`modal-dialog_app ${className}`}
      backdropClassName="modal-backdrop_app"
    >
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

export default DialogModal;
