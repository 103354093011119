import { convertFromRaw } from 'draft-js';
import {
  filter,
  find,
  get,
  isArray,
  isEmpty,
  map,
  mapKeys,
  mapValues,
  omit,
  reject,
  sortBy,
  toInteger,
  values,
} from 'lodash';

import { camelKeys, camelKeysRecursive } from 'lib/object';
import { transformTemplate } from 'components/Messaging/Message/lib';

interface RawInvalidFieldsType {
  [key: string]: Array<string>;
}

export const convertValidationMessages = (messages: RawInvalidFieldsType): AH$InvalidFields =>
  mapKeys(
    mapValues(messages, (message) => message[0]),
    (_, key) => {
      if (key === 'access_type') {
        return 'permissions';
      }
      if (key === 'candidate_statuses') {
        return 'statuses';
      }
      if (key === 'granted_users') {
        return 'hiringManagers';
      }
      return key;
    }
  );

export const stripDescription = (description: string): string =>
  description && description.replace(/<br ?\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '');

export const filterUsers = (users: Array<API$Assignee>): Array<AH$AssigneeShort> =>
  users.filter(({ is_active }) => is_active).map(({ id, name }) => ({ id, name }));

export const getAssigneesIds = <T extends { id: number }>(assignees: Array<T>): Array<number> =>
  assignees.map(({ id }) => id);

export const transformCandidate = ({
  profile,
  status,
  id,
}: API$Candidate): API$Profile & { candidateId: number; status: AH$CandidateStatus } => ({
  ...(profile as API$Profile),
  profileEvents: [], // TODO: при необходимости подставить правильные ивенты
  candidateId: id,
  status,
});

export const flattenStatuses = (statuses: Array<AH$Status>): Array<AH$Status> =>
  statuses.reduce((acc, rawStatus) => {
    const status = { ...rawStatus, substatus: false };

    if (status.substatuses.length) {
      const substatuses = status.substatuses.map((substatus) => ({
        ...substatus,
        substatus: true,
      }));

      return [...acc, status, ...substatuses];
    }

    return [...acc, status];
  }, [] as Array<AH$Status>);

export const convertMessagingStatusesToUrl = (
  messagingStatuses: Array<{ mailingListId: number; status: string }>
): Array<string> =>
  messagingStatuses.map<string>((status) => `${status.mailingListId},${status.status}`);

export const getIncludedStatuses = (
  status: API$MailingRecipientsStatus
): Array<API$MailingRecipientsStatus> => {
  if (status === 'opened') return ['replied'];
  if (status === 'sent') return ['opened', ...getIncludedStatuses('opened')];
  return [];
};

export const getCountWithIncludedStatuses = (
  stats: AH$MailingList['stats'],
  key: string
): number => {
  let increase = 0;

  if (key === 'opened') {
    increase = getCountWithIncludedStatuses(stats, 'replied');
  } else if (key === 'sent') {
    increase = getCountWithIncludedStatuses(stats, 'opened');
  }

  if (key === 'error') {
    return values(get(stats, 'error', {})).reduce((acc, val) => acc + val, 0);
  }

  return get(stats, key, 0) + increase;
};

export const convertStatusesFromUrl = (statuses: Array<string> = []): Array<number> =>
  isArray(statuses) ? statuses.map(toInteger) : [toInteger(statuses)];

export const transformStatuses = (statuses: Array<API$Status>): Array<AH$Status> =>
  sortBy(map(statuses, camelKeys), 'order').map((status) =>
    isEmpty(status.substatuses)
      ? status
      : { ...status, substatuses: transformStatuses(status.substatuses) }
  ) as Array<AH$Status>;

export const transformCompanyStatuses = (statuses: Array<API$Status> = []): AH$CompanyStatuses => {
  const defaultStatuses = transformStatuses(filter(statuses, 'selected'));
  const availableStatuses = transformStatuses(reject(statuses, 'selected'));
  const allStatuses = [...defaultStatuses, ...availableStatuses];

  return { defaultStatuses, availableStatuses, allStatuses };
};

export const transformMailingList = (mailingList: API$MailingList): AH$MailingList => ({
  ...camelKeysRecursive(mailingList),
  templates: mailingList.templates.map(transformTemplate) as Array<AH$MailingListTemplate>,
});

export const transformFolder = (
  folder: API$Folder
): { candidateStatuses: Array<AH$Status>; folder: AH$Folder } => ({
  folder: {
    ...camelKeysRecursive(omit(folder, ['candidate_statuses', 'mailing_lists'])),
    mailingLists: folder.mailing_lists
      ?.filter(({ messaging_type }) => messaging_type === 'mailing')
      .map(transformMailingList),
  },
  candidateStatuses: transformStatuses(folder['candidate_statuses']),
});

export const filterHiringManagers = (users: Array<AH$FolderCreator>): Array<AH$FolderCreator> =>
  filter(users, ['type', 'hm']);

export const mapAssignees = (
  assignees: Array<AH$FolderCreator>,
  withEmail = false
): Array<AH$AssigneeShort> =>
  assignees.map(({ firstName, lastName, id, email }) => {
    const fullName = `${firstName} ${lastName}`;
    const notAccepted = isEmpty(fullName.trim());
    const name = withEmail ? `${fullName} — ${email}` : fullName;

    return {
      id,
      notAccepted,
      name: notAccepted ? email : name,
    };
  });

export const convertMessagingStatusesFromUrl = (
  messagingStatusQuery: string | Array<string> | null
): Array<{ mailingListId: number; status: string }> => {
  if (!messagingStatusQuery) {
    return [];
  }

  const messagingStatuses = (
    Array.isArray(messagingStatusQuery) ? messagingStatusQuery : [messagingStatusQuery]
  ).map<{ mailingListId: number; status: string }>((queryParam) => {
    const splitedParam = queryParam.split(',');

    return {
      mailingListId: parseInt(splitedParam[0], 10),
      status: splitedParam[1],
    };
  });

  return messagingStatuses;
};

// now is suitable for only one mailing list per folder
export const calculateCandidates = (
  messagingStatuses: Array<{ mailingListId: number; status: string }>,
  mailingLists?: Array<AH$MailingList> | null
): number => {
  if (!mailingLists) {
    return 0;
  }

  const mailingList = find(
    mailingLists,
    (list) => list.id === messagingStatuses[0].mailingListId
  ) as AH$MailingList;

  return messagingStatuses[0].status === 'total'
    ? mailingList.recipientsCount
    : getCountWithIncludedStatuses(mailingList.stats, messagingStatuses[0].status);
};

export const getFolderDescription = (description: string): string => {
  try {
    return convertFromRaw(JSON.parse(description)).getPlainText();
  } catch (err) {
    return description;
  }
};
