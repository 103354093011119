import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

const Category = CATEGORIES.statistics;
const Location = LOCATIONS.statistics;

export enum StatisticsSections {
  Search = 'Search',
  Pipeline = 'Pipeline',
  Messaging = 'Messaging',
}

export const openStatisticsTab = (section: StatisticsSections): void => {
  sendEvent('Open Statistics Tab', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const clickStatisticsLegend = (section: StatisticsSections): void => {
  sendEvent('Click Statistics Legend', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const saveStatisticsSettings = (section: StatisticsSections): void => {
  sendEvent('Save Statistics Settings', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const exportStatistics = (section: StatisticsSections): void => {
  sendEvent('Export Statistics', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const openStatisticsQueries = (section: StatisticsSections): void => {
  sendEvent('Open Statistics Queries', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const openStatisticsViews = (section: StatisticsSections): void => {
  sendEvent('Open Statistics Views', {
    Location,
    Category,
    Statistics_Section: section,
  });
};

export const changeStatisticsFilter = (section: StatisticsSections, filterId: string): void => {
  sendEvent('Change Statistics Filter', {
    Location,
    Category,
    Statistics_Section: section,
    Filter_ID: filterId,
  });
};
