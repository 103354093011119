import { Component, ErrorInfo, ReactNode } from 'react';

import Error from 'components/Error';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo?: string | null;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    errorInfo: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Frontend error:', error, errorInfo);
    this.setState({ errorInfo: errorInfo.componentStack });
  }

  public render() {
    if (this.state.hasError) {
      return <Error trace={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
