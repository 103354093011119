import { FC, useCallback, useEffect } from 'react';

import { setSystemNotificationStatus } from 'lib/apiV6';
import { useDispatch } from 'hooks';
import { getCurrentUser, setDialogModal } from 'slices/app';
import useEditor from 'components/Messaging/Message/hooks/useEditor';
import { metrics } from '../metrics';
import DefaultFullNotification from './DefaultFullNotification';
import MaintenanceFullNotification from './MaintenanceFullNotification';

type FullFormatNotificationProps = {
  notification: AH$SystemNotification;
  isPreview?: boolean;
};

const FullNotification: FC<FullFormatNotificationProps> = ({ notification, isPreview = false }) => {
  const dispatch = useDispatch();
  const editor = useEditor({
    initText: notification.content,
    readOnly: true,
  });

  const closeFullNotification = useCallback(() => {
    dispatch(setDialogModal({ show: false }));
  }, [dispatch]);

  const handleButtonClick = useCallback(() => {
    if (notification.buttonLink) {
      window.open(notification.buttonLink, '_blank', 'noopener noreferrer');
      closeFullNotification();
    } else {
      closeFullNotification();
    }
    metrics.clickOnButton();
  }, [closeFullNotification, notification.buttonLink]);

  useEffect(() => {
    if (!isPreview) {
      setSystemNotificationStatus(notification.id, 'read').then(() => {
        dispatch(getCurrentUser());
      });
    }
  }, []);

  switch (notification.type) {
    case 'maintenance':
      return (
        <MaintenanceFullNotification
          notification={notification}
          editor={editor}
          handleButtonClick={handleButtonClick}
          handleClose={closeFullNotification}
        />
      );
    default:
      return (
        <DefaultFullNotification
          notification={notification}
          editor={editor}
          handleButtonClick={handleButtonClick}
          handleClose={closeFullNotification}
        />
      );
  }
};

export default FullNotification;
