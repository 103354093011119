import { CATEGORIES } from './categories';
import { extractProfileInfo, sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const openCurrentPositions = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Current Position', {
    Location,
    Category,
    Profile_Id,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openLocations = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Locations', {
    Location,
    Category,
    Profile_Id,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openProfileViews = (
  Profile_Id: number,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open profile views', {
    Location: LOCATIONS.search,
    Category: CATEGORIES.shortProfile,
    Profile_Id,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openEarlierPositions = (
  Profile_Id: number,
  Location: LOCATIONS,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Earlier Positions', {
    Location,
    Category: CATEGORIES.shortProfile,
    Profile_Id,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openPositionDetail = (data: {
  Location: LOCATIONS;
  Profile_Id: number;
  Access_Type?: AH$Folder['accessType'];
  Is_MLTR_Profile?: boolean;
  Is_Recommended_Profile?: boolean;
  Search_Form_Type?: 'v2' | 'v1';
}): void => {
  sendEvent('Open Position Details', {
    ...data,
    Category: CATEGORIES.shortProfile,
  });
};

export const editTags = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Edit tags', {
    Profile_Id,
    Location,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const searchByTag = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Search By Tag', {
    Profile_Id,
    Location,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openProfileAccount = (
  Profile_Id: number,
  Site: string,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Profile Account', {
    Profile_Id,
    Site,
    Location,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openProfileContacts = (
  profile: AH$Profile,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  uiInitiator?: string
): void => {
  sendEvent(
    'Open Contacts',
    {
      Profile_Id: profile.id,
      Location,
      Category,
      Contact_Opens_Count: 1,
      Is_Recommended_Profile,
      Access_Type,
      Is_MLTR_Profile,
      Search_Form_Type: 'v2',
      UI_Initiator: uiInitiator,
      ...extractProfileInfo(profile),
    },
    ['amplitude']
  );
};

export const updateProfileContacts = (
  profile: AH$Profile,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean
): void => {
  sendEvent(
    'Update Contacts',
    {
      Profile_Id: profile.id,
      Location,
      Category,
      Contact_Opens_Count: 1,
      Is_Recommended_Profile,
      Access_Type,
      Is_MLTR_Profile,
      Search_Form_Type: 'v2',
      ...extractProfileInfo(profile),
    },
    ['amplitude']
  );
};

export const openUpdateContactsAttempt = (
  profile: AH$Profile,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean
): void => {
  sendEvent(
    'OpenUpdate Contacts Attempt',
    {
      Profile_Id: profile.id,
      Location,
      Category,
      Contact_Opens_Count: 1,
      Is_Recommended_Profile,
      Access_Type,
      Is_MLTR_Profile,
      Search_Form_Type: 'v2',
      ...extractProfileInfo(profile),
    },
    ['amplitude']
  );
};

export const deleteCandidateFromFolder = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Profile_Id?: number,
  Profiles_Count?: number,
  Is_Recommended_Profile?: boolean,
  Is_Smart_Folders_Button = false,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Delete Candidate From Folder', {
    Profile_Id,
    Location,
    Category,
    Profiles_Count,
    Is_Recommended_Profile,
    Is_Smart_Folders_Button,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openFolder = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Folder', {
    Location,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const addComment = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Comment_Type?: 'General' | 'Folder',
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Add Comment', {
    Location,
    Category,
    Comment_Type,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const editComment = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Comment_Type?: 'General' | 'Folder',
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Edit Comment', {
    Location,
    Category,
    Comment_Type,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const deleteComment = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Comment_Type?: 'General' | 'Folder',
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Delete Comment', {
    Location,
    Category,
    Comment_Type,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const chooseCandidate = (
  Profile_Id: number,
  Location: LOCATIONS,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Choose Candidate', {
    Location,
    Profile_Id,
    Category: CATEGORIES.shortProfile,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openFullProfile = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES = CATEGORIES.shortProfile,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  profile?: AH$Profile,
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent(
    'Open Full Profile',
    {
      Location,
      Profile_Id,
      Category,
      Is_Recommended_Profile,
      Access_Type,
      Is_MLTR_Profile,
      Search_Form_Type,
      ...(profile ? extractProfileInfo(profile) : undefined),
    },
    ['amplitude']
  );
};

export const openMessage = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Mass_Mailing?: boolean,
  Is_Recommended_Profile?: boolean,
  Access_Type: AH$Template['accessType'] = 'public',
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Mailing List', {
    Location,
    Profile_Id,
    Category,
    Is_Mass_Mailing,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openAttachment = (
  Profile_Id: number,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open attachment', {
    Location,
    Category,
    Profile_Id,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openTab = (
  Tab_Name: string,
  Location: LOCATIONS,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Open Tab', {
    Tab_Name,
    Location,
    Category: CATEGORIES.shortProfile,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};
