import generateUuid from 'uuid/v4';

let uuid = sessionStorage.getItem('uuid');

let refererUuid = sessionStorage.getItem('referer-uuid');

export const setRefererUuid = (): void => {
  refererUuid = uuid;
  uuid = generateUuid();

  sessionStorage.setItem('uuid', uuid);
  sessionStorage.setItem('referer-uuid', refererUuid as string);
};

interface Headers {
  'X-AH-Session-Id': string;
  'X-AH-Referer-Session-Id'?: string;
}

export const getSessionUuidHeaders = (): Headers => {
  if (!uuid) {
    uuid = generateUuid();
    sessionStorage.setItem('uuid', uuid);
  }

  const headers: Headers = {
    'X-AH-Session-Id': uuid,
  };

  if (refererUuid) {
    headers['X-AH-Referer-Session-Id'] = refererUuid;
  }

  return headers;
};
