export default {
  maintenanceTitle: {
    id: 'notificationCenter.maintenance.title',
    defaultMessage: 'Technical maintenance in AmazingHiring',
  },
  learnMoreButton: {
    id: 'notificationCenter.learnMore',
    defaultMessage: 'Learn more',
  },
  hideButton: {
    id: 'notificationCenter.hide',
    defaultMessage: 'Hide',
  },
  ok: {
    id: 'notificationCenter.ok',
    defaultMessage: 'OK',
  },
};
