import * as React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import BaseIcon from 'components/Base/Icon';
import messages from './messages';
import styles from './PasswordValidator.scss';

const theme = thematize(styles);

export const validators = [
  {
    rule: (value: string): boolean => value.length >= 12,
    message: 'length',
  },
  {
    rule: (value: string): boolean => value !== value.toUpperCase(),
    message: 'lowercase',
  },
  {
    rule: (value: string): boolean => value !== value.toLowerCase(),
    message: 'uppercase',
  },
  {
    rule: (value: string): boolean => /\d/.test(value),
    message: 'digits',
  },
  {
    rule: (value: string): boolean => /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value),
    message: 'special',
  },
] as const;

const Icon = ({ valid }: { valid: boolean }): React.ReactElement => (
  <span className={theme('rule', { valid })}>
    {valid ? (
      <span key="valid">
        <BaseIcon type="checkCircle" />
      </span>
    ) : (
      <span key="notValid">
        <BaseIcon type="crossCircle" />
      </span>
    )}
  </span>
);

interface Props {
  controlledInput: boolean;
  forElementWithId: string;
  placement?: string;
}

interface State {
  focus: boolean;
}

class PasswordValidator extends React.Component<Props, State> {
  static defaultProps = {
    controlledInput: false,
    placement: 'right',
  };

  state = {
    focus: false,
  };

  componentDidMount(): void {
    const target = this.getTarget();

    if (target) {
      if (!this.props.controlledInput) {
        target.addEventListener('input', this.handleInput);
      }
      target.addEventListener('focus', this.handleFocus);
      target.addEventListener('blur', this.handleBlur);
    }
  }

  componentWillUnmount(): void {
    const target = this.getTarget();

    if (target) {
      if (!this.props.controlledInput) {
        target.removeEventListener('input', this.handleInput);
      }
      target.removeEventListener('focus', this.handleFocus);
      target.removeEventListener('blur', this.handleBlur);
    }
  }

  handleInput = (): void => {
    this.forceUpdate();
  };

  handleFocus = (): void => {
    this.setState({ focus: true });
  };

  handleBlur = (): void => {
    this.setState({ focus: false });
  };

  getTarget = (): HTMLElement | null => document.getElementById(this.props.forElementWithId);

  render(): React.ReactElement | null {
    const target = this.getTarget();
    const { focus } = this.state;
    const value = get(target, 'value', '');

    return target ? (
      <Overlay
        show={focus}
        target={target}
        placement={this.props.placement as React.ComponentProps<typeof Overlay>['placement']}
      >
        <Popover id={this.props.forElementWithId}>
          <div className={theme('rules')}>
            {validators.map(({ rule, message }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`rule-${i}`} {...dataQa(`${this.props.forElementWithId}-${message}`)}>
                <Icon valid={rule(value)} />
                &ensp;
                <FormattedMessage {...messages[message]} />
              </div>
            ))}
          </div>
        </Popover>
      </Overlay>
    ) : null;
  }
}

export default PasswordValidator;
