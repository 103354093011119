import { Dispatch } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/globals';
import { FetchingStatusesActionTypes } from './fetchingStatuses';

export const REDUCER_KEYS: {
  foldersProfiles: 'foldersProfiles';
  profileMain: 'profileMain';
  profilePossible: 'profilePossible';
  searchProfiles: 'searchProfiles';
} = {
  foldersProfiles: 'foldersProfiles',
  searchProfiles: 'searchProfiles',
  profilePossible: 'profilePossible',
  profileMain: 'profileMain',
};

export const createStatusActions = (
  dispatch: Dispatch,
  type: FetchingStatusesActionTypes
): {
  setError: (err?: any) => void;
  setLoading: () => void;
  setSuccess: () => void;
} => ({
  setLoading: (): void => {
    dispatch({
      type,
      payload: FETCHING_STATUSES.LOADING,
    });
  },
  setSuccess: (): void => {
    dispatch({
      type,
      payload: FETCHING_STATUSES.SUCCESS,
    });
  },
  setError: (error): void => {
    dispatch({
      type,
      error,
      payload: FETCHING_STATUSES.ERROR,
    });
  },
});
