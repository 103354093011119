const skillMessage = {
  id: 'query.skill',
  defaultMessage: 'Skills',
};

export default {
  text: {
    id: 'query.text',
    defaultMessage: 'Additional criteria',
  },

  metaqueries: {
    id: 'query.metaqueries',
    defaultMessage: 'Specialization',
  },

  ai: {
    id: 'query.ai',
    defaultMessage: 'AI sourcing',
  },

  companySearch: {
    id: 'query.companySearch',
    defaultMessage: 'Company',
  },

  lastCompanySearch: {
    id: 'query.lastCompanySearch',
    defaultMessage: 'Current company',
  },

  position: {
    id: 'query.position',
    defaultMessage: 'Title',
  },

  lastPosition: {
    id: 'query.lastPosition',
    defaultMessage: 'Current title',
  },

  skill: skillMessage,
  preferableSkill: skillMessage,
  skillAll: skillMessage,
  skillAny: skillMessage,

  mainSkillHigh: {
    id: 'query.mainSkillHigh',
    defaultMessage: 'Main skills',
  },

  location: {
    id: 'query.location',
    defaultMessage: 'City or country',
  },

  locationRange: {
    id: 'query.location',
    defaultMessage: 'City or country',
  },

  educationSearch: {
    id: 'query.educationSearch',
    defaultMessage: 'University',
  },

  name: {
    id: 'query.name',
    defaultMessage: 'Name or nickname',
  },

  comment: {
    id: 'query.comment',
    defaultMessage: 'Comment',
  },

  folder: {
    id: 'query.folder',
    defaultMessage: 'Folder',
  },

  any: {
    id: 'query.any',
    defaultMessage: 'any',
  },

  all: {
    id: 'query.all',
    defaultMessage: 'any',
  },

  current: {
    id: 'query.current',
    defaultMessage: 'current',
  },

  main: {
    id: 'query.main',
    defaultMessage: 'main',
  },

  exclude: {
    id: 'query.exclude',
    defaultMessage: 'Exclude from search',
  },

  km: {
    id: 'query.km',
    defaultMessage: 'km',
  },

  mi: {
    id: 'query.mi',
    defaultMessage: 'mi',
  },

  range: {
    id: 'query.range',
    defaultMessage: 'range',
  },

  onlyCities: {
    id: 'query.onlyCities',
    defaultMessage: 'Only available for cities or towns',
  },

  or: {
    id: 'form.or',
    defaultMessage: 'or',
  },

  synonyms: {
    id: 'form.synonyms',
    defaultMessage: 'synonyms',
  },

  addCondition: {
    id: 'form.addCondition',
    defaultMessage: 'Add condition',
  },

  excludeCondition: {
    id: 'form.excludeCondition',
    defaultMessage: 'Exclude condition',
  },

  selectCondition: {
    id: 'form.selectCondition',
    defaultMessage: 'Select condition',
  },

  cancelCondition: {
    id: 'form.cancelCondition',
    defaultMessage: 'Cancel condition',
  },

  newCondition: {
    id: 'form.newCondition',
    defaultMessage: 'New condition',
  },

  search: {
    id: 'form.search',
    defaultMessage: 'Search',
  },

  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
  },

  find: {
    id: 'form.find',
    defaultMessage: 'Find',
  },

  newSearch: {
    id: 'form.newSearch',
    defaultMessage: 'New search',
  },

  history: {
    id: 'form.history',
    defaultMessage: 'History',
  },

  back: {
    id: 'form.back',
    defaultMessage: 'Back',
  },

  historyShowFull: {
    id: 'history.showFull',
    defaultMessage: 'Full search history',
  },

  historyEmpty: {
    id: 'history.empty',
    defaultMessage: 'Search history is empty. Look for someone to fill it out.',
  },
  features: {
    id: 'form.notice.features',
    defaultMessage: 'Easier editing.<br/>Boolean search support.',
  },
  pro: {
    id: 'form.pro',
    defaultMessage: 'PRO',
  },
  queryName: {
    id: 'savedQuery.name',
    defaultMessage: 'Name',
  },
  queryDescription: {
    id: 'savedQuery.description',
    defaultMessage: 'Description',
  },
  save: {
    id: 'savedQuery.save',
    defaultMessage: 'Save',
  },
  saveQuery: {
    id: 'savedQuery.saveQuery',
    defaultMessage: 'Save query',
  },
  fieldTips: {
    metaqueries: {
      id: 'form.fieldTips.metaqueries',
      defaultMessage: `
      Choose a specialization or programming language -
      <br></br>the system will analyze and show candidates based
      <br></br>on their current position and core skill
      `,
    },
    companySearch: {
      id: 'form.fieldTips.companySearch',
      defaultMessage: `
      Choose a company or exclude it from the search.
      <br></br>Depending on the position of the slider, you can select
      <br></br>the current place of work or any from the experience
      `,
    },
    position: {
      id: 'form.fieldTips.position',
      defaultMessage: `
      Choose a specific position.
      <br></br>Depending on the position of the slider,
      <br></br>you can select either current position
      <br></br>or any position in experience
      `,
    },
    skill: {
      id: 'form.fieldTips.skill',
      defaultMessage: `
      Specify a certain programming language.
      <br></br>Move the slider to select only those candidates
       <br></br>who are currently using this skill
      `,
    },
    location: {
      id: 'form.fieldTips.location',
      defaultMessage: `
      Choose a location or exclude it from the search.
      <br></br>Move the slider to add a search
      <br></br>within the range of up to 160 km / 100 mi from the city
      `,
    },
    educationSearch: {
      id: 'form.fieldTips.educationSearch',
      defaultMessage: `
      Specify the name
      <br></br>of the educational institution
      `,
    },
    name: {
      id: 'form.fieldTips.name',
      defaultMessage: `
      Specify the name or nickname
      <br></br>of the candidate from the social network
      `,
    },
    comment: {
      id: 'form.fieldTips.comment',
      defaultMessage: `
      Find the candidate by the comments
      <br></br>you and your team added to their profile
      `,
    },
    folder: {
      id: 'form.fieldTips.folder',
      defaultMessage: `
      Choose a certain folder to search inside or to exclude
      <br></br>so that the profiles added to it don’t appear
      <br></br>in the search results
      `,
    },
    text: {
      id: 'form.fieldTips.text',
      defaultMessage: `
      Put any additional information about the candidate
      <br></br>such as software, certifications, etc.
      <br></br>Use # to search by tags you or your team
      <br></br>added to the candidate’s profile
      `,
    },
  },
};
