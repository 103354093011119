import { oneLine } from 'common-tags';

import { whiteLabelFormattedName, whiteLabelName } from 'lib/whitelabel';

export type FIELD_SETTINGS = {
  suggest: null | string;
  type: string;
  exactSearchable?: boolean;
  locationRange?: undefined | number;
  mergeTo?: undefined | string;
  notBoolean?: boolean;
  skillRange?: undefined | number;
};

export const FIELDS = new Map<string, FIELD_SETTINGS>([
  [
    'metaqueries',
    {
      type: 'metaqueries',
      suggest: null,
      exactSearchable: false,
    },
  ],
  [
    'companySearch',
    {
      type: 'companySearch',
      suggest: 'company',
      exactSearchable: true,
    },
  ],
  [
    'lastCompanySearch',
    {
      type: 'lastCompanySearch',
      suggest: 'company',
      mergeTo: 'companySearch',
      exactSearchable: true,
    },
  ],
  [
    'position',
    {
      type: 'position',
      suggest: 'position',
      exactSearchable: true,
    },
  ],
  [
    'lastPosition',
    {
      type: 'lastPosition',
      suggest: 'position',
      mergeTo: 'position',
      exactSearchable: true,
    },
  ],
  [
    'skill',
    {
      type: 'skill',
      suggest: 'skill',
      exactSearchable: true,
    },
  ],
  [
    'skillAll',
    {
      type: 'skillAll',
      suggest: 'skill',
      exactSearchable: true,
      skillRange: 0,
    },
  ],
  [
    'skillAny',
    {
      type: 'skillAny',
      suggest: 'skill',
      exactSearchable: true,
    },
  ],
  [
    'mainSkillHigh',
    {
      type: 'mainSkillHigh',
      suggest: 'mainSkill',
      mergeTo: 'skill',
      exactSearchable: true,
    },
  ],
  [
    'preferableSkill',
    {
      type: 'preferableSkill',
      suggest: 'skill',
      exactSearchable: true,
    },
  ],
  [
    'location',
    {
      type: 'location',
      suggest: 'location',
      exactSearchable: false,
    },
  ],
  [
    'locationRange',
    {
      type: 'locationRange',
      suggest: 'location',
      mergeTo: 'location',
      exactSearchable: false,
      locationRange: 0,
    },
  ],
  [
    'educationSearch',
    {
      type: 'educationSearch',
      suggest: 'education',
      exactSearchable: true,
    },
  ],
  [
    'name',
    {
      type: 'name',
      suggest: null,
      exactSearchable: true,
    },
  ],
  [
    'comment',
    {
      type: 'comment',
      suggest: null,
    },
  ],
  [
    'folder',
    {
      type: 'folder',
      suggest: 'folder',
      exactSearchable: false,
    },
  ],
  [
    'text',
    {
      type: 'text',
      suggest: 'text',
      exactSearchable: false,
    },
  ],
]);

export const DnDItemTypes = {
  STATUS: 'STATUS',
};

export const ROLES_WITHOUT_COMPANY = new Set(['super_user', 'sales_manager']);

export const EXTENSION_ID = 'didkfdopbffjkpolefhpcjkohcpalicd';
export const LINK_FOR_EXT = `https://chrome.google.com/webstore/detail/amazinghiring/didkfdopbffjkpolefhpcjkohcpalicd?utm_ah=${window.location.hostname}`;
export const LINK_FOR_EXT_MATCHY = `https://chromewebstore.google.com/detail/%D0%BC%D1%8D%D1%82%D1%87%D0%B8/mfacfcabilnmjannppfjbjbecogdkpfc?hl=ru`;

export const LINK_FOR_HELP_CENTER = 'https://help.amazinghiring.com/';
export const LINK_FOR_HELP_CENTER_MATCHY = 'https://help.matchy.ru/';

export const LINK_FOR_FOLDERS_HELP = 'https://help.amazinghiring.com/category/1125';

export const LINK_FOR_FOLDERS_HELP_MATCHY = 'https://help.matchy.ru/category/2850';

export const TEST_LINK_IF_EXT_INSTALLED =
  'chrome-extension://didkfdopbffjkpolefhpcjkohcpalicd/img/logo-128.png';

export const SOURCING_ON_DEMAND = 'https://amazinghiring.com/outsourcing/';
export const G_MATE =
  'https://getmatch.ru/#sendRequest?utm_campaign=1&utm_content=bannersearch&utm_medium=website&utm_source=ah';
export const DISCLAIMER_LINK = 'https://help.amazinghiring.com/article/4822';
export const DISCLAIMER_LINK_MATCHY = 'https://help.matchy.ru/article/12040';

export const WIDE_SCREEN_WIDTH = 1550;

export const INVALID_DATE = 'Invalid date';

export const IS_MAC = window.navigator.platform.toLowerCase().includes('mac');

export const LINK_FOR_GOOGLE_OAUTH = '/oauth/login/google-oauth2/';

export const GMATE_BASE_URL = 'https://getmatch.ru/';

export const BROWSER_LANGUAGE = navigator.language.split('-')[0];

export const EUROPE_SEARCH_BLOCKED_ERROR_CODE = [100, 102, 103, 104];
export const DELETED_PAGE_ERROR_CODE = 101;

export const FETCHING_STATUSES: {
  ERROR: 'ERROR';
  LOADING: 'LOADING';
  SUCCESS: 'SUCCESS';
} = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

export const DUPLICATE_STATUS_ERROR_CODES = [1201, 1202];

export const VENDORS: AH$RawApps = {
  greenhouse: {
    id: 'greenhouse',
    name: 'Greenhouse',
    url: 'https://www.greenhouse.io/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4788',
      ru: 'https://help.amazinghiring.com/article/4788',
    },
    description: {
      en: oneLine`Greenhouse is an applicant tracking system and recruiting software
      designed to help you find better candidates and improve your entire recruiting process.`,
      ru: oneLine`Greenhouse — это система ведения кандидатов и программное обеспечение,
      спроектированное для того, чтобы помочь вам найти лучших кандидатов и улучшить весь
      ваш процесс найма.`,
    },
  },
  greenhouse_import: {
    id: 'greenhouse_import',
    name: 'Greenhouse Profiles Import',
    linkName: 'Greenhouse',
    url: 'https://www.greenhouse.io/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4889',
      ru: 'https://help.amazinghiring.com/article/4889',
    },
    description: {
      en: oneLine`You can import candidates’ profiles from Greenhouse for convenience.`,
      ru: oneLine`Вы можете импортировать профили кандидатов из Greenhouse для удобной работы с ними.`,
    },
  },
  teamtailor: {
    id: 'teamtailor',
    name: 'Teamtailor',
    url: 'https://www.teamtailor.com/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4784',
      ru: 'https://help.amazinghiring.com/article/4784',
    },
    description: {
      en: oneLine`Teamtailor is the applicant tracking system made for all types of companies. With
      modern features optimized for you and your candidates, you will get everything you need to recruit successfully.`,
      ru: oneLine`Teamtailor — это ATS система, предназначенная для всех типов компаний.
      Благодаря современному и оптимизированному функционалу вы получите все необходимое для успешного найма.`,
    },
  },
  smartrecruiters: {
    id: 'smartrecruiters',
    name: 'SmartRecruiters',
    url: 'https://www.smartrecruiters.com/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4787',
      ru: 'https://help.amazinghiring.com/article/4787',
    },
    description: {
      en: oneLine`SmartRecruiters is a modern and enterprise-grade talent
      acquisition suite that empowers businesses to hire talent on-demand, and under budget.`,
      ru: oneLine`SmartRecruiters — это современная и надежная облачная ATS система, разработанная для повышения продуктивности рекрутеров.
      Платформа помогает наладить совместную работу команды и повысить опыт кандидатов при прохождении этапов отбора.`,
    },
  },
  smartrecruiters_import: {
    id: 'smartrecruiters_import',
    name: 'SmartRecruiters Profiles Import',
    linkName: 'SmartRecruiters',
    url: 'https://www.smartrecruiters.com/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4786',
      ru: 'https://help.amazinghiring.com/article/4786',
    },
    description: {
      en: oneLine`You can import candidates’ profiles from SmartRecruiters for convenience.`,
      ru: oneLine`Вы можете импортировать профили кандидатов из SmartRecruiters для удобной работы с ними.`,
    },
  },
  lever: {
    id: 'lever',
    name: 'Lever',
    url: 'https://www.lever.co/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4783',
      ru: 'https://help.amazinghiring.com/article/4783',
    },
    description: {
      en: oneLine`The Lever Talent Acquisition Suite offers everything you need to build talent relationships
      across multiple channels, have complete pipeline coverage from source to hire to rediscovery and have unified insights.`,
      ru: oneLine`Lever предлагает все необходимое для выстраивания взаимоотношений с кандидатами посредством разных каналов.
      Система позволяет вести кандидатов по всему процессу отбора: с момента добавления в систему до найма.
      Также платформа помогает вести пайплайн кандидатов и имеет отдельный раздел аналитики.`,
    },
  },
  workable: {
    id: 'workable',
    name: 'Workable',
    url: 'https://www.workable.com/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4782',
      ru: 'https://help.amazinghiring.com/article/4782',
    },
    description: {
      en: oneLine`Workable is the all-in-one hiring solution that helps you find the right person for every job.`,
      ru: oneLine`Workable — это комплексное решение, позволяющее найти правильного кандидата на каждую вашу вакансию.`,
    },
  },
  mixmax: {
    id: 'mixmax',
    name: 'Mixmax',
    url: 'https://mixmax.com',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4789',
      ru: 'https://help.amazinghiring.com/article/4789',
    },
    description: {
      en: oneLine`Send engaging emails with instant scheduling, email tracking, polls, and
      surveys right in your Gmail.`,
      ru: oneLine`Отправляйте email-рассылки с мгновенным планированием (в календаре), отслеживанием писем,
      проведением опросов, исследований прямо в вашем Gmail.`,
    },
  },
  zapier: {
    id: 'zapier',
    name: 'Zapier',
    url: 'https://zapier.com/',
    helpUrl: {
      en: 'https://help.amazinghiring.com/article/4785',
      ru: 'https://help.amazinghiring.com/article/4785',
    },
    description: {
      en: oneLine`Easy automation for busy people. Zapier moves info between your web apps
      automatically, so you can focus on your most important work.`,
      ru: oneLine`Простая автоматизация для занятых людей. Zapier автоматически синхронизирует
      информацию между вашими веб-приложениями, чтобы вы могли сосредоточиться на своей работе.`,
    },
  },
  gmate: {
    id: 'gmate',
    name: 'getmatch',
    url: {
      ru: 'https://getmatch.ru/',
      en: 'https://getmatch.ru/',
    },
    description: {
      en: oneLine`The bot finds a match, an expert reviews it, and you do the interview.
      Meet the getmatch bot and team: We help you find software engineers, designers, or managers.
      We have 20,000 specialists ready for interviews, including some you won’t find in the public market.`,
      ru: oneLine`Бот находит, эксперт проверяет, вы собеседуете.
      Это getmatch и его команда: поможем вам найти разработчиков, дизайнеров, менеджеров.
      У нас 20,000 кандидатов, которые готовы к интервью. В том числе — те, кого нет на открытом рынке.`,
    },
  },
  huntflow_import: {
    id: 'huntflow_import',
    name: 'Huntflow',
    url: {
      ru: 'https://huntflow.ru/',
      en: 'https://huntflow.ru/',
    },
    helpUrl: {
      en: whiteLabelName === 'matchy' ? 'https://help.matchy.ru/article/14443' : '',
      ru: whiteLabelName === 'matchy' ? 'https://help.matchy.ru/article/14443' : '',
    },
    description: {
      en: oneLine`Huntflow is a cloud CRM for recruitment automation and applicant tracking. Connect your account with ${
        whiteLabelName === 'matchy' ? 'Matchy' : 'AmazingHiring'
      } to exclude profiles you add in CRM or to source in this pipeline right in the platform.`,
      ru: oneLine`Хантфлоу - это облачная CRM для автоматизации рекрутмента и ведения базы кандидатов. Подключите ваш аккаунт в ${
        whiteLabelName === 'matchy' ? 'Мэтчи' : 'AmazingHiring'
      }, чтобы исключать профили из вашей CRM при новых поисках на платформе и строить запросы по вашему пайплайну Хантфлоу.`,
    },
  },
  messaging: {
    gmail: {
      id: 'gmail',
      name: 'Gmail',
      url: 'https://gmail.com',
      helpUrl: {
        en: 'https://help.amazinghiring.com/article/4790',
        ru: 'https://help.amazinghiring.com/article/4790',
      },
      description: {
        en: oneLine`Send messages to candidates directly from ${
          whiteLabelFormattedName === 'Мэтчи' ? 'Matchy' : whiteLabelFormattedName
        } using your gmail.`,
        ru: oneLine`Отправляйте письма кандидатам прямо из ${whiteLabelFormattedName}, используя свою почту gmail.`,
      },
    },

    outlook: {
      id: 'outlook',
      name: 'Outlook',
      url: 'https://outlook.com',
      helpUrl: {
        en: 'https://help.amazinghiring.com/article/4791',
        ru: 'https://help.amazinghiring.com/article/4791',
      },
      description: {
        en: oneLine`Send messages to candidates directly from ${
          whiteLabelFormattedName === 'Мэтчи' ? 'Matchy' : whiteLabelFormattedName
        } using your outlook.`,
        ru: oneLine`Отправляйте письма кандидатам прямо из ${whiteLabelFormattedName}, используя свою почту outlook.`,
      },
    },
  },
};

export const SENTRY_DSN =
  'https://241762a941904ce5b3b55bcaa8daddd6@sentry.amazinghiring.com:9000/27';

export const emailRegexp =
  /([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})/g;

export const ALL_DISPLAY_SECTIONS: AH$User['displaySections'] = {
  personalInfo: true,
  contacts: true,
  tags: true,
  resumes: true,
  changeCandidate: true,
  comments: true,
  exportCsv: true,
  exportAts: true,
  messagingInfo: true,
  messagingMessages: true,
};

export const CONTACT_TYPES: {
  email: 'email';
  link: 'link';
  phone: 'phone';
  skype: 'skype';
  telegram: 'telegram';
} = {
  phone: 'phone',
  email: 'email',
  skype: 'skype',
  telegram: 'telegram',
  link: 'link',
};

// Контакты данного типа отображаются в полной, краткой карточках профиля; при создании и редактировании профиля.
export const USED_CONTACT_TYPES: Array<keyof typeof CONTACT_TYPES> = [
  CONTACT_TYPES.phone,
  CONTACT_TYPES.email,
  CONTACT_TYPES.skype,
  CONTACT_TYPES.telegram,
  CONTACT_TYPES.link,
];

export const CLIENT_TYPE = {
  clientCompanyWorker: 'ClientCompanyWorker',
  clientCompanyAdmin: 'ClientCompanyAdmin',
  hiringManager: 'HiringManager',
} as const;

export const APP_NODE = document.getElementById('app');

export const AH_PHONE = '+7 499 394 6347';
