const UPDATE_INTERVAL_TIME = 15000;
const UPDATE_TIMEOUT_TIME = 0;
const UPDATE_TIMES = 3;

export const pingServerForData = (
  callback: () => void,
  {
    updateIntervalTime = UPDATE_INTERVAL_TIME,
    updateTimeoutTime = UPDATE_TIMEOUT_TIME,
    updateTimes = UPDATE_TIMES,
  }: { updateIntervalTime?: number; updateTimeoutTime?: number; updateTimes?: number } = {}
): { interval: NodeJS.Timeout; timeout: NodeJS.Timeout } => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  let interval: ReturnType<typeof setInterval> | null = null;

  let counter = 0;

  interval = setInterval(() => {
    counter += 1;
    if (counter >= updateTimes) {
      clearInterval(interval as NodeJS.Timeout);
      return;
    }
    callback();
  }, updateIntervalTime);

  timeout = setTimeout(() => {
    callback();
  }, updateTimeoutTime);

  return { timeout, interval };
};

export default pingServerForData;
