import * as React from 'react';
import { ContentBlock, ContentState } from 'draft-js';

import Image from './Image';

interface Props {
  block: ContentBlock;
  contentState: ContentState;
}

const Media = ({ contentState, block }: Props): React.ReactElement | null => {
  const entityKey = block.getEntityAt(0);
  const entity = contentState.getEntity(entityKey);
  const data = entity.getData();
  const type = entity.getType();

  if (type === 'IMAGE') return <Image {...data} entityKey={entityKey} />;

  return null;
};

function mediaBlockRenderer(block: ContentBlock): any {
  if (block.getType() === 'atomic' && block.getEntityAt(0)) {
    return {
      component: Media,
      editable: false,
    };
  }

  return null;
}

export default mediaBlockRenderer;
