import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { adminPanel } from 'slices/adminPanel';
import { app } from 'slices/app';
import { ats } from 'slices/ats';
import { bannedLinks } from 'slices/bannedLinks';
import { company } from 'slices/company';
import { dataEnrichment } from 'slices/dataEnrichment';
import { fetchingStatuses } from 'slices/fetchingStatuses';
import { filters } from 'slices/filters';
import { folders } from 'slices/folders';
import { messaging } from 'slices/messaging';
import { profileMainReducer, profilePossibleReducer } from 'slices/profile';
import { profilesFoldersReducer, profilesSearchReducer } from 'slices/profiles';
import { searchForm } from 'slices/searchForm';
import { serviceOrders } from 'slices/serviceOrders';
import { statistics } from 'slices/statistics';
import { systemMessage } from 'slices/systemMessage';
import { telegramDialogs } from 'slices/telegramDialogs';
import { telegramManager } from 'slices/telegramManager';

enableMapSet();

export const reducer = combineReducers({
  foldersProfiles: profilesFoldersReducer,
  searchProfiles: profilesSearchReducer,
  filters,
  folders,
  searchForm,
  serviceOrders,
  company,
  app,
  messaging,
  dataEnrichment,
  profilePossible: profilePossibleReducer,
  profileMain: profileMainReducer,
  bannedLinks,
  adminPanel,
  systemMessage,
  fetchingStatuses,
  statistics,
  ats,
  telegramManager,
  telegramDialogs,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

export default store;
