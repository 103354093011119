import { capitalize, endsWith, startsWith } from 'lodash';

/**
 * Capitalizes every word in `string` splited by `separator`
 *
 * @param {string} string The string to capitalize
 * @param {string} [separator=' ']
 * @returns {string} The capitalized string
 * @example
 *
 * capitalizeEvery('frontend developer')
 * // => 'Frontend Developer'
 * capitalizeEvery('ru.stackoverflow', '.')
 * // => 'Ru.Stackoverflow'
 */
export function capitalizeEvery(string: string, separator = ' '): string {
  return string
    .split(separator)
    .map((word) => capitalize(word))
    .join(separator);
}

/**
 * Extracts first word from string with separators
 *
 * @example
 * firstWord('github_repo');
 * // => 'github'
 * firstWord('facebook-profile', '-');
 * // => 'facebook'
 *
 * @param {string} [str] The string to get word
 * @param {string|RegExp} [separator] The symbol to use for separating words
 * @returns {string} First word
 */
export function firstWord(str = '', separator = '_'): string {
  return str.split(separator)[0];
}

/**
 * Cleans input
 * @param {RegExp} re Symbols to clean from
 * @param {string} str Raw input
 * @return {string} Cleaned output
 */
function clearStr(re: RegExp, str: string): string {
  return str.replace(re, '').trim();
}

/**
 * Cleans string from quotes
 *
 * @example
 * clearQuotes(`"Machine learning"`);
 * // => 'Machine learning'
 *
 * @param {string} s Raw input
 * @returns {string} Cleaned output
 */
export function clearQuotes(s: string): string {
  return clearStr(/[«‹»›„‚“‟‘‛”"]+/g, s);
}

/**
 * Removes braces from input
 * @param {string} s
 * @returns {string}
 */
export function clearBraces(s: string): string {
  return clearStr(/[()]+/g, s);
}

/**
 * Lowers input string case
 * @param {string} s Raw input
 * @returns {string}
 */
export function toLower(s: string): string {
  return s.toLowerCase();
}

/**
 * Checks if string is wrapped in wrapper
 * @param {string} s
 * @param {string} wrapper
 * @returns {boolean}
 */
export function wrappedIn(s: string, wrapper: string): boolean {
  return startsWith(s, wrapper) && endsWith(s, wrapper);
}

/**
 * Checks if string is wrapped in quotes
 * @param {string} s
 * @returns {boolean}
 */
export function wrappedInQuotes(s: string): boolean {
  return wrappedIn(s, '"');
}
