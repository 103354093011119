import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { difference, get, isUndefined, map, noop, omit } from 'lodash';

import { AH$RouterLocation } from 'types';
import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise } from 'lib/abortablePromise';
import * as api from 'lib/api';
import * as apiV6 from 'lib/apiV6';
import { camelKeysRecursive, turnArrayToObject } from 'lib/object';
import { stringify } from 'lib/query';
import {
  addProfileEvents,
  isHiringManager,
  isManager,
  mergeProfileDisplayContacts,
  scrollTo,
  startLoading,
  stopLoading,
  transformComments,
  transformContacts,
  transformFolders,
  transformProfile,
} from 'lib/utils';
import { State } from 'stores/ReduxStore';
import {
  getErrorHandler,
  handleError,
  receiveLimitsInfo,
  removeCandidates,
  sendSystemMessage,
  setProfileReferer,
} from 'slices/app';
import { getFolder, getFolders } from 'slices/folders';
import { receiveSearchHistory, setSearchQueryId, setSmartFolder } from 'slices/searchForm';
import { REDUCER_KEYS } from 'slices/utils';
import { isAndBetween, valIdsForUrlFacetIds } from 'components/Filters/config';
import { convertFromUrl, convertToUrl } from 'components/Filters/lib';

export enum ProfileFocusDirection {
  Next = 'next',
  Prev = 'prev',
}

export enum ProfileIndexPosition {
  Last = 'last',
  First = 'first',
  Middle = 'middle',
}

type ReducerKey = typeof REDUCER_KEYS.searchProfiles | typeof REDUCER_KEYS.foldersProfiles;

export type ProfilesState = {
  readonly commentsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly contactsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly error: any;
  readonly externalContactsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly fetchingProfilesStatus: $Values<typeof FETCHING_STATUSES>;
  readonly focusedProfile: { id: number | null; indexPosition: ProfileIndexPosition };
  readonly folderStatuses: {
    data: Array<API$Status>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly foldersStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly hintsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly pages: AH$Pages | null;
  readonly profiles: Array<number>;
  readonly profilesById: { [key: number]: AH$Profile };
  readonly profilesComments: { [key: number]: Array<AH$ProfileComment> | undefined };
  readonly profilesFolders: { [key: number]: Array<AH$ProfileFolder> };
  readonly profilesFoldersRecommendations: {
    data: Array<AH$Profile>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly profilesHints: { [key: number]: Array<AH$ProfileHint> };
  readonly profilesRecipients: {
    [key: number]: {
      data: Array<AH$Recipient>;
      pages: API$Pages;
    };
  };
  readonly profilesViews: { [key: number]: Array<AH$ProfileView> | undefined };
  readonly recipientsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  readonly recommendations: {
    resultItems: Array<AH$Profile>;
    status: 'OK' | 'WAITING' | 'NO_RESULTS' | null;
  };
  readonly viewsStatusById: { [key: number]: $Values<typeof FETCHING_STATUSES> };
  aiProgress?: null;
  isAILearning?: boolean;
  survey?: Record<string, any>;
};

export const profilesInitialState: ProfilesState = {
  profiles: [],
  fetchingProfilesStatus: FETCHING_STATUSES.LOADING,
  profilesById: {},
  pages: null,
  error: null,
  profilesFolders: {},
  profilesFoldersRecommendations: { data: [], status: FETCHING_STATUSES.LOADING },
  profilesRecipients: {} as {
    [key: number]: {
      data: Array<AH$Recipient>;
      pages: { count: number; next: string | null; previous: string | null };
    };
  },
  profilesComments: {},
  profilesViews: {},
  profilesHints: {},
  contactsStatusById: {},
  commentsStatusById: {},
  foldersStatusById: {},
  recipientsStatusById: {},
  hintsStatusById: {},
  viewsStatusById: {},
  folderStatuses: {
    data: [],
    status: FETCHING_STATUSES.SUCCESS,
  },
  focusedProfile: { id: null, indexPosition: ProfileIndexPosition.Middle },
  recommendations: {
    status: null,
    resultItems: [],
  },
  externalContactsStatusById: {},
};

let profilesRequest: null | AbortablePromise<{
  pages: AH$Pages;
  payload: API$ProfilesPayload;
  profileReferer: string;
}> = null;

const createProfilesSlice = (sliceName: ReducerKey) => {
  const profilesFoldersFetching = createAction<number>(`${sliceName}/profilesFoldersFetching`);
  const profilesFoldersError = createAction<number>(`${sliceName}/profilesFoldersError`);
  const profilesRecipientsFetching = createAction<number>(
    `${sliceName}/profilesRecipientsFetching`
  );
  const profilesRecipientsError = createAction<number>(`${sliceName}/profilesRecipientsError`);
  const profilesLoading = createAction(`${sliceName}/profilesLoading`);
  const profilesLoaded = createAction<
    | { pages: AH$Pages | null; profiles: Array<API$Profile>; isError?: boolean }
    | { error: any; isError?: boolean }
  >(`${sliceName}/profilesLoaded`);
  const profilesFoldersRecommendationsLoading = createAction(
    `${sliceName}/profilesFoldersRecommendationsLoading`
  );
  const profilesFoldersRecommendationsLoaded = createAction<Array<AH$Profile>>(
    `${sliceName}/profilesFoldersRecommendationsLoaded`
  );
  const profilesFoldersRecommendationsError = createAction(
    `${sliceName}/profilesFoldersRecommendationsError`
  );

  const receiveProfilesFolders = createAsyncThunk(
    `${sliceName}/receiveProfilesFolders`,
    async (
      {
        profile,
        onSuccess = noop,
      }: { profile: number; onSuccess?: (folders: Array<AH$ProfileFolder>) => void },
      { rejectWithValue, dispatch }
    ) => {
      try {
        dispatch(profilesFoldersFetching(profile));
        const { payload } = await apiV6.candidatesGet({ profile, short: true });
        const folders = transformFolders(payload.results);

        onSuccess(folders);
        return { profileId: profile, folders };
      } catch (e) {
        getErrorHandler()(e);
        dispatch(profilesFoldersError(profile));

        return rejectWithValue(e);
      }
    }
  );

  const receiveProfilesRecipients = createAsyncThunk(
    `${sliceName}/receiveProfilesRecipients`,
    async (
      { profileId, params }: { profileId: number; params?: API$MailingRecipientsParams },
      { rejectWithValue, dispatch }
    ) => {
      try {
        dispatch(profilesRecipientsFetching(profileId));
        const { payload } = await apiV6.getMailingRecipients(params);

        return {
          data: payload.results.map(camelKeysRecursive) as Array<AH$Recipient>,
          pages: {
            ...omit(payload, 'results'),
          },
          profileId,
        };
      } catch (e) {
        getErrorHandler()(e);
        dispatch(profilesRecipientsError(profileId));

        return rejectWithValue(e);
      }
    }
  );

  const receiveProfiles = createAsyncThunk(
    `${sliceName}/receiveProfiles`,
    async (
      {
        location,
        params,
        loading = true,
      }: {
        location: AH$RouterLocation;
        params: {
          folderId?: string;
          page?: number;
          perPage?: number;
          position?: number;
          viewedProfiles?: Array<number>;
        };
        loading?: boolean;
      },
      { rejectWithValue, getState, dispatch }
    ) => {
      const {
        page = 1,
        perPage = 20,
        position = 0,
        folderId,
        viewedProfiles = [],
        ...rest
      } = params;

      try {
        if (profilesRequest && profilesRequest.abort) {
          profilesRequest.abort();
        }
        const {
          checkedFilters,
          excludedFilters,
          viewsTransformer,
          extViewed,
          mltrDays,
          commentDays,
        } = convertFromUrl(valIdsForUrlFacetIds as Array<[string, string]>, location);
        const convertedF = convertToUrl({
          valIdsForUrlFacetIds: valIdsForUrlFacetIds as Array<[string, string]>,
          checkedFilters,
          excludedFilters,
          isAndBetween,
          extViewed: extViewed || (getState() as State).filters.extViewed,
          viewsTransformer: viewsTransformer || (getState() as State).filters.viewsTransformer,
          smartFolderId: folderId,
          mltrDays: mltrDays || (getState() as State).filters.mltrDays,
          commentDays: commentDays || (getState() as State).filters.commentDays,
        });
        const isShownExcluded = !!(
          (rest as any)?.f?.['-smartFolderHits'] || (rest as any)?.f?.['-shown']
        );
        const params = stringify({
          ...rest,
          ...(folderId && isShownExcluded ? { per_page: perPage } : { per_page: perPage, page }),
          convertedF,
        });

        if (!folderId) {
          dispatch(setSmartFolder(null));
        }
        if (loading) {
          scrollTo(position);
          dispatch(profilesLoading());
        }
        profilesRequest = abortable(
          folderId
            ? apiV6.smartFoldersProfiles(params, {
                folderId: parseInt(folderId, 10),
                viewedProfiles,
              })
            : api.profiles(params)
        );

        const {
          payload: { profiles, profiles_events, folder_id, folder_name, query_id, excluded_items },
          pages,
          profileReferer,
        } = await profilesRequest;

        const updateProfiles = addProfileEvents({ profiles_events, profiles });

        dispatch(setProfileReferer(profileReferer));
        const { chosenCandidatesIds } = (getState() as State).app;

        if (chosenCandidatesIds.size) {
          const chosenIds = Array.from(chosenCandidatesIds);
          const ids = map(updateProfiles, 'docId');
          const diff = difference(chosenIds, ids);

          if (diff.length) {
            dispatch(removeCandidates(diff));
          }
        }
        dispatch(profilesLoaded({ profiles: updateProfiles, pages }));
        if (folder_id && folder_name) {
          dispatch(
            setSmartFolder({
              id: folder_id,
              name: folder_name,
              viewedProfilesCount: excluded_items,
            }) as any
          );
        }
        dispatch(setSearchQueryId(query_id));
        dispatch(receiveSearchHistory({}));
      } catch (e: any) {
        if (e !== 'ABORTED_PROMISE') {
          if (!isUndefined(e.response)) {
            if (e.response.status === 413) {
              sendSystemMessage({
                error: true,
                payload: { content: 'Too many profiles are included or excluded from folder' },
              });
            }

            e.response
              .json()
              .then((errorMessage: any) => {
                if (typeof errorMessage === 'object' && errorMessage.folderId?.folderId) {
                  handleError({ content: errorMessage.folderId.folderId });
                } else if (typeof errorMessage === 'string') {
                  handleError({ content: errorMessage });
                }
                dispatch(profilesLoaded({ error: errorMessage, isError: true }));
              })
              .catch(() => {
                getErrorHandler({
                  getError: () => dispatch(profilesLoaded({ error: e, isError: true })),
                })(e);
              });
          } else {
            getErrorHandler({
              getError: () => dispatch(profilesLoaded({ error: e, isError: true })),
            })(e);
          }
          rejectWithValue(e);
        }
      }
    }
  );

  const receiveProfilesComments = createAsyncThunk(
    `${sliceName}/receiveProfilesComments`,
    async (profileId: number, { rejectWithValue, getState }) => {
      const isHM = isHiringManager((getState() as State).app.user.role);

      try {
        const [
          {
            payload: { results: folderComments },
          },
          { payload: generalComments },
        ] = await Promise.all([
          apiV6.candidatesGet({
            profile: profileId,
            short: true,
          }),
          isHM
            ? // eslint-disable-next-line no-promise-executor-return
              new Promise<{ payload: Array<API$Comment> }>((resolve) => resolve({ payload: [] }))
            : apiV6.commentsGet(profileId, { general: true }),
        ]);

        const allComments = transformComments(
          generalComments.map(camelKeysRecursive) as Array<AH$ProfileComment>,
          transformFolders(folderComments)
        );
        const foldersComments = allComments.filter(({ candidateId }) => candidateId);

        return {
          profileId,
          comments: isHM ? foldersComments : allComments,
        };
      } catch (e) {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }
    }
  );

  const receiveProfilesContacts = createAsyncThunk(
    `${sliceName}/receiveProfilesContacts`,
    async (
      {
        profileId,
        type,
        onSuccess = noop,
      }: {
        profileId: number;
        type: 'open' | 'update';
        onSuccess?: (contacts: AH$ProfileDisplayContacts) => void;
      },
      { rejectWithValue, getState, dispatch }
    ) => {
      try {
        const { user } = (getState() as State).app;
        const isCustomContactFinderEnabled = get(user, 'company.enableContactsWithoutSources');

        let contactsOfContactFinder: AH$ProfileDisplayContacts = {};

        if (type === 'open') {
          const { payload } = await api.contactsGet(profileId);

          if (isCustomContactFinderEnabled) {
            const {
              payload: { displayContacts },
            } = await apiV6.findContacts(profileId);

            contactsOfContactFinder = displayContacts;
          }

          if (!isManager(user.role)) {
            dispatch(receiveLimitsInfo());
          }
          const contacts = mergeProfileDisplayContacts(payload, contactsOfContactFinder);

          onSuccess(contacts);

          return {
            profileId,
            contacts,
          };
        }
        const {
          payload: { displayContacts },
        } = await apiV6.findContacts(profileId);

        if (!isManager(user.role)) {
          dispatch(receiveLimitsInfo());
        }

        onSuccess(displayContacts);

        return {
          profileId,
          contacts: displayContacts,
        };
      } catch (e) {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }
    }
  );

  const receiveProfilesViews = createAsyncThunk(
    `${sliceName}/receiveProfilesViews`,
    async (profile: number, { rejectWithValue }) => {
      try {
        const { payload } = await api.viewsGet(profile);

        return {
          profileId: profile,
          views: payload.sort((a, b) => b.timestamp - a.timestamp),
        };
      } catch (e) {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }
    }
  );

  const receiveProfilesHints = createAsyncThunk(
    `${sliceName}/receiveProfilesHints`,
    async (
      { profileId, searchQuery }: { profileId: number; searchQuery: string },
      { rejectWithValue }
    ) => {
      try {
        const { payload } = await api.receiveProfileHints(profileId, searchQuery);

        return { profileId, hints: payload };
      } catch (e) {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }
    }
  );

  const deleteComment = createAsyncThunk(
    `${sliceName}/deleteComment`,
    async (
      { profileId, commentId }: { commentId: number; profileId: number },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsDelete(profileId, commentId);
        dispatch(receiveProfilesComments(profileId));
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const updateComment = createAsyncThunk(
    `${sliceName}/updateComment`,
    async (
      { profileId, commentId, text }: { commentId: number; profileId: number; text: string },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsUpdate(profileId, commentId, text);
        dispatch(receiveProfilesComments(profileId));
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const deleteCandidates = createAsyncThunk(
    `${sliceName}/deleteCandidates`,
    async (
      {
        candidates,
        profileId,
        folderId,
        saveMessagingHistory,
        onSuccess = noop,
      }: {
        candidates: Array<number>;
        folderId?: number;
        onSuccess?: () => void;
        profileId?: number;
        saveMessagingHistory?: boolean;
      },
      { rejectWithValue, dispatch, getState }
    ) => {
      try {
        await apiV6.candidatesDelete({ ids: candidates, saveMessagingHistory });
        onSuccess();
        if (folderId) {
          dispatch(getFolder({ id: folderId }));
        } else if (profileId) {
          dispatch(receiveProfilesFolders({ profile: profileId }));
        }
        const {
          folders: { foldersStatus, assigneeId, page },
        } = getState() as State;

        dispatch(getFolders({ foldersStatus, assigneeId, page }));
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const addComment = createAsyncThunk(
    `${sliceName}/addComment`,
    async (
      {
        profileId,
        text,
        candidateId,
      }: { candidateId: number | null; profileId: number; text: string },
      { rejectWithValue, dispatch }
    ) => {
      try {
        await apiV6.commentsAdd(profileId, text, candidateId);
        dispatch(receiveProfilesComments(profileId));
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const receiveFolderStatuses = createAsyncThunk(
    `${sliceName}/receiveFolderStatuses`,
    async (folderId: number, { rejectWithValue }) => {
      try {
        const {
          payload: { candidate_statuses },
        } = await apiV6.folderGet(folderId);

        return candidate_statuses;
      } catch (e) {
        getErrorHandler()(e);

        return rejectWithValue(e);
      }
    }
  );

  const changeCandidateStatus = createAsyncThunk(
    `${sliceName}/changeCandidateStatus`,
    async (
      {
        candidateId,
        statusId,
        profileId,
        folderId,
        onSuccess = noop,
      }: {
        candidateId: number;
        profileId: number;
        statusId: number;
        folderId?: number;
        onSuccess?: (candidateId?: number) => void;
      },
      { rejectWithValue, getState, dispatch }
    ) => {
      try {
        await apiV6.candidatePatch(candidateId, statusId);
        const { folder } = (getState() as State).folders;

        if (
          folder &&
          sliceName === REDUCER_KEYS.foldersProfiles &&
          folderId &&
          folderId === folder.id
        ) {
          onSuccess(candidateId);
        } else {
          dispatch(receiveProfilesFolders({ profile: profileId }));
        }
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const markDiversity = createAsyncThunk(
    `${sliceName}/markDiversity`,
    async (
      {
        profileId,
        race,
        gender,
        onSuccess = noop,
      }: { gender: string; onSuccess: () => void; profileId: number; race: string },
      { rejectWithValue }
    ) => {
      try {
        startLoading();
        await api.markDiversity(profileId, race, gender);
        stopLoading();
        onSuccess();
      } catch (e) {
        getErrorHandler()(e);

        rejectWithValue(e);
      }
    }
  );

  const receiveProfilesExternalContacts = createAsyncThunk(
    `${sliceName}/receiveProfilesExternalContacts`,
    async (
      {
        profileId,
        errorMessage,
        onSuccess = noop,
      }: { errorMessage: string; profileId: number; onSuccess?: () => void },
      { rejectWithValue, getState, dispatch }
    ) => {
      try {
        const {
          app: { user },
        } = getState() as { app: { user: AH$User } };
        const { payload } = await apiV6.findContacts(profileId);

        onSuccess();

        if (!isManager(user.role)) {
          dispatch(receiveLimitsInfo());
        }
        return { profileId, contacts: payload.displayContacts };
      } catch (e) {
        getErrorHandler()({ message: errorMessage, name: 'ResponseError' });

        return rejectWithValue(e);
      }
    }
  );

  const slice = createSlice({
    name: sliceName,
    initialState: profilesInitialState,
    reducers: {
      changeFocusedProfile: (
        state,
        action: PayloadAction<{ dir: ProfileFocusDirection; profileId?: number }>
      ) => {
        const currentIndex = state.profiles.indexOf(state.focusedProfile.id || -1);
        const newIndex =
          action.payload.dir === ProfileFocusDirection.Prev ? currentIndex - 1 : currentIndex + 1;
        const index = action.payload.profileId
          ? state.profiles.indexOf(action.payload.profileId)
          : newIndex;
        const profile = state.profiles[index] || state.profiles[0];

        let indexPosition = ProfileIndexPosition.Middle;

        if (index === state.profiles.length - 1) {
          indexPosition = ProfileIndexPosition.Last;
        } else if (index === 0) {
          indexPosition = ProfileIndexPosition.First;
        }
        state.focusedProfile = { id: profile || null, indexPosition };
      },
      setIsProfileAddedToFolder: (
        state,
        action: PayloadAction<{ id: number; isAddedToFolder: boolean }>
      ) => {
        const { id: profileId, isAddedToFolder } = action.payload;
        const profile = state.profilesById[profileId];

        state.profilesById[profileId] = { ...profile, IsAddedToFolder: isAddedToFolder };
      },
      setDisplayTags: (
        state,
        action: PayloadAction<{ data: AH$DisplayTag[]; profileId: number }>
      ) => {
        const { profileId, data } = action.payload;

        state.profilesById[profileId].displayProfileTags = data;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(profilesFoldersFetching, (state, action) => {
        state.foldersStatusById[action.payload] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesFolders.fulfilled, (state, action) => {
        state.profilesFolders[action.payload.profileId] = action.payload.folders;
        state.foldersStatusById[action.payload.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(profilesFoldersError, (state, action) => {
        state.foldersStatusById[action.payload] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(profilesRecipientsFetching, (state, action) => {
        state.recipientsStatusById[action.payload] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesRecipients.fulfilled, (state, action) => {
        state.profilesRecipients[action.payload.profileId] = {
          data: action.payload.data,
          pages: action.payload.pages,
        };
        state.recipientsStatusById[action.payload.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(profilesRecipientsError, (state, action) => {
        state.recipientsStatusById[action.payload] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(profilesLoading, (state) => {
        state.fetchingProfilesStatus = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(profilesLoaded, (state, action) => {
        if (action.payload.isError && 'error' in action.payload) {
          state.profiles = [];
          state.profilesById = {};
          state.pages = null;
          state.error = action.payload.error;
          state.fetchingProfilesStatus = FETCHING_STATUSES.ERROR;
        } else if ('profiles' in action.payload) {
          state.profiles = map(action.payload.profiles, 'docId');
          state.profilesById = turnArrayToObject(
            'id',
            action.payload.profiles.map(transformProfile) || []
          );
          state.pages = action.payload.pages;
          state.error = null;
          state.survey = {};
          state.isAILearning = false;
          state.aiProgress = null;
          state.fetchingProfilesStatus = FETCHING_STATUSES.SUCCESS;
          state.focusedProfile = {
            id: get(action.payload.profiles[0], 'docId', null),
            indexPosition: ProfileIndexPosition.First,
          };
        }
      });
      builder.addCase(receiveProfilesComments.pending, (state, action) => {
        state.commentsStatusById[action.meta.arg] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesComments.fulfilled, (state, action) => {
        state.profilesComments[action.payload.profileId] = action.payload.comments;
        state.commentsStatusById[action.payload.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(receiveProfilesComments.rejected, (state, action) => {
        state.commentsStatusById[action.meta.arg] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(receiveProfilesContacts.pending, (state, action) => {
        state.contactsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesContacts.fulfilled, (state, action) => {
        const { recommendations, profilesById } = state;
        const { profileId } = action.payload;
        const currentProfile =
          profilesById[profileId] || recommendations.resultItems.find(({ id }) => id === profileId);
        const isRecommendedProfile = !profilesById[profileId];
        const contacts = transformContacts(
          {
            ...action.payload.contacts,
            link: currentProfile.displayLinks,
          },
          profileId,
          currentProfile.orderId
        );
        const profile = {
          ...currentProfile,
          displayContacts: action.payload.contacts,
          displayContactMasks: {},
          ...contacts,
        };

        if (isRecommendedProfile) {
          const itemIndex = recommendations.resultItems.findIndex(({ id }) => id === profileId);
          const newRecommendationItems = [...recommendations.resultItems];

          newRecommendationItems[itemIndex] = profile;
          state.contactsStatusById[profileId] = FETCHING_STATUSES.SUCCESS;
          state.recommendations.resultItems = newRecommendationItems;
        } else {
          state.contactsStatusById[profileId] = FETCHING_STATUSES.SUCCESS;
          state.profilesById[profileId] = profile;
        }
      });
      builder.addCase(receiveProfilesContacts.rejected, (state, action) => {
        state.contactsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(receiveProfilesViews.pending, (state, action) => {
        state.viewsStatusById[action.meta.arg] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesViews.fulfilled, (state, action) => {
        state.profilesViews[action.payload.profileId] = action.payload.views;
        state.viewsStatusById[action.payload.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(receiveProfilesViews.rejected, (state, action) => {
        state.viewsStatusById[action.meta.arg] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(receiveProfilesHints.pending, (state, action) => {
        state.hintsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesHints.fulfilled, (state, action) => {
        state.profilesHints[action.payload.profileId] = action.payload.hints;
        state.hintsStatusById[action.payload.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(receiveProfilesHints.rejected, (state, action) => {
        state.hintsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(deleteComment.pending, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(deleteComment.fulfilled, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(deleteComment.rejected, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(updateComment.pending, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(updateComment.fulfilled, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(updateComment.rejected, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(deleteCandidates.rejected, (state, action) => {
        if (action.meta.arg.profileId) {
          state.foldersStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
        }
      });
      builder.addCase(addComment.pending, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(addComment.fulfilled, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(addComment.rejected, (state, action) => {
        state.commentsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(receiveFolderStatuses.pending, (state) => {
        state.folderStatuses.status = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveFolderStatuses.fulfilled, (state, action) => {
        state.folderStatuses = {
          data: action.payload,
          status: FETCHING_STATUSES.SUCCESS,
        };
      });
      builder.addCase(receiveFolderStatuses.rejected, (state) => {
        state.folderStatuses.status = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(changeCandidateStatus.pending, (state, action) => {
        state.foldersStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(changeCandidateStatus.rejected, (state, action) => {
        state.foldersStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(receiveProfilesExternalContacts.pending, (state, action) => {
        state.externalContactsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.LOADING;
      });
      builder.addCase(receiveProfilesExternalContacts.fulfilled, (state, action) => {
        const { recommendations, profilesById } = state;
        const { profileId } = action.payload;
        const currentProfile =
          profilesById[profileId] || recommendations.resultItems.find(({ id }) => id === profileId);
        const isRecommendedProfile = !profilesById[profileId];
        const contacts = transformContacts(
          {
            ...action.payload.contacts,
            link: currentProfile.displayLinks,
          },
          profileId,
          currentProfile.orderId
        );
        const profile = {
          ...currentProfile,
          displayContacts: action.payload.contacts,
          displayContactMasks: {},
          ...contacts,
        };

        if (isRecommendedProfile) {
          const itemIndex = recommendations.resultItems.findIndex(({ id }) => id === profileId);
          const newRecommendationItems = [...recommendations.resultItems];

          newRecommendationItems[itemIndex] = profile;
          state.contactsStatusById[profileId] = FETCHING_STATUSES.SUCCESS;
          state.recommendations.resultItems = newRecommendationItems;
        } else {
          state.contactsStatusById[profileId] = FETCHING_STATUSES.SUCCESS;
          state.profilesById[profileId] = profile;
        }
        state.externalContactsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.SUCCESS;
      });
      builder.addCase(receiveProfilesExternalContacts.rejected, (state, action) => {
        state.externalContactsStatusById[action.meta.arg.profileId] = FETCHING_STATUSES.ERROR;
      });
      builder.addCase(profilesFoldersRecommendationsLoading, (state) => {
        state.profilesFoldersRecommendations = { data: [], status: FETCHING_STATUSES.LOADING };
      });
      builder.addCase(profilesFoldersRecommendationsLoaded, (state, action) => {
        state.profilesFoldersRecommendations = {
          data: action.payload,
          status: FETCHING_STATUSES.SUCCESS,
        };
      });
      builder.addCase(profilesFoldersRecommendationsError, (state) => {
        state.profilesFoldersRecommendations = { data: [], status: FETCHING_STATUSES.ERROR };
      });
    },
  });

  return {
    ...slice.actions,
    profilesFoldersRecommendationsLoading,
    profilesFoldersRecommendationsLoaded,
    profilesFoldersRecommendationsError,
    profilesFoldersFetching,
    receiveProfilesFolders,
    profilesFoldersError,
    profilesRecipientsFetching,
    receiveProfilesRecipients,
    profilesRecipientsError,
    profilesLoading,
    profilesLoaded,
    receiveProfilesComments,
    receiveProfilesContacts,
    receiveProfilesExternalContacts,
    receiveProfilesViews,
    receiveProfilesHints,
    deleteComment,
    updateComment,
    deleteCandidates,
    addComment,
    receiveFolderStatuses,
    changeCandidateStatus,
    markDiversity,
    receiveProfiles,
    reducer: slice.reducer,
  };
};

export const { reducer: profilesSearchReducer, ...profilesSearchActions } = createProfilesSlice(
  REDUCER_KEYS.searchProfiles
);

export const { reducer: profilesFoldersReducer, ...profilesFoldersActions } = createProfilesSlice(
  REDUCER_KEYS.foldersProfiles
);
