export default {
  messagesDeleted: {
    id: 'systemMessages.messagesDeleted',
    defaultMessage: 'Message sequence deleted',
  },
  sequenceStopped: {
    id: 'systemMessages.sequenceStopped',
    defaultMessage: 'Message sequence stopped',
  },
  mailingListDeleted: {
    id: 'systemMessages.mailingListDeleted',
    defaultMessage: 'Mailing list deleted',
  },
  mailingListStopped: {
    id: 'systemMessages.mailingListStopped',
    defaultMessage: 'Mailing list stopped',
  },
  fileUploadError: {
    id: 'systemMessages.fileUploadError',
    defaultMessage: 'Upload failed. Please, try again later.',
  },
  fileParsingError: {
    id: 'systemMessages.fileParsingError',
    defaultMessage: 'Profile could not be created. Please, try uploading another file',
  },
  fileUploaded: {
    id: 'systemMessages.fileUploaded',
    defaultMessage: 'File {name} attached to the profile',
  },
  bannedLinkCreated: {
    id: 'systemMessages.bannedLinkCreated',
    defaultMessage: 'Link {url} banned',
  },
  bannedLinkDeleted: {
    id: 'systemMessages.bannedLinkDeleted',
    defaultMessage: 'Link {url} unbanned',
  },
};
