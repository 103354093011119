import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { changeRoute, NavigateTo } from 'slices/app';

export type NavigateFunction = (to: NavigateTo) => void;

const useCustomNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const func = useMemo(() => changeRoute(navigate), [navigate]);

  return func;
};

export default useCustomNavigate;
