import { del, directGet, download, get, patch, post, put } from './request';

export { onError } from './request';

export function profiles(
  params: string
): Promise<{ pages: AH$Pages; payload: API$ProfilesPayload; profileReferer: string }> {
  return directGet(`/api/v6/profiles/?${params}`);
}

export function profilesFilters(params: string): Promise<{
  gdpr: API$GdprFilters;
  payload: { [key: string]: Array<API$FilterValue> };
}> {
  return directGet(`/api/profiles/filters/?${params}`);
}

export function historyGet(params?: {
  creator_id?: string | null;
  date_range?: string | null;
  filter_smart_folders?: boolean;
  limit?: number;
  offset?: number;
}): Promise<{
  payload: API$PaginatedResponse<API$SearchHistoryQuery>;
}> {
  return get('/api/internal/search_queries/', params);
}

export function historyEntryHide(query: string): Promise<void> {
  return post('/api/internal/search_queries/hide/', { data: { query } });
}

export function companyGet(name: string): Promise<{ payload: API$CompanyProfile['info'] }> {
  return get(`/api/internal/companies/${name}/`);
}

export function acceptTos(id: number, accept: boolean): Promise<void> {
  return post(`/api/accept_tos/${id}/`, { data: { accept } });
}

export function suggestsGet(type2terms: API$Type2term): Promise<{ payload: API$RawTypeToSuggest }> {
  return post('/api/v6/suggestions/', { data: { type2terms } });
}

export function moveCandidates(
  type: 'folder' | 'sequence',
  id: number,
  destinationFolderId: number,
  candidateIds: Array<number>,
  copyComments: boolean,
  saveMessagingHistory?: boolean
): Promise<{ payload: API$MovedOrCopiedCandidates }> {
  return post(`/api/v6/${type === 'sequence' ? 'sequences' : 'folders'}/${id}/candidates/move/`, {
    data: {
      destination_folder: destinationFolderId,
      candidates: candidateIds,
    },
    params: {
      withComments: copyComments,
      saveMessagingHistory,
    },
  });
}

export function copyCandidates(
  type: 'folder' | 'sequence',
  id: number,
  destinationFolderId: number,
  candidateIds: Array<number>,
  copyComments: boolean
): Promise<{ payload: API$MovedOrCopiedCandidates }> {
  return post(`/api/v6/${type === 'sequence' ? 'sequences' : 'folders'}/${id}/candidates/copy/`, {
    data: {
      destination_folder: destinationFolderId,
      candidates: candidateIds,
    },
    params: {
      withComments: copyComments,
    },
  });
}

/* TODO: Удалить после согласования */
export function singularProfiles(
  id: number
): Promise<{ payload: { data: Array<API$SingularProfile>; status: string } }> {
  return get('/api/singular_profiles/v4/', { id });
}

export function profileByUrl({
  names = [''],
  links = [''],
}: {
  links: Array<string>;
  names: Array<string>;
}): Promise<{ payload: { exactItems: Array<API$Profile>; possibleItems: Array<API$Profile> } }> {
  return post('/api/internal/ext/profiles/search_by_url/', { data: { names, links } });
}
/* TODO: Удалить после согласования */
export function addProfileLink(
  profileId: number,
  link: string
): Promise<{ payload: { status: 'string' } }> {
  return post('/api/addlnk/v4/', { data: { profile_id: profileId, link } });
}

export function detachProfileByUrl(
  profileId: number,
  url: string
): Promise<{
  payload: {
    permId: number;
    permIdDetached: number;
    success: boolean;
    unitedProfile: API$CompanyInAdminPanel;
    unitedProfileDetached: API$CompanyInAdminPanel;
  };
  status: string;
}> {
  return post(`/api/profiles/${profileId}/detach/`, { data: { detached_profile_url: url } });
}

export function historyProfile(
  profileId: number,
  company_id: string
): Promise<{ payload: API$HistoryProfile }> {
  return get(`/api/profiles/${profileId}/history/`, { company_id });
}

export type TSourceProfileReport = 'full_profile' | 'ext' | 'serp' | 'folder';
export type TReasonProfileReport =
  | 'query_mismatch'
  | 'not_updated'
  | 'incorrect_bio'
  | 'wrong_contacts'
  | 'wrong_location'
  | 'other';
export function reportProfile(
  profile_id: number,
  source: TSourceProfileReport,
  reason: TReasonProfileReport,
  text?: string,
  query?: string
): Promise<void> {
  return post(`/api/bug_reports/`, { data: { profile_id, source, reason, text, query } });
}

/* TODO: Удалить после согласования */
export function mergeProfiles(
  profileFirst: number,
  profileSecond: number
): Promise<{ success: boolean }> {
  return post('/api/glun/v4/', {
    data: {
      profile_id_first: profileFirst,
      profile_id_second: profileSecond,
    },
  });
}

export function getToken(): Promise<{ payload: { token: string } }> {
  return get('/api/access_token/');
}

export function generateToken(): Promise<{ payload: { token: string } }> {
  return post('/api/access_token/');
}

export function deleteToken(): Promise<void> {
  return del('/api/access_token/');
}

export function getApiStatsByCompany(
  company_id: number,
  date_range?: string
): Promise<{ payload: API$ApiStats }> {
  return get('/api/api_calls/aggregated/', { company_id, date_range });
}

export function getApiStats(
  start_date: string,
  end_date: string
): Promise<{ payload: API$ApiStats }> {
  return get('/api/api_calls/aggregated/', { start_date, end_date });
}

export function socialAccountsGet(): Promise<{ payload: API$SocialAccounts }> {
  return get('/api/social_accounts/');
}

export function socialAccountsPost(
  vendor: string,
  accessToken: string | null
): Promise<{ payload: API$SocialAccount }> {
  const data = { vendor, access_token: accessToken };

  return post('/api/social_accounts/', { data });
}

export function socialAccountDelete(vendor: string): Promise<void> {
  return del(`/api/social_accounts/${vendor}/`);
}

export function getEmailAliases(): Promise<{ payload: { email: string; name: string }[] }> {
  return get('/messaging/gmail/aliases/');
}

export function renewAndGetEmailAliases(): Promise<{ payload: { email: string; name: string }[] }> {
  return post('/messaging/gmail/aliases/');
}

export function mixmaxSequenceReports(
  profileId: number
): Promise<{ payload: Array<API$MixmaxReport> }> {
  return get('/api/mixmax/sequence_reports/', {
    ah_profile_id: profileId,
  });
}

export function greenhouseVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/greenhouse/vacancies/');
}

export function greenhouseCandidateVacancies(
  profileId: number | string
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/greenhouse/vacancies/', {
    ah_profile_id: profileId,
  });
}

export function greenhouseSelectCandidate(
  profileId: number,
  jobId?: number | string,
  asProspect?: boolean
): Promise<void> {
  const data: {
    ah_profile_id: number;
    as_prospect: boolean | undefined;
    job_id: number | string | undefined;
  } = {
    ah_profile_id: profileId,
    job_id: undefined,
    as_prospect: asProspect,
  };

  if (jobId) {
    data['job_id'] = jobId;
  }

  return post('/api/greenhouse/candidates/', { data });
}

export function greenhouseUpdateCandidate(profileId: number): Promise<void> {
  return patch('/api/greenhouse/candidates/', { data: { ah_profile_id: profileId } });
}

export function greenhouseEnableAutoUpdate(profileId: number): Promise<void> {
  return post(`/api/greenhouse/candidates/${profileId}/updates/`, { data: {} });
}

export function greenhouseDisableAutoUpdate(profileId: number): Promise<void> {
  return del(`/api/greenhouse/candidates/${profileId}/updates/`);
}

export function exportGreenhouseVacancy(
  type: 'folder' | 'sequence',
  folderId: number,
  jobId: number | string | null,
  candidateIds: Array<number> | null,
  asProspect?: boolean,
  updatesSubscription = false
): Promise<void> {
  const data = {
    job_id: jobId || null,
    updates_subscription: updatesSubscription,
    ...(jobId && { as_prospect: asProspect }),
    ...(candidateIds && { candidate_ids: candidateIds }),
  };

  return post(
    `/api/${
      type === 'sequence' ? 'sequences' : 'vacancies'
    }/${folderId}/candidates/export/greenhouse`,
    { data }
  );
}

export function smartrecruitersCandidateExportCheck(profileId: number): Promise<void> {
  return get(`/api/smartrecruiters/candidate/check/${profileId}`);
}

export function smartrecruitersVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/smartrecruiters/vacancies/');
}

export function smartrecruitersCandidateVacancies(
  profileId: number | string
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/smartrecruiters/vacancies/', {
    ah_profile_id: profileId,
  });
}

/**
 * @param {number} profileId
 * @param {number} [jobId]
 * @returns {Promise.<ResponsePayload, ResponseError>}
 */
export function smartrecruitersSelectCandidate(
  profileId: number,
  jobId?: string | number
): Promise<void> {
  const data: {
    ah_profile_id: number;
    job_id: string | number | undefined;
  } = {
    ah_profile_id: profileId,
    job_id: undefined,
  };

  if (jobId) {
    data['job_id'] = jobId;
  }

  return post('/api/smartrecruiters/candidate/', { data });
}

export function smartrecruitersUpdateCandidate(profileId: number): Promise<void> {
  return patch('/api/smartrecruiters/candidate/', { data: { ah_profile_id: profileId } });
}

export function exportSmartrecruitersVacancy(
  type: 'folder' | 'sequence',
  folderId: number,
  jobId: string | number | null,
  candidateIds: Array<number> | null
): Promise<void> {
  const data = {
    job_id: jobId || null,
    ...(candidateIds && { candidate_ids: candidateIds }),
  };

  return post(
    `/api/${
      type === 'sequence' ? 'sequences' : 'vacancies'
    }/${folderId}/candidates/export/smartrecruiters`,
    { data }
  );
}

export const exportFolderToTeamtailor = (
  type: 'folder' | 'sequence',
  folderId: number,
  jobId: string | number | null,
  candidateIds: Array<number> | null,
  asProspect?: boolean,
  updatesSubscription = false
): Promise<void> => {
  const data = {
    job_id: asProspect ? null : jobId,
    ...(candidateIds && { candidate_ids: candidateIds }),
    updates_subscription: updatesSubscription,
  };

  return post(
    `/api/${
      type === 'sequence' ? 'sequences' : 'vacancies'
    }/${folderId}/candidates/export/teamtailor/`,
    { data }
  );
};

export function teamtailorEnableAutoUpdate(profileId: number): Promise<void> {
  return post(`/api/teamtailor/candidates/${profileId}/updates/`, { data: {} });
}

export function teamtailorDisableAutoUpdate(profileId: number): Promise<void> {
  return del(`/api/teamtailor/candidates/${profileId}/updates/`);
}

export function resendActivationEmail(): Promise<void> {
  return get('/api/resend_activation_email/');
}

export function resendVerificationEmail(): Promise<void> {
  return get('/api/resend_verification_email/');
}

export function markDiversity(profileId: number, race: string, gender: string): Promise<void> {
  return post(`/api/profiles/${profileId}/diversity_labels/`, { data: { race, gender } });
}

export function markSkill(profileId: number, skill: string, skillLevel: string): Promise<void> {
  return post(`/api/profiles/${profileId}/skill_labels/`, { data: { skill, skillLevel } });
}

export function serviceOrdersGet(): Promise<{
  payload: Array<API$ProfileServiceOrder>;
}> {
  return get('/api/service_orders/');
}

export function serviceOrderGet(orderId: number): Promise<{ payload: API$ProfileServiceOrder }> {
  return get(`/api/service_orders/${orderId}/`);
}

export function orderCreditPacks(
  plan: string,
  credits_count: number,
  price: number
): Promise<void> {
  return post(`/api/credit_packs/`, { data: { plan, credits_count, price } });
}

export function orderService({
  service,
  price,
  profileId,
  contactTypes,
}: API$OrderService): Promise<{ payload: API$ProfileServiceOrder }> {
  const data = {
    profile_id: profileId,
    contact_types: contactTypes,
  };

  return post(`/api/service_orders/`, { data: { service, price, data } });
}

export function changeOrderStatus(
  orderId: number,
  status: string | null
): Promise<{ payload: API$ProfileServiceOrder }> {
  return patch(`/api/service_orders/${orderId}/`, { data: { status } });
}

export function currentUser(): Promise<{
  payload: API$User & { system_notifications: API$SystemNotification[] };
}> {
  return get('/api/internal/users/me/');
}

export function invitations(data: API$Invitation): Promise<{ payload: API$UserInvite }> {
  return post(`/api/v6/invitations/`, { data });
}

export function acceptInvitation(key: string, data: API$AcceptInvite): Promise<void> {
  return put(`/api/v6/invitations/${key}/accept/`, { data });
}

export function getInvitation(
  key: string
): Promise<{ payload: { email: string; role: AH$User['role'] } }> {
  return get(`/api/v6/invitations/${key}`);
}

export function contactsGet(id: number): Promise<{ payload: API$ProfileDisplayContacts }> {
  return get(`/api/v6/profiles/${id}/contacts/`);
}

export function canOpenHiddenContacts(
  id: number,
  data: Array<number>,
  type: 'folder' | 'sequence' = 'folder'
): Promise<{ payload: API$CreditLimits }> {
  return post(
    `/api/${type === 'sequence' ? 'sequences' : 'vacancies'}/${id}/candidates/export/check`,
    { data }
  );
}

export function downloadFile(
  id: number,
  data: Array<number>,
  params: API$DownloadFileParams,
  type: 'folder' | 'sequence' = 'folder'
): Promise<void> {
  return download(
    `/api/${type === 'sequence' ? 'sequences' : 'vacancies'}/${id}/candidates/export/csv`,
    { data, params }
  );
}

export function getCSVFields(): Promise<{
  payload: { data: Array<API$CsvCheckbox>; status: { code: number; message: string } };
}> {
  return get(`/api/candidates/export/columns`);
}

export function getSavedQueries(params?: API$PaginationParams): Promise<{
  payload: API$PaginatedResponse<API$SavedQuery>;
}> {
  return get('/api/savedqueries/', params);
}

export function querySave(data: {
  description: string;
  name: string;
  query: string;
}): Promise<void> {
  return post('/api/savedqueries/', { data });
}

export function queryDelete(id: number): Promise<void> {
  return del(`/api/savedqueries/${id}`);
}

export function checkCreditLimits(): Promise<{ payload: API$Quotas }> {
  return get('/api/quotas/my/');
}

export function viewsGet(profileId: number): Promise<{ payload: Array<API$ProfileView> }> {
  return get(`/api/profiles/${profileId}/events/`);
}

export function receiveProfileHints(
  profileId: number,
  params: string
): Promise<{ payload: Array<API$ProfileHint> }> {
  return get(`/api/profiles/${profileId}/search_hints/${params}`);
}

export function getProfileById(
  id: number,
  profileReferer?: string
): Promise<{ payload: Array<API$Profile> }> {
  return get(
    `/api/internal/profiles/${id}/`,
    {},
    null,
    false,
    profileReferer ? { 'X-AH-Profile-Referer': encodeURI(profileReferer) } : undefined
  );
}

export function getResumesById(id: number): Promise<{ payload: Array<API$ProfileResumeFull> }> {
  return get(`/api/internal/profiles/${id}/resumes/`);
}

export const exportFolderToLever = (
  type: 'folder' | 'sequence',
  folderId: number,
  jobId: string | number | null,
  candidateIds: Array<number> | null,
  asProspect?: boolean
): Promise<void> => {
  const data = {
    job_id: asProspect ? null : jobId,
    ...(candidateIds && { candidate_ids: candidateIds }),
  };

  return post(
    `/api/${type === 'sequence' ? 'sequences' : 'vacancies'}/${folderId}/candidates/export/lever/`,
    { data }
  );
};

export const exportFolderToWorkable = (
  type: 'folder' | 'sequence',
  folderId: number,
  jobId: string | number | null,
  candidateIds: Array<number> | null
): Promise<void> => {
  const data = {
    job_id: jobId,
    ...(candidateIds && { candidate_ids: candidateIds }),
  };

  return post(
    `/api/${
      type === 'sequence' ? 'sequences' : 'vacancies'
    }/${folderId}/candidates/export/workable/`,
    { data }
  );
};

export function teamtailorVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/teamtailor/vacancies/');
}

export function teamtailorCandidateVacancies(
  ah_profile_id: string | number
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/teamtailor/vacancies/', { ah_profile_id });
}

export function teamtailorSelectCandidate(
  ah_profile_id: string | number,
  job_id?: string | number
): Promise<void> {
  return post('/api/teamtailor/candidates/', { data: { ah_profile_id, job_id } });
}

export function teamtailorUpdateCandidate(profileId: number): Promise<void> {
  return patch('/api/teamtailor/candidates/', { data: { ah_profile_id: profileId } });
}

export function workableVacancies(): Promise<{ payload: Array<API$IntegrationVacancy> }> {
  return get('/api/workable/vacancies/');
}

export function workableSelectCandidate(
  ah_profile_id: string | number,
  job_id?: string | number
): Promise<void> {
  return post('/api/workable/candidates/', { data: { ah_profile_id, job_id } });
}

export function workableCandidateVacancies(
  ah_profile_id: string | number
): Promise<{ payload: API$IntegrationCandidateVacancies }> {
  return get('/api/workable/vacancies/', { ah_profile_id });
}

export function workableUpdateCandidate(profileId: number): Promise<void> {
  return patch('/api/workable/candidates/', { data: { ah_profile_id: profileId } });
}

export function getRedirectLinkSSO(email: string): Promise<{ payload: { link: string } }> {
  return get('/sso/auth/sso_url/', { email });
}

export function checkUserStatus(): Promise<{
  payload: { is_activated: boolean; is_verified: boolean };
}> {
  return get('/api/v6/user-status/');
}
