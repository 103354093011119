import { del, get, post } from './request';

export function uploadFile(formData: FormData): Promise<{ payload: { file_id: number } }> {
  return post('/api/files/v1/', {
    dataType: 'formData',
    data: formData,
  });
}

export function deleteFile(fileId: number, resume_id: string): Promise<void> {
  return del(`/api/files/v1/${fileId}/`, null, { resume_id });
}

export function getFile(fileId: number): Promise<{ payload: API$File }> {
  return get(`/api/files/v1/${fileId}/`);
}
