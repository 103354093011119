import {
  Children,
  CSSProperties,
  FC,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContentState } from 'draft-js';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import Input from 'components/Base/Input';
import InputWithAutoComplete from 'components/Base/Input/InputWithAutoComplete';
import { useSequenceProfileViewContext } from 'components/Messaging/Sequences/SequenceProfileView/SequenceProfileViewContext';
import { fontStyleMap } from '../../FontControl';
import styles from './Variable.scss';

const theme = thematize(styles);

const getVarStyle = (styleSet: string[]): CSSProperties =>
  styleSet.reduce<CSSProperties>((acc, cur) => {
    if (cur === 'BOLD') {
      return { ...acc, fontWeight: 'bold' };
    }
    if (cur === 'ITALIC') {
      return { ...acc, fontStyle: 'italic' };
    }

    const fontSize = fontStyleMap[cur as keyof typeof fontStyleMap];

    if (fontSize) {
      return { ...acc, ...fontSize };
    }

    return acc;
  }, {});

type VariableProps = {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
};

export const Variable: FC<VariableProps> = ({ children, contentState, entityKey }) => {
  const [isEditing, toggleEditing] = useState(false);
  const { customData, setCustomData } = useSequenceProfileViewContext();
  const entity = contentState.getEntity(entityKey);
  const { variable } = entity.getData();
  const data = customData[variable] || {};
  const { value = '' } = data;
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const child = Children.toArray(children)[0];
  const styleSet: string[] = isValidElement(child) ? child.props.styleSet : [];
  const style = getVarStyle(styleSet);

  useEffect(() => {
    // Для того, чтобы дропдаун с опциями не убирался сразу
    if (isEditing) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isEditing]);

  if (!setCustomData) {
    return <span className={theme('variable')}>{children}</span>;
  }

  const handleSave = () => {
    setCustomData({ [variable]: { ...data, value: inputValue } });
    toggleEditing(false);
    tracking.changeRecipientCustomData(false, 'public');
  };

  const handleCancel = () => {
    toggleEditing(false);
    setInputValue(value);
  };

  if (isEditing) {
    return (
      <span style={style} className={theme('editing')}>
        <InputWithAutoComplete
          inputRef={inputRef}
          input={<Input appearance="lined" className={theme('input')} />}
          displayAllOptions
          value={inputValue}
          onChange={(value) => setInputValue(value)}
        >
          {data.data?.length > 0 &&
            data.data.map((option) => (
              <InputWithAutoComplete.Option textValue={option} key={option} />
            ))}
        </InputWithAutoComplete>
        <Icon
          type="check"
          className={theme('editing__icon', { color: 'blue' })}
          onClick={handleSave}
        />
        <Icon
          type="cross"
          className={theme('editing__icon', { color: 'grey' })}
          onClick={handleCancel}
        />
      </span>
    );
  }

  if (!value) {
    return (
      <span
        style={style}
        className={theme('container', { empty: true })}
        onClick={() => toggleEditing(true)}
      >
        {variable}
      </span>
    );
  }

  return (
    <span style={style} className={theme('container')}>
      {value}
      <span className={theme('actions')}>
        <span className={theme('actions__text')}>{value}</span>
        <Icon
          type="comments"
          className={theme('actions__icon')}
          onClick={() => toggleEditing(true)}
        />
      </span>
    </span>
  );
};

export default Variable;
