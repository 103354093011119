import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { ContentState } from 'draft-js';
import { Map } from 'immutable';
import { get, isBoolean, isEmpty, noop } from 'lodash';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import { addRootEventListener, removeRootEventListener } from 'lib/utils';
import { useCustomRouterLocation } from 'hooks';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import { getCurrentTextSuggestion } from 'components/QueryEditor/lib';
import LocationRangeButton from './LocationRangeButton';
import Synonyms from './Synonyms';
import styles from './Term.scss';

const theme = thematize(styles);

interface SynonymsButtonProps {
  focused: boolean;
  className?: string;
}

export const SynonymsButton: FC<SynonymsButtonProps> = ({ focused, className }) => (
  <span
    className={`${theme('ellipsis')} ${className}`}
    onMouseDown={(e): void => {
      if (focused) {
        // не допускает изменение позиции каретки
        e.preventDefault();
      }
    }}
  >
    <Icon type="ellipsis" />
  </span>
);

interface TermProps {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
}

const Term: FC<TermProps> = ({ children, contentState, entityKey }) => {
  const { pathname } = useCustomRouterLocation();
  const searchFormType = useMemo(() => (pathname.includes('-old') ? 'v1' : 'v2'), [pathname]);
  const isV2 = searchFormType === 'v2';
  const entity = contentState.getEntity(entityKey);
  const { field, text, whithSynonym } = entity.getData();
  const [open, setOpen] = useState(false);
  const blockData = contentState.getFirstBlock().getData();
  const suggestions = blockData.get('suggestions') || Map();
  const focused = blockData.get('focused');
  const locationRange = field.get('locationRange');
  const suggestion = getCurrentTextSuggestion(suggestions, text, field.get('suggest'));
  const isTag = field.get('suggest') === 'text' && text.startsWith('#');
  const suggestionWithTrimLocation =
    ['location', 'folder'].includes(field.get('suggest')) || isTag ? [] : suggestion;
  const isLocationRange = field.get('type') === 'locationRange';
  const isKnown = get(suggestion, 'isKnown') && (!isLocationRange || !whithSynonym);
  const synonyms = get(suggestionWithTrimLocation, 'synonyms', []);
  const variants = get(suggestionWithTrimLocation, 'variants', []);
  const isHistoryOrSaved = ['#history', '#saved-queries'].includes(window.location.hash);
  const bold = suggestion?.isKnown && isLocationRange && !focused;
  const disabled = isLocationRange && !bold && !isHistoryOrSaved;
  const showSynonyms = whithSynonym && (!isEmpty(synonyms) || !isEmpty(variants));

  const handleClick = useCallback(
    (isOpen: KeyboardEvent | boolean = false): void => {
      const opened = isBoolean(isOpen) ? !isOpen : open;
      const isChanged = opened !== !open;

      if (isChanged) {
        const onSynonymsToggle =
          contentState.getFirstBlock().getData().get('onSynonymsToggle') || noop;

        if (!opened) {
          tracking.openSynonyms(
            tracking.LOCATIONS[window.location.pathname === '/' ? 'home' : 'search']
          );
        }
        setOpen((open) => !open);
        onSynonymsToggle(entityKey);
      }
    },
    [open, contentState, entityKey]
  );

  useEffect(() => {
    if (open) {
      addRootEventListener('keydown', handleClick);
    } else {
      removeRootEventListener('keydown', handleClick);
    }

    return () => {
      removeRootEventListener('keydown', handleClick);
    };
  }, [open, handleClick]);

  if (isV2) {
    return <span id={entityKey}>{children}</span>;
  }

  return (
    <span id={entityKey} className={theme('name', { isKnown, disabled, bold })}>
      {children}
      {isKnown &&
        (showSynonyms || (isLocationRange && !!locationRange)) &&
        (isLocationRange ? (
          <LocationRangeButton range={locationRange} />
        ) : (
          <Dropdown
            menuShouldBlockScroll
            optionsOpened={open}
            onFocusLoss={() => handleClick(false)}
            onButtonClick={() => handleClick(true)}
            bodyClassName={theme('body')}
            button={
              <div>
                <SynonymsButton focused={focused} />
              </div>
            }
          >
            <Synonyms synonyms={synonyms} variants={variants} />
          </Dropdown>
        ))}
    </span>
  );
};

export default Term;
