import {
  AtomicBlockUtils,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import getEmojiRegex from 'emoji-regex';
import { find } from 'lodash';

import { generateId } from 'lib/utils';
import { getHTMLOrText } from 'components/Messaging/Message/hooks/useEditor';

const emojiRegex = getEmojiRegex();

export const transformEditorSelection = (editorState: EditorState): EditorState => {
  let selection = editorState.getSelection();

  let isChanged = false;

  const isBackward = selection.getIsBackward();
  const blockMap = editorState.getCurrentContent().getBlockMap();
  const startBlock = blockMap.get(selection.getStartKey());
  const endBlock = blockMap.get(selection.getEndKey());
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  const startOffsetMessageVar = startBlock
    .getText()
    .slice(0, startOffset)
    .match(/{{?(\w+)?}?$/);
  const endOffsetMessageVar = endBlock
    .getText()
    .slice(endOffset)
    .match(/^{?(\w+)?}?}/);

  if (startOffsetMessageVar) {
    selection = selection.set(
      isBackward ? 'focusOffset' : 'anchorOffset',
      startOffset - startOffsetMessageVar[0].length
    ) as SelectionState;
    isChanged = true;
  }
  if (endOffsetMessageVar) {
    selection = selection.set(
      isBackward ? 'anchorOffset' : 'focusOffset',
      endOffset + endOffsetMessageVar[0].length
    ) as SelectionState;
    isChanged = true;
  }
  return isChanged ? EditorState.acceptSelection(editorState, selection) : editorState;
};

export function insertLink(editorState: EditorState, text: string, url: string): EditorState {
  const selection = editorState.getSelection();
  const contentState = Modifier.replaceText(editorState.getCurrentContent(), selection, text);
  const newSelection = new SelectionState({
    anchorKey: selection.getStartKey(),
    anchorOffset: selection.getStartOffset(),
    focusKey: selection.getStartKey(),
    focusOffset: selection.getStartOffset() + text.length,
  });
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url,
    readOnly: false,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const contentStateWithAppliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    newSelection,
    entityKey
  );
  const newEditorState = EditorState.push(
    editorState,
    contentStateWithAppliedEntity,
    'insert-characters'
  );

  return EditorState.forceSelection(
    newEditorState,
    newSelection.merge({
      anchorOffset: newSelection.getEndOffset(),
    }) as SelectionState
  );
}

export function getEntitySelection(
  editorState: EditorState,
  entityKey: string
): SelectionState | null {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const selectedBlock = content.getBlockForKey(selection.getStartKey());

  let entitySelection = null;

  selectedBlock.findEntityRanges(
    (character) => character.getEntity() === entityKey,
    (start, end) => {
      entitySelection = selection.merge({
        anchorOffset: start,
        focusOffset: end,
        isBackward: false,
      });
    }
  );

  return entitySelection;
}

export function removeLink(editorState: EditorState, entityKey: string): EditorState {
  const selection = editorState.getSelection();
  const entitySelection = getEntitySelection(editorState, entityKey);

  if (!entitySelection) {
    return editorState;
  }

  const contentState = Modifier.applyEntity(editorState.getCurrentContent(), entitySelection, null);
  const newEditorState = EditorState.push(editorState, contentState, 'apply-entity');

  return EditorState.forceSelection(
    newEditorState,
    selection.isCollapsed()
      ? selection
      : (selection.merge({
          anchorOffset: entitySelection.getEndOffset(),
          focusOffset: entitySelection.getEndOffset(),
        }) as SelectionState)
  );
}

export function getSelectedEntityKey(editorState: EditorState, entityType?: string): string | null {
  const selection = editorState.getSelection();

  if (selection.getStartKey() !== selection.getEndKey()) {
    return null;
  }
  const content = editorState.getCurrentContent();
  const selectedBlock = content.getBlockForKey(selection.getStartKey());
  const entityAtStart = selectedBlock.getEntityAt(selection.getStartOffset());
  const entityAtEnd =
    selectedBlock.getEntityAt(selection.getEndOffset()) ||
    (selection.getEndOffset() > 0 && selectedBlock.getEntityAt(selection.getEndOffset() - 1)) ||
    null;

  if (entityType) {
    const isStartEntityOfWrongType =
      entityAtStart && content.getEntity(entityAtStart).getType() !== entityType;
    const isEndEntityOfWrongType =
      entityAtEnd && content.getEntity(entityAtEnd).getType() !== entityType;

    if (isStartEntityOfWrongType || isEndEntityOfWrongType) {
      return null;
    }
  }

  const isEntitySelected =
    (selection.isCollapsed() && entityAtEnd) ||
    (!selection.isCollapsed() && entityAtStart && entityAtEnd && entityAtStart === entityAtEnd);

  return isEntitySelected ? entityAtEnd : null;
}

export function getTextFromSelection(editorState: EditorState): string {
  const selected = getFragmentFromSelection(editorState);

  return selected ? selected.map((block: ContentBlock) => block.getText()).join('') : '';
}

export function modifyImages(editorState: EditorState): EditorState {
  let contentState = editorState.getCurrentContent();
  let isChanged = false;
  const blockMap = contentState.getBlockMap();
  const imageIds: Array<number> = [];

  blockMap.forEach((block, key) => {
    (block as ContentBlock).findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
      },
      (start, end) => {
        const entityKey = (block as ContentBlock).getEntityAt(start);
        const entity = contentState.getEntity(entityKey);
        const selection = new SelectionState({
          anchorKey: key,
          anchorOffset: start,
          focusKey: key,
          focusOffset: end,
        });
        const data = entity.getData();
        const { alt, src, internalId } = data;
        const isEmoji = alt && alt.match(emojiRegex);
        const isAHImage = internalId || (src && src.includes(window.location.origin));

        if (isEmoji || !isAHImage) {
          // Replace emoji images with unicode and remove other images
          contentState = Modifier.replaceText(
            Modifier.applyEntity(contentState, selection, null),
            selection,
            isEmoji ? alt : ' '.repeat(end - start)
          ).set('selectionBefore', contentState.getSelectionBefore()) as ContentState;
          isChanged = true;
        } else if (isAHImage) {
          // Fix duplicated images on copy-paste
          if (imageIds.includes(internalId)) {
            const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
              ...data,
              internalId: generateId(),
            });
            const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();

            contentState = Modifier.replaceText(
              contentStateWithEntity,
              selection,
              ' '.repeat(end - start),
              undefined,
              newEntityKey
            ).set('selectionBefore', contentState.getSelectionBefore()) as ContentState;
            isChanged = true;
          } else {
            imageIds.push(internalId);
          }
        }
      }
    );
  });

  return isChanged ? EditorState.set(editorState, { currentContent: contentState }) : editorState;
}

export function transformSignature(signature: string): string {
  return `<div></div><div></div><span data-editor="signature">${signature}</span>`;
}

export function removeSignature(body: string): string {
  return body.replace(/(<br\/>)*<div><span data-editor="signature">.*<\/span><\/div>$/, '');
}

export function parseVariables(
  editorState: EditorState,
  usedVariables: Array<string>
): EditorState {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const newBlocksState = blocks.reduce((editorState, block) => {
    const text = block.get('text');
    const key = block.get('key');
    const contentState = editorState.getCurrentContent();
    let newEditorState = editorState;

    text.replace(/{{.*?}}/g, (match: string, offset: number) => {
      if (block.getEntityAt(offset) || !usedVariables.includes(match)) return '';
      const newSelection = new SelectionState({
        anchorKey: key,
        anchorOffset: offset,
        focusKey: key,
        focusOffset: offset + match.length,
      });
      const contentStateWithEntity = contentState.createEntity('VAR', 'IMMUTABLE');
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithAppliedEntity = Modifier.applyEntity(
        contentStateWithEntity,
        newSelection,
        entityKey
      );

      newEditorState = EditorState.push(
        newEditorState,
        contentStateWithAppliedEntity,
        'insert-characters'
      );

      newEditorState = EditorState.forceSelection(
        newEditorState,
        newSelection.merge({
          anchorOffset: offset + match.length,
        }) as SelectionState
      );

      return '';
    });

    return newEditorState;
  }, editorState);

  return newBlocksState;
}

export function addImage(
  editorState: EditorState,
  data: Partial<AH$MessageAttachment>
): EditorState {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', data);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getCurrentContent().getSelectionAfter()
  );
}

export function getAllImages(editorState: EditorState): Array<Partial<AH$MessageAttachment>> {
  const contentState = editorState.getCurrentContent();
  const attachments: Array<Partial<AH$MessageAttachment>> = [];

  contentState.getBlockMap().forEach((block) => {
    if (block) {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          if (entityKey !== null) {
            const entity = contentState.getEntity(entityKey);
            const type = entity.getType();
            const data = entity.getData();

            if (type === 'IMAGE' && data.src && data.id && !find(attachments, ['id', data.id])) {
              attachments.push(data);
            }
          }

          return false;
        },
        () => {}
      );
    }
  });

  return attachments;
}

export function updateImages(
  editorState: EditorState,
  attachments: Array<Partial<AH$MessageAttachment>>
): { additionalAttachments: Array<Partial<AH$MessageAttachment>>; editorState: EditorState } {
  const contentState = editorState.getCurrentContent();
  const additionalAttachments: Array<Partial<AH$MessageAttachment>> = [];

  let newContentState;

  contentState.getBlockMap().forEach((block) => {
    if (block) {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          if (entityKey !== null) {
            const entity = contentState.getEntity(entityKey);
            const type = entity.getType();
            const data = entity.getData();

            if (type === 'IMAGE' && data.src && data.id) {
              const attachment = find(attachments, ['id', data.id]);

              if (attachment) {
                newContentState = contentState.mergeEntityData(entityKey, {
                  ...attachment,
                  isInlineImage: true,
                });
              } else {
                const additionalData = {
                  isInlineImage: true,
                  internalId: generateId(),
                };

                newContentState = contentState.mergeEntityData(entityKey, additionalData);
                additionalAttachments.push({
                  ...data,
                  ...additionalData,
                });
              }
            }
          }

          return false;
        },
        () => {}
      );
    }
  });

  return {
    editorState: newContentState
      ? EditorState.set(editorState, { currentContent: newContentState })
      : editorState,
    additionalAttachments,
  };
}

export function getSelectedBlocks(
  contentState: ContentState,
  anchorKey: string,
  focusKey: string
): Array<ContentBlock> {
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = anchorKey;

    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey) as ContentBlock;

      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }

  return selectedBlocks;
}

export function alignWithImages(editorState: EditorState, type: string): EditorState {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const selectedBlocks = getSelectedBlocks(contentState, startKey, endKey);
  const align = (type.split('align')[1] || 'left').toLowerCase();

  let newContentState = contentState;

  selectedBlocks.forEach((block) => {
    if (block) {
      if (block.getType() === 'atomic') {
        block.findEntityRanges(
          (character) => {
            const entityKey = character.getEntity();

            if (entityKey !== null) {
              const entity = contentState.getEntity(entityKey);
              const type = entity.getType();
              const data = entity.getData();

              if (type === 'IMAGE' && data.src && data.id) {
                newContentState = newContentState.mergeEntityData(entityKey, { align });
              }
            }

            return false;
          },
          () => {}
        );
      } else {
        const currentSelection = new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: 0,
          focusKey: block.getKey(),
          focusOffset: block.getLength() - 1,
        });

        newContentState = Modifier.setBlockType(newContentState, currentSelection, type);
      }
    }
  });

  return EditorState.forceSelection(
    EditorState.push(editorState, newContentState, 'change-block-type'),
    selection
  );
}

export function mergeEntityData(
  editorState: EditorState,
  entityKey: string,
  data: Record<string, any>
): EditorState {
  return EditorState.set(editorState, {
    currentContent: editorState.getCurrentContent().mergeEntityData(entityKey, data),
  });
}

export function deleteImage(editorState: EditorState, internalId: number): EditorState {
  const contentState = editorState.getCurrentContent();
  let selection: SelectionState | undefined;

  contentState.getBlockMap().forEach((block) => {
    if (block && block.getType() === 'atomic') {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          if (entityKey !== null) {
            const entity = contentState.getEntity(entityKey);
            const type = entity.getType();
            const data = entity.getData();

            if (type === 'IMAGE' && data.internalId === internalId) {
              return true;
            }
          }

          return false;
        },
        (start, end) => {
          const blockKey = block.getKey();

          selection = new SelectionState({
            anchorKey: blockKey,
            anchorOffset: start,
            focusKey: blockKey,
            focusOffset: end,
          });
        }
      );
    }
  });

  return selection
    ? EditorState.push(
        editorState,
        Modifier.removeRange(contentState, selection, 'forward'),
        'remove-range'
      )
    : editorState;
}

interface GetTemplateTextDataParam {
  body: EditorState;
  subject: EditorState;
}

interface GetTemplateTextDataReturn {
  bodyHTML: string;
  bodyText: string;
  nameText: string;
  subjectText?: string;
}

export const getTemplateTextData = ({
  subject,
  body,
}: GetTemplateTextDataParam): GetTemplateTextDataReturn => {
  const subjectText = subject.getCurrentContent().getPlainText();
  const bodyText = body.getCurrentContent().getPlainText();
  const bodyHTML = removeSignature(getHTMLOrText(body));

  return {
    subjectText,
    nameText: subjectText,
    bodyText,
    bodyHTML,
  };
};

export const insertVariable = (editorState: EditorState, text: string): EditorState => {
  const selection = editorState.getSelection();
  const contentState = Modifier.replaceText(editorState.getCurrentContent(), selection, text);
  const newSelection = new SelectionState({
    anchorKey: selection.getStartKey(),
    anchorOffset: selection.getStartOffset(),
    focusKey: selection.getStartKey(),
    focusOffset: selection.getStartOffset() + text.length,
  });
  const contentStateWithEntity = contentState.createEntity('VAR', 'IMMUTABLE');
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const contentStateWithAppliedEntity = Modifier.applyEntity(
    contentStateWithEntity,
    newSelection,
    entityKey
  );
  const newEditorState = EditorState.push(
    editorState,
    contentStateWithAppliedEntity,
    'insert-characters'
  );
  const editorStateWithNewSelection = EditorState.forceSelection(
    newEditorState,
    newSelection.merge({
      anchorOffset: newSelection.getEndOffset(),
    }) as SelectionState
  );

  return editorStateWithNewSelection;
};
