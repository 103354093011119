import { Scrollbars } from '@amazing-hiring/react-custom-scrollbars';
import { Component } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, map } from 'lodash';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import messages from './messages';
import styles from './Synonyms.scss';

const theme = thematize(styles);

interface AngleProps {
  opened?: boolean;
}

const Angle = ({ opened = false }: AngleProps) => (
  <>
    <span key="down" className={theme('angle', { hidden: !opened })}>
      <Icon type="angleDown" />
    </span>
    <span key="right" className={theme('angle', { hidden: opened })}>
      <Icon type="angleRight" />
    </span>
  </>
);

type SynonymProps = {
  className: string;
  name: string;
  variants: Array<string>;
};

type SynonymState = {
  opened: boolean;
};

class Synonym extends Component<SynonymProps, SynonymState> {
  static defaultProps = {
    variants: [],
  };

  state = {
    opened: false,
  };

  handleClick = (): void => {
    if (!this.state.opened) {
      tracking.openSubSynonyms(
        tracking.LOCATIONS[window.location.pathname === '/' ? 'home' : 'search']
      );
    }

    this.setState(({ opened }) => ({
      opened: !opened,
    }));
  };

  render(): React.ReactElement {
    const { name, variants, className } = this.props;
    const { opened } = this.state;

    return (
      <div className={className}>
        {variants.length > 0 ? (
          <div>
            <div onClick={this.handleClick} className={theme('synonym_with_variants')}>
              <Angle opened={opened} />
              {name}
            </div>
            {variants.map((variant, i) => (
              <div
                // eslint-disable-next-line
                key={`variant-${i}`}
                className={`${theme('item')} ${theme('variant', { hidden: !opened })}`}
              >
                {variant}
              </div>
            ))}
          </div>
        ) : (
          <div>{name}</div>
        )}
      </div>
    );
  }
}

type Props = {
  synonyms?: Array<AH$Synonym>;
  variants?: Array<string>;
};

const Synonyms = ({ synonyms = [], variants = [] }: Props): React.ReactElement => (
  <div
    onMouseDown={(e: React.MouseEvent<HTMLDivElement>): void => {
      // // не допускает изменения позиции каретки
      e.preventDefault();
    }}
    onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
      // // не допускает закрытия списка
      e.stopPropagation();
    }}
  >
    <Scrollbars autoHeight autoHeightMax={200} style={{ width: 200 }}>
      {!isEmpty(synonyms) && [
        <div key="header" className={theme('header')}>
          <FormattedMessage {...messages.synonyms} />
        </div>,
        <div key="body" className={theme('body')}>
          {map(synonyms, ({ name, variants }) => (
            <Synonym key={name} name={name} variants={variants} className={theme('item')} />
          ))}
        </div>,
      ]}
      {!isEmpty(variants) && [
        <div key="header" className={theme('header')}>
          <FormattedMessage {...messages.variants} />
        </div>,
        <div key="body" className={theme('body')}>
          {map(variants, (name) => (
            <Synonym key={name} name={name} className={theme('item')} />
          ))}
        </div>,
      ]}
    </Scrollbars>
  </div>
);

export default Synonyms;
