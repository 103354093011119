import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import styles from './Layout.scss';

const theme = thematize(styles);

interface Props {
  children: ReactElement;
  className: string;
  flexibleTop: boolean;
  header: ReactElement;
  leftward: boolean;
  side: ReactElement | null;
  glassHeader?: boolean;
}

const Layout = ({
  children,
  header,
  side,
  className,
  leftward,
  flexibleTop,
}: Props): ReactElement => (
  <div className={theme('container')}>
    <div className={theme('top', { full: !side })}>
      <div className={theme('header', { leftward, flexible: flexibleTop })}>{header}</div>
    </div>
    {side && <div className={theme('side')}>{side}</div>}
    <div className={`${theme('content')} ${className}`}>{children}</div>
  </div>
);

Layout.defaultProps = {
  className: '',
  leftward: false,
  flexibleTop: false,
  header: null,
  side: null,
};

export default Layout;
