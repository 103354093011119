import { MutableRefObject, useCallback, useEffect } from 'react';
import { every } from 'lodash';

type Ref = MutableRefObject<any>;
const checkIfTargetContainsRef = (e: MouseEvent, ref: Ref): boolean | null | undefined =>
  !ref || !ref.current || (e.target instanceof Node && !ref.current.contains(e.target));

const useFocusLoss = (ref: Ref | Array<Ref>, callback: () => void): void => {
  const handleDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (Array.isArray(ref)) {
        if (every(ref, (currentRef) => checkIfTargetContainsRef(e, currentRef))) {
          callback();
        }
      } else if (checkIfTargetContainsRef(e, ref)) {
        callback();
      }
    },
    [ref, callback]
  );

  const handleEscapeKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (['Escape', 'Tab'].includes(e.key)) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    document.body.addEventListener('click', handleDocumentClick);
    document.body.addEventListener('keydown', handleEscapeKeydown);

    return (): void => {
      document.body.removeEventListener('click', handleDocumentClick);
      document.body.removeEventListener('keydown', handleEscapeKeydown);
    };
  }, [handleDocumentClick, handleEscapeKeydown]);
};

export default useFocusLoss;
