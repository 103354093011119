import { FC } from 'react';

import { AH$MessageEditor } from 'types';
import { MESSAGE_EDITOR_TYPES } from 'tracking';
import thematize from 'lib/thematize';
import Button from 'components/Base/Button';
import Dialog from 'components/Base/Dialog';
import Icon from 'components/Base/Icon';
import Text, { Is } from 'components/Base/Text';
import Editor from 'components/Messaging/Message/Editor';
import styles from './DefaultFullNotification.scss';

const theme = thematize(styles);

type DefaultNotificationProps = {
  editor: AH$MessageEditor;
  handleButtonClick: () => void;
  handleClose: () => void;
  notification: AH$SystemNotification;
};

const DefaultFullNotification: FC<DefaultNotificationProps> = ({
  notification,
  handleButtonClick,
  editor,
  handleClose,
}) => (
  <Dialog className={theme('container')}>
    <div className={theme('close-btn')} onClick={handleClose}>
      <Icon type="cross" />
    </div>
    <span className={theme('logo')} />
    <Text is={Is.Regular} className={theme('title')}>
      {notification.title}
    </Text>
    <Editor
      {...editor}
      readOnly
      mod="notification"
      mailingVariables={[]}
      className={theme('text')}
      type={MESSAGE_EDITOR_TYPES.notification}
    />
    <Button color="primary" className={theme('button')} size="l" onClick={handleButtonClick}>
      {notification.buttonText || 'OK'}
    </Button>
  </Dialog>
);

export default DefaultFullNotification;
