import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import thematize from 'lib/thematize';
import Button from 'components/Base/Button';
import Text from 'components/Base/Text';
import Logo from 'components/Logo';
import messages from './messages';
import styles from './styles.scss';

const theme = thematize(styles);

const Error = ({ trace = '' }: { trace?: string | null }) => {
  const [showError, setShowError] = useState(false);

  return (
    <div className={theme('wrapper')}>
      <div className={theme('container')}>
        <Logo logoClassName={theme('logo')} isLink={false} />
        <div className={theme('text-container')}>
          <Text
            className={theme('title')}
            handleClick={trace ? () => setShowError(true) : undefined}
          >
            <FormattedMessage {...messages.title} />
          </Text>
          {!!trace && <pre className={theme('error-trace', { show: showError })}>{trace}</pre>}
          <a className={theme('button')} href="/">
            <Button appearance="filled" color="primary">
              <FormattedMessage {...messages.main} />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
