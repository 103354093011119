import queryString from 'query-string';
import url from 'url';

import { AH$RouterQuery } from 'types';
import { pickBy } from './object';

/**
 * Add GET parameter to URL
 * @param {string} initialUrl Initial address
 * @param {Object} [params] Target parameters
 * @returns {string} Result link
 */
export function addGetParams(
  initialUrl: string,
  params: { [key: string]: any } | null = {}
): string {
  const parsedUrl = url.parse(initialUrl, true);

  parsedUrl.query = { ...parsedUrl.query, ...pickBy(params || {}) };
  parsedUrl.search = '';

  return url.format(parsedUrl);
}

export function createLocation(
  location: Partial<Location> | string = window.location
): Location & { profilesQuery: AH$RouterQuery; query: AH$RouterQuery } {
  return {
    ...(location as Location),
    query: queryString.parse(location.search),
    profilesQuery: queryString.parse(location.search as string),
  };
}

type ParsedUrl = url.UrlWithParsedQuery & { keyword: string; primaryDomain: string };

export const parseURL = (string: string): ParsedUrl => {
  const parsed = url.parse(string, true);
  const hostname = parsed.hostname || parsed.href;
  const host = parsed.host || parsed.href;

  let primaryDomain = '';

  if (hostname) {
    const splitted = hostname.split('.');

    if (splitted.length > 1) {
      primaryDomain = `${splitted[splitted.length - 2]}.${splitted[splitted.length - 1]}`;
    }
  }

  return {
    ...parsed,
    host,
    hostname,
    primaryDomain,
    keyword: primaryDomain ? primaryDomain.split('.')[0] : '',
  };
};

export const getDataFromFragmentUrl = (parsedUrl: ParsedUrl): Array<string> =>
  parsedUrl.protocol === 'ah:' && parsedUrl.pathname ? parsedUrl.pathname.split('/') : [];

export const isFullProfile = (): boolean => /profiles\/\d+\//.test(window.location.pathname);
