/**
 * The Java backend shows suggestions for tags if they came in the text field.
 * That's why we need to extract tags from skills and put it to the text.
 * @param {Object.<string, string[]>} termsByType
 * @returns {Object.<string, string[]>}
 */
export function noTagToSkillSuggestType(termsByType: AH$Type2term): AH$Type2term {
  const type2terms2: AH$Type2term = {};

  Object.entries(termsByType).forEach(([suggestType, terms]) => {
    const noTags = terms.filter(({ text: term }: { text: string }) => !term.startsWith('#'));

    if (suggestType === 'text' && noTags.length > 0) {
      const restTerms = terms.filter(({ text: term }: { text: string }) => term.startsWith('#'));

      type2terms2['skill'] = noTags;
      type2terms2[suggestType] = restTerms;
    } else {
      type2terms2[suggestType as keyof AH$Type2term] = terms;
    }
  });

  return type2terms2;
}
