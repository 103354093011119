import { createContext, useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { noop } from 'lodash';

import { getFile } from 'lib/apiFilesV1';
import thematize from 'lib/thematize';
import { generateId } from 'lib/utils';
import { getErrorHandler } from 'slices/app';
import styles from './Image.scss';

const theme = thematize(styles);
const MIN_IMAGE_WIDTH = 25;

export const ImageContext = createContext<{
  editorWidth: number;
  focusedImageId: number | null;
  onFocus: (id: number | null) => void;
  onPropertiesChange: (entityKey: string, props: Record<string, any>) => void;
}>({
  focusedImageId: null,
  editorWidth: 0,
  onFocus: noop,
  onPropertiesChange: noop,
});

const corners = ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'] as const;

interface Props {
  entityKey: string;
  id: number;
  internalId: number;
  name: string;
  size: number;
  src: string;
  align?: 'left' | 'center' | 'right';
  height?: number;
  width?: number;
}

const Image = ({
  src,
  name,
  id,
  size,
  internalId,
  entityKey,
  width: initWidth,
  height: initHeight,
  align = 'left',
}: Props): React.ReactElement => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { focusedImageId, editorWidth, onFocus, onPropertiesChange } = useContext(ImageContext);
  const [width, setWidth] = useState<number | null>(initWidth || null);
  const [height, setHeight] = useState<number | null>(initHeight || null);
  const isFocused = focusedImageId === internalId;
  const imgStyle =
    width && height && (width <= editorWidth || !editorWidth)
      ? { width: `${width}px`, height: `${height}px` }
      : undefined;

  const handleImgLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    if (!initWidth) {
      const { width, height } = e.target.getBoundingClientRect();

      onPropertiesChange(entityKey, { width: Math.floor(width), height: Math.floor(height) });
    } else if (editorWidth && initWidth > editorWidth && initHeight) {
      const newHeight = Math.floor(editorWidth * (initHeight / initWidth));

      onPropertiesChange(entityKey, {
        width: editorWidth,
        height: Math.floor(editorWidth * (initHeight / initWidth)),
      });
      setWidth(editorWidth);
      setHeight(newHeight);
    }
  };

  const handleMouseDown =
    (position: (typeof corners)[number]) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
      if (e.button !== 0) return;

      const isLeft = position.includes('Left');
      const isTop = position.includes('top');
      const startX = e.clientX;
      const startY = e.clientY;
      const { width = 0, height = 0 } = imgRef?.current?.getBoundingClientRect() || {};
      const heightWidthRatio = height / width;
      let newWidth = width;
      let newHeight = height;

      const doDrag = (dragEvent: MouseEvent) => {
        const deltaX = isLeft ? startX - dragEvent.clientX : dragEvent.clientX - startX;
        const deltaY = isTop ? startY - dragEvent.clientY : dragEvent.clientY - startY;
        const isYBigger = Math.abs(deltaY) > Math.abs(deltaX);
        const delta = isYBigger ? deltaY : deltaX;
        const isImgSmaller = delta < 0;
        const calculatedWidth = Math.floor(width + delta);

        newWidth = isImgSmaller
          ? Math.max(MIN_IMAGE_WIDTH, calculatedWidth)
          : Math.min(editorWidth, calculatedWidth);
        newHeight = Math.floor(newWidth * heightWidthRatio);
        setWidth(newWidth);
        setHeight(newHeight);
      };

      const stopDrag = (e: MouseEvent): void => {
        e.stopPropagation();
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
        document.body.className = document.body.className.replace(' resizing', '');

        onPropertiesChange(entityKey, { width: newWidth, height: newHeight });
      };

      e.stopPropagation();
      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
      document.body.className += ' resizing';
    };

  useEffect(() => {
    if (!internalId && src) {
      const fileIdMatch = src.match(/api\/files\/v1\/(\d*)\//);

      if (fileIdMatch) {
        getFile(parseInt(fileIdMatch[1], 10))
          .then(({ payload: { id, created_at, created_by, filename, size } }) => {
            onPropertiesChange(entityKey, {
              id,
              size,
              createdAt: created_at,
              createdBy: created_by,
              name: filename,
              internalId: generateId(),
              isInlineImage: true,
              src: `/api/files/v1/${id}/download/`,
            });
          })
          .catch(getErrorHandler());
      }
    }
  }, []);

  return (
    <div style={{ textAlign: align }}>
      <div
        className={theme('container')}
        onClick={(e) => {
          e.stopPropagation();
          onFocus(internalId);
        }}
      >
        <img
          src={src}
          data-file-size={size && Math.floor(size / 1024)}
          alt={name}
          data-file-id={id}
          className={theme('image', { focused: isFocused })}
          draggable={false}
          style={imgStyle}
          ref={imgRef}
          onLoad={handleImgLoad}
        />
        {isFocused &&
          corners.map((position) => (
            <span
              key={position}
              className={theme('corner', { position })}
              onMouseDown={handleMouseDown(position)}
            />
          ))}
      </div>
    </div>
  );
};

export default Image;
