import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { noop, uniqBy } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise, tryAbortPromise } from 'lib/abortablePromise';
import * as apiV6 from 'lib/apiV6';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'telegramDialogs';

let fetchDialogs: null | AbortablePromise<{
  payload: { count: number; next: string; previous: string; results: AH$TelegramDialog[] };
}> = null;

export type TelegramDialogsState = {
  dialogs: {
    count: number;
    next: string | null;
    offset: number;
    previous: string | null;
    results: AH$TelegramDialog[];
  };
  fetchingStatus: $Values<typeof FETCHING_STATUSES>;
  sortByRecruiter: string;
  sortBySimCard: string;
};

export const telegramDialogsInitialState: TelegramDialogsState = {
  dialogs: { count: 0, next: '', previous: '', results: [], offset: 0 },
  fetchingStatus: 'SUCCESS',
  sortByRecruiter: '',
  sortBySimCard: '',
};

export const getAllDialogs = createAsyncThunk(
  `${SLICE_NAME}/getAllDialogs`,
  async (
    {
      created_by_id,
      limit = 20,
      offset = 0,
      telegram_account_id,
      onSuccess = noop,
      onError = noop,
    }: {
      created_by_id?: number;
      limit?: number;
      offset?: number;
      onError?: (error: string) => void;
      onSuccess?: () => void;
      telegram_account_id?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      if (fetchDialogs && fetchDialogs.abort) {
        fetchDialogs.abort();
      }

      fetchDialogs = abortable(
        apiV6.getTelegramDialogs({
          created_by_id,
          limit,
          offset,
          telegram_account_id,
        })
      );

      const { payload } = await fetchDialogs;

      onSuccess();

      return payload;
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          parseError: true,
          silent: true,
          getError: ({ error }: { error: string }) => {
            onError(error);
          },
        })(e);
        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

const telegramDialogsSlice = createSlice({
  name: SLICE_NAME,
  initialState: telegramDialogsInitialState,
  reducers: {
    resetDialogs: (state) => {
      state.dialogs = telegramDialogsInitialState.dialogs;
      state.sortByRecruiter = '';
      state.sortBySimCard = '';
      state.fetchingStatus = FETCHING_STATUSES.SUCCESS;
      tryAbortPromise(fetchDialogs);
    },
    setOffsetDialogs: (state, action: PayloadAction<number>) => {
      state.dialogs.offset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDialogs.pending, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getAllDialogs.fulfilled, (state, action) => {
      if (action.payload) {
        state.fetchingStatus = FETCHING_STATUSES.SUCCESS;
        state.dialogs.next = action.payload.next;
        state.dialogs.previous = action.payload.previous;
        state.dialogs.count = action.payload?.count;
        state.dialogs.results = uniqBy([...state.dialogs.results, ...action.payload.results], 'id');
      }
    });
    builder.addCase(getAllDialogs.rejected, (state) => {
      state.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
  },
});

export const { resetDialogs, setOffsetDialogs } = telegramDialogsSlice.actions;
export const telegramDialogs = telegramDialogsSlice.reducer;
