import { ATS } from 'slices/ats';
import { CATEGORIES } from './categories';
import { getOpenerLocation, sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const copyContact = (
  Profile_Id: number,
  Contact_Type: string,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1',
  UI_Initiator?: string
): void => {
  sendEvent('Copy Contact', {
    Location,
    Profile_Id,
    Contact_Type,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
    UI_Initiator,
  });
};

export const editContacts = (
  Profile_Id: number,
  Contact_Type: string,
  Location: LOCATIONS,
  Category: CATEGORIES
): void => {
  sendEvent('Edit Contacts', { Location, Profile_Id, Contact_Type, Category });
};

export const profileContactClick = (
  Profile_Id: number,
  Contact_Type: string,
  Location: LOCATIONS,
  Category: CATEGORIES,
  Is_Recommended_Profile?: boolean,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Click Profile Contact', {
    Profile_Id,
    Contact_Type,
    Location,
    Category,
    Is_Recommended_Profile,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const openSkillSource = (): void => {
  sendEvent('Open Skill Source', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const skillSourceClick = (): void => {
  sendEvent('Click Skill Source ', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const showAllSkills = (): void => {
  sendEvent('Show All Skills', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const showCompanyInfo = (): void => {
  sendEvent('Show Company Info', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const reportProfile = (Profile_Id: number): void => {
  sendEvent('Report Profile', {
    Location: LOCATIONS.fullProfile,
    Profile_Id,
    Category: CATEGORIES.fullProfile,
  });
};

export const openProfileAttachment = (): void => {
  sendEvent('Open Profile Attachment', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const removeProfileAttachment = (): void => {
  sendEvent('Remove Profile Attachment', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const enrichFromAttachment = (): void => {
  sendEvent('Enrich From Attachment', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const attachFileToProfile = (): void => {
  sendEvent('Attach File To Profile', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const addProfileToFolder = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Profiles_Count?: number,
  Is_Recommended_Profile?: boolean,
  Is_Smart_Folders_Button = false,
  Access_Type?: AH$Folder['accessType'],
  Is_MLTR_Profile?: boolean,
  Search_Form_Type?: 'v2' | 'v1'
): void => {
  sendEvent('Add Profile To Folder', {
    Location,
    Category,
    Profiles_Count,
    ph_count: Profiles_Count,
    Is_Recommended_Profile,
    Is_Smart_Folders_Button,
    Access_Type,
    Is_MLTR_Profile,
    Search_Form_Type,
  });
};

export const createSingleMessage = (): void => {
  sendEvent(
    'Create Single Message',
    {
      Location: LOCATIONS.fullProfile,
      Category: CATEGORIES.fullProfile,
    },
    ['amplitude']
  );
};

export const similarProfileClick = (): void => {
  sendEvent('Click Similar Profile', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const similarProfilesSearch = (): void => {
  sendEvent('Search Similar Profiles', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const connectATS = (
  Vendor: ATS,
  Location: LOCATIONS = LOCATIONS.fullProfile,
  Category: CATEGORIES = CATEGORIES.fullProfile
): void => {
  sendEvent(
    'Connect ATS',
    {
      Location,
      Category,
      Vendor,
    },
    ['amplitude']
  );
};

export const clickATSVacancyLink = (Vendor: ATS): void => {
  sendEvent('Click ATS Vacancy Link', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
    Vendor,
  });
};

export const clickATSCandidateLink = (Vendor: ATS): void => {
  sendEvent('Click ATS Candidate Link', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
    Vendor,
  });
};

export const possibleProfileClick = (): void => {
  sendEvent('Click Possible Profile', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const requestContactsMining = (): void => {
  sendEvent('Request Contacts Mining', {
    Location: LOCATIONS.fullProfile,
    Category: CATEGORIES.fullProfile,
  });
};

export const viewFullProfile = (Profile_Id: number, referer: string | null | undefined): void => {
  sendEvent('View Full Profile', {
    Location: LOCATIONS.fullProfile,
    Profile_Id,
    Category: CATEGORIES.fullProfile,
    Opener: getOpenerLocation(referer),
  });
};
