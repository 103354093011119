import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { noop, remove } from 'lodash';

import * as api from 'lib/apiV6';
import { getErrorHandler, handleError } from 'slices/app';
import { FetchingStatusesActionTypes } from './fetchingStatuses';
import { createStatusActions } from './utils';

const SLICE_NAME = 'bannedLinks';

export const receiveBannedLinks = createAsyncThunk(
  `${SLICE_NAME}/receiveBannedLinks`,
  async (_, { rejectWithValue }) => {
    try {
      const result = await api.bannedLinksGet();

      return result.payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const createBannedLink = createAsyncThunk(
  `${SLICE_NAME}/createBannedLink`,
  async (
    { urlForBan, onSuccess = noop }: { onSuccess: () => void; urlForBan: string },
    { rejectWithValue, dispatch }
  ) => {
    const { setError, setLoading, setSuccess } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.bannedLinkAdded
    );

    try {
      setLoading();

      const result = await api.bannedLinksPost(urlForBan);

      setSuccess();
      onSuccess();
      return result.payload;
    } catch (e) {
      setError();
      getErrorHandler({
        parseError: true,
        getError: (error) => {
          handleError({ content: error.url });
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const deleteBannedLink = createAsyncThunk(
  `${SLICE_NAME}/deleteBannedLink`,
  async (urlForDelete: string, { rejectWithValue, dispatch }) => {
    const { setError, setLoading, setSuccess } = createStatusActions(
      dispatch,
      FetchingStatusesActionTypes.bannedLinkDeleted
    );

    try {
      setLoading();

      await api.bannedLinksDelete(urlForDelete);
      setSuccess();

      return { url: urlForDelete };
    } catch (e) {
      setError();
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export type BannedLinksState = {
  links: Array<API$BannedLink>;
};

export const bannedLinksInitialState: BannedLinksState = {
  links: [],
};

const bannedLinksSlice = createSlice({
  name: SLICE_NAME,
  initialState: bannedLinksInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(receiveBannedLinks.fulfilled, (state, action) => {
        state.links = action.payload;
      })
      .addCase(createBannedLink.fulfilled, (state, action) => {
        state.links.unshift(action.payload);
      })
      .addCase(deleteBannedLink.fulfilled, (state, action) => {
        remove(state.links, action.payload);
      });
  },
});

export const bannedLinks = bannedLinksSlice.reducer;
