import { setDialogModal } from 'slices/app';
import useDispatch from './useDispatch';

interface Return {
  closeModal: () => void;
  setModal: (content: any) => void;
}

const useModal = (): Return => {
  const dispatch = useDispatch();

  const closeModal = (): void => {
    dispatch(setDialogModal({ show: false }));
  };

  const setModal = (content: any) => {
    dispatch(
      setDialogModal({
        show: true,
        content,
      })
    );
  };

  return { setModal, closeModal };
};

export default useModal;
