import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const clickSideMenu = (Section: string): void => {
  sendEvent('Click Side Menu', {
    Location: LOCATIONS.sideMenu,
    Category: CATEGORIES.sideMenu,
    Section,
  });
};
