import * as React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { get } from 'lodash';

import thematize from 'lib/thematize';
import { useSelector } from 'hooks';
import useDidMount from 'hooks/useDidMount';
import { clearSystemMessage } from 'slices/app';
import Icon from 'components/Icon';
import styles from './SystemMessages.css';

const theme = thematize(styles);

const defaultDelay = 3000;

const SystemMessages = (): React.ReactElement => {
  let timer: number;

  const didMount = useDidMount();
  const systemMessage = useSelector(({ systemMessage }) => systemMessage);
  const { formatMessage } = useIntl();
  const { data = {}, warning = false, error = false } = systemMessage;
  const content = get(data, 'content', null);
  const formatted = get(data, 'formatted', true);
  const formattedContent = formatted ? content : formatMessage(...(content as [MessageDescriptor]));
  const visible = content && get(data, 'isVisible', true);
  const closeByDelay = (): void => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      clearSystemMessage();
    }, get(systemMessage, 'data.delay', defaultDelay));
  };

  const handleCloseClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    clearSystemMessage();
  };

  React.useEffect(() => {
    if (didMount && get(data, 'isClosingByDelay', true) && visible) {
      closeByDelay();
    }
  }, [data, visible, didMount]);

  return (
    <div className={theme('container', { visible, warning, error })}>
      <div className={theme('close')} onClick={handleCloseClick}>
        <Icon kind="cross" size="medium" />
      </div>

      <div className={theme('message')}>{formattedContent}</div>
    </div>
  );
};

export default SystemMessages;
