import { Scrollbars } from '@amazing-hiring/react-custom-scrollbars';
import { ReactElement, ReactNode } from 'react';

import thematize from 'lib/thematize';
import ReduxStore from 'stores/ReduxStore';
import { hideModal } from 'slices/app';
import Icon from 'components/Icon';
import styles from './Modal.scss';

const theme = thematize(styles);

const Close = ({ onClose }: { onClose?: () => void }): ReactElement => (
  <div
    className={theme('close')}
    onClick={(): void => {
      ReduxStore.dispatch(hideModal());
      if (onClose) onClose();
    }}
  >
    <div className={theme('close__icon')}>
      <Icon kind="cross" size="medium" />
    </div>

    <div className={theme('close__tip')}>esc</div>
  </div>
);

interface Props {
  content: ReactElement;
  title: ReactNode;
  onClose?: () => void;
}

const ModalContainer = ({ title, content, onClose }: Props): ReactElement => (
  <div className={theme('container')}>
    <div className={theme('header')}>
      <Close onClose={onClose} />
      <div className={theme('heading')}>{title}</div>
    </div>

    <Scrollbars className={theme('scrollbars')}>
      <div className={theme('content')}>{content}</div>
    </Scrollbars>
  </div>
);

export default ModalContainer;
