import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import thematize from 'lib/thematize';
import Hr from 'components/Base/Hr';
import messages from './messages';
import Item, { Props as SideMenuItemProps } from './SideMenuDropdownItem';
import styles from './User.scss';

const theme = thematize(styles);

interface Props {
  company: string;
  isExt: boolean;
  links: Array<SideMenuItemProps>;
  name: string;
}

const User = ({ name, company, isExt = false, links }: Props): React.ReactElement => (
  <div className={theme('container')}>
    <div className={theme('user')}>
      <div className={theme('user-name')}>{name}</div>
      <div className={theme('user-company')}>
        {isExt ? <FormattedMessage {...messages.extensionUser} /> : company}
      </div>
    </div>
    <div className={theme('items')}>
      {links.map((child, i) =>
        child.isLine ? (
          // eslint-disable-next-line react/no-array-index-key
          <Hr className={theme('hr')} key={`line${i}`} />
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={`sidemenu-dropdown-item-${i}`} {...child} />
        )
      )}
    </div>
  </div>
);

export default User;
