import { FC, useCallback, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Dropdown from 'components/Base/Dropdown';
import Tooltip from 'components/CustomTooltip';
import AddVar from './AddVar';
import messages from './messages';
import styles from './VarControl.scss';

const theme = thematize(styles);

interface Props {
  containerHeight: number | null;
  focusEditor: () => void;
  mailingVariables: Array<AH$MailingVariable>;
  onChange: (val: string) => void;
  onDropdownClose: () => void;
  onDropdownOpen: () => void;
  mod?: 'editor' | 'subject' | 'signature';
  onAddVar?: (val: string) => void;
}

const VarControl: FC<Props> = ({
  mod = 'editor',
  mailingVariables,
  onChange,
  onDropdownOpen,
  onDropdownClose,
  onAddVar,
  focusEditor,
  containerHeight,
}) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();
  const [isAdding, setIsAdding] = useState(false);

  const preventDefault = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
  };

  const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsAdding(true);
  };

  const handleCancelAddClick = useCallback((e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setIsAdding(false);
    focusEditor();
  }, []);

  const handleAddVar = (name: string, e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    if (onAddVar) {
      onAddVar(name);
      setIsAdding(false);
    }
  };

  const handleChange = (name: string) => (): void => {
    onChange(`{{${name}}}`);

    if (open) {
      onDropdownClose();
      setOpen(false);
    } else {
      onDropdownOpen();
      setOpen(true);
    }
  };

  const handleFocusLoss = () => {
    onDropdownClose();
    setOpen(false);
    setIsAdding(false);
  };

  const handleButtonClick = () => {
    if (open) {
      onDropdownClose();
      setOpen(false);
      setIsAdding(false);
    } else {
      onDropdownOpen();
      setOpen(true);
    }
  };

  return (
    <Dropdown
      sticky
      closeOnAwayClick
      textAlignCenter
      onFocusLoss={handleFocusLoss}
      bodyClassName={theme('list', { mod })}
      bodyStyles={{ maxHeight: (containerHeight && containerHeight - 50) || undefined }}
      containerClassName={theme('container')}
      optionsOpened={open}
      button={
        <Tooltip id="variables-tooltip" message={formatMessage(messages.variables)}>
          <Button
            className={theme('button')}
            color={COLORS.default}
            appearance={APPEARANCES.text}
            onMouseDown={preventDefault}
            onClick={handleButtonClick}
          >
            <span className={theme('var')}>{'{{…}}'}</span>
          </Button>
        </Tooltip>
      }
    >
      {isAdding ? (
        <AddVar onCancel={handleCancelAddClick} onAdd={handleAddVar} />
      ) : (
        <>
          {mailingVariables.map(({ name }) => (
            <Dropdown.Option key={name} textValue={name} onOptionClick={handleChange(name)} />
          ))}
          {onAddVar && (
            <Dropdown.Option
              closeOnClick={false}
              optionClassName={theme('add-var')}
              onOptionClick={(_, e) => handleAddClick(e)}
            >
              <FormattedMessage {...messages.addVariable} />
            </Dropdown.Option>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default VarControl;
