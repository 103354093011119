import { MouseEvent, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorState, Modifier, RichUtils } from 'draft-js';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import { transformEditorSelection } from './lib';
import messages from './messages';
import styles from './FontControl.scss';

const theme = thematize(styles);

export const FONT_STYLES = [
  { label: 'small', style: 'SMALL' },
  { label: 'normal', style: 'NORMAL' },
  { label: 'large', style: 'LARGE' },
  { label: 'huge', style: 'HUGE' },
];

export const fontStyleMap = {
  SMALL: {
    fontSize: 'x-small',
  },
  LARGE: {
    fontSize: 'large',
  },
  HUGE: {
    fontSize: 'xx-large',
  },
};

interface Props {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  onDropdownClose: () => void;
  onDropdownOpen: () => void;
}

const FontControl = ({
  onChange,
  editorState,
  onDropdownOpen,
  onDropdownClose,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const currentStyle = editorState.getCurrentInlineStyle();

  const fontStyles = Object.keys(fontStyleMap);
  const { formatMessage } = useIntl();

  const isActive = (style: string): boolean => {
    if (style === 'NORMAL') {
      return !fontStyles.some((style) => currentStyle.has(style));
    }
    return currentStyle.has(style);
  };

  const toggleSize = (style: string): void => {
    let nextEditorState = transformEditorSelection(editorState);
    const selection = nextEditorState.getSelection();
    const prevSelection = editorState.getSelection();

    if (selection.isCollapsed() && !currentStyle.has(style)) {
      nextEditorState = fontStyles
        .filter((fontStyle) => currentStyle.has(fontStyle) || fontStyle === style)
        .reduce((state, style) => RichUtils.toggleInlineStyle(state, style), nextEditorState);
    } else {
      const nextContentState = fontStyles.reduce(
        (contentState, fontStyle) =>
          fontStyle === style
            ? Modifier.applyInlineStyle(contentState, selection, fontStyle)
            : Modifier.removeInlineStyle(contentState, selection, fontStyle),
        nextEditorState.getCurrentContent()
      );
      const formattedEditorState = EditorState.push(
        nextEditorState,
        nextContentState,
        'change-inline-style'
      );

      nextEditorState = prevSelection.isCollapsed()
        ? EditorState.acceptSelection(formattedEditorState, prevSelection)
        : formattedEditorState;
    }

    onChange(nextEditorState);
  };

  const handleToggleDropdown = () => {
    if (open) {
      setOpen(false);
      onDropdownClose();
    } else {
      setOpen(true);
      onDropdownOpen();
    }
  };

  const handleOptionMouseDown = (style: string) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    toggleSize(style);
    onDropdownClose();
    setOpen(false);
  };

  const handleFocusLoss = () => {
    setOpen(false);
    onDropdownClose();
  };

  return (
    <div className={theme('container')} {...dataQa('font-control')}>
      <Dropdown
        sticky
        onFocusLoss={handleFocusLoss}
        bodyClassName={theme('list')}
        optionsOpened={open}
        button={
          <Tooltip id="size-tooltip" message={formatMessage(messages.size)}>
            <Button
              className={theme('button')}
              color={COLORS.default}
              appearance={APPEARANCES.text}
              onClick={handleToggleDropdown}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              <Icon type="heading" />
            </Button>
          </Tooltip>
        }
      >
        {FONT_STYLES.map(({ label, style }) => (
          <Dropdown.Option
            key={style}
            optionClassName={theme('label', { size: label })}
            onMouseDown={handleOptionMouseDown(style)}
          >
            <span className={theme('check')}>
              {isActive(style) && (
                <span>
                  <Icon type="check" />
                </span>
              )}
            </span>
            <span className={theme('option', { size: label })}>
              <FormattedMessage {...messages[`${label}Font` as keyof typeof messages]} />
            </span>
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default FontControl;
