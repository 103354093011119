import { Fragment, ReactElement } from 'react';

import { useSelector } from 'hooks';

const Modals = (): ReactElement => {
  const { modals } = useSelector((state) => state.messaging);
  const modalsArr = Object.values(modals);

  return (
    <>
      {modalsArr.map(({ modal, id }) => (
        <Fragment key={id}>{modal}</Fragment>
      ))}
    </>
  );
};

export default Modals;
