import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContentState, EditorState, SelectionState } from 'draft-js';
import { isEmpty } from 'lodash';

import thematize from 'lib/thematize';
import { usePrevious } from 'hooks';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS, SIZES } from 'components/Base/constants';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import Input from 'components/Base/Input';
import Tooltip from 'components/CustomTooltip';
import {
  getEntitySelection,
  getSelectedEntityKey,
  getTextFromSelection,
  insertLink,
  removeLink,
} from './lib';
import messages from './messages';
import styles from './InsertLink.scss';

const theme = thematize(styles);

interface LinkProps {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
}

export const Link = ({ children, contentState, entityKey }: LinkProps): React.ReactElement => {
  const { url, readOnly } = contentState.getEntity(entityKey).getData();

  return readOnly ? (
    <a className={theme('link')} href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <span className={theme('link')}>{children}</span>
  );
};

interface FormProps {
  onCancel: () => void;
  onSubmit: () => void;
  onUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onVisibleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitMessage: React.ReactNode;
  url: string;
  visibleText: string;
}

const Form = ({
  visibleText,
  url,
  onVisibleTextChange,
  onUrlChange,
  onSubmit,
  onCancel,
  submitMessage,
}: FormProps): React.ReactElement => {
  const isNameEntered = visibleText.length > 0;
  const [isInputVisible, setInputVisible] = React.useState(false);

  React.useEffect(() => {
    // Необходимо, чтобы не слетал фокус с редактора в случае,
    // когда форма отрисовалась, а дропдаун закрылся.
    setInputVisible(true);
  }, []);

  return (
    <>
      <div className={theme('field')}>
        <span className={theme('field-name')}>
          <FormattedMessage {...messages.visibleText} />
        </span>
        {isInputVisible && (
          <div className={theme('input-container')}>
            <Input
              id="visible-text"
              autoFocus={!isNameEntered}
              appearance="lined"
              type="text"
              value={visibleText}
              className={theme('link-input')}
              onChange={onVisibleTextChange}
            />
          </div>
        )}
      </div>
      <div className={theme('field')}>
        <span className={theme('field-name')}>
          <FormattedMessage {...messages.link} />
        </span>
        <div className={theme('input-container')}>
          {isInputVisible && (
            <Input
              id="link"
              type="text"
              value={url}
              autoFocus={isNameEntered}
              onChange={onUrlChange}
              appearance="lined"
              className={theme('link-input')}
            />
          )}
        </div>
      </div>
      <div className={theme('actions')}>
        <Button
          color={COLORS.primary}
          appearance={APPEARANCES.outlined}
          size={SIZES.s}
          onClick={onCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          color={COLORS.primary}
          size={SIZES.s}
          disabled={isEmpty(visibleText) || isEmpty(url)}
          onClick={onSubmit}
        >
          {submitMessage}
        </Button>
      </div>
    </>
  );
};

interface LinkDataProps {
  formatMessage: (message: AH$FormattedMessage) => string;
  onDeleteClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onEditClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  url: string;
}

const LinkData = ({
  url,
  onEditClick,
  onDeleteClick,
  formatMessage,
}: LinkDataProps): React.ReactElement => (
  <div className={theme('data')}>
    <a href={url} target="_blank" rel="noopener noreferrer" className={theme('data-link')}>
      {url}
    </a>
    <Tooltip id="link-edit" message={formatMessage(messages.editLink)} placement="top">
      <span className={theme('data-icon')} onClick={onEditClick}>
        <Icon type="comments" />
      </span>
    </Tooltip>
    <Tooltip id="link-delete" message={formatMessage(messages.deleteLink)} placement="top">
      <span className={theme('data-icon')} onMouseDown={onDeleteClick}>
        <Icon type="delete" />
      </span>
    </Tooltip>
  </div>
);

interface Props {
  editorState: EditorState;
  focusEditor: () => void;
  isAnyDropdownOpen: boolean;
  onChange: (editorState: EditorState) => void;
}

const InsertLink = ({
  editorState,
  onChange,
  focusEditor,
  isAnyDropdownOpen,
}: Props): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [visibleText, setVisibleText] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [editingLink, setEditingLink] = React.useState(false);
  const focusedEntityId = React.useMemo(
    () => getSelectedEntityKey(editorState, 'LINK'),
    [editorState]
  );
  const prevFocusedEntityId = usePrevious(focusedEntityId);
  const currentLinkUrl = React.useMemo(() => {
    if (focusedEntityId) {
      const { url } = editorState.getCurrentContent().getEntity(focusedEntityId).getData();

      return url.startsWith('http') ? url : `http://${url}`;
    }
    return '';
  }, [editorState, focusedEntityId]);

  React.useEffect(() => {
    if (focusedEntityId && editorState.getSelection().getHasFocus() && !isAnyDropdownOpen) {
      setDropdownOpen(true);
    } else if ((!focusedEntityId && prevFocusedEntityId) || isAnyDropdownOpen) {
      setDropdownOpen(false);
      setEditingLink(false);
    }
  }, [editorState, focusedEntityId, prevFocusedEntityId, isAnyDropdownOpen]);

  React.useEffect(() => {
    if (!focusedEntityId) {
      setVisibleText(getTextFromSelection(editorState));
      setUrl('');
    }
  }, [editorState, focusedEntityId]);

  const onVisibleTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVisibleText(e.target.value);
  }, []);

  const onUrlChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  const handleOpen = React.useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    if (!focusedEntityId) {
      setDropdownOpen(false);
    }
  }, [focusedEntityId]);

  const handleCancelClick = (): void => {
    if (editingLink) {
      setEditingLink(false);
    } else {
      setDropdownOpen(false);
    }
    focusEditor();
  };

  const handleInsert = (): void => {
    const newEditorState = insertLink(
      focusedEntityId
        ? EditorState.acceptSelection(
            editorState,
            getEntitySelection(editorState, focusedEntityId) as SelectionState
          )
        : editorState,
      visibleText,
      url.trim()
    );

    setEditingLink(false);
    onChange(newEditorState);
    handleClose();
  };

  const handleDeleteLink = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      if (focusedEntityId) {
        onChange(removeLink(editorState, focusedEntityId));
      }
    },
    [onChange, editorState, focusedEntityId]
  );

  const handleEditLinkClick = React.useCallback(() => {
    if (focusedEntityId) {
      const { url } = editorState.getCurrentContent().getEntity(focusedEntityId).getData();
      const formattedUrl = url.startsWith('http') ? url : `http://${url}`;
      const text = getTextFromSelection(
        EditorState.acceptSelection(
          editorState,
          getEntitySelection(editorState, focusedEntityId) as SelectionState
        )
      );

      setVisibleText(text);
      setUrl(formattedUrl);
      setEditingLink(true);
    }
  }, [editorState, focusedEntityId]);

  return (
    <div className={theme('container')}>
      <Dropdown
        sticky
        disableAnimation
        optionsOpened={dropdownOpen}
        bodyClassName={theme('list')}
        button={
          <Tooltip id="insert-link-tooltip" message={formatMessage(messages.insertLink)}>
            <Button
              className={theme('button')}
              color={COLORS.default}
              appearance={APPEARANCES.text}
              onClick={dropdownOpen ? handleClose : handleOpen}
              onMouseDown={(e): void => {
                e.preventDefault();
              }}
            >
              <Icon type="linkAlt" />
            </Button>
          </Tooltip>
        }
      >
        {(!prevFocusedEntityId && !focusedEntityId) || editingLink ? (
          <Form
            visibleText={visibleText}
            url={url}
            onVisibleTextChange={onVisibleTextChange}
            onUrlChange={onUrlChange}
            onSubmit={handleInsert}
            onCancel={handleCancelClick}
            submitMessage={<FormattedMessage {...messages[editingLink ? 'save' : 'insert']} />}
          />
        ) : (
          <LinkData
            url={currentLinkUrl}
            onEditClick={handleEditLinkClick}
            onDeleteClick={handleDeleteLink}
            formatMessage={formatMessage}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default InsertLink;
