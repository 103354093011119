import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

const Category = CATEGORIES.smartFolders;

export enum FolderType {
  currentFolder = 'Current Folder',
  newFolder = 'New Folder',
  otherFolder = 'Other Folder',
}

export const showSmartFoldersHistory = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Show Smart Folders History', { Category, Location: LOCATIONS.search, Access_Type });
};

export const clickFolderOpen = (
  Folder_Type: FolderType,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Click Folder Open', { Category, Location: LOCATIONS.home, Folder_Type, Access_Type });
};

export const clickFolderSearch = (
  Location: LOCATIONS,
  Folder_Type: FolderType,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Click Folder Search', { Category, Location, Folder_Type, Access_Type });
};

export const clickOtherFolders = (): void => {
  sendEvent('Click Other Folders', { Category, Location: LOCATIONS.home });
};

export const clickNewFolder = (): void => {
  sendEvent('Click New Folder', { Category, Location: LOCATIONS.home });
};
