import { compact, includes, max, min, range } from 'lodash';

/**
 * Excludes second array items from the first one
 * @TODO Replace by Lodash v4 differenceBy method
 * @param arr {Array} The array to inspect
 * @param values {Array} The array to exlude
 * @param key {string} Key name
 * @returns {Array} Filtered values
 */
export function differenceBy<T extends Record<string, any>>(
  arr: Array<T>,
  values: Array<T>,
  key: string
): Array<T> {
  const keys = values.map((value) => value[key]);

  return arr.filter((a) => keys.indexOf(a[key]) === -1);
}

/**
 * "Dries" array to null if there is no truthy items
 * @param {Array} arr
 * @returns {Array|null}
 */
export function dry(arr: Array<any>): Array<any> | null {
  const compactArr = compact(arr);

  return compactArr.length ? compactArr : null;
}

/**
 * Aggregates array items field by keys
 *
 * @example
 * aggregate('value', ['type'], [
 *   { type: 'age', value: '2' },
 *   { type: 'level', value: 'senior' }
 *   { type: 'age', value: '4' }
 * ])
 * // => [{ type: 'age', value: ['2', '4'] }, { type: 'level', value: ['senior'] }]
 *
 * @param {string} field Field name to merge items
 * @param {Array.<string>} keys Item's keys to merge by
 * @param {Array.<Object>} arr List to merge
 * @returns {Array.<Object>}
 */
export function aggregate(
  field: string,
  keys: Array<string>,
  arr: Array<Record<string, any>>
): Array<Record<string, any>> {
  const map = new Map();

  arr.forEach((value) => {
    const key = keys.map((k) => value[k]).join('');
    const val = map.get(key);

    map.set(key, {
      ...value,
      [field]: val ? [...val[field], value[field]] : [value[field]],
    });
  });

  return Array.from(map.entries()).map(([, v]) => v);
}

/**
 * Looking for items with `query` string inclusion
 * @param {Array} collection
 * @param {string} query
 * @returns {Array}
 */
export function searchText(
  collection: Array<AH$FilterValue>,
  query: string
): Array<Record<string, any>> {
  if (!query) {
    return collection;
  }

  const normalizedQuery = query.toLowerCase().trim();

  return collection.filter(({ name }) => includes((name || '').toLowerCase(), normalizedQuery));
}

/**
 * Fills pagination pages numbers array
 * @param {number} visible Items in array
 * @param {number} last Last item
 * @param {number} [current] Number should lie in range
 * @returns {number[]}
 */
export function fillPaginationPages(visible: number, last: number, current = 1): Array<number> {
  const fill = (start: number) => range(start, start + Math.min(visible, last));
  const notFit = last > visible;

  // tail
  if (notFit && current === last) {
    return fill(last - visible + 1);
  }

  // middle
  if (notFit && current >= visible) {
    return fill(current + 1 - visible + 1);
  }

  // from beginning
  return fill(1);
}

/**
 * Возвращает минимальное из больших чем значение target
 * @param {Array} collection
 * @param {number} target
 * @returns {number}
 */
export function minOfMaxBy(collection: Array<number>, target: number): number | null | undefined {
  const max = collection.filter((item) => item > target);

  return max.length > 0 ? min(max) : null;
}

/**
 * Возвращает максимальное из меньших чем значение target
 * @param {Array} collection
 * @param {number} target
 * @returns {number}
 */
export function maxOfMinBy(collection: Array<number>, target: number): number | null | undefined {
  const min = collection.filter((item) => item < target);

  return min.length > 0 ? max(min) : null;
}

/**
 * Меняет позицию объекта в массиве
 * @param {Array} array
 * @param {number} currentIndex
 * @param {number} desiredIndex
 * @returns {array}
 */
export function changeItemPosition(
  array: Array<any>,
  currentIndex: number,
  desiredIndex: number
): Array<any> {
  const transformedArray = [...array];
  const item = array[currentIndex];

  transformedArray.splice(currentIndex, 1);
  transformedArray.splice(desiredIndex, 0, item);
  return transformedArray;
}

export function insertItem<T>(array: Array<T>, item: T, desiredIndex?: number | null): Array<T> {
  const transformedArray = [...array];

  if (typeof desiredIndex !== 'number' || transformedArray.length - 1 === desiredIndex) {
    transformedArray.push(item);
  } else {
    transformedArray.splice(desiredIndex, 0, item);
  }
  return transformedArray;
}
