import { FC } from 'react';
import { useIntl } from 'react-intl';

import { AH$MessageEditor } from 'types';
import { MESSAGE_EDITOR_TYPES } from 'tracking';
import thematize from 'lib/thematize';
import Button from 'components/Base/Button';
import Dialog from 'components/Base/Dialog';
import Icon from 'components/Base/Icon';
import Text, { Is } from 'components/Base/Text';
import Editor from 'components/Messaging/Message/Editor';
import messages from '../../messages';
import styles from '../DefaultFullNotification/DefaultFullNotification.scss';

const theme = thematize(styles);

type MaintenanceNotificationProps = {
  editor: AH$MessageEditor;
  handleButtonClick: () => void;
  handleClose: () => void;
  notification: AH$SystemNotification;
};

const MaintenanceFullNotification: FC<MaintenanceNotificationProps> = ({
  notification,
  editor,
  handleButtonClick,
  handleClose,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog className={theme('container')}>
      <div className={theme('close-btn')} onClick={handleClose}>
        <Icon type="cross" />
      </div>
      <span className={theme('emoji')}>&#128736;</span>
      <Text is={Is.Regular} className={theme('title')}>
        {notification.title || formatMessage(messages.maintenanceTitle)}
      </Text>
      <Editor
        {...editor}
        readOnly
        mod="notification"
        mailingVariables={[]}
        className={theme('text')}
        type={MESSAGE_EDITOR_TYPES.notification}
      />
      <Button color="primary" className={theme('button')} size="l" onClick={handleButtonClick}>
        {notification.buttonText || formatMessage(messages.ok)}
      </Button>
    </Dialog>
  );
};

export default MaintenanceFullNotification;
