import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, omit, pickBy, snakeCase } from 'lodash';

import { AH$RouterQuery } from 'types';
import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise } from 'lib/abortablePromise';
import * as api from 'lib/apiV6';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'statistics';
let statisticsRequest: null | AbortablePromise<{
  payload: API$Statistics;
}> = null;

let statisticsTableRequest: null | AbortablePromise<{ payload: API$Statistics }> = null;

export const getStatistics = createAsyncThunk(
  `${SLICE_NAME}/getStatistics`,
  async (
    {
      section,
      query,
      companyId,
    }: {
      companyId: string;
      query: AH$RouterQuery;
      section: AH$StatisticsSection;
    },
    { rejectWithValue }
  ) => {
    try {
      if (statisticsRequest && statisticsRequest.abort) {
        statisticsRequest.abort();
      }
      statisticsRequest = abortable(api.getStatistics(section, companyId, query));
      const { payload } = await statisticsRequest;

      return payload;
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);
      }

      return rejectWithValue(e);
    }
  }
);

export const getStatisticsTable = createAsyncThunk(
  `${SLICE_NAME}/getStatisticsTable`,
  async (
    {
      section,
      query,
      companyId,
      item,
    }: {
      companyId: string;
      item: 'foldersTable' | 'usersTable' | 'templatesTable';
      query: AH$RouterQuery;
      section: AH$StatisticsSection;
    },
    { rejectWithValue }
  ) => {
    try {
      const snakeCaseItem = snakeCase(item);

      if (statisticsTableRequest && statisticsTableRequest.abort) {
        statisticsTableRequest.abort();
      }

      statisticsTableRequest = abortable(
        api.getStatistics(section, companyId, { ...query, item: snakeCaseItem })
      );

      const { payload } = await statisticsTableRequest;

      return { ...payload, item, snakeCaseItem };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);
      }
      return rejectWithValue(e);
    }
  }
);

export const getStatisticsFilters = createAsyncThunk(
  `${SLICE_NAME}/getStatisticsFilters`,
  async (
    {
      section,
      query,
      companyId,
    }: { companyId: string; query: AH$RouterQuery; section: AH$StatisticsSection },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await api.getStatisticsFilters(section, companyId, query);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const getStatisticsSettings = createAsyncThunk(
  `${SLICE_NAME}/getStatisticsSettings`,
  async (
    { section, companyId }: { companyId: string; section: AH$StatisticsSection },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await api.getStatisticsSettings(section, companyId);

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export const setStatisticsSettings = createAsyncThunk(
  `${SLICE_NAME}/setStatisticsSettings`,
  async (
    {
      data,
      companyId,
      onSuccess,
      section,
    }: {
      companyId: string;
      data: Record<string, Record<string, boolean>>;
      onSuccess: () => void;
      section: AH$StatisticsSection;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await api.setStatisticsSettings(section, companyId, data);

      onSuccess();
      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

export type StatisticsState = {
  filters: {
    data: Record<string, Array<string | [string, number]>>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  foldersTable: {
    pages: API$Pages | null;
    results: Array<Record<string, string | number>>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  query: AH$RouterQuery;
  selectedTable: 'foldersTable' | 'usersTable' | 'templatesTable';
  settings: {
    data: Record<string, Record<string, boolean>>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  status: $Values<typeof FETCHING_STATUSES>;
  statusesNames: Record<number, { color: number; name: string }>;
  tableHeaders: Array<string>;
  templatesTable: {
    pages: API$Pages | null;
    results: Array<Record<string, string | number>>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  timeline: Array<Record<string, string | number>>;
  total: API$Statistics['total'];
  usersTable: {
    pages: API$Pages | null;
    results: Array<Record<string, string | number>>;
    status: $Values<typeof FETCHING_STATUSES>;
  };
};

export const statisticsInitialState: StatisticsState = {
  status: FETCHING_STATUSES.LOADING,
  total: {},
  settings: {
    status: FETCHING_STATUSES.LOADING,
    data: {},
  },
  filters: {
    status: FETCHING_STATUSES.LOADING,
    data: {},
  },
  timeline: [],
  usersTable: {
    status: FETCHING_STATUSES.LOADING,
    results: [],
    pages: null,
  },
  foldersTable: {
    status: FETCHING_STATUSES.LOADING,
    results: [],
    pages: null,
  },
  templatesTable: {
    status: FETCHING_STATUSES.LOADING,
    results: [],
    pages: null,
  },
  statusesNames: {},
  tableHeaders: [],
  selectedTable: 'usersTable' as const,
  query: {},
};

export const statisticsSlice = createSlice({
  name: SLICE_NAME,
  initialState: statisticsInitialState,
  reducers: {
    setSelectedTable: (
      state,
      action: PayloadAction<'foldersTable' | 'usersTable' | 'templatesTable'>
    ) => {
      state.selectedTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state) => {
      state.status = FETCHING_STATUSES.LOADING;
      state.total = {};
      state.timeline = [];
      state.usersTable = {
        status: FETCHING_STATUSES.LOADING,
        results: [],
        pages: null,
      };
      state.templatesTable = {
        status: FETCHING_STATUSES.LOADING,
        results: [],
        pages: null,
      };
      state.foldersTable = { status: FETCHING_STATUSES.LOADING, results: [], pages: null };
      state.statusesNames = {};
    });
    builder.addCase(getStatistics.fulfilled, (state, { payload, meta }) => {
      state.status = FETCHING_STATUSES.SUCCESS;
      state.timeline = get(payload, 'timeline', []);
      state.usersTable = {
        results: get(payload, 'users_table.results', []),
        pages: omit(get(payload, 'users_table'), 'results'),
        status: FETCHING_STATUSES.SUCCESS,
      };
      state.foldersTable = {
        results: get(payload, 'folders_table.results', []),
        pages: omit(get(payload, 'folders_table'), 'results'),
        status: FETCHING_STATUSES.SUCCESS,
      };
      state.templatesTable = {
        results: get(payload, 'templates_table.results', []),
        pages: omit(get(payload, 'templates_table'), 'results'),
        status: FETCHING_STATUSES.SUCCESS,
      };
      state.total = pickBy(payload.total, (val) => val.value > 0);
      state.statusesNames = get(payload, 'statuses_names', {});
      state.tableHeaders = get(payload, 'table_headers', []);
      state.query = meta.arg.query || {};
    });
    builder.addCase(getStatistics.rejected, (state) => {
      state.status = FETCHING_STATUSES.ERROR;
      state.total = {};
      state.timeline = [];
      state.usersTable = {
        status: FETCHING_STATUSES.ERROR,
        results: [],
        pages: null,
      };
      state.templatesTable = {
        status: FETCHING_STATUSES.LOADING,
        results: [],
        pages: null,
      };
      state.foldersTable = {
        status: FETCHING_STATUSES.LOADING,
        results: [],
        pages: null,
      };
      state.statusesNames = {};
    });
    builder.addCase(getStatisticsTable.pending, (state, action) => {
      state[action.meta.arg.item] = {
        status: FETCHING_STATUSES.LOADING,
        results: [],
        pages: null,
      };
    });
    builder.addCase(getStatisticsTable.fulfilled, (state, action) => {
      state[action.payload.item] = {
        status: FETCHING_STATUSES.SUCCESS,
        results: get(action.payload, [action.payload.snakeCaseItem, 'results']),
        pages: omit(
          get(action.payload, action.payload.snakeCaseItem) as API$Statistics['folders_table'],
          'results'
        ),
      };
    });
    builder.addCase(getStatisticsTable.rejected, (state, action) => {
      state[action.meta.arg.item] = {
        status: FETCHING_STATUSES.ERROR,
        results: [],
        pages: null,
      };
    });
    builder.addCase(getStatisticsFilters.pending, (state) => {
      state.filters.status = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getStatisticsFilters.fulfilled, (state, action) => {
      state.filters.status = FETCHING_STATUSES.SUCCESS;
      state.filters.data = action.payload;
    });
    builder.addCase(getStatisticsFilters.rejected, (state) => {
      state.filters.status = FETCHING_STATUSES.ERROR;
      state.filters.data = {};
    });
    builder.addCase(getStatisticsSettings.pending, (state) => {
      state.settings.status = FETCHING_STATUSES.LOADING;
      state.settings.data = {};
    });
    builder.addCase(getStatisticsSettings.fulfilled, (state, action) => {
      state.settings = { status: FETCHING_STATUSES.SUCCESS, data: action.payload };
    });
    builder.addCase(getStatisticsSettings.rejected, (state) => {
      state.settings.status = FETCHING_STATUSES.ERROR;
      state.settings.data = {};
    });
    builder.addCase(setStatisticsSettings.pending, (state) => {
      state.settings.status = FETCHING_STATUSES.LOADING;
      state.settings.data = {};
    });
    builder.addCase(setStatisticsSettings.fulfilled, (state, action) => {
      state.settings.status = FETCHING_STATUSES.SUCCESS;
      state.settings.data = action.payload;
    });
    builder.addCase(setStatisticsSettings.rejected, (state) => {
      state.settings.status = FETCHING_STATUSES.ERROR;
      state.settings.data = {};
    });
  },
});

export const { setSelectedTable } = statisticsSlice.actions;
export const statistics = statisticsSlice.reducer;
