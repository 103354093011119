import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

export const createFolder = (
  Location: LOCATIONS,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Create Folder', { Location, Category: CATEGORIES.folders, Access_Type });
};

export const statusChange = (
  Location: LOCATIONS,
  Category: CATEGORIES,
  Short_Profile_Category?: 'Tab' | 'Card',
  Profiles_Count?: number,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Change Status', {
    Location,
    Category,
    Short_Profile_Category,
    Profiles_Count,
    Access_Type,
  });
};

export const changeAssigneeFilter = (Location: LOCATIONS): void => {
  sendEvent('Change Assignee Filter', { Location, Category: CATEGORIES.folders });
};

export const changeFolderStatusFilter = (): void => {
  sendEvent('Change Folder Status Filter', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
  });
};

export const selectFolder = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Select Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Access_Type,
  });
};

export const archiveFolder = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Archive Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Access_Type,
  });
};

export const unarchiveFolder = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Unarchive Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Access_Type,
  });
};

export const deleteFolder = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Delete Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Access_Type,
  });
};

export const editFolder = (Access_Type: AH$Folder['accessType'] = 'public'): void => {
  sendEvent('Edit Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Access_Type,
  });
};

export const moveToAnotherFolder = (
  Profiles_Count: number,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Move To Another Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Profiles_Count,
    Access_Type,
  });
};

export const copyToAnotherFolder = (
  Profiles_Count: number,
  Access_Type: AH$Folder['accessType'] = 'public'
): void => {
  sendEvent('Copy To Another Folder', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
    Profiles_Count,
    Access_Type,
  });
};

export const changeCandidateStatusFilter = (): void => {
  sendEvent('Change Candidate Status Filter', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
  });
};

export const selectCandidateStatusCheckbox = (): void => {
  sendEvent('Select Candidate Status Checkbox', {
    Location: LOCATIONS.folders,
    Category: CATEGORIES.folders,
  });
};
