import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { AH$RouterLocation } from 'types';
import { parse } from 'lib/query';

const useCustomRouterLocation = (): AH$RouterLocation => {
  const location = useLocation();
  const customLocation = useMemo(() => {
    const query = queryString.parse(location.search);
    const profilesQuery = parse(location.search);

    return { ...location, query, profilesQuery };
  }, [location]);

  return customLocation;
};

export default useCustomRouterLocation;
