import { DELETED_PAGE_ERROR_CODE } from 'constants/globals';

export default {
  defaultMessage: {
    id: 'formattedError.defaultMessage',
    defaultMessage: 'The data could not be displayed',
  },
  errorSorryMessage: {
    id: 'formattedError.errorSorryMessage',
    defaultMessage: 'Sorry, an error has occurred',
  },
  userNotFound: {
    id: 'formattedError.userNotFound',
    defaultMessage: 'Employee not found',
  },
  100: {
    id: 'formattedError.accessRestriction',
    defaultMessage: 'This location is not supported for your account',
  },
  102: {
    id: 'formattedError.ageErrorFilter',
    defaultMessage: `Filter "Age" is not available for this location`,
  },
  103: {
    id: 'formattedError.genderErrorFilter',
    defaultMessage: `Filter "Gender" is not available for this location`,
  },
  104: {
    id: 'formattedError.diversityErrorFilter',
    defaultMessage: `Filter "Diversity" is not available for this location`,
  },
  400: {
    id: 'formattedError.badRequest',
    defaultMessage: 'Bad request',
  },
  401: {
    id: 'formattedError.unauthorized',
    defaultMessage: 'Unauthorized',
  },
  403: {
    id: 'formattedError.forbidden',
    defaultMessage: 'Forbidden',
  },
  404: {
    id: 'formattedError.notFound',
    defaultMessage: 'Not found',
  },
  500: {
    id: 'formattedError.internalServerError',
    defaultMessage: 'Internal server error',
  },
  502: {
    id: 'formattedError.badGateway',
    defaultMessage: 'Invalid server response',
  },
  503: {
    id: 'formattedError.serviceUnavailable',
    defaultMessage: 'Service unavailable',
  },
  504: {
    id: 'formattedError.gatewayTimeout',
    defaultMessage: 'Timed out server response',
  },
  [DELETED_PAGE_ERROR_CODE]: {
    id: 'formattedError.deletedProfile',
    defaultMessage: 'Profile was deleted upon the owner’s request',
  },
};
