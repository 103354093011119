export default {
  length: {
    id: 'passwordValidator.length',
    defaultMessage: 'Must be 12 characters or more',
  },
  lowercase: {
    id: 'passwordValidator.lowercase',
    defaultMessage: '1 or more unique lowercase characters',
  },
  uppercase: {
    id: 'passwordValidator.uppercase',
    defaultMessage: '1 or more unique uppercase characters',
  },
  digits: {
    id: 'passwordValidator.digits',
    defaultMessage: '1 or more unique digits',
  },
  special: {
    id: 'passwordValidator.special',
    defaultMessage: '1 or more non unique special characters',
  },
};
