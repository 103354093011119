/**
 * @typedef {Object} ParsedQuery
 * @property {Array} q
 * @property {Array} [f]
 * @property {string} [s]
 * @property {boolean} [hiddenStars]
 * @property {string} [page]
 */

import { camelCase, flattenDeep, flow, flowRight, pick, transform } from 'lodash';
import queryString from 'query-string';

import { AH$RouterLocation, AH$RouterQuery } from 'types';
import { parsePageNumber } from 'lib/utils';
import { SearchFormState } from 'slices/searchForm';
import { transformToUrl } from 'components/QueryEditor/lib';
import { aggregate, dry } from './array';
import { pickBy } from './object';

const NORMALIZED_NAME: Record<string, string> = {
  skill: 'skillAll',
  mainSkillHigh: 'skillAll',
};

// ============================================================================================== //
/**
 * Returns encoded GET parameter value
 *
 * @example
 * paramaterize('foo', 'bar', 0)
 * // => 'foo[0]:bar'
 *
 * @param {string} name
 * @param {string} value
 * @param {number} index
 * @returns {string}
 */
function paramaterize(name: string, value: string, index: number): string {
  return `${name}[${index}]:${encodeURIComponent(value)}`;
}

function converToQueryWithTerms(query: AH$SearchQueryParamQ): AH$SearchQueryParamQWithTerms {
  return query.reduce<AH$SearchQueryParamQWithTerms>(
    (reduction, { field, excluded, rawText, notBoolean }) => {
      if (rawText.length === 0) {
        return reduction;
      }

      return [
        ...reduction,
        {
          excluded,
          field: notBoolean ? field : camelCase(`boolean-${field}`),
          terms: [{ id: rawText }],
        },
      ];
    },
    []
  );
}

/**
 * Convert query
 *
 * @example
 * convertQuery([{
 *   field: 'company',
 *   excluded: false,
 *   terms: [{ id: 'Johnson & Johnson' }]
 * }, {
 *   field: 'company',
 *   excluded: false,
 *   terms: [{ id: 'ОАО "РЖД"' }]
 * }, {
 *   field: 'skill',
 *   excluded: false,
 *   terms: [{ id: 'python' }]
 * }])
 * // => { company: [['Johnson & Johnson'], ['ОАО "РЖД"']], skill: [['python']] }
 *
 * @param {Array.<Object>} q
 * @returns {Object.<string, Array>}
 */
function convertQuery(query: AH$SearchQueryParamQWithTerms): {
  [field: string]: Array<Array<string>>;
} {
  return query.reduce<{ [field: string]: Array<Array<string>> }>(
    (acc, { field, excluded, terms }) => {
      const name = `${excluded ? '-' : ''}${field}`;
      const prevValue = acc[name];
      const value = terms.map(({ id }) => id);

      return {
        ...acc,
        [name]: prevValue ? [...prevValue, value] : [value],
      };
    },
    {}
  );
}

/**
 * Stringifies range filter value
 *
 * @example
 * stringifyRange([0, 20])
 * // => '*-20'
 *
 * @param low
 * @param high
 * @returns {string}
 */
function stringifyRange([low, high]: Array<number>): string {
  return `${low === 0 ? '*' : low}-${high === Infinity ? '*' : high}`;
}

/**
 * Convert filter value internal represantion to serializable view
 * @param {string} name
 * @param {Array|Set} value
 * @returns {Array}
 */
function convertFilterValue(
  name: string,
  value: Array<number> | Set<string>
): Array<Array<string>> {
  if (value instanceof Set) {
    // Using logical AND for site accounts filter
    if (name === 'site') {
      return Array.from(value.values()).map((v) => [v]);
    }

    return [Array.from(value.values())];
  }

  return [[stringifyRange(value)]];
}
/**
 * Convert filters from internal representation to request format
 *
 * @example
 * convertFilters({
 *   age: [0, 50],
 *   site: Set {'linkedin.com', 'github.com'},
 *   seniorityLevel: Set {'senior', 'team-lead'}
 * });
 * // =>
 * { age: [['*-50']],
 *   site: [['linkedin.com'], ['github.com']],
 *   seniorityLevel: [['senior', 'team-lead']] }
 *
 * @param {Object.<string, Array|Set>} filters
 * @returns {Object.<string, Array>}
 */
function convertFilters(filters: { [filter: string]: Array<number> | Set<string> } = {}): {
  [filter: string]: Array<Array<string>>;
} {
  return transform(pickBy(filters), (acc, val, key) =>
    Object.assign(acc, {
      [key]: convertFilterValue(key, val),
    })
  );
}

/**
 * Generates GET parameters array
 * @param {Object.<string, Array>} f
 * @returns {Array.<string>|null}
 */
function genParams(
  f: { [filter: string]: Array<Array<string>> } = {}
): Array<Array<Array<string>>> {
  return Object.keys(f).map((name) =>
    f[name].map((values, i) => values.map((value) => paramaterize(name, value, i)))
  );
}

/**
 * Parameterizes array items
 * @type {Function}
 * @param {Array.<Object>}
 * @returns {Array.<string>|null}
 */
const parameterizeArray = flowRight(dry, flattenDeep, genParams);

export const convertedFiltersToQuery = flow(convertFilters, parameterizeArray);

/**
 * Generates URL query representaion
 * @param {Array.<Object>|Object} q Query
 * @param {Array.<Object>} [f] Filters
 * @param {Object} [rest] Rest params
 * @returns {string}
 */
export function stringify(
  {
    q,
    f,
    convertedF,
    ...rest
  }: {
    [restKeys: string]: any;
    convertedF?: Array<string>;
    f?: AH$SearchQueryParamF;
    q?: AH$SearchQueryParamQ | AH$SearchQueryParamQWithTerms;
  },
  { searchFromMerging = false }: { searchFromMerging?: boolean } = {}
): string {
  if (!q) return '';

  const queryWithTerms: AH$SearchQueryParamQWithTerms = searchFromMerging
    ? (q as AH$SearchQueryParamQWithTerms)
    : converToQueryWithTerms(q as AH$SearchQueryParamQ);

  const convertedQuery = convertQuery(queryWithTerms);
  const params = pickBy({
    q: parameterizeArray(convertedQuery),
    f: convertedF || convertedFiltersToQuery(f as AH$SearchQueryParamF),
    ...rest,
  });

  return queryString.stringify(params, { encode: false });
}

/**
 * Parses query parsed GET params
 *
 *   ↓ flag           ↓ index
 * /^(-)?([a-zA-Z]*)\[(\d)\]:(.*)$/
 *       ↑ name              ↑ value
 *
 * @example
 * parseParam('-foo[0]:bar')
 * // => { flag: true, name: 'foo', value: 'bar', index: 0 }
 *
 * @param param
 * @returns {null|{flag: boolean, name: string, value: string, index: number}}
 */

type PraseParamReturn = { flag: boolean; index: number; name: string; value: string } | null;

function parseParam(param: string): PraseParamReturn {
  const parsedParam = /^(-)?([a-zA-Z]+)\[(\d+)\]:(.+)$/.exec(param);

  if (parsedParam) {
    return {
      flag: parsedParam[1] === '-',
      name: parsedParam[2],
      index: parseInt(parsedParam[3], 10),
      value: parsedParam[4],
    };
  }

  return null;
}

/**
 * Parses query parsed GET params
 *
 * @example
 * parseParam('-skill[0]:python')
 * // => { excluded: true, field: 'foo', queryText: 'bar', index: 0 }
 *
 * @param param
 * @returns {{excluded: boolean, field: string, queryText: string, index: number}|null}
 */

type ParseQueryParamReturn = {
  excluded: boolean;
  field: string;
  index: number;
  queryText: string;
} | null;

function parseQueryParam(param: string): ParseQueryParamReturn {
  const parsedParam = parseParam(param);

  if (parsedParam) {
    return {
      excluded: parsedParam.flag,
      field: camelCase(parsedParam.name.replace('boolean', '')),
      queryText: parsedParam.value.trim(),
      index: parsedParam.index,
    };
  }

  return null;
}

/**
 * Parses range from string
 *
 * @example
 * parseFilters('*-18');
 * // => [0, 18]
 *
 * @param {string} range
 * @return {[number, number]}
 * @private
 */
function parseRange(range: string | Array<string>): Array<number> | null {
  const parsedRange = /^(\*|\d+)-(\*|\d+)$/.exec(Array.isArray(range) ? range[0] : range);

  if (parsedRange) {
    const min = parseInt(parsedRange[1], 10) || 0;
    const max = parseInt(parsedRange[2], 10) || Infinity;

    return [min, max];
  }

  return null;
}

/**
 * Parses facet parsed GET params
 *
 * @example
 * parseFacetParam('age[0]:*-50')
 * // => { name: 'age', value: '*-50', index: 0, excluded: false }
 *
 * @param param
 * @returns {{name: string, value: string, index: number, excluded: boolean}|null}
 */

type ParseFilterParamReturn = {
  excluded: boolean;
  index: number;
  name: string;
  value: string;
} | null;

function parseFilterParam(param: string): ParseFilterParamReturn {
  const parsedParam = parseParam(param);

  if (parsedParam) {
    return {
      name: `${parsedParam.flag ? '-' : ''}${parsedParam.name}`,
      value: parsedParam.value,
      index: parsedParam.index,
      excluded: parsedParam.flag,
    };
  }

  return null;
}

/**
 * Parses query
 * @param {string|Array.<string>} q
 * @returns {Array}
 */

export function parseQuery(q: string | Array<string>): AH$SearchQueryParamQ {
  const qItems = Array.isArray(q) ? q : [q];

  return aggregate(
    'queryText',
    ['excluded', 'field', 'index'],
    flattenDeep<Array<ParseQueryParamReturn>>(qItems.map<any>(parseQueryParam)).filter(Boolean)
  ).map<{ excluded: boolean; field: string; rawText: string }>(
    ({ excluded, field, queryText: query }) => ({
      excluded,
      field,
      rawText: query.join(' OR '),
      notBoolean: !qItems.find((s) => s.includes(camelCase(`boolean ${field}`))),
    })
  );
}

function transformFilters(
  filters: Array<{ name: string; value: Array<string> }>
): AH$SearchQueryParamF {
  return filters.reduce(
    (acc, { name, value }) =>
      Object.assign(acc, {
        [name]: parseRange(value) || new Set(value),
      }),
    {}
  );
}

/**
 * Parses filters
 * @param {string|Array.<string>} f
 * @returns {Array}
 */
export function parseFilters(f: string | Array<string> = []): AH$SearchQueryParamF {
  const fItems = Array.isArray(f) ? f : [f];
  const filters = aggregate(
    'value',
    ['name'],
    flattenDeep<NonNullable<ParseFilterParamReturn>>(fItems.map<any>(parseFilterParam)).filter(
      Boolean
    )
  ).map((item) => pick(item, ['name', 'value']));

  return transformFilters(filters);
}

/**
 * Parses URL search query
 * @param {string} query
 * @returns {ParsedQuery}
 */

export type ParseReturn = {
  [restKey: string]: any;
  f?: AH$SearchQueryParamF;
  q?: AH$SearchQueryParamQ;
};

export function parse(query: string): ParseReturn {
  const { q, f, ...rest } = queryString.parse(query);

  return {
    q: parseQuery(q),
    f: parseFilters(f),
    ...rest,
  };
}

type GenerateSearchQueryReturn = {
  [restKey: string]: any;
  f?: AH$SearchQueryParamF;
  q?: AH$SearchQueryParamQ;
  rankingResource?: string;
};

export const transformLegacyRawText = (
  query: AH$SearchQueryParamQItem
): AH$SearchQueryParamQItem => {
  const regexpLeftParenthesis = /(\w+|\d+)\s\((\w+|\d+)/gi;
  const regexpRightParenthesis = /(\d+|\w+)\)(\s(\w+|\d+)|\S)/gi;

  return {
    ...query,
    notBoolean: true,
    field: NORMALIZED_NAME[query.field] || query.field,
    rawText: query.rawText
      .replaceAll(' AND NOT ', ',-')
      .replaceAll('NOT ', '-')
      .replaceAll(' AND ', query.excluded ? ',-' : ',')
      .replaceAll(' OR ', query.excluded ? ',-' : ',')
      .replaceAll('<', '')
      .replaceAll('>', '')
      .replaceAll(query.rawText.match(regexpLeftParenthesis) ? '' : '(', '')
      .replaceAll(query.rawText.match(regexpRightParenthesis) ? '' : ' )', ''),
  };
};

export function generateSearchQuery(
  {
    q,
    f,
    s,
    ...rest
  }: {
    [restKey: string]: any;
    f?: AH$SearchQueryParamF;
    q?: AH$SearchQueryParamQ;
    s?: string;
  },
  isV2?: boolean
): GenerateSearchQueryReturn {
  return {
    q: isV2 ? q?.filter((item) => !item.rawText.includes('ai:')).map(transformLegacyRawText) : q,
    f,
    rankingResource: s,
    ...rest,
  };
}

export function getActiveSmartFolderId(location: Location = window.location): number | null {
  const query = parse(location.search as string);

  return parseInt(query.folderId, 10) || null;
}

export const getNavigateToSearchLocation = (
  location: AH$RouterLocation,
  queries: SearchFormState['queries'],
  suggestions: SearchFormState['suggestions'],
  activeSmartFolder: SearchFormState['activeSmartFolder'],
  convertedF?: Array<string>
): {
  hash?: string;
  pathname?: string;
  query?: AH$RouterQuery;
  replace?: boolean;
  search?: string;
  state?: any;
} => {
  const isV1 = window.location.pathname.includes('-old');
  const page = parsePageNumber(location.query.page);
  const q: AH$SearchQueryParamQ = transformToUrl(queries, suggestions, true);
  const search = stringify({
    q,
    f: {
      ...location.profilesQuery.f,
      ...(isV1 && activeSmartFolder
        ? { '-smartFolderHits': new Set([activeSmartFolder.id]) }
        : undefined),
    },
    s: location.profilesQuery.s,
    folderId: activeSmartFolder ? activeSmartFolder.id : undefined,
    convertedF,
    page: page === 1 ? undefined : page,
  });

  return {
    ...(isV1 ? { pathname: '/profiles-old/' } : undefined),
    search: `?${search}`,
  };
};

export const removePageNumber = (navigateTo: {
  hash?: string;
  pathname?: string;
  query?: AH$RouterQuery;
  replace?: boolean;
  search?: string;
  state?: any;
}): {
  hash?: string;
  pathname?: string;
  query?: AH$RouterQuery;
  replace?: boolean;
  search?: string;
  state?: any;
} => ({
  ...navigateTo,
  search: navigateTo.search?.replace(/page=\d+/g, 'page=1'),
  query: { ...navigateTo?.query, page: 1 },
});
