import { ReactElement, useEffect } from 'react';

interface Props {
  user: AH$User;
}

const Wootric = ({ user }: Props): ReactElement => {
  useEffect(() => {
    window.wootricSettings = {
      email: user.email,
      created_at: Math.floor(Date.parse(user.dateJoined) / 1000),
      account_token: 'NPS-b83251cb',
      language: user.settings.language.toUpperCase(),
    };

    // Beacon
    const beacon = document.createElement('script');

    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.src = 'https://disutgh7q0ncc.cloudfront.net/beacon.js';
    beacon.nonce = window.NONCE;
    beacon.onload = (): void => {
      if (window.location.pathname === '/' && window.wootric) {
        window.wootric('run');
      }
    };
    if (document.getElementById('wootric-beacon') === null) {
      document.body.appendChild(beacon);
    }
  }, []);

  return <div />;
};

export default Wootric;
