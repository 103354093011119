export enum CATEGORIES {
  shortProfile = 'Short Profile',
  fullProfile = 'Full Profile',
  statistics = 'Statistics',
  search = 'Search',
  query = 'Query',
  messaging = 'Messaging',
  folders = 'Folders',
  sideMenu = 'Side Menu',
  ats = 'ATS',
  login = 'Login',
  apps = 'Apps',
  extension = 'Extension',
  smartFolders = 'Smart Folders',
  marketInsights = 'Market Insights',
  sequences = 'Sequences',
}

export enum SearchType {
  ai = 'AI',
  smartFolders = 'Smart Folders',
  searchForm = 'Search Form',
  savedQueries = 'Saved Queries',
  history = 'History',
  resultsPage = 'Results Page',
  folder = 'Folder',
  editedProfiles = 'Edited Profiles',
  similarProfiles = 'Similar Profiles',
}
