export default [
  'huntflow',
  'gmsservices',
  'linkedin',
  'xing',
  'angel',
  'facebook',
  'fb',
  'twitter',
  'moikrug',
  'vk',
  'vkontakte',
  'github',
  'gitlab',
  'stackoverflow',
  'kaggle',
  'dribbble',
  'behance',
  'be',
  'personal',
  'plus-google',
  'profiles-google',
  'gravatar',
  'slideshare',
  'meetup',
  'headhunter',
  'hh',
  'ru-stackoverflow',
  'bitbucket',
  'sourceforge',
  'npmjs',
  'instagram',
  'habrahabr',
  'habr',
  'career-habr',
  'livejournal',
  'juick',
  'play-google',
  'youtube',
  'lookatme',
  'blogspot',
  'blogger',
  'wikipedia',
  'careers-stackoverflow',
  'stackexchange',
  'serverfault',
  'superuser',
  'askubuntu',
  'programmers-stackexchange',
  'sqa-stackexchange',
  'unix-stackexchange',
  'codereview-stackexchange',
  'math-stackexchange',
  'physics-stackexchange',
  'sql',
  'picasaweb-google',
  'picasa',
  'mail',
  'geekli',
  'freelansim',
  'free-lance',
  'fl',
  'freelance',
  'odesk',
  'elance',
  'flickr',
  'tumblr',
  'brainstorage',
  'ask',
  'pinterest',
  'wordpress',
  'vimeo',
  'myspace',
  'rubygems',
  'ucoz',
  'clan',
  'at',
  'yahoo',
  'last',
  'lastfm',
  'formspring',
  'spring',
  'narod',
  'live',
  'microsoft',
  'about',
  'soundcloud',
  'deviantart',
  'odnoklassniki',
  'gmail',
  'promodj',
  'pdj',
  'toster',
  'quora',
  'wix',
  'sites-google',
  'orkut',
  'foursquare',
  'stihi',
  '500px',
  'drive2',
  'yelp',
  'cargocollective',
  'liveinternet',
  'amazon',
  'etsy',
  'timeforlife',
  'jimdo',
  'issuu',
  'webs',
  'weebly',
  'friendfeed',
  'aha',
  'diary',
  'delicious',
  'coroflot',
  'imdb',
  'coderwall',
  'steamcommunity',
  'stumbleupon',
  'klout',
  'myopenid',
  'resumup',
  'speakerdeck',
  'dtf',
  'addons-mozilla',
  'mozilla',
  'goldenline',
  'itunes-apple',
  'lleo-aha',
  'dou',
  'revision',
  'metacpan',
  'codeforces',
  'researchgate',
  'viadeo',
  'e-xecutive',
  'keybase',
];
