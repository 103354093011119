import { flow, noop } from 'lodash';

import { useModal } from 'hooks';
import ConfirmDialog, { Props as ConfirmDialogProps } from 'components/Base/Dialog/ConfirmDialog';

type Return = (props: ConfirmDialogProps) => void;

const useConfirmModal = (): Return => {
  const { setModal, closeModal } = useModal();

  return ({
    title,
    content,
    onConfirm = noop,
    onCancel = noop,
    onDecline,
    confirmText,
    cancelText,
    declineText,
    classNames,
  }): void => {
    const handleConfirm = flow([closeModal, onConfirm]);
    const handleCancel = flow([closeModal, onCancel]);
    const handleDecline = onDecline ? flow([closeModal, onDecline]) : undefined;

    setModal(
      <ConfirmDialog
        title={title}
        content={content}
        confirmText={confirmText}
        cancelText={cancelText}
        declineText={declineText}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        onDecline={handleDecline}
        classNames={classNames}
      />
    );
  };
};

export default useConfirmModal;
