import { CATEGORIES } from './categories';
import { sendEvent } from './lib';
import { LOCATIONS } from './locations';

const Category = CATEGORIES.marketInsights;
const Location = LOCATIONS.search;

export const switchSearchResults = (Search_Mode: string): void => {
  sendEvent('Switch Search Mode', { Location, Category, Search_Mode });
};

export const downloadMarketInsights = (): void => {
  sendEvent('Download Market Insights', { Location, Category });
};

export const showMoreMarketInsightsData = (Facet_ID: string): void => {
  sendEvent('Show More Market Insights Data', { Location, Category, Facet_ID });
};

export const showLessMarketInsightsData = (Facet_ID: string): void => {
  sendEvent('Show Less Market Insights Data', { Location, Category, Facet_ID });
};

export const changeMarketInsightsCondition = (
  Is_Excluded: boolean,
  Is_Checked: boolean,
  Condition_ID: string,
  Condition_Value: string,
  Condition_Type: 'Checkbox' | 'Range' | 'SearchFormQuery'
): void => {
  sendEvent('Change Market Insights Condition', {
    Location,
    Category,
    Is_Excluded,
    Is_Checked,
    Condition_ID,
    Condition_Value,
    Condition_Type,
  });
};
