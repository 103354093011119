import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { noop } from 'lodash';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import Hr from 'components/Base/Hr';
import Icon from 'components/Base/Icon';
import SideMenuDropdown from './SideMenuDropdown';
import SideMenuDropdownItem from './SideMenuDropdownItem';
import styles from './SideMenu.scss';

const theme = thematize(styles);

interface LinkComponentProps {
  linkTo: string;
  onClick: () => void;
  title: React.ReactElement | string;
  badge?: React.ReactElement;
  iconName?: React.ComponentProps<typeof Icon>['type'];
}

const LinkComponent = ({
  title,
  linkTo,
  onClick = noop,
  iconName,
  badge,
  ...props
}: LinkComponentProps): React.ReactElement => (
  <div className={theme('item')}>
    <NavLink
      to={linkTo}
      onClick={() => {
        onClick();
        tracking.clickSideMenu(linkTo);
      }}
      className={({ isActive }) =>
        `${theme('item_inactive')} ${isActive ? theme('item_active') : ''}`
      }
      {...props}
    >
      <div className={theme('icon')}>
        <Icon type={iconName} />
      </div>
      {title}
    </NavLink>
    {badge && <span className={theme('badge')}>{badge}</span>}
  </div>
);

interface NotLinkComponentProps {
  title: React.ReactElement | string;
  iconName?: React.ComponentProps<typeof Icon>['type'];
  linkTo?: string;
}

const NotLinkComponent = ({
  title,
  linkTo = '',
  iconName,
  ...props
}: NotLinkComponentProps): React.ReactElement => (
  <div className={theme('item')}>
    <a href={linkTo} {...props}>
      <div className={theme('icon')}>
        <Icon type={iconName} />
      </div>
      {title}
    </a>
  </div>
);

interface DropDownProps {
  title: React.ReactElement | string;
  dropdownButtonContent?: React.ReactElement;
  iconName?: React.ComponentProps<typeof Icon>['type'];
}

const renderDropdownButtonContent = ({
  dropdownButtonContent,
  title,
  iconName,
}: DropDownProps): React.ReactElement =>
  dropdownButtonContent || (
    <div>
      <div className={theme('icon')}>
        <Icon type={iconName || 'caretDown'} />
      </div>
      {title}
    </div>
  );

interface TopItemProps {
  title: React.ReactElement | string;
  alignItems?: string;
  badge?: React.ReactElement;
  children?: Array<{
    isLine?: boolean;
    isUsingRouter?: boolean;
    linkTo?: string;
    name?: string;
    title?: React.ReactElement | string;
  }>;
  dropdownButtonContent?: React.ReactElement;
  iconName?: React.ComponentProps<typeof Icon>['type'];
  isUsingRouter?: boolean;
  linkTo?: string;
  onClick?: () => void;
}

const TopItem = ({
  title,
  isUsingRouter = false,
  linkTo,
  onClick = noop,
  iconName,
  children,
  alignItems,
  dropdownButtonContent,
  ...props
}: TopItemProps): React.ReactElement => {
  if (isUsingRouter && linkTo) {
    return (
      <LinkComponent
        title={title}
        linkTo={linkTo}
        onClick={onClick}
        iconName={iconName}
        {...props}
      />
    );
  }

  if (children) {
    const hideCorner = children.length === 1;

    return (
      <SideMenuDropdown
        buttonContent={renderDropdownButtonContent({
          dropdownButtonContent,
          iconName,
          title,
        })}
        alignItems={alignItems}
        hideCorner={hideCorner}
        {...props}
      >
        {children.map((child, i) =>
          child.isLine ? (
            // eslint-disable-next-line react/no-array-index-key
            <Hr key={`line${i}`} className={theme('hr')} />
          ) : (
            <SideMenuDropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={`sidemenu-dropdown-item-${i}`}
              name={child.name}
              title={child.title}
              linkTo={child.linkTo}
              isUsingRouter={child.isUsingRouter}
              {...child}
            />
          )
        )}
      </SideMenuDropdown>
    );
  }

  return <NotLinkComponent title={title} linkTo={linkTo} iconName={iconName} {...props} />;
};

export default TopItem;
