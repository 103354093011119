import { FC, useRef } from 'react';
import { Transition } from 'react-transition-group';

import thematize from 'lib/thematize';
import { useDispatch, useSelector } from 'hooks';
import { setSnackbar } from 'slices/app';
import styles from './Snackbar.scss';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const theme = thematize(styles);

const Snackbar: FC = () => {
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const snackbar = useSelector(({ app }) => app.snackbar);

  const { content, show } = snackbar;

  const handleExited = (): void => {
    dispatch(setSnackbar(null));
  };

  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      timeout={duration}
      onExited={handleExited}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <div
          aria-live="polite"
          aria-atomic="true"
          className={theme('wrapper')}
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className={theme('snackbar')}>{content}</div>
        </div>
      )}
    </Transition>
  );
};

export default Snackbar;
