import * as React from 'react';
import { Link } from 'react-router-dom';

import * as tracking from 'tracking';
import thematize from 'lib/thematize';
import styles from './SideMenu.scss';

const theme = thematize(styles);

export interface Props {
  isLine?: boolean;
  isUsingRouter?: boolean;
  linkTo?: string;
  name?: string;
  title?: React.ReactElement | string;
}

const SideMenuDropdownItem = ({
  title,
  linkTo = '',
  isUsingRouter,
  ...props
}: Props): React.ReactElement =>
  isUsingRouter ? (
    <Link
      to={linkTo}
      onClick={() => {
        tracking.clickSideMenu(linkTo);
      }}
      {...props}
    >
      <div className={theme('dropdown-item')}>{title}</div>
    </Link>
  ) : (
    <a href={linkTo} {...props}>
      <div className={theme('dropdown-item')}>{title}</div>
    </a>
  );

SideMenuDropdownItem.defaultProps = {
  isUsingRouter: false,
};

export default SideMenuDropdownItem;
