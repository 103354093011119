import * as React from 'react';
import { get } from 'lodash';

import thematize from 'lib/thematize';
import { useUser } from 'hooks';
import { KM_TO_MI } from 'components/SearchForm/LocationRanges';
import styles from './Term.scss';

const theme = thematize(styles);

interface ButtonProps {
  range: number;
  className?: string;
  format?: string;
  isHideValue?: boolean;
}

const Button = ({
  range,
  className = '',
  format = '',
  isHideValue = false,
}: ButtonProps): React.ReactElement => {
  const user = useUser();
  const settingsFormat = get(user, 'settings.rangeMeasure') || 'km';
  const value = settingsFormat === 'mi' ? KM_TO_MI[range as keyof typeof KM_TO_MI] : range;

  const text = `${isHideValue ? format || settingsFormat : `${value} ${format || settingsFormat}`}`;

  return <span className={`${theme('location-range')} ${className}`}>{text}</span>;
};

export default Button;
