import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { EditorState } from 'draft-js';
import { find } from 'lodash';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import Tooltip from 'components/CustomTooltip';
import messages from 'components/Messaging/Message/Editor/messages';
import styles from './AlignText.scss';

const theme = thematize(styles);

const ALIGNMENTS = ['alignLeft', 'alignCenter', 'alignRight'] as const;

interface Props {
  editorState: EditorState;
  onChange: (type: string) => void;
  onDropdownClose: () => void;
  onDropdownOpen: () => void;
}

const AlignText: React.FC<Props> = ({ onChange, editorState, onDropdownOpen, onDropdownClose }) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();
  const selection = editorState.getSelection();
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getAnchorKey())
    .getType();
  const currentAlignment = find(ALIGNMENTS, (style) => style === currentBlockType) || ALIGNMENTS[0];

  const handleToggleDropdown = () => {
    if (open) {
      setOpen(false);
      onDropdownClose();
    } else {
      setOpen(true);
      onDropdownOpen();
    }
  };

  const handleFocusLoss = () => {
    setOpen(false);
    onDropdownClose();
  };

  return (
    <div {...dataQa('font-control')}>
      <Dropdown
        sticky
        closeOnAwayClick
        textAlignCenter
        onFocusLoss={handleFocusLoss}
        containerClassName={theme('container')}
        bodyClassName={theme('list')}
        optionsOpened={open}
        button={
          <Tooltip id="size-tooltip" message={formatMessage(messages.alignText)}>
            <Button
              className={theme('button')}
              color={COLORS.default}
              appearance={APPEARANCES.text}
              onClick={handleToggleDropdown}
              onMouseDown={(e): void => {
                e.preventDefault();
              }}
            >
              <span key={currentAlignment}>
                <Icon type={currentAlignment} />
              </span>
            </Button>
          </Tooltip>
        }
      >
        {ALIGNMENTS.map((alignment) => (
          <Dropdown.Option
            optionClassName={theme('label')}
            key={alignment}
            onMouseDown={(e): void => {
              e.preventDefault();
              onChange(alignment);
              handleFocusLoss();
            }}
          >
            <span>
              <Icon type={alignment} />
            </span>
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default AlignText;
