import { camelCase, identity, isObject, transform } from 'lodash';
import pick from 'lodash/pickBy';

/**
 * Removes obj empty keys
 * @param {Object} obj
 * @returns {Object}
 */
export const pickBy = (obj: Record<string, any>): Record<string, any> => pick(obj, identity);

/**
 * Turns over array to object by key
 * @example
 *
 * turnArrayToObject('id', [{ id: 1, value: 'foo' }, { id: 2, value: 'bax' }])
 * // => { 1: { id: 1, value: 'foo' }, 2: { id: 2, value: 'bax' } }
 *
 * @param {String} key Key
 * @param {Array} arr Collection
 * @returns {Object}
 */
export function turnArrayToObject<T extends Record<string, any>>(
  key: string,
  arr: Array<T>
): Record<number, T> {
  return arr.reduce((obj, item) => ({ ...obj, [item[key]]: item }), {});
}

/**
 * Transforms obj keys to camelCase
 * @param {Object} obj
 * @returns {Object}
 */
export function camelKeys<T extends Record<string, any>>(obj: Record<string, any>): T {
  return transform(obj, (acc, val, key) => Object.assign(acc, { [camelCase(key)]: val }));
}

export function invertNum(obj: Record<string, any>): Record<string, any> {
  return transform(obj, (acc, val, key) => Object.assign(acc, { [val]: parseInt(key, 10) }));
}

export function camelKeysRecursive<T extends Record<string, any>>(obj: Record<string, any>): T {
  return transform(obj, (acc, val, key) =>
    Object.assign(acc, { [camelCase(key)]: isObject(val) ? camelKeysRecursive(val) : val })
  );
}
