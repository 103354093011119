import { CacheProvider } from '@emotion/react';
import { lazy, Suspense, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import createCache from '@emotion/cache';
import { get, size } from 'lodash';

import { isHiringManager, isManager } from 'lib/utils';
import { useCustomRouterLocation, useUser } from 'hooks';
import ReduxStore from 'stores/ReduxStore';
import { receiveSavedQueries, receiveSearchHistory } from 'slices/searchForm';
import Index from 'components/App';
import ErrorBoundary from 'components/ErrorBoundary';
// Required to make react-select work with CSP
const emotionCache = createCache({
  key: 'key',
  nonce: window.NONCE,
  prepend: true,
});

const TelegramManagerPage = lazy(() => import('components/pages/telegram-messenger/manager'));
const TelegramMessagesPage = lazy(() => import('components/pages/telegram-messenger/messages'));
const PresentationSSOSettings = lazy(
  () => import('components/AdminPanel/Company/SSO/PresentationSSOSettings')
);
const EditSSOSettings = lazy(() => import('components/AdminPanel/Company/SSO/EditSSOSettings'));
const Folder = lazy(() => import('components/Folders/Folder'));
const FolderEdit = lazy(() => import('components/Folders/Editing'));
const Messaging = lazy(() => import('components/Folders/Messaging'));
const FoldersDataFetcher = lazy(() => import('components/Folders/FoldersDataFetcher'));
const SignInSearch = lazy(() => import('components/Login/SignInSearch'));
const Login = lazy(() => import('components/Login'));
const SignUp = lazy(() => import('components/Login/SignUp'));
const LoginAccount = lazy(() => import('components/Login/Account'));
const LoginPassword = lazy(() => import('components/Login/Password'));
const LoginConfirm = lazy(() => import('components/Login/Confirm'));
const Apps = lazy(() => import('components/Apps'));
const AppsExtension = lazy(() => import('components/Apps/AppsExtension'));
const Home = lazy(() => import('components/Home'));
const Search = lazy(() => import('components/Search/Wrapper'));
const Folders = lazy(() => import('components/Folders'));
const SentMessages = lazy(() => import('components/Messaging/Sent'));
const Merging = lazy(() => import('components/Merging'));
const ManageToken = lazy(() => import('components/ManageToken'));
const ManageOrders = lazy(() => import('components/ManageOrders'));
const NeedActivation = lazy(() => import('components/NeedActivation'));
const Activation = lazy(() => import('components/Activation'));
const NeedVerification = lazy(() => import('components/NeedVerification'));
const MatchingHistory = lazy(() => import('components/MatchingHistory'));
const ExtensionCompany = lazy(() => import('components/ExtensionCompany'));
const ClientUpdate = lazy(() => import('components/ClientUpdate'));
const Profile = lazy(() => import('components/Profile'));
const FolderSettings = lazy(() => import('components/Folders/Settings'));
const DataEnrichment = lazy(() => import('components/DataEnrichment'));
const Templates = lazy(() => import('components/Messaging/Templates'));
const MessagingSettings = lazy(() => import('components/Messaging/Settings'));
const ProfileRealtimeDiff = lazy(() => import('components/ProfileRealtimeDiff'));
const ProfileCreate = lazy(() => import('components/Profile/ProfileCreate'));
const ProfileEditing = lazy(() => import('components/Profile/ProfileEditing'));
const Settings = lazy(() => import('components/Settings'));
const BannedLinks = lazy(() => import('components/BannedLinks'));
const ActiveUsers = lazy(() => import('components/ActiveUsers'));
const Companies = lazy(() => import('components/AdminPanel/Companies'));
const Company = lazy(() => import('components/AdminPanel/Company'));
const CompanyInfo = lazy(() => import('components/AdminPanel/Company/Info'));
const EditCompany = lazy(() => import('components/AdminPanel/Company/EditCompany'));
const AddCredits = lazy(() => import('components/AdminPanel/Company/AddCredits'));
const ApiStats = lazy(() => import('components/AdminPanel/Company/AdminApiStats'));
const NewEmployee = lazy(() => import('components/AdminPanel/Company/Employees/NewEmployee'));
const EditEmployee = lazy(() => import('components/AdminPanel/Company/Employees/EditEmployee'));
const Employees = lazy(() => import('components/AdminPanel/Company/Employees'));
const MoveToCompany = lazy(() => import('components/AdminPanel/Company/Employees/MoveToCompany'));
const HiringManagers = lazy(() => import('components/AdminPanel/Company/HiringManagers'));
const HMCompanySettings = lazy(() => import('components/AdminPanel/HMSettings/HMCompanySettings'));
const ExtensionNotifications = lazy(() => import('components/AdminPanel/ExtensionNotifications'));
const CreateExtensionNotification = lazy(
  () => import('components/AdminPanel/ExtensionNotifications/NotificationForm')
);
const EditExtensionNotification = lazy(
  () => import('components/AdminPanel/ExtensionNotifications/EditNotification')
);
const MetaQueries = lazy(() => import('components/AdminPanel/MetaQueries'));
const MetaQueriesInfo = lazy(() => import('components/AdminPanel/MetaQueries/Info'));
const MetaQueryForm = lazy(() => import('components/AdminPanel/MetaQueries/MetaQueryForm'));
const Managers = lazy(() => import('components/AdminPanel/Managers'));
const NewManager = lazy(() => import('components/AdminPanel/Managers/NewManager'));
const EditManager = lazy(() => import('components/AdminPanel/Managers/EditManager'));
const LicenseExpired = lazy(() => import('components/License/LicenseExpired'));
const LicenseNotStarted = lazy(() => import('components/License/LicenseNotStarted'));
const Statistics = lazy(() => import('components/Statistics'));
const SearchStatistics = lazy(() => import('components/Statistics/Search'));
const SearchSettings = lazy(() => import('components/Statistics/Search/Settings'));
const ATSStatistics = lazy(() => import('components/Statistics/ATS'));
const ATSStatisticsSettings = lazy(() => import('components/Statistics/ATS/Settings'));
const MessagingStatistics = lazy(() => import('components/Statistics/Messaging'));
const MessagingStatisticsSettings = lazy(() => import('components/Statistics/Messaging/Settings'));
const RecoverPassword = lazy(() => import('components/Login/RecoverPassword'));
const ResetPassword = lazy(() => import('components/Login/ResetPassword'));
const ATSTokenForm = lazy(() => import('components/Apps/TokenForm'));
const ATSSubdomainForm = lazy(() => import('components/Apps/SubdomainForm'));
const ATSSubdomainChecker = lazy(() => import('components/Apps/SubdomainForm/SubdomainChecker'));
const CustomSettings = lazy(() => import('components/AdminPanel/Company/CustomSettings'));
const LimitExceeded = lazy(() => import('components/LimitExceeded'));
const UserSelect = lazy(() => import('components/Apps/UserSelect'));
const WithoutSearchMenu = lazy(() => import('components/WithoutSearchMenu'));
const SystemNotifications = lazy(() => import('components/AdminPanel/SystemNotifications'));
const CreateSystemNotification = lazy(
  () => import('components/AdminPanel/SystemNotifications/CreateSystemNotification')
);
const EditSystemNotification = lazy(
  () => import('components/AdminPanel/SystemNotifications/EditSystemNotification')
);
const SequenceForm = lazy(() => import('components/Messaging/Sequences/SequenceForm'));
const SequenceProfileView = lazy(
  () => import('components/Messaging/Sequences/SequenceProfileView')
);
const SequenceDetailInfo = lazy(() => import('components/Messaging/Sequences/SequenceDetailInfo'));
const SequencesMainPage = lazy(() => import('components/Messaging/Sequences'));

const ProxyLink = lazy(() => import('components/ProxyLink'));
const PageNotFound = lazy(() => import('components/PageNotFound'));

const HomeWrapper = () => {
  const user = useUser();
  const isExtCompany = user.company && user.company.isExtCompany;
  const isHM = isHiringManager(user.role);

  useEffect(() => {
    if (!isHM) {
      ReduxStore.dispatch(receiveSearchHistory({}));
      if (!isManager(user.role)) {
        ReduxStore.dispatch(receiveSavedQueries({}));
      }
    }
  }, [isHM, user.role]);
  if (isHM) {
    return <Navigate replace to="/folders/" />;
  }

  return isExtCompany ? <ExtensionCompany /> : <Home />;
};

const SearchWrapper = () => {
  const user = useUser();
  const isHM = isHiringManager(user.role);
  const location = useCustomRouterLocation();

  if (
    ((!location.profilesQuery.q || !location.profilesQuery.q.length) &&
      location.pathname === '/profiles-old/') ||
    isHM
  ) {
    return <HomeWrapper />;
  }

  return <Search />;
};

const StatisticsWrapper = () => (
  <Statistics>
    <Routes>
      <Route path="search/settings" element={<SearchSettings />} />
      <Route path="search" element={<SearchStatistics />} />
      <Route path="pipeline/settings" element={<ATSStatisticsSettings />} />
      <Route path="pipeline" element={<ATSStatistics />} />
      <Route path="messaging/settings" element={<MessagingStatisticsSettings />} />
      <Route path="messaging" element={<MessagingStatistics />} />
    </Routes>
  </Statistics>
);

type Props = {
  locales: {
    ru: {
      [key: string]: string;
    };
  };
};

const App = ({ locales }: Props) => {
  const { host } = window.location;
  const ruLocale = host.includes('.ru');
  const user = useUser();
  const userLocale = get(user, 'settings.language', null);
  const browserLocale = ruLocale ? 'ru' : 'en';
  const locale = userLocale || browserLocale;
  const isHM = isHiringManager(user.role);

  return (
    <CacheProvider value={emotionCache}>
      <IntlProvider locale={locale} messages={locales[locale as keyof typeof locales]}>
        <ErrorBoundary>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <Index>
                <Suspense fallback={<div />}>
                  <Routes>
                    <Route path="/" element={<SearchWrapper />} />
                    <Route path="login" element={<SignInSearch />} />
                    <Route path="login/sso" element={<SignInSearch isSSO />} />
                    <Route
                      path="oauth/*"
                      element={
                        <Login>
                          <Routes>
                            <Route path="register/:backend" element={<LoginAccount />} />
                            <Route path="attention_newbie/:backend" element={<LoginConfirm />} />
                            <Route path="confirm_password/:backend" element={<LoginPassword />} />
                          </Routes>
                        </Login>
                      }
                    />
                    <Route path="users/need-activation" element={<NeedActivation />} />
                    <Route path="users/need-verification" element={<NeedVerification />} />
                    <Route path="users/activate/:token" element={<Activation />} />
                    <Route
                      path="users/registration"
                      element={
                        <Login>
                          <SignUp />
                        </Login>
                      }
                    />
                    <Route
                      path="users/recover"
                      element={
                        <Login>
                          <RecoverPassword />
                        </Login>
                      }
                    />
                    <Route
                      path="users/reset/:token"
                      element={
                        <Login>
                          <ResetPassword />
                        </Login>
                      }
                    />
                    <Route path="license-expired" element={<LicenseExpired />} />
                    <Route path="license-not-started" element={<LicenseNotStarted />} />
                    <Route path="intelligence-old" element={<SearchWrapper />} />
                    <Route path="intelligence" element={<SearchWrapper />} />
                    <Route path="profiles-old" element={<SearchWrapper />} />
                    <Route path="profiles" element={<SearchWrapper />} />
                    <Route path="profiles/ban" element={<BannedLinks />} />
                    <Route path="profiles/create" element={<ProfileCreate />} />
                    <Route path="profiles/:profileId" element={<Profile />} />
                    <Route path="profiles/:profileId/history" element={<MatchingHistory />} />
                    <Route path="profiles/:profileId/edit" element={<Merging />} />
                    <Route path="profiles/:profileId/editing" element={<ProfileEditing />} />
                    <Route path="profiles/:pk/links/:hash" element={<ProxyLink />} />
                    {/* TODO: next Route is a fallback for old proxy links URLs. remove it after 01.05.2024 */}
                    <Route path="l/:pk/:hash" element={<ProxyLink />} />
                    <Route
                      path="profiles/:profileId/rt/:companyId"
                      element={<ProfileRealtimeDiff />}
                    />
                    <Route path="folders/statuses" element={<FolderSettings />} />
                    <Route
                      path="folders/*"
                      element={
                        <Folders>
                          <Routes>
                            <Route
                              path="new"
                              element={isHM ? <Navigate replace to="/folders" /> : <FolderEdit />}
                            />
                            <Route
                              path=":folderId/*"
                              element={
                                <Routes>
                                  <Route
                                    path="edit"
                                    element={
                                      isHM ? (
                                        <Navigate replace to="/folders" />
                                      ) : (
                                        <FoldersDataFetcher>
                                          <FolderEdit />
                                        </FoldersDataFetcher>
                                      )
                                    }
                                  />
                                  <Route
                                    path="messaging"
                                    element={
                                      isHM ? <Navigate replace to="/folders" /> : <Messaging />
                                    }
                                  />
                                  <Route
                                    index
                                    element={
                                      <FoldersDataFetcher>
                                        <Folder />
                                      </FoldersDataFetcher>
                                    }
                                  />
                                </Routes>
                              }
                            />
                          </Routes>
                        </Folders>
                      }
                    />
                    <Route path="messaging/templates" element={<Templates />} />
                    <Route path="messaging/sent" element={<SentMessages />} />
                    <Route path="messaging/settings" element={<MessagingSettings />} />
                    <Route path="messaging/sequences" element={<SequencesMainPage />} />
                    <Route path="messaging/sequences/new" element={<SequenceForm />} />
                    <Route path="messaging/sequences/:sequenceId/edit" element={<SequenceForm />} />
                    <Route
                      path="messaging/sequences/:sequenceId"
                      element={<SequenceDetailInfo />}
                    />
                    <Route
                      path="messaging/sequences/:sequenceId/profile/:profileId"
                      element={<SequenceProfileView />}
                    />
                    <Route
                      path="telegram-messenger/*"
                      element={
                        <Routes>
                          <Route path="messages" element={<TelegramMessagesPage />} />
                          <Route path="manager" element={<TelegramManagerPage />} />
                        </Routes>
                      }
                    />
                    <Route path="api_management" element={<ManageToken />} />
                    <Route path=":vendor/set" element={<UserSelect />} />
                    <Route path="apps/lever/connect" element={<ATSTokenForm />} />
                    <Route path="apps/greenhouse/connect" element={<ATSTokenForm />} />
                    <Route path="apps/teamtailor/connect" element={<ATSTokenForm />} />
                    <Route path="apps/workable/connect" element={<ATSSubdomainForm />} />
                    <Route
                      path="workable/workable-subdomain-error"
                      element={<ATSSubdomainChecker />}
                    />
                    <Route path="apps_extension" element={<AppsExtension />} />
                    <Route
                      path="apps"
                      element={
                        !size(ReduxStore.getState().app.apps) ? (
                          <Navigate replace to="/" />
                        ) : (
                          <Apps opener="apps" />
                        )
                      }
                    />
                    <Route path="service_orders" element={<ManageOrders />} />
                    <Route path="invitations/accept-invite/:key" element={<ClientUpdate />} />
                    <Route path="data_enrichment" element={<DataEnrichment />} />
                    <Route path="extension" element={<ExtensionCompany />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="active-users" element={<ActiveUsers />} />
                    <Route path="menu" element={<WithoutSearchMenu />} />
                    <Route path="companies/new" element={<Companies />} />
                    <Route
                      path="companies/:companyId/statistics/*"
                      element={<StatisticsWrapper />}
                    />
                    <Route path="company/statistics/*" element={<StatisticsWrapper />} />
                    <Route path="statistics/*" element={<StatisticsWrapper />} />
                    <Route
                      path="companies/:companyId/*"
                      element={
                        <Company>
                          <Routes>
                            <Route index element={<CompanyInfo />} />
                            <Route path="update" element={<EditCompany />} />
                            <Route path="credit-transactions" element={<AddCredits />} />
                            <Route path="custom-settings" element={<CustomSettings />} />
                            <Route path="api-statistics" element={<ApiStats />} />
                            <Route path="employees/add" element={<NewEmployee />} />
                            <Route path="employees/:employeeId" element={<EditEmployee />} />
                            <Route
                              path="employees/:employeeId/move-to-company"
                              element={<MoveToCompany />}
                            />
                            <Route path="employees" element={<Employees />} />
                            <Route
                              path="hiring-managers/settings"
                              element={<HMCompanySettings />}
                            />
                            <Route path="hiring-managers/add" element={<NewEmployee />} />
                            <Route path="hiring-managers/:employeeId" element={<EditEmployee />} />
                            <Route path="hiring-managers" element={<HiringManagers />} />
                          </Routes>
                        </Company>
                      }
                    />
                    <Route path="companies" element={<Companies />} />
                    <Route path="notifications" element={<ExtensionNotifications />} />
                    <Route path="notifications/add" element={<CreateExtensionNotification />} />
                    <Route path="notifications/edit/:id" element={<EditExtensionNotification />} />
                    <Route path="notifications/system" element={<SystemNotifications />} />
                    <Route path="notifications/system/add" element={<CreateSystemNotification />} />
                    <Route
                      path="notifications/system/edit/:id"
                      element={<EditSystemNotification />}
                    />
                    <Route
                      path="company/*"
                      element={
                        <Company>
                          <Routes>
                            <Route index element={<CompanyInfo />} />
                            <Route path="update" element={<EditCompany />} />
                            <Route path="credit-transactions" element={<AddCredits />} />
                            <Route path="employees/add" element={<NewEmployee />} />
                            <Route path="employees/:employeeId" element={<EditEmployee />} />
                            <Route path="employees" element={<Employees />} />
                            <Route
                              path="hiring-managers/settings"
                              element={<HMCompanySettings />}
                            />
                            <Route path="hiring-managers/add" element={<NewEmployee />} />
                            <Route path="hiring-managers/:employeeId" element={<EditEmployee />} />
                            <Route path="hiring-managers" element={<HiringManagers />} />
                            <Route path="sso-info" element={<PresentationSSOSettings />} />
                            <Route path="sso-info/update" element={<EditSSOSettings />} />
                            <Route path="sso-info/add" element={<EditSSOSettings />} />
                          </Routes>
                        </Company>
                      }
                    />
                    <Route
                      path="metaqueries/*"
                      element={
                        <MetaQueries>
                          <Routes>
                            <Route index element={<MetaQueriesInfo />} />
                            <Route path="add" element={<MetaQueryForm />} />
                            <Route path=":metaQueryId" element={<MetaQueryForm />} />
                          </Routes>
                        </MetaQueries>
                      }
                    />
                    <Route path="managers/add" element={<NewManager />} />
                    <Route path="managers/:employeeId" element={<EditManager />} />
                    <Route path="managers" element={<Managers />} />
                    <Route path="limit-exceeded" element={<LimitExceeded />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </Index>
            </BrowserRouter>
          </DndProvider>
        </ErrorBoundary>
      </IntlProvider>
    </CacheProvider>
  );
};

export default App;
