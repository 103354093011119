export const config = {
  jointalent: {
    showDemo: false,
    showSingUp: false,
    formattedName: 'Join Talent',
    showTips: false,
    salesEmail: 'sales@amazinghiring.com',
    csManagerEmail: 'cs@amazinghiring.com',
    noReplyEmail: 'noreply@amazinghiring.com',
    chromeFeedbackEmail: 'chrome-feedback@amazinghiring.com',
  },
  amazinghiring: {
    showDemo: true,
    showSingUp: true,
    formattedName: 'AmazingHiring',
    showTips: false,
    salesEmail: 'sales@amazinghiring.com',
    csManagerEmail: 'cs@amazinghiring.com',
    noReplyEmail: 'noreply@amazinghiring.com',
    chromeFeedbackEmail: 'chrome-feedback@amazinghiring.com',
  },
  matchy: {
    showDemo: true,
    showSingUp: true,
    formattedName: 'Мэтчи',
    showTips: false,
    salesEmail: 'sales@matchy.ru',
    csManagerEmail: 'cs@matchy.ru',
    noReplyEmail: 'noreply@matchy.ru',
    chromeFeedbackEmail: 'chrome-feedback@matchy.ru',
  },
};
const getWhiteLabelName = () => {
  if (window.location.hostname.includes('jointalent')) return 'jointalent';
  if (window.location.hostname.includes('matchy')) return 'matchy';
  return 'amazinghiring';
};

export const whiteLabelName = getWhiteLabelName();
export const whiteLabelFormattedName = config[whiteLabelName].formattedName;
export const whiteLabelSalesEmail = config[whiteLabelName].salesEmail;
export const whiteLabelCSManagerEmail = config[whiteLabelName].csManagerEmail;
export const whiteLabelNoReplyEmail = config[whiteLabelName].noReplyEmail;
export const whiteLabelChromeFeedBackEmail = config[whiteLabelName].chromeFeedbackEmail;
